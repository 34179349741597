import { CardContent, CircularProgress, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import Moment from "react-moment";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../actions";
import { getContract, getContractHistory } from "../actions/Contract";
import FixedPageItem from "../custom/FixedPageItem";
import HeaderPageItem from "../custom/HeaderPageItem";
import Number from "../custom/Number";
import PageContainer, { CardPageItem } from "../custom/Page";
import { Roles } from "../util";

class CustomerContractHistory extends Component {
  state = { contract: {}, history: null };

  loadContractData = (id) => {
    this.setState({ contract: {}, history: null });
    getContract(id)
      .then((data) => {
        this.setState({ contract: data });
        return getContractHistory(id);
      })
      .then((data) => {
        this.setState({ history: data });
      });
  };

  componentDidMount() {
    this.loadContractData(this.props.match.params.id);
  }

  render() {
    const { currentUser } = this.props;
    const { contract, history: contractHistory } = this.state;

    if (!contract.id) {
      return <CircularProgress style={{ marginTop: "25%" }} />;
    } else {
      return (
        <PageContainer>
          <HeaderPageItem
            title={"LCContract C" + contract.id}
            showBackButton
            destination={
              currentUser.role === Roles.Agent && currentUser.id !== contract.user.id
                ? `/userContract/${contract.id}`
                : `/contract/${contract.id}`
            }
          />
          <FixedPageItem
            position="top"
            height={45}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">
              <FormattedMessage id="app.monthlyHistory" />
            </Typography>
          </FixedPageItem>
          {!contractHistory ? (
            <CircularProgress style={{ marginTop: "25%" }} />
          ) : !contractHistory.length ? (
            <Typography>
              <FormattedMessage id="app.contractHistory.empty" />
            </Typography>
          ) : (
            <>
              {contractHistory.map((m, index) => (
                <this.MonthPageItem
                  key={index}
                  name={
                    <Moment
                      date={m.createdAt}
                      format={this.props.currentUser.locale == "br" ? "MMMM [de] YYYY" : "MMMM/YY"}
                    />
                  }
                  balance={m.balance}
                  yieldObj={m.currentYield}
                />
              ))}
            </>
          )}
        </PageContainer>
      );
    }
  }

  MonthPageItem = ({ name, balance, yieldObj }) => (
    <CardPageItem raised>
      <CardContent>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="body1" color="primary">
            {name}
          </Typography>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <Typography variant="caption" color="textSecondary">
              <FormattedMessage id="app.contract.increase" />
            </Typography>
            <Typography>
              <Number value={yieldObj.val} currency="LCT" />
            </Typography>
          </div>

          <div>
            <Typography variant="caption" color="textSecondary">
              %
            </Typography>
            <Typography>
              <Number value={yieldObj.per} display="percent" />
            </Typography>
          </div>

          <div>
            <Typography variant="caption" color="textSecondary">
              <FormattedMessage id="app.camel.balance" />
            </Typography>
            <Typography>
              <Number value={balance} currency="LCT" />
            </Typography>
          </div>
        </div>
      </CardContent>
    </CardPageItem>
  );
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default withRouter(connect(mapStateToProps, actions)(CustomerContractHistory));
