import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, Button, DialogActions } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

export const HelpDialog = ({ open, onClose, title, children }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>
      <FormattedMessage id={title} />
    </DialogTitle>
    <DialogContent>
      <DialogContentText>{children}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Ok
      </Button>
    </DialogActions>
  </Dialog>
);
