import { Button, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import * as actions from "../../actions";
import performRequest from "../../configAxios";
import InputFile from "../../custom/InputFile";
import { Roles } from "../../util";

class FormAttachment extends Component {
  state = {
    fileList: [],
    fileError: true,
    fileErrorMessage: "",
    currentCount: 0,
    totalCount: 0,
  };

  onSubmit = (formProps) => {
    const { onActionComplete, showErrorMessage, showSuccessMessage, user } = this.props;
    let promises = [];
    this.setState({ totalCount: formProps.file.length });
    for (let i = 0; i < formProps.file.length; i++) {
      let file = formProps.file[i];
      var formData = new FormData();
      formData.append("file", file);
      formData.append("description", file.name.replace(/\.[^/.]+$/, ""));
      formData.append("type", "ATTACHMENT");
      promises.push(
        performRequest("POST", `/user/kyc/admin/${user.id}`, formData, true).then((response) => {
          this.setState((prevState) => ({ currentCount: prevState.currentCount + 1 }));
        })
      );
    }
    Promise.all(promises)
      .then(() => {
        showSuccessMessage("app.successfully.added");
        onActionComplete();
      })
      .catch((e) => {
        if (e.response) {
          showErrorMessage(e.response.data.message);
        } else {
          showErrorMessage(e);
        }
      });
  };

  validaFile = (files) => {
    let fileList = [];
    this.setState({ fileError: false, fileErrorMessage: "" });
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        let type = files[i].type;
        let valid = type.startsWith("image/") || type.startsWith("application/pdf");
        if (!valid) {
          this.setState({ fileError: true, fileErrorMessage: "app.invalid.format" });
        } else {
          type = type.split("/")[1];
        }
        fileList.push({ name: files[i].name, size: files[i].size, type, valid });
      }
    }
    this.setState({ fileList });
  };

  render() {
    const { handleSubmit, pristine, submitting, invalid } = this.props;
    const { fileList, fileError, fileErrorMessage, currentCount, totalCount } = this.state;
    //console.log("pristine =", pristine, "submitting =", submitting, "invalid =", invalid, "fileError =", fileError, "fileErrorMessage =", fileErrorMessage);
    return (
      <>
        <form onSubmit={handleSubmit(this.onSubmit)} style={{ display: "flex", justifyContent: "space-between", marginBottom: 16 }}>
          <Field type="file" name="file" label="app.file" color="primary" component={InputFile} onChange={(e) => this.validaFile(e.target.files)} accept=".pdf, .jpg, .png, .jpeg" multiple />
          {totalCount > 0 ? (
            <Typography color="primary" gutterBottom>
              Sent: {currentCount} / {totalCount}
            </Typography>
          ) : fileList.length > 0 ? (
            <Typography color="primary" gutterBottom>
              {fileList.length} files
            </Typography>
          ) : null}
          <Button id="bSubmitForm" variant="contained" color="primary" type="submit" disabled={pristine || submitting || invalid || fileError}>
            <FormattedMessage id="app.send" />
          </Button>
        </form>
        {fileErrorMessage && (
          <Typography gutterBottom color="primary">
            Error: {fileErrorMessage}
          </Typography>
        )}
        {fileList.map((f) => (
          <ValidFileInfo key={f.name} file={f} />
        ))}
      </>
    );
  }
}

function ValidFileInfo({ file, ...props }) {
  let size = (file.size / 1024).toFixed(0);
  return (
    <Typography variant="caption" gutterBottom component="div" style={{ display: "flex", justifyContent: "space-between" }} {...props}>
      {file.valid ? (
        <>
          <div>
            [{file.type}] {file.name}
          </div>
          <div>{size} KB</div>
        </>
      ) : (
        <>
          <div>
            <b>
              [<font color="red">{file.type}</font>] {file.name}
            </b>
          </div>
          <div>{size} KB</div>
        </>
      )}
    </Typography>
  );
}

const validate = (values) => {
  const errors = {};
  if (!values.file) {
    errors.file = "app.upload.error";
  }
  return errors;
};

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default compose(
  reduxForm({
    form: "formAttachment",
    validate,
  }),
  connect(mapStateToProps, actions)
)(FormAttachment);
