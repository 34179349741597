import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { Button, Typography } from "@material-ui/core";
import { CustomInput, CustomSelect } from "../../custom";
import * as actions from "../../actions";
import performRequest from "../../configAxios";
import CloudUpload from "@material-ui/icons/CloudUpload";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { Roles } from "../../util";
import InputFile from "../../custom/InputFile";
import IMG2B from "../../static/verificaçãoCNHError.jpg";
import IMG1B from "../../static/verificaçãoCNHOk.jpg";
import IMG2A from "../../static/verificaçãoDocError.jpg";
import IMG1A from "../../static/verificaçãoDocOk.jpg";
import moment from "moment";

class FormSendSelfie extends Component {
  render() {
    const today = moment();
    return (
      <>
        <FormattedHTMLMessage id="app.documents.idWithPhoto.dialog.1" />
        <br />
        <FormattedHTMLMessage id="app.documents.idWithPhoto.dialog.2" />
        <br />
        <div style={{ marginBottom: 8, marginTop: 32 }}>
          <FormattedHTMLMessage id="app.documents.idWithPhoto.dialog.11" />
        </div>
        <div style={{ display: "flex", marginBottom: 8 }}>
          <div style={{ color: "green", marginRight: 8 }}>✓</div>
          <FormattedHTMLMessage id="app.documents.idWithPhoto.dialog.12" />
        </div>
        <div style={{ display: "flex", marginBottom: 8 }}>
          <div style={{ color: "green", marginRight: 8 }}>✓</div>
          <FormattedHTMLMessage id="app.documents.idWithPhoto.dialog.13" /> {today.format("DD/MM/YYYY")}
        </div>
        <div style={{ alignContent: "center", marginTop: 32 }}>
          <img src={IMG1A} alt={1} height={150} style={{ margin: 8 }} />
          <img src={IMG1B} alt={1} height={150} style={{ margin: 8 }} />
        </div>
        <div style={{ display: "flex", marginBottom: 8 }}>
          <div style={{ color: "green", marginRight: 8 }}>✓</div>
          <FormattedHTMLMessage id="app.documents.idWithPhoto.dialog.3" />
        </div>
        <div style={{ display: "flex", marginBottom: 8 }}>
          <div style={{ color: "green", marginRight: 8 }}>✓</div>
          <FormattedHTMLMessage id="app.documents.idWithPhoto.dialog.4" />
        </div>
        <div style={{ display: "flex", marginBottom: 8 }}>
          <div style={{ color: "green", marginRight: 8 }}>✓</div>
          <FormattedHTMLMessage id="app.documents.idWithPhoto.dialog.5" />
        </div>
        <div style={{ display: "flex", marginBottom: 8 }}>
          <div style={{ color: "green", marginRight: 8 }}>✓</div>
          <FormattedHTMLMessage id="app.documents.idWithPhoto.dialog.6" />
        </div>
        <div style={{ display: "flex", marginBottom: 8 }}>
          <div style={{ color: "green", marginRight: 8 }}>✓</div>
          <FormattedHTMLMessage id="app.documents.idWithPhoto.dialog.7" />
        </div>
        <div style={{ alignContent: "center", marginTop: 32 }}>
          <img src={IMG2A} alt={1} height={150} style={{ margin: 8 }} />
          <img src={IMG2B} alt={1} height={150} style={{ margin: 8 }} />
        </div>
        <div style={{ display: "flex", marginBottom: 8 }}>
          <div style={{ color: "red", marginRight: 8 }}>✘</div>
          <FormattedHTMLMessage id="app.documents.idWithPhoto.dialog.8" />
        </div>
        <div style={{ display: "flex", marginBottom: 8 }}>
          <div style={{ color: "red", marginRight: 8 }}>✘</div>
          <FormattedHTMLMessage id="app.documents.idWithPhoto.dialog.9" />
        </div>
        <div style={{ display: "flex", marginBottom: 8 }}>
          <div style={{ color: "red", marginRight: 8 }}>✘</div>
          <FormattedHTMLMessage id="app.documents.idWithPhoto.dialog.10" />
        </div>

        <Button
          fullWidth
          color="primary"
          variant="contained"
          style={{ marginBottom: 16, marginTop: 32 }}
          onClick={this.props.onActionComplete}
        >
          <FormattedMessage id="app.documents.idWithPhoto.dialog.button" />
        </Button>
      </>
    );
  }
}
export default FormSendSelfie;
