import { getDataFromServer } from "../util";

// Saques
export const getIncomePayments = () => getDataFromServer("GET", "/payment/income", null, true);
export const getRedemptionPayment = (contractId) => getDataFromServer("GET", `/payment/redemption?contractId=${contractId}`, null, true);
export const getAllRedemptionPayment = (payed) => getDataFromServer("GET", `/payment/redemptions?payed=${payed}`, null, true);
export const getInfoPayments = (userId) => getDataFromServer("GET", `/payment/infoPayment?userId=${userId}`, null, true);
//export const getAllRedemptionPayment = payed =>
//getDataFromServer("GET", `/payment/redemptions?payed=${payed}`, null, true);
//export const getInfoPayments = userId => getDataFromServer("GET", `/payment/infoPayment?userId=${userId}`, null, true);
//export const getRedemptionPayment = contractId =>
//getDataFromServer("GET", `/payment/redemption?contractId=${contractId}`, null, true);
export const updatePayment = (id, formProps) => getDataFromServer("PUT", `payment/${id}`, formProps, true);

export const getComissionPaymentsValue = (startDate, finalDate) => getDataFromServer("GET", "/payment/comission?startDate=" + startDate + "&finalDate=" + finalDate, null, true);

export const getProfitPaymentsValue = (startDate, finalDate) => getDataFromServer("GET", "/payment/profit?startDate=" + startDate + "&finalDate=" + finalDate, null, true);

export const getAgentPayments = (agentId, startDate, finalDate) => getDataFromServer("GET", `/payment/agent/${agentId}?startDate=${startDate}&finalDate=${finalDate}`, null, true);
