import React from "react";
import { Avatar } from "@material-ui/core";
import { PHOTO_URL } from "../config";
import avatarDefault from "../static/avatarDefault.png";

export default function AbtcAvatar({ imageUrl, size, style, classes, avatarStyle, hideOnlineStatus, disableLink }) {
  style = {
    position: "relative",
    display: "block",
    textDecoration: "none",
    ...style
  };
  avatarStyle = {
    backgroundColor: "black",
    color: "black",
    fonstWeight: "bold",
    fontSize: "5vh",
    ...avatarStyle
  };
  if (size) {
    style.width = size;
    style.height = size;
    avatarStyle.width = size;
    avatarStyle.height = size;
  }
  const onlineStyle = {
    position: "absolute",
    right: 0,
    bottom: 0,
    width: "30%",
    height: "30%"
  };
  return (
    <div style={style} classes={classes}>
      <InnerAvatar imageUrl={imageUrl} hideOnlineStatus={hideOnlineStatus} avatarStyle={avatarStyle} onlineStyle={onlineStyle} />
    </div>
  );
}

function InnerAvatar({ imageUrl, hideOnlineStatus, avatarStyle, onlineStyle }) {
  return (
    <>
      <Avatar src={imageUrl ? PHOTO_URL + imageUrl : avatarDefault} alt={""} style={avatarStyle}>
        <svg
          viewBox="0 0 20 20"
          style={{
            fontSize: "30%",
            fontWeight: "bold",
            width: "100%",
            height: "100%",
            textTransform: "uppercase"
          }}
        >
          <text x="10" y="10.7" textAnchor="middle" alignmentBaseline="central">
            ok
          </text>
        </svg>
      </Avatar>
    </>
  );
}
