import {
  Button,
  CardContent,
  CircularProgress,
  Collapse,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import NumberFmt from "../custom/Number";
import IconCheck from "@material-ui/icons/Check";
import IconUncheck from "@material-ui/icons/Close";
import IconManager from "@material-ui/icons/Group";
import IconInfo from "@material-ui/icons/Info";
import IconInfoOutlined from "@material-ui/icons/InfoOutlined";
import IconArrowDown from "@material-ui/icons/KeyboardArrowDown";
import IconArrowUp from "@material-ui/icons/KeyboardArrowUp";
import IconLaunch from "@material-ui/icons/Launch";
import IconAgent from "@material-ui/icons/Person";
import IconCustomer from "@material-ui/icons/PersonOutline";
import React, { useEffect, useReducer, useState } from "react";
import { connect } from "react-redux";
import { getUsersBelowMe, getContracts } from "../actions";
import { dispatchErrorMessage } from "../actions/Alert";
import HeaderPageItem from "../custom/HeaderPageItem";
import PageContainer, { CardPageItem, PageItem } from "../custom/Page";
import { isAgent, isAgentManager, isAgentOf, Roles } from "../util";
import Moment from "react-moment";
import { FormattedMessage } from "react-intl";

function ReportHierarchical({ currentUser, dispatch }) {
  const [loading, setLoading] = useState(true);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [managers, setManagers] = useState([]);
  const [agents, setAgents] = useState([]);
  const [customers, setCustomers] = useState([]);
  const classes = useStyles();

  function isAgentManagerBelow(manager, user) {
    return isAgentManager(manager) && isAgentOf(user, manager);
  }

  function isAgentManagerBelowId(manager, id) {
    return isAgentManager(manager) && manager.consultant.id === id;
  }

  function isAgentBelow(agent, user) {
    return isAgent(agent) && isAgentOf(user, agent);
  }

  function isCustomerBelow(customer, user) {
    return customer.role !== Roles.Agent && isAgentOf(user, customer);
  }

  function SimplifyUserClone(u) {
    let consultant = null;
    if (u.consultant) {
      consultant = SimplifyUserClone(u.consultant);
    }
    let contractAgent = null;
    if (u.contractAgent) {
      contractAgent = { id: u.contractAgent.id, type: u.contractAgent.type, status: u.contractAgent.status };
    }
    return {
      id: u.id,
      name: u.name,
      role: u.role,
      email: u.email,
      apt: u.apt,
      contractAgent,
      consultant,
    };
  }

  function SimplifyUser(u) {
    if (u.consultant) {
      u.consultant = SimplifyUserClone(u.consultant);
    }
    if (u.contractAgent) {
      u.contractAgent = { id: u.contractAgent.id, type: u.contractAgent.type, status: u.contractAgent.status };
    }
    for (const p in u) {
      if (!["id", "name", "role", "email", "apt", "consultant", "contractAgent"].includes(p)) {
        delete u[p];
      }
    }
    return u;
  }

  function AddUIData(u) {
    u.ui = {
      isSelected: false,
      isInfoOpen: false,
      isListOpen: false,
    };
    return u;
  }

  function filterAndFillBelow(allUsers, allContracts, filterFunc) {
    const users = allUsers.filter(filterFunc);
    users.forEach((m) => {
      SimplifyUser(m);
      AddUIData(m);
      fillBellow(m, allUsers, allContracts);
    });
    return users;
  }

  function fillBellow(user, allUsers, allContracts) {
    user.managers = filterAndFillBelow(allUsers, allContracts, (u) => isAgentManagerBelow(u, user));
    user.agents = filterAndFillBelow(allUsers, allContracts, (u) => isAgentBelow(u, user));
    user.customers = filterAndFillBelow(allUsers, allContracts, (u) => isCustomerBelow(u, user));
    user.contracts = allContracts
      .filter((c) => c.user.id === user.id)
      .map((c) => ({
        id: c.id,
        userId: c.user.id,
        amount: c.amount,
        investmentId: c.segment.id,
        status: c.status,
        phase: c.phase,
        executing: c.executing,
        initialDate: c.initialDate,
        dueDate: c.dueDate,
        type: c.type,
        createdAt: c.createdAt,
        updatedAt: c.updatedAt,
        archiveDate: c.archiveDate,
        statusRedeemPaid: c.statusRedeemPaid,
        birthday: c.birthday,
        canceledBy: c.canceledBy,
        deleted: c.deleted,
        previsionAmount: c.previsionAmount,
        splitAgent: c.splitAgent,
        splitPercent: c.splitPercent,
        yield: c.yield,
      }));
  }

  function compareContracts(c1, c2) {
    const cpStatus = c1.status - c2.status;
    const cpId = c2.id - c1.id;
    if (cpStatus !== 0) {
      return cpStatus;
    } else return cpId;
  }

  async function getReport() {
    try {
      console.time("loading");
      setLoading(true);
      const isAdmin = [Roles.Admin, Roles.FinanceManager, Roles.Kyc].includes(currentUser.role);
      const contractsVisibleToMe = await getContracts();
      contractsVisibleToMe.sort((c1, c2) => compareContracts);
      const usersVisibleToMe = await getUsersBelowMe();
      usersVisibleToMe.sort((u1, u2) => u1.name.localeCompare(u2.name));
      const topId = isAdmin ? 1 : currentUser.id;
      setManagers(filterAndFillBelow(usersVisibleToMe, contractsVisibleToMe, (u) => !u.ui && isAgentManagerBelowId(u, topId)));
      setAgents(filterAndFillBelow(usersVisibleToMe, contractsVisibleToMe, (u) => !u.ui && isAgent(u)));
      if (!isAdmin) {
        setCustomers(filterAndFillBelow(usersVisibleToMe, contractsVisibleToMe, (u) => !u.ui && u.role !== Roles.Agent));
      }
      setLoading(false);
      console.timeEnd("loading");
    } catch (e) {
      dispatchErrorMessage(e.message, dispatch);
      setLoading(false);
    }
  }

  function effect() {
    getReport();
  }

  useEffect(effect, []);

  function setAllLists_(user, open) {
    user.ui.isListOpen = open && (user.managers.length > 0 || user.agents.length > 0 || user.customers.length > 0);
    user.managers.forEach((manager) => setAllLists_(manager, open));
    user.agents.forEach((agent) => setAllLists_(agent, open));
    user.customers.forEach((customer) => setAllLists_(customer, open));
  }

  function setAllLists(open) {
    managers.forEach((manager) => setAllLists_(manager, open));
    forceUpdate();
  }

  if (loading) return <CircularProgress style={{ marginTop: "25%" }} />;
  return (
    <PageContainer full>
      <HeaderPageItem title="app.report.hierarchical" showBackButton destination="/reports" />
      <CardPageItem raised>
        <CardContent>
          <Button variant="outlined" size="small" onClick={(e) => setAllLists(true)}>
            Abrir todos
          </Button>{" "}
          <Button variant="outlined" size="small" onClick={(e) => setAllLists(false)}>
            Fechar todos
          </Button>
        </CardContent>
      </CardPageItem>
      <PageItem>
        {managers.length > 0 && (
          <>
            <Typography variant="caption" classes={{ root: classes.listTitle }}>
              Gestores
            </Typography>
            <List>
              {managers.map((manager) => (
                <UserListItem key={manager.id} user={manager} classes={classes} />
              ))}
            </List>
          </>
        )}
        {agents.length > 0 && (
          <>
            <Typography variant="caption" classes={{ root: classes.listTitle }}>
              Agentes
            </Typography>
            <List>
              {agents.map((agent) => (
                <UserListItem key={agent.id} user={agent} classes={classes} />
              ))}
            </List>
          </>
        )}
        {customers.length > 0 && (
          <>
            <Typography variant="caption" classes={{ root: classes.listTitle }}>
              Clientes
            </Typography>
            <List>
              {customers.map((customer) => (
                <UserListItem key={customer.id} user={customer} classes={classes} />
              ))}
            </List>
          </>
        )}
      </PageItem>
    </PageContainer>
  );
}

function UserListItem({ user, classes, lines, bgIntensity = 50 }) {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [openInfo, setOpenInfo] = useState(false);
  const hasManagers = user.managers.length > 0;
  const hasAgents = user.agents.length > 0;
  const hasCustomers = user.customers.length > 0;
  const aptColor = { true: "green", false: "red" };
  const contractCount = user.contracts.length;
  const activeContractCount = user.contracts.filter((c) => c.status !== "ARCHIVE").length;
  bgIntensity -= 10;
  if (bgIntensity < 0) bgIntensity = 0;

  return (
    <>
      {lines && <hr style={{ border: "1px solid #333", padding: 0, margin: 0 }} />}
      <div
        style={{
          borderLeft: "1px solid #333",
          backgroundColor: user.ui.isListOpen ? `rgb(${bgIntensity},${bgIntensity},${bgIntensity})` : "unset",
        }}
      >
        <ListItem
          button={hasManagers || hasAgents || hasCustomers}
          onClick={() => {
            if (hasManagers || hasAgents || hasCustomers) {
              user.ui.isListOpen = !user.ui.isListOpen;
              forceUpdate();
            }
          }}
        >
          <ListItemIcon>
            <div>
              {isAgentManager(user) ? (
                <IconManager classes={{ root: classes.iconManager }} />
              ) : isAgent(user) ? (
                <IconAgent classes={{ root: classes.iconAgent }} />
              ) : (
                <IconCustomer classes={{ root: classes.iconCustomer }} />
              )}
              {user.role === Roles.Agent &&
                (user.contractAgent?.status === "ACTIVE" ? (
                  <IconCheck style={{ fontSize: "100%", color: "lime", marginLeft: -6, marginTop: -2, position: "absolute" }} />
                ) : (
                  <IconUncheck style={{ fontSize: "100%", color: "red", marginLeft: -6, marginTop: -2, position: "absolute" }} />
                ))}
            </div>
          </ListItemIcon>
          <ListItemText
            primary={
              <>
                {user.name} <small style={{ color: "#999" }}>({user.id})</small>{" "}
                <Typography variant="caption" classes={{ root: classes.contractCount }}>
                  <b>{contractCount === 0 ? "" : `Contratos: ${activeContractCount} / ${contractCount}`}</b>
                </Typography>
              </>
            }
          />
          <ListItemSecondaryAction>
            {(hasManagers || hasAgents || hasCustomers) && (
              <IconButton
                size="small"
                onClick={() => {
                  user.ui.isListOpen = !user.ui.isListOpen;
                  forceUpdate();
                }}
              >
                {user.ui.isListOpen ? <IconArrowUp /> : <IconArrowDown />}
              </IconButton>
            )}
            <IconButton
              size="small"
              onClick={() => {
                setOpenInfo(!openInfo);
              }}
            >
              {openInfo ? <IconInfo style={{ color: aptColor[user.apt] }} /> : <IconInfoOutlined style={{ color: aptColor[user.apt] }} />}
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <Collapse in={openInfo} timeout="auto" mountOnEnter unmountOnExit>
          <div
            style={{
              display: "flex",
              paddingLeft: 75,
              paddingTop: 0,
              paddingBottom: 8,
              paddingRight: 16,
              justifyContent: "space-between",
              color: "#999",
            }}
          >
            <div>
              <Typography>{user.email}</Typography>
              <Typography>
                {isAgentManager(user) ? "AGENT MANAGER" : user.role} - <small style={{ color: aptColor[user.apt] }}>{user.apt ? "apto" : "inapto"}</small>
                {user.role === Roles.Agent && (
                  <>
                    {" "}
                    - <small style={{ color: aptColor[user.contractAgent?.status === "ACTIVE"] }}>agreement: {user.contractAgent?.status}</small>
                  </>
                )}
              </Typography>
              {user.contracts.length > 0 && (
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ color: "#999" }}>id</TableCell>
                        <TableCell align="right" style={{ color: "#999" }}>
                          <small>valor</small>
                        </TableCell>
                        <TableCell align="center" style={{ color: "#999" }}>
                          <small>início</small>
                        </TableCell>
                        <TableCell align="center" style={{ color: "#999" }}>
                          <small>final</small>
                        </TableCell>
                        <TableCell align="center" style={{ color: "#999" }}>
                          <small>estado</small>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {user.contracts.map((c) => (
                        <TableRow key={c.id}>
                          <TableCell>
                            <Link href={"/contract/" + c.id} target="_blank" style={{ color: c.status !== "ARCHIVE" ? "#EEE" : "#999" }}>
                              C{c.id}
                            </Link>
                          </TableCell>
                          <TableCell align="right" style={{ color: c.status !== "ARCHIVE" ? "#EEE" : "#999" }}>
                            <NumberFmt value={c.amount} />
                          </TableCell>
                          <TableCell align="center" style={{ color: c.status !== "ARCHIVE" ? "#EEE" : "#999" }}>
                            <Moment date={c.initialDate} />
                          </TableCell>
                          <TableCell align="center" style={{ color: c.status !== "ARCHIVE" ? "#EEE" : "#999" }}>
                            <Moment date={c.dueDate} />
                          </TableCell>
                          <TableCell align="center" style={{ color: c.status !== "ARCHIVE" ? "#EEE" : "#999" }}>
                            <FormattedMessage id={`app.enuns.${c.phase}`} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </div>
            <div>
              <IconButton size="small" href={"/user/" + user.id} target="_blank">
                <IconLaunch />
              </IconButton>
            </div>
          </div>
        </Collapse>
        <Collapse in={user.ui.isListOpen} timeout="auto" mountOnEnter unmountOnExit style={{ marginLeft: "5%" }}>
          {hasManagers && (
            <>
              <Typography variant="caption" classes={{ root: classes.listTitle }}>
                Gestores
              </Typography>
              <List>
                {user.managers.map((manager) => (
                  <UserListItem key={manager.id} user={manager} classes={classes} bgIntensity={bgIntensity} />
                ))}
              </List>
            </>
          )}
          {hasAgents && (
            <>
              <Typography variant="caption" classes={{ root: classes.listTitle }}>
                Agentes
              </Typography>
              <List>
                {user.agents.map((agent) => (
                  <UserListItem key={agent.id} user={agent} classes={classes} bgIntensity={bgIntensity} />
                ))}
              </List>
            </>
          )}
          {hasCustomers && (
            <>
              <Typography variant="caption" classes={{ root: classes.listTitle }}>
                Clientes
              </Typography>
              <List>
                {user.customers.map((customer) => (
                  <UserListItem key={customer.id} user={customer} classes={classes} bgIntensity={bgIntensity} />
                ))}
              </List>
            </>
          )}
        </Collapse>
      </div>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  mainHeaderRow: {
    "& > th": {
      //fontWeight: "bold",
      borderBottom: "3px solid #333",
      color: "#AAA",
    },
  },
  mainRow: {
    "& > *": {
      borderBottom: "unset",
    },
    "& > td:first-child": {
      width: 10,
      paddingLeft: 10,
      paddingRight: 0,
    },
  },
  detailBox: {
    backgroundColor: "#151515",
    paddingLeft: 50,
  },
  detailHeaderRow: {
    "& > th": {
      //fontWeight: "bold",
      borderBottom: "3px solid #222",
      color: "#AAA",
    },
  },
  detailRow: {
    "& > td": { borderBottom: "1px solid #222" },
  },
  iconManager: {
    color: theme.palette.primary.main,
  },
  iconAgent: {
    color: "white",
  },
  iconCustomer: {
    color: "#666",
  },
  listTitle: {
    color: "#999",
    paddingLeft: 16,
  },
  contractCount: {
    color: theme.palette.primary.main,
  },
}));

export default connect((state) => ({ currentUser: state.user.user.me }))(ReportHierarchical);
