import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Number from "./Number";
import InfoItem from "./InfoItem";

class ValueInfoItem extends Component {
  static propTypes = {
    caption: PropTypes.any,
    extraCaption: PropTypes.any,
    value: PropTypes.number,
    percent: PropTypes.number,
    color: PropTypes.string,
    key: PropTypes.any,
    currency: PropTypes.string
  };

  render() {
    const { percent, value, currency, ...rest } = this.props;
    return (
      <InfoItem
        text={percent !== undefined ? <Number value={percent} display="percent" /> : ""}
        extraText={value !== undefined && <Number value={value} currency={currency} />}
        {...rest}
      />
    );
  }
}
export default withRouter(ValueInfoItem);

export const YieldInfoItem = ({ yieldObj, ...rest }) => <ValueInfoItem value={yieldObj.val} percent={yieldObj.per} {...rest} />;
