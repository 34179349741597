import { Button, CardContent } from "@material-ui/core";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import * as actions from "../../actions";
import { editOperation, infoOperation } from "../../actions";
import CustomInputForm, { CustomMonthPicker } from "../../custom/CustomInput";

// VERIFICADO LCTInputMask - precisou chamar o getConfig.

class FormMonthlyOperation extends Component {
  onSubmit = (formProps) => {
    const { showSuccessMessage, showErrorMessage, onActionComplete } = this.props;
    if (formProps.id) {
      editOperation(formProps.id, formProps)
        .then((response) => {
          showSuccessMessage("app.edit.sucesfully");
          onActionComplete();
        })
        .catch((e) => {
          showErrorMessage(e.response ? e.response.data.message : e);
        });
    } else {
      infoOperation(formProps)
        .then((response) => {
          showSuccessMessage("app.create");
          onActionComplete();
        })
        .catch((e) => {
          showErrorMessage(e.response ? e.response.data.message : e);
        });
    }
  };

  componentDidMount() {
    this.props.getConfig();
    this.props.initialize(this.props.selected);
  }

  render() {
    const { handleSubmit, config } = this.props;
    if (!config.LCTInputMask) return null;
    const lctMask = config.LCTInputMask();
    return (
      <CardContent>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <Field
            name="capacity"
            label="app.company.monthly.ceiling"
            InputLabelProps={{
              shrink: true,
            }}
            component={CustomInputForm}
            {...lctMask}
          />
          <Field
            name="operationDate"
            label="app.date"
            InputLabelProps={{
              shrink: true,
            }}
            component={CustomMonthPicker}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            style={{ marginTop: 16 }}
            disabled={this.props.pristine || this.props.submitting || this.props.invalid}
          >
            <FormattedMessage id="app.button.save" />
          </Button>
        </form>
      </CardContent>
    );
  }
}

const validate = (values, props) => {
  const errors = {};
  if (!values.capacity) {
    errors.capacity = "app.value.report";
  }
  if (!values.operationDate) {
    errors.operationDate = "app.date.inform";
  }
  return errors;
};

function mapStateToProps(state) {
  return {
    config: state.config,
  };
}

export default compose(
  reduxForm({
    form: "formMonthlyOperation",
    validate,
  }),
  connect(mapStateToProps, actions)
)(FormMonthlyOperation);
