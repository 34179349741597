import { Button, CardContent, CircularProgress } from "@material-ui/core";
import React, { Component } from "react";
import Moment from "react-moment";
import { connect } from "react-redux";
import * as actions from "../actions";
import { getUser2 } from "../actions";
import { getKyc, updateKyc } from "../actions/Kyc";
import FormDialog from "../custom/FormDialog";
import HeaderPageItem from "../custom/HeaderPageItem";
import InfoItem from "../custom/InfoItem";
import PageContainer, { CardPageItem } from "../custom/Page";
import FormAddress from "./components/FormAddress";
import FormDocId from "./components/FormDocId";
import FormDocumentReject from "./components/FormDocumentReject";
import FormDocumentInvalid from "./components/FormDocumentInvalid";
import FormSocialContract from "./components/FormSocialContract";
import axios from "axios";
import cookie from "react-cookies";
import { API_URL } from "../config";
import { FormattedMessage } from "react-intl";
import { Enuns } from "../util";

class AdminKyc extends Component {
	state = {
		kyc: {},
		loading: true,
		dialog: false,
		user: {},
		title: "",
		reject: false,
		invalid: false,
	};

	componentDidMount() {
		getKyc(this.props.match.params.id)
			.then((data) => {
				getUser2(data.user.id).then((user) => {
					this.setState({ kyc: data, loading: false, user });
				});
			})
			.catch((e) => {
				this.props.history.push("/404");
			});
	}

	downloadDocument = (e) => {
		const { kyc } = this.state;
		axios({
			url: `${API_URL}/user/kyc/${kyc.id}/download`,
			method: "GET",
			headers: { Authorization: `Bearer ${cookie.load("LCAuthToken")}` },
			responseType: "blob", // important
		}).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", kyc.fileUrl);
			document.body.appendChild(link);
			link.click();
		});
		if (kyc.status == "PENDING")
			updateKyc(this.state.kyc.id, { status: "ANALYZING" }).then((data) => this.setState({ kyc: data }));
	};

	accept = () => {
		updateKyc(this.state.kyc.id, { status: "APPROVED" }).then((data) => {
			this.setState({ kyc: data, dialog: false });
			this.props.history.push("/user/" + this.state.user.id);
		});
	};

	onApprove = (id) => {
		const { onActionComplete, showErrorMessage, showSuccessMessage } = this.props;
		updateKyc(id, { status: "APPROVED" })
			.then((response) => {
				this.setState({ kyc: response });
				showSuccessMessage("app.document.approved");
			})
			.catch((e) => {
				if (e.response) {
					showErrorMessage(e.response.data.message);
				} else {
					showErrorMessage(e);
				}
			});
	};
	render() {
		const { kyc, loading, title, user } = this.state;
		if (loading)
			return (
				<CardPageItem>
					<CircularProgress style={{ marginTop: "25%" }} />
				</CardPageItem>
			);
		return (
			<PageContainer>
				<HeaderPageItem
					title="app.document.title"
					showBackButton
					destination={"/user/" + kyc.user.id}
					values={kyc.id}
				/>

				<CardPageItem>
					<CardContent>
						<>
							<InfoItem caption={<FormattedMessage id="app.user" />} text={kyc.user.name} />
							<InfoItem
								caption={<FormattedMessage id="app.type" />}
								text={<FormattedMessage id={`app.enuns.${kyc.documentType}`} />}
							/>
							{kyc.description && (
								<InfoItem caption={<FormattedMessage id="app.description" />} text={kyc.description} />
							)}
							<InfoItem caption={"Status"} text={<FormattedMessage id={`app.enuns.${kyc.status}`} />} />
							<InfoItem
								caption={<FormattedMessage id="app.createDate" />}
								text={<Moment date={kyc.createdAt} format="DD/MM/YYYY" />}
							/>
							<InfoItem
								caption={<FormattedMessage id="app.statusDate" />}
								text={<Moment date={kyc.statusDate} format="DD/MM/YYYY" />}
							/>
							{kyc.reason && (
								<InfoItem caption={<FormattedMessage id="app.reason" />} text={kyc.reason} />
							)}

							{kyc.fileUrl && (
								<Button
									fullWidth
									variant="outlined"
									onClick={this.downloadDocument}
									style={{ marginTop: 16 }}
								>
									<FormattedMessage id="app.downloadDocument" />
								</Button>
							)}
							{kyc.status == "ANALYZING" ? (
								<>
									<Button
										fullWidth
										variant="outlined"
										onClick={
											kyc.documentType == "ID" ||
											kyc.documentType == "SOCIAL_CONTRACT" ||
											kyc.documentType == "ADDRESS"
												? () =>
														this.setState({
															dialog: true,
															title: kyc.documentType,
														})
												: this.accept
										}
										style={{ marginTop: 16 }}
									>
										<FormattedMessage id="app.accept" />
									</Button>
									<Button
										fullWidth
										variant="outlined"
										onClick={() => this.setState({ reject: true })}
										style={{ marginTop: 16 }}
									>
										<FormattedMessage id="app.reject" />
									</Button>
								</>
							) : kyc.status == "APPROVED" ? (
								<>
									<Button
										fullWidth
										variant="outlined"
										onClick={() => this.setState({ invalid: true })}
										style={{ marginTop: 16 }}
									>
										<FormattedMessage id="app.invalidate" />
									</Button>
								</>
							) : kyc.status == "INVALID" ? (
								<>
									<Button
										fullWidth
										variant="outlined"
										onClick={() => this.onApprove(kyc.id)}
										style={{ marginTop: 16 }}
									>
										<FormattedMessage id="app.toApprove" />
									</Button>
								</>
							) : null}
						</>
						<FormDialog
							title={`app.enuns.${title}`}
							open={this.state.dialog}
							variant="secondary"
							noHeader
							onClose={(e) => this.setState({ dialog: false })}
						>
							{kyc.documentType == "ADDRESS" && (
								<FormAddress onActionComplete={this.accept} user={user} />
							)}
							{kyc.documentType == "ID" && <FormDocId onActionComplete={this.accept} user={user} />}
							{kyc.documentType == "SOCIAL_CONTRACT" && (
								<FormSocialContract onActionComplete={this.accept} user={user} />
							)}
						</FormDialog>
						<FormDialog
							title="app.reason.rejection"
							open={this.state.reject}
							variant="secondary"
							noHeader
							onClose={(e) => this.setState({ reject: false })}
						>
							<FormDocumentReject
								onActionComplete={(kyc) => this.setState({ reject: false, kyc })}
								id={kyc.id}
							/>
						</FormDialog>
						<FormDialog
							title="app.reason.invalidation"
							open={this.state.invalid}
							variant="secondary"
							noHeader
							onClose={(e) => this.setState({ invalid: false })}
						>
							<FormDocumentInvalid
								onActionComplete={(kyc) => this.setState({ invalid: false, kyc })}
								id={kyc.id}
							/>
						</FormDialog>
					</CardContent>
				</CardPageItem>
			</PageContainer>
		);
	}
}

function mapStateToProps(state) {
	return {
		currentUser: state.user.user.me,
	};
}

export default connect(mapStateToProps, actions)(AdminKyc);
