import {
  CardContent,
  CircularProgress,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  withTheme,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import * as moment from "moment";
import "moment/locale/es";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import Moment from "react-moment";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import * as actions from "../actions";
import { getContract, getContractMonthHistory } from "../actions/Contract";
import FixedPageItem from "../custom/FixedPageItem";
import HeaderPageItem from "../custom/HeaderPageItem";
import InfoItem from "../custom/InfoItem";
import Number, {
  getLCTDisplayMode,
  LCTDisplayModes,
  setLCTConvertedCurrency,
  setLCTDisplayMode,
  setLCTRate,
} from "../custom/Number";
import PageContainer, { CardPageItem } from "../custom/Page";
import { YieldInfoItem } from "../custom/ValueInfoItem";
import ContractChart from "./components/ContractChart";
import { Roles } from "../util";
import * as momentTimezone from "moment-timezone";

class CustomerActiveContract extends Component {
  state = {
    contract: {},
    month: {},
    showCardInfo: false,
    menuAnchor: undefined,
    chartShowPer: false,
    chartShowBalance: true,
    dateUpdate: momentTimezone(),
    loading: true,
  };

  currentMonth = moment();
  terminated = this.currentMonth.isAfter(moment(this.state.contract.dueDate).startOf("month"));

  loadContractData = (id, month) => {
    this.setState({ month: {}, loading: true });
    getContract(id)
      .then((contract) => {
        this.setState({ contract: contract });
        return getContractMonthHistory(id, month.month() + 1, month.year());
      })
      .then((data) => {
        let dateUpdate = momentTimezone("22:00:00", "HH:mm:ss");
        dateUpdate.tz(this.props.currentUser.timezone);
        this.currentMonth = month;
        data.daysInTotal = this.currentMonth.daysInMonth();
        this.generateChartData(data);
        this.setState({ dateUpdate: dateUpdate, loading: false });
      });
  };

  componentDidMount() {
    this.loadContractData(this.props.match.params.id, this.currentMonth);
  }

  componentWillUnmount() {
    setLCTDisplayMode(LCTDisplayModes.Original);
  }

  nextMonth() {
    this.loadContractData(this.props.match.params.id, this.currentMonth.add(1, "M"));
  }

  previousMonth() {
    this.loadContractData(this.props.match.params.id, this.currentMonth.subtract(1, "M"));
  }

  hasNext() {
    if (this.state.contract.status == "ACTIVE") {
      return this.currentMonth.isBefore(moment().startOf("month"));
    } else if (this.terminated) {
      this.setState({ showCardInfo: true });
      return false;
    }
  }

  hasPrevious() {
    return this.currentMonth.isAfter(moment(this.state.contract.createdAt).endOf("month"));
  }

  generateChartData = (data) => {
    const { contract } = this.state;
    let chartData = [];

    if (data.history && data.history.length > 0) {
      let historyFirst = moment(data.history[0].createdAt).date();
      let ok = false;
      const dailyAvg = data.estimated.val / (1 + data.daysInTotal - historyFirst);
      for (let d = 0; d < data.daysInTotal; d++) {
        const historyIndex = d - historyFirst + 1;
        ok = historyIndex >= 0;
        if (ok) {
          const estimated = dailyAvg * (historyIndex + 1);
          chartData.push({
            day: d + 1,
            estim: data.isCurrent ? contract.yield.val - data.total.val + estimated : null,
            yield_per:
              historyIndex >= 0 && historyIndex < data.history.length ? data.history[historyIndex].yield.per : null,
            balance:
              historyIndex >= 0 && historyIndex < data.history.length ? data.history[historyIndex].balance : null,
          });
        }
      }
    }
    this.setState({ chartData, month: data });
  };

  render() {
    const {
      currentUser,
      theme: { palette },
    } = this.props;
    const { loading, contract, month, chartData } = this.state;
    if (loading) {
      return <CircularProgress style={{ marginTop: "25%" }} />;
    } else {
      return (
        <PageContainer>
          <HeaderPageItem
            title={"app.lccontract"}
            showBackButton
            destination={
              currentUser.role == Roles.Admin || currentUser.role == Roles.FinanceManager
                ? "/userContract/" + contract.id
                : currentUser.id !== contract.user.id
                ? "/customer/" + contract.user.id
                : "/contracts"
            }
            onMore={(e) => this.setState({ menuAnchor: e.target })}
            values={contract.id}
          >
            <Menu
              onClose={(e) => this.setState({ menuAnchor: undefined })}
              open={!!this.state.menuAnchor}
              anchorEl={this.state.menuAnchor}
            >
              <MenuItem
                disabled={currentUser.role == Roles.Admin || currentUser.role == Roles.FinanceManager}
                component={Link}
                to={"/contractDetail/" + contract.id}
                onClick={(e) => this.setState({ menuAnchor: undefined })}
              >
                <FormattedMessage id="app.contractDetails" />
              </MenuItem>
              <MenuItem
                component={Link}
                to={"/contractHistory/" + contract.id}
                onClick={(e) => this.setState({ menuAnchor: undefined })}
              >
                <FormattedMessage id="app.monthlyHistory" />
              </MenuItem>
            </Menu>
          </HeaderPageItem>

          <FixedPageItem position="top" height={45}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <IconButton onClick={(e) => this.previousMonth()} disabled={!this.hasPrevious()}>
                <ChevronLeftIcon />
              </IconButton>

              <Typography variant="h6">{this.currentMonth.locale(currentUser.locale).format("MMMM YYYY")}</Typography>

              <IconButton onClick={(e) => this.nextMonth()} disabled={!this.hasNext()}>
                <ChevronRightIcon />
              </IconButton>
            </div>
          </FixedPageItem>

          <CardPageItem>
            <CardContent style={{ paddingBottom: 0 }}>
              {!month.month ? (
                <></>
              ) : (
                <>
                  {month.isCurrent ? (
                    <>
                      <InfoItem
                        variant="h5"
                        color="primary"
                        caption={
                          <FormattedMessage
                            id="app.hourOfDailyIncome"
                            values={{ time: this.state.dateUpdate.format("HH:mm:ss").toString() }}
                          />
                        }
                        text={
                          month.today.per != 0 ? (
                            <Number value={month.today.per} display="percent" />
                          ) : (
                            <Typography style={{ color: "white" }}>
                              <FormattedMessage
                                id="app.updateTime"
                                values={{ time: this.state.dateUpdate.format("HH:mm:ss").toString() }}
                              />
                            </Typography>
                          )
                        }
                        extraText={month.today.val ? <Number value={month.today.val} currency="LCT" /> : ""}
                      />
                      <YieldInfoItem
                        variant="h5"
                        caption={<FormattedMessage id="app.monthlyYield" values={{ days: month.history.length }} />}
                        yieldObj={month.total}
                        currency="LCT"
                      />
                      <YieldInfoItem
                        variant="h5"
                        color="textSecondary"
                        caption={
                          <FormattedMessage id="app.estimatedMonthYieldWithDays" values={{ days: month.daysInTotal }} />
                        }
                        yieldObj={month.estimated}
                        currency="LCT"
                      />
                    </>
                  ) : (
                    <>
                      <YieldInfoItem
                        variant="h5"
                        color="primary"
                        caption={
                          <FormattedMessage id="app.monthYieldWithDays" values={{ days: month.history.length }} />
                        }
                        yieldObj={month.total}
                        currency="LCT"
                      />
                      <YieldInfoItem
                        variant="h5"
                        caption={<FormattedMessage id="app.avgYield" />}
                        yieldObj={month.dailyAvg}
                        currency="LCT"
                      />
                    </>
                  )}
                </>
              )}
            </CardContent>
          </CardPageItem>

          <CardPageItem style={{ textAlign: "center" }}>
            {!month.history ? (
              <CircularProgress style={{ marginTop: 50 }} />
            ) : month.history.length == 0 ? (
              <Typography color="textSecondary" style={{ textAlign: "center" }}>
                <FormattedMessage id="app.noDataGraphic" />
              </Typography>
            ) : (
              <ContractChart
                chartData={chartData}
                contract={contract}
                currentMonth={this.currentMonth}
                height={200}
                marginBotton={0}
                fullScreen
              />
            )}
          </CardPageItem>

          <CardPageItem>
            {!month.history ? (
              <></>
            ) : (
              <>
                <CardContent>
                  <Divider style={{ marginBottom: 16 }} />
                  <Typography gutterBottom variant="h6">
                    <FormattedMessage id="app.monthDetails" />
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td align="left">
                            <FormattedMessage id="app.day" />
                          </td>
                          <td align="right">
                            <FormattedMessage id="app.contract.increase" />
                          </td>
                          <td align="center">%</td>
                          <td align="right">
                            <FormattedMessage id="app.camel.balance" />
                          </td>
                        </tr>
                        {month.history
                          .slice()
                          .reverse()
                          .map((d) => (
                            <tr
                              key={d.createdAt}
                              style={{
                                color: moment().isSame(d.createdAt, "day") ? palette.primary.main : "inherit",
                              }}
                            >
                              <td
                                align="left"
                                style={{
                                  color: moment().isSame(d.createdAt, "day")
                                    ? palette.primary.main
                                    : palette.text.primary,
                                }}
                              >
                                <Moment date={d.createdAt} format="DD" />
                              </td>
                              <td align="right">
                                <Number value={d.yield.val} currency="LCT" />
                              </td>
                              <td
                                align="center"
                                style={{
                                  color: moment().isSame(d.createdAt, "day")
                                    ? palette.primary.main
                                    : palette.text.primary,
                                }}
                              >
                                <Number value={d.yield.per} display="percent" />
                              </td>
                              <td align="right">
                                <Number value={d.balance} currency="LCT" />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </Typography>
                </CardContent>
              </>
            )}
          </CardPageItem>

          <FixedPageItem isCard position="bottom" height={90}>
            <CardContent>
              <InfoItem
                variant="h5"
                caption={<FormattedMessage id="app.dashboard.total.title" />}
                extraCaption={<FormattedMessage id="app.totalIncrease" />}
                text={<Number value={contract.amount} currency="LCT" />}
                extraText={<Number value={contract.yield.val} currency="LCT" />}
              />
            </CardContent>
          </FixedPageItem>
        </PageContainer>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default withTheme(withRouter(connect(mapStateToProps, actions)(CustomerActiveContract)));
