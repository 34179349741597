import { Button } from "@material-ui/core";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import * as actions from "../../actions";
import { getAddressInfo } from "../../actions";
import performRequest from "../../configAxios";
import CustomInputForm from "../../custom/CustomInput";
import { FormattedMessage } from "react-intl";

class FormAddress extends Component {
  componentDidMount() {
    const { user, initialize } = this.props;
    initialize({ country: user.country });
  }
  onSubmit = formProps => {
    const {
      onActionComplete,
      showErrorMessage,
      showSuccessMessage,
      user
    } = this.props;
    performRequest("PUT", `/user/${user.id}`, { ...user, ...formProps }, true)
      .then(response => {
        showSuccessMessage("app.update");
        onActionComplete();
      })
      .catch(e => {
        if (e.response) {
          showErrorMessage(e.response.data.message);
        } else {
          showErrorMessage(e);
        }
      });
  };

  verifyCep = cep => {
    if (cep.length == 8) {
      getAddressInfo(cep.replace(/[^A-Za-z0-9]/gi, ""))
        .then(data => {
          if (!data.erro) {
            this.props.change("address", data.logradouro + " - " + data.bairro);
            this.props.change("city", data.localidade);
            this.props.change("state", data.uf);
            this.props.change("country", "Brasil");
          }
        })
        .catch(e => {});
    }
  };
  render() {
    const { handleSubmit } = this.props;
    return (
      <Fragment>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <Field
            name="zipCode"
            label="app.zipCode"
            InputLabelProps={{
              shrink: true
            }}
            inputProps={{
              maxLength: 17
            }}
            component={CustomInputForm}
            onChange={e => this.verifyCep(e.target.value)}
          />
          <Field
            name="address"
            label="app.address"
            InputLabelProps={{
              shrink: true
            }}
            component={CustomInputForm}
          />
          <Field
            name="city"
            label="app.city"
            InputLabelProps={{
              shrink: true
            }}
            component={CustomInputForm}
          />
          <Field
            name="state"
            label="app.state"
            InputLabelProps={{
              shrink: true
            }}
            component={CustomInputForm}
          />
          <Field
            name="country"
            label="app.country"
            InputLabelProps={{
              shrink: true
            }}
            component={CustomInputForm}
          />
          <Button
            id="bSubmitOrderForm"
            style={{ marginTop: 10 }}
            variant="contained"
            color="primary"
            size="large"
            type="submit"
          >
            <FormattedMessage id="app.button.save" />
          </Button>
        </form>
      </Fragment>
    );
  }
}

const validate = (values, props) => {
  const errors = {};
  if (!values.address) {
    errors.address = "app.form.address.error";
  }
  if (!values.zipCode) {
    errors.zipCode = "app.form.zipcode.error";
  }
  if (!values.city) {
    errors.city = "app.form.city.error";
  }
  if (!values.state) {
    errors.state = "app.form.state.error";
  }
  if (!values.country) {
    errors.country = "app.form.country.error";
  }
  return errors;
};

function mapStateToProps(state) {
  return {
    initialValues: { country: state.user.user.me.country }
  };
}

export default compose(
  reduxForm({
    form: "formAddress",
    validate
  }),
  connect(mapStateToProps, actions)
)(FormAddress);
