import { USER_LOGOUT } from "./types";
import performRequest from "../configAxios";
import { requestAndDispatch } from "../util";

export const signin = (params, onSuccess, onError) => {
  return requestAndDispatch(
    "default",
    "POST",
    "/auth",
    params,
    false,
    onSuccess,
    onError,
    "[signin]"
  );
};

export const logout = (params, onSuccess, onError) => {
  return requestAndDispatch(
    USER_LOGOUT,
    "POST",
    `/auth/logout`,
    null,
    true,
    onSuccess,
    onError,
    "[logout]"
  );
};
export const validateUser = formProps => async dispatch => {
  await performRequest("GET", "/auth/validate/admin", formProps, true);
};
