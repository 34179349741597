import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { getContract } from "../actions/Contract";
import CustomerActiveContract from "./CustomerActiveContract";
import CustomerInactiveContract from "./CustomerInactiveContract";
import CustomerTerminatedContract from "./CustomerTerminatedContract";

class CustomerContract extends Component {
  state = {
    contract: {}
  };

  componentDidMount() {
    getContract(this.props.match.params.id).then(data => {
      this.setState({ contract: data });
    });
  }

  render() {
    const { contract } = this.state;
    if (!contract.id) {
      return null;
    } else if (contract.status == "ACTIVE") {
      return <CustomerActiveContract {...this.props} />;
    } else if (contract.status == "INACTIVE") {
      return <CustomerInactiveContract {...this.props} />;
    } else {
      return <CustomerTerminatedContract {...this.props} />;
    }
  }
}

export default withRouter(CustomerContract);
