import React from "react";
import { FormGroup, MenuItem, FormHelperText, TextField } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

const CustomSelectNormal = ({ input, name, label, meta: { touched, error }, values, defaultValue, disabled, InputLabelProps }) => {
  return (
    <FormGroup>
      <TextField
        {...input}
        id={name}
        select
        name={label}
        label={<FormattedMessage id={label} />}
        variant="outlined"
        margin="normal"
        InputLabelProps={InputLabelProps}
        error={touched && error ? true : false}
        disabled={disabled}
      >
        {defaultValue && (
          <MenuItem value="" disabled>
            <em>{defaultValue}</em>
          </MenuItem>
        )}
        {values.map(value => {
          return (
            <MenuItem key={value.value} value={value.value}>
              {value.input}
            </MenuItem>
          );
        })}
      </TextField>
      {touched && error && (
        <FormHelperText id="name-error-text" style={{ marginBottom: 16 }}>
          <span style={{ color: "white", padding: 8, backgroundColor: "red", borderRadius: 10 }}>
            <FormattedMessage id={error} />
          </span>
        </FormHelperText>
      )}
    </FormGroup>
  );
};

export default CustomSelectNormal;
