import {
  Button,
  CardContent,
  CircularProgress,
  Collapse,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import IconCheck from "@material-ui/icons/Check";
import IconUncheck from "@material-ui/icons/Close";
import IconManager from "@material-ui/icons/Group";
import IconInfo from "@material-ui/icons/Info";
import IconInfoOutlined from "@material-ui/icons/InfoOutlined";
import IconArrowDown from "@material-ui/icons/KeyboardArrowDown";
import IconArrowUp from "@material-ui/icons/KeyboardArrowUp";
import IconLaunch from "@material-ui/icons/Launch";
import IconAgent from "@material-ui/icons/Person";
import IconCustomer from "@material-ui/icons/PersonOutline";
import React, { useEffect, useReducer, useState } from "react";
import { FormattedMessage } from "react-intl";
import Moment from "react-moment";
import { connect } from "react-redux";
import * as actions from "../actions";
import HeaderPageItem from "../custom/HeaderPageItem";
import InfoItem from "../custom/InfoItem";
import InfoList, { InfoListItem } from "../custom/InfoList";
import NumberFmt from "../custom/Number";
import PageContainer, { CardPageItem, PageItem } from "../custom/Page";
import { hasActualRole, isAgent, isAgentManager, Roles } from "../util";
import * as ru from "../util/reportUtils";

function ManagerAndAgents({ currentUser, getConfig, dispatch }) {
  const [loading, setLoading] = useState(true);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [simpleCurrentUser, setSimpleCurrentUser] = useState({});
  const [managers, setManagers] = useState([]);
  const [agents, setAgents] = useState([]);
  const [customers, setCustomers] = useState([]);
  const classes = useStyles();

  function addUIData(user) {
    user.ui = {
      isSelected: false,
      isInfoOpen: false,
      isListOpen: false,
    };
  }

  async function getReport() {
    //try {
    console.time("loading");
    setLoading(true);
    if (hasActualRole([Roles.FinanceManager], currentUser)) {
      // Administradores financeiros possuem a mesma visão do agente interno.
      currentUser = await actions.getUser2(25);
    }
    const simpleCurrentUser = await ru.simplifyUser(JSON.parse(JSON.stringify(currentUser)), [], null);
    const [managers_, agents_, customers_] = await ru.getUsersAndContractsBelowMe(simpleCurrentUser, addUIData);
    setManagers(managers_);
    setAgents(agents_);
    setCustomers(customers_);

    setSimpleCurrentUser(simpleCurrentUser);
    setLoading(false);
    console.timeEnd("loading");
    // } catch (e) {
    //   dispatchErrorMessage(e.message, dispatch);
    //   setLoading(false);
    // }
  }

  function effect() {
    getConfig().then(() => getReport());
  }

  useEffect(effect, []);

  function setAllLists_(user, open) {
    user.ui.isListOpen = open && (user.managers.length > 0 || user.agents.length > 0 || user.customers.length > 0);
    user.managers.forEach((manager) => setAllLists_(manager, open));
    user.agents.forEach((agent) => setAllLists_(agent, open));
    user.customers.forEach((customer) => setAllLists_(customer, open));
  }

  function setAllLists(open) {
    managers.forEach((manager) => setAllLists_(manager, open));
    forceUpdate();
  }

  if (loading) return <CircularProgress style={{ marginTop: "25%" }} />;
  return (
    <PageContainer full>
      <HeaderPageItem title="app.report.hierarchical" showBackButton destination="/reports" />
      <CardPageItem raised>
        <CardContent>
          <p style={{ color: "red", fontSize: "120%", textAlign: "center" }}>
            Atenção: este relatório está passando por mudanças e pode apresentar valores incorretos.
          </p>
          <Button variant="outlined" size="small" onClick={(e) => setAllLists(true)}>
            Abrir todos
          </Button>{" "}
          <Button variant="outlined" size="small" onClick={(e) => setAllLists(false)}>
            Fechar todos
          </Button>
        </CardContent>
      </CardPageItem>
      <PageItem>
        {managers.length > 0 && (
          <>
            <Typography variant="caption" classes={{ root: classes.listTitle }}>
              Gestores
            </Typography>
            <List>
              {managers.map((manager) => (
                <UserListItem key={manager.id} currentUser={simpleCurrentUser} user={manager} classes={classes} />
              ))}
            </List>
          </>
        )}
        {agents.length > 0 && (
          <>
            <Typography variant="caption" classes={{ root: classes.listTitle }}>
              Agentes
            </Typography>
            <List>
              {agents.map((agent) => (
                <UserListItem key={agent.id} currentUser={simpleCurrentUser} user={agent} classes={classes} />
              ))}
            </List>
          </>
        )}
        {customers.length > 0 && (
          <>
            <Typography variant="caption" classes={{ root: classes.listTitle }}>
              Clientes
            </Typography>
            <List>
              {customers.map((customer) => (
                <UserListItem key={customer.id} currentUser={simpleCurrentUser} user={customer} classes={classes} />
              ))}
            </List>
          </>
        )}
      </PageItem>
    </PageContainer>
  );
}

function UserListItem({ currentUser, user, classes, lines, bgIntensity = 50 }) {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [openInfo, setOpenInfo] = useState(false);
  const hasManagers = user.managers.length > 0;
  const hasAgents = user.agents.length > 0;
  const hasCustomers = user.customers.length > 0;
  const aptColor = { true: "green", false: "red" };
  const contractCount = user.contracts.length;
  const activeContractCount = user.contracts.filter((c) => c.status !== "ARCHIVE").length;
  bgIntensity -= 10;
  if (bgIntensity < 0) bgIntensity = 0;

  const IdAndValue = ({ id, value }) => (
    <>
      <small style={{ color: "#999" }}>({id})</small> <NumberFmt value={value ?? 0} fractionDigits={2} />
    </>
  );

  return (
    <>
      {lines && <hr style={{ border: "1px solid #333", padding: 0, margin: 0 }} />}
      <div
        style={{
          borderLeft: "1px solid #333",
          backgroundColor: user.ui.isListOpen ? `rgb(${bgIntensity},${bgIntensity},${bgIntensity})` : "unset",
        }}
      >
        <ListItem
          button={hasManagers || hasAgents || hasCustomers}
          onClick={() => {
            if (hasManagers || hasAgents || hasCustomers) {
              user.ui.isListOpen = !user.ui.isListOpen;
              forceUpdate();
            }
          }}
        >
          <ListItemIcon>
            <div>
              {isAgentManager(user) ? (
                <IconManager classes={{ root: classes.iconManager }} />
              ) : isAgent(user) ? (
                <IconAgent classes={{ root: classes.iconAgent }} />
              ) : (
                <IconCustomer classes={{ root: classes.iconCustomer }} />
              )}
              {user.role === Roles.Agent &&
                (user.contractAgent?.status === "ACTIVE" ? (
                  <IconCheck style={{ fontSize: "100%", color: "lime", marginLeft: -6, marginTop: -2, position: "absolute" }} />
                ) : (
                  <IconUncheck style={{ fontSize: "100%", color: "red", marginLeft: -6, marginTop: -2, position: "absolute" }} />
                ))}
            </div>
          </ListItemIcon>
          <ListItemText
            primary={
              <>
                {user.name} <small style={{ color: "#999" }}>({user.id})</small>{" "}
                <Typography variant="caption" classes={{ root: classes.contractCount }}>
                  <b>{contractCount === 0 ? "" : `Contratos: ${activeContractCount} / ${contractCount}`}</b>
                </Typography>
              </>
            }
          />
          <ListItemSecondaryAction>
            {(hasManagers || hasAgents || hasCustomers) && (
              <IconButton
                size="small"
                onClick={() => {
                  user.ui.isListOpen = !user.ui.isListOpen;
                  forceUpdate();
                }}
              >
                {user.ui.isListOpen ? <IconArrowUp /> : <IconArrowDown />}
              </IconButton>
            )}
            <IconButton
              size="small"
              onClick={() => {
                setOpenInfo(!openInfo);
              }}
            >
              {openInfo ? <IconInfo style={{ color: aptColor[user.apt] }} /> : <IconInfoOutlined style={{ color: aptColor[user.apt] }} />}
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <Collapse in={openInfo} timeout="auto" mountOnEnter unmountOnExit>
          <div
            style={{
              maxWidth: "55%",
              paddingLeft: 75,
              paddingTop: 0,
              paddingBottom: 0,
              paddingRight: 16,
              justifyContent: "space-between",
              color: "#999",
            }}
          >
            <InfoItem
              caption={<small style={{ color: aptColor[user.apt] }}>{user.apt ? "apto" : "inapto"}</small>}
              text={user.email}
              extraCaption={
                user.role === Roles.Agent && (
                  <small style={{ color: aptColor[user.contractAgent?.status === "ACTIVE"] }}>agreement: {user.contractAgent?.status}</small>
                )
              }
              extraText={
                <>
                  {isAgentManager(user) ? "AGENT MANAGER" : user.role}{" "}
                  {[Roles.Admin, Roles.FinanceManager].includes(currentUser.role) && (
                    <IconButton size="small" href={"/user/" + user.id} target="_blank">
                      <IconLaunch />
                    </IconButton>
                  )}
                </>
              }
            />
            <InfoList>
              <InfoListItem label="Rendimento recebido:" info={<NumberFmt value={user.received.return} currency={"LCT"} />} />
            </InfoList>
            <InfoList>
              <InfoListItem label="Gestão recebida:" info={<NumberFmt value={user.received.management} currency={"LCT"} />} />
              <InfoListItem label="Comissão recebida:" info={<NumberFmt value={user.received.commission} currency={"LCT"} />} />
              <InfoListItem label="Split recebido:" info={<NumberFmt value={user.received.split} currency={"LCT"} />} />
              <InfoListItem
                label="Comissão total recebida:"
                info={<NumberFmt value={user.received.management + user.received.commission + user.received.split} currency={"LCT"} />}
              />
            </InfoList>
          </div>
          <div
            style={{
              paddingLeft: 10,
              paddingTop: 0,
              paddingBottom: 16,
              paddingRight: 10,
              color: "#999",
            }}
          >
            {user.contracts.length > 0 && (
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ color: "#999" }}>id</TableCell>
                      <TableCell align="right" style={{ color: "#999" }}>
                        <small>valor</small>
                      </TableCell>
                      <TableCell align="center" style={{ color: "#999" }}>
                        <small>início</small>
                      </TableCell>
                      <TableCell align="center" style={{ color: "#999" }}>
                        <small>final</small>
                      </TableCell>
                      <TableCell align="center" style={{ color: "#999" }}>
                        <small>estado</small>
                      </TableCell>
                      <TableCell align="right" style={{ color: "#999" }}>
                        <small>rendimento</small>
                      </TableCell>
                      <TableCell align="right" style={{ color: "#999" }}>
                        <small>comissão</small>
                      </TableCell>
                      <TableCell align="right" style={{ color: "#999" }}>
                        <small>gestão</small>
                      </TableCell>
                      <TableCell align="right" style={{ color: "#999" }}>
                        <small>split</small>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {user.contracts.map((c) => (
                      <TableRow key={c.id}>
                        <TableCell>
                          <Link href={"/contract/" + c.id} target="_blank" style={{ color: c.status !== "ARCHIVE" ? "#EEE" : "#999" }}>
                            C{c.id}
                          </Link>
                        </TableCell>
                        <TableCell align="right" style={{ color: c.status !== "ARCHIVE" ? "#EEE" : "#999" }}>
                          <NumberFmt value={c.amount} />
                        </TableCell>
                        <TableCell align="center" style={{ color: c.status !== "ARCHIVE" ? "#EEE" : "#999" }}>
                          <Moment date={c.initialDate} />
                        </TableCell>
                        <TableCell align="center" style={{ color: c.status !== "ARCHIVE" ? "#EEE" : "#999" }}>
                          <Moment date={c.dueDate} />
                        </TableCell>
                        <TableCell align="center" style={{ color: c.status !== "ARCHIVE" ? "#EEE" : "#999" }}>
                          <FormattedMessage id={`app.enuns.${c.phase}`} />
                        </TableCell>
                        {c.status !== "ARCHIVE" ? (
                          <>
                            <TableCell align="right" style={{ color: c.status !== "ARCHIVE" ? "#EEE" : "#999" }}>
                              <IdAndValue id={c.customer?.id} value={c.generated.return} />
                            </TableCell>
                            <TableCell align="right" style={{ color: c.status !== "ARCHIVE" ? "#EEE" : "#999" }}>
                              <IdAndValue id={c.agent?.id} value={c.generated.commission} />
                            </TableCell>
                            <TableCell align="right" style={{ color: c.status !== "ARCHIVE" ? "#EEE" : "#999" }}>
                              {c.manager != null ? <IdAndValue id={c.manager?.id} value={c.generated.management} /> : "N/A"}
                            </TableCell>
                            <TableCell align="right" style={{ color: c.status !== "ARCHIVE" ? "#EEE" : "#999" }}>
                              {c.generated.split ? <IdAndValue id={c.splitAgent?.id} value={c.generated.split} /> : "N/A"}
                            </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell align="center" colSpan={4} style={{ color: c.status !== "ARCHIVE" ? "#EEE" : "#999" }}></TableCell>
                          </>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </Collapse>
        <Collapse in={user.ui.isListOpen} timeout="auto" mountOnEnter unmountOnExit style={{ marginLeft: "5%" }}>
          {hasManagers && (
            <>
              <Typography variant="caption" classes={{ root: classes.listTitle }}>
                Gestores
              </Typography>
              <List>
                {user.managers.map((manager) => (
                  <UserListItem key={manager.id} currentUser={currentUser} user={manager} classes={classes} bgIntensity={bgIntensity} />
                ))}
              </List>
            </>
          )}
          {hasAgents && (
            <>
              <Typography variant="caption" classes={{ root: classes.listTitle }}>
                Agentes
              </Typography>
              <List>
                {user.agents.map((agent) => (
                  <UserListItem key={agent.id} currentUser={currentUser} user={agent} classes={classes} bgIntensity={bgIntensity} />
                ))}
              </List>
            </>
          )}
          {hasCustomers && (
            <>
              <Typography variant="caption" classes={{ root: classes.listTitle }}>
                Clientes
              </Typography>
              <List>
                {user.customers.map((customer) => (
                  <UserListItem key={customer.id} currentUser={currentUser} user={customer} classes={classes} bgIntensity={bgIntensity} />
                ))}
              </List>
            </>
          )}
        </Collapse>
      </div>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  mainHeaderRow: {
    "& > th": {
      //fontWeight: "bold",
      borderBottom: "3px solid #333",
      color: "#AAA",
    },
  },
  mainRow: {
    "& > *": {
      borderBottom: "unset",
    },
    "& > td:first-child": {
      width: 10,
      paddingLeft: 10,
      paddingRight: 0,
    },
  },
  detailBox: {
    backgroundColor: "#151515",
    paddingLeft: 50,
  },
  detailHeaderRow: {
    "& > th": {
      //fontWeight: "bold",
      borderBottom: "3px solid #222",
      color: "#AAA",
    },
  },
  detailRow: {
    "& > td": { borderBottom: "1px solid #222" },
  },
  iconManager: {
    color: theme.palette.primary.main,
  },
  iconAgent: {
    color: "white",
  },
  iconCustomer: {
    color: "#666",
  },
  listTitle: {
    color: "#999",
    paddingLeft: 16,
  },
  contractCount: {
    color: theme.palette.primary.main,
  },
}));

export default connect((state) => ({ currentUser: state.user.user.me }), actions)(ManagerAndAgents);
