import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import * as actions from "../../actions";
import { cnpjNormalizer, cpfNormalizer } from "../../util";

class ContractAgentManagerDescriptionBR extends Component {
  state = {
    checked1: false,
    checked2: false,
  };

  render() {
    let { contractUser, contractAgent } = this.props;

    if (this.state.checked1 && this.state.checked2) {
      this.props.validContractAgent(true);
    } else {
      this.props.validContractAgent(false);
    }

    const nome_do_gestor = contractUser.name;
    const pessoa_física_juridica =
      contractUser.type === "NATURAL_PERSON" ? (
        <FormattedMessage id="app.naturalPerson" />
      ) : (
        <FormattedMessage id="app.company" />
      );
    const cpf_cnpj = contractUser.documentType;
    const numero_cpf_cnpj =
      contractUser.type == "NATURAL_PERSON"
        ? contractUser.taxResidence == "Brasil"
          ? cpfNormalizer(contractUser.documentId)
          : contractUser.documentId
        : contractUser.taxResidence == "Brasil"
        ? cnpjNormalizer(contractUser.documentId)
        : contractUser.documentId;
    const endereco = `${contractUser.address} - ${contractUser.city} / ${contractUser.state} - ${contractUser.country}`;
    const managerComission = (contractAgent.managerComission * 100).toFixed(2);

    return (
      <>
        <div className="doc" style={{ textAlign: "justify" }}>
          <p class="c7">
            <span class="c0">CONTRATO DE PRESTAÇÃO DE SERVIÇO AUTÔNOMO COM SUB REPRESENTAÇÃO</span>
          </p>
          <p class="c2">
            <span class="c4 c1">1. DAS PARTES</span>
          </p>
          <p class="c2">
            <span class="c1">CLÁUSULA 1ª:</span>
            <span class="c0">
              &nbsp;Lux Capital, LLC., pessoa jurídica de direito privado com sede sito 1309 Coffeen Avenue, Suite 1816,
              Sheridan Wyoming 82801, United States, inscrita sob nº 2020-927450, doravante denominada CONTRATANTE,
            </span>
          </p>
          <p class="c2">
            <span class="c1">CLÁUSULA 2ª:</span>
            <span class="c3">&nbsp;e de outro lado, </span>
            <span class="c1">{nome_do_gestor}</span>
            <span class="c3">, </span>
            <span class="c1">{pessoa_física_juridica}</span>
            <span class="c3">&nbsp;com </span>
            <span class="c1">{cpf_cnpj}</span>
            <span class="c3">&nbsp;</span>
            <span class="c1">{numero_cpf_cnpj}</span>
            <span class="c3">, residente na </span>
            <span class="c1">{endereco}</span>
            <span class="c0">
              , neste instrumento denominada CONTRATADA, decidem por firmar contrato de prestação de serviços autônomos
              regidos pelas cláusulas abaixo acordadas:
            </span>
          </p>
          <p class="c2">
            <span class="c4 c1">2. DO OBJETO</span>
          </p>
          <p class="c2">
            <span class="c1">CLÁUSULA 3ª:</span>
            <span class="c0">
              &nbsp;É objeto do presente contrato a prestação de serviços a ser realizado pela CONTRATADA em prol da
              CONTRATANTE, exercida pela divulgação e apresentação à clientes, demonstrando os serviços que estão
              disponíveis pela CONTRATANTE, dentro da sua capacidade empresarial nos termos jurídicos por ela disposta,
              tratando-se exclusivamente de promoção de tornar conhecido e de como fazer uso dos serviços oferecidos
              pela CONTRATANTE.
            </span>
          </p>
          <p class="c2">
            <span class="c0">
              Parágrafo Primeiro: Pela forma estipulada neste contrato, a empresa CONTRATADA utilizará o modelo de
              sub-representação para executar os serviços dispostos no objeto deste termo.
            </span>
          </p>
          <p class="c2">
            <span class="c0">
              Parágrafo segundo: os sub-representantes serão denominados AGENTES e estão sujeitos ao CONTRATO DE
              PRESTAÇÃO DE SERVIÇO AUTÔNOMO, o qual deverão aceitar via opção dentro da plataforma da CONTRATANTE.
            </span>
          </p>
          <p class="c2">
            <span class="c1">CLÁUSULA 4ª:</span>
            <span class="c0">
              &nbsp;A prestação do serviço objeto deste instrumento será executada na região determinada pela
              CONTRATADA, a qual deve ser informada à CONTRATANTE quanto a escolha do local de atuação;
            </span>
          </p>
          <p class="c2">
            <span class="c4 c1">3. DA SUB-REPRESENTAÇÃO</span>
          </p>
          <p class="c2">
            <span class="c1">CLÁUSULA 5ª:</span>
            <span class="c0">
              &nbsp;Fica a CONTRATADA autorizada a utilizar-se de sub-representação para exercer a atividade objeto
              deste contrato, cabendo única e exclusivamente à CONTRATADA a responsabilização pelo recrutamento,
              seleção, relacionamento, informação, manutenção, gerenciamento, gestão, organização, administração e
              coordenação dos referidos sub-representante, possibilitando que estes exerçam as atividades descritas na
              Cláusula 3ª, à favor da CONTRATANTE.
            </span>
          </p>
          <p class="c2">
            <span class="c0">
              Parágrafo Primeiro: O uso da marca, dos produtos, dos serviços, documentos e disponibilização de
              treinamentos permanecem com a exclusividade total da CONTRATANTE;
            </span>
          </p>
          <p class="c2">
            <span class="c0">
              Parágrafo Segundo: Fica neste contrato definido e estipulado a responsabilidade da CONTRATADA para com a
              totalidade de serviços e comunicação com os seus sub-representados, cabendo a empresa CONTRATANTE cumprir
              com as obrigações aqui mencionadas em razão apenas e tão somente da CONTRATADA.
            </span>
          </p>
          <p class="c2">
            <span class="c4 c1">4. DO PAGAMENTO</span>
          </p>
          <p class="c2">
            <span class="c1">CLÁUSULA 6ª:</span>
            <span class="c0">
              &nbsp;Pelo cumprimento do objeto deste instrumento, identificado na Cláusula 3ª, a CONTRATADA receberá,
              mensalmente, uma porcentagem do volume custodiado de cada LCContract ativo sob a responsabilidade de um de
              seus sub-representantes.
            </span>
          </p>
          <p class="c2">
            <span class="c0">a) O valor da &nbsp;porcentagem é de {managerComission}%.</span>
          </p>
          <p class="c2">
            <span class="c1">CLÁUSULA 7ª:</span>
            <span class="c0">
              &nbsp;Os pagamentos referentes à prestação de serviço realizados serão efetuados por meio de crédito na
              conta da CONTRATADA na plataforma. Os valores referentes ao comissionamento da prestação de serviço serão
              pagos na stable coin determinada conforme o país do cadastro da CONTRATADA.
            </span>
          </p>
          <p class="c2">
            <span class="c1">CLÁUSULA 8ª:</span>
            <span class="c0">
              &nbsp;O pagamento do comissionamento ocorrerá no dia de aniversário do LCContract ao qual se refere.
            </span>
          </p>
          <p class="c2">
            <span class="c0">
              Parágrafo Único: O dia de aniversário de um LCContract é o dia em que ele foi ativado, contabilizando um
              aniversário a cada mês.
            </span>
          </p>
          <p class="c2">
            <span class="c1">CLÁUSULA 9ª:</span>
            <span class="c0">
              &nbsp;O saque dos valores da conta na plataforma deverá ser feito conforme regras válidas para todos
              usuários da mesma, conforme descrito nos TERMOS E CONDIÇÕES DE USO.
            </span>
          </p>
          <p class="c2">
            <span class="c4 c1">5. DAS OBRIGAÇÕES DA CONTRATANTE</span>
          </p>
          <p class="c2">
            <span class="c1">CLÁUSULA 10ª:</span>
            <span class="c0">&nbsp;Fica obrigada a contratante a:</span>
          </p>
          <p class="c2">
            <span class="c0">
              a) fornecer informação, esclarecimentos e capacitação para o bom desenvolvimento das atividades objeto
              deste ato contratual;
            </span>
          </p>
          <p class="c2">
            <span class="c0">
              b) fornecer suporte técnico necessário para o seguimento da atividade objeto deste contrato;
            </span>
          </p>
          <p class="c2">
            <span class="c0">
              c) fiscalizar a atividade da CONTRATADA e propiciar que a mesma desenvolva às atividades de acordo com as
              normativas e orientações proporcionada da CONTRATANTE;
            </span>
          </p>
          <p class="c2">
            <span class="c0">
              d) realizar o pagamento dos serviços prestados observado o disposto nas cláusulas 5ª a 7ª do presente
              termo.
            </span>
          </p>
          <p class="c2">
            <span class="c0">
              e) avisar previamente a CONTRATADA das reuniões, cursos ou treinamentos a serem realizados pela
              CONTRATANTE ou por quem esta, exclusivamente, indicar apta(o) para tal, com antecedência de 5 dias.
            </span>
          </p>
          <p class="c2">
            <span class="c4 c1">6. DAS OBRIGAÇÕES DA CONTRATADA</span>
          </p>
          <p class="c2">
            <span class="c1">CLÁUSULA 11ª:</span>
            <span class="c0">&nbsp;Fica obrigada a contratada à:</span>
          </p>
          <p class="c2">
            <span class="c0">
              a) realizar a prestação de serviço objeto deste contrato de acordo com as normas, informações e
              capacitação proporcionada pela CONTRATANTE, ou por quem esta, exclusivamente, indicar apta(o) para tal;
            </span>
          </p>
          <p class="c2">
            <span class="c0">
              b) exercer, de maneira personalíssima, a prestação de serviço objeto deste contrato, além de exigir o
              mesmo de seus sub-representados.
            </span>
          </p>
          <p class="c2">
            <span class="c0">
              c) prestar de maneira clara e objetiva, informações e orientações aos clientes sobre a funcionalidade e
              serviços da empresa CONTRATANTE;
            </span>
          </p>
          <p class="c2">
            <span class="c0">
              d) fornecer, informar, preencher adequadamente à plataforma os dados dos respectivos clientes à empresa
              CONTRATANTE.
            </span>
          </p>
          <p class="c2">
            <span class="c0">
              e) fornecer à CONTRATANTE, sempre que solicitado, no prazo de 48 horas, as informações referente à
              prestação de serviços deste contrato.
            </span>
          </p>
          <p class="c2">
            <span class="c0">
              f) participar das reuniões, cursos ou treinamentos proporcionados pela empresa CONTRATANTE, previamente
              agendados, ou por quem esta, exclusivamente, indicar apta(o) para tal.
            </span>
          </p>
          <p class="c2">
            <span class="c0">
              g) arcar com os custos de deslocamento, estadias, transportes, seguros ou quaisquer outras despesas
              necessárias para o atendimento aos seus clientes, bem como ao comparecimento às reuniões, cursos e
              treinamentos previamente agendados pela CONTRATANTE, ou por quem esta, exclusivamente, indicar apta(o)
              para tal.
            </span>
          </p>
          <p class="c2">
            <span class="c0">
              h) responder adequadamente a declaração dos tributos fiscais dos valores recebidos em prol da prestação de
              serviço efetuada.
            </span>
          </p>
          <p class="c2">
            <span class="c0">
              i) informar, comunicar, repassar, instruir, esclarecer, advertir e orientar o sub-representado de maneira
              clara e correta todos os procedimentos que devem ser empregados para a realização do objeto deste
              contrato, conforme instruções repassadas pela CONTRATANTE.
            </span>
          </p>
          <p class="c2">
            <span class="c0">
              j) manter um contrato vigente de prestação de serviços diretamente com os sub-representados estipulando
              suas responsabilidades e obrigações para com esta e os respectivos serviços disponibilizados pela
              CONTRATANTE.
            </span>
          </p>
          <p class="c2">
            <span class="c0">
              k) informar obrigatoriamente os sub-representados de cursos, reuniões ou treinamentos oferecidos pela
              CONTRATANTE para que estes possam comparecer em tempo hábil e se fazerem presentes para que possam receber
              as orientações respectivas de cada curso, reunião ou treinamento.
            </span>
          </p>
          <p class="c2">
            <span class="c1 c4">7. DA VIGÊNCIA CONTRATUAL</span>
          </p>
          <p class="c2">
            <span class="c1">CLÁUSULA 12ª:</span>
            <span class="c0">
              &nbsp;O presente contrato de prestação de serviço passa a vigorar na data de sua assinatura, passando a
              ser vigente por prazo indeterminado.
            </span>
          </p>
          <p class="c2">
            <span class="c4 c1">8. DA RESCISÃO CONTRATUAL</span>
          </p>
          <p class="c2">
            <span class="c1">CLÁUSULA 13ª:</span>
            <span class="c3">
              &nbsp;A rescisão imotivada do contrato é facultada por ambas as partes e pode ser solicitada com aviso
              prévio de 30 (trinta) dias, por meio de notificação escrita, salvo quando interrupções não sistêmicas,
              governamentais ou de órgãos regulamentadores e fiscalizadores, que por ventura possam vir a alterar o{" "}
            </span>
            <span class="c3 c5">modus operandi</span>
            <span class="c0">&nbsp;do mercado em questão.</span>
          </p>
          <p class="c2">
            <span class="c1">CLÁUSULA 14ª:</span>
            <span class="c0">
              &nbsp;O contrato poderá ainda ser rescindido de pleno direito e sem prévia notificação, por justo motivo,
              este entendido como:
            </span>
          </p>
          <p class="c2">
            <span class="c0">
              Parágrafo Primeiro: Unilateralmente, pela parte inocente, na hipótese de descumprimento de qualquer das
              obrigações assumidas neste contrato, sem prejuízo da indenização, pela parte infratora à inocente, pelas
              eventuais perdas e danos a que a parte infratora der causa;
            </span>
          </p>
          <p class="c2">
            <span class="c0">
              Parágrafo Segundo: No caso de pedido, decretação ou homologação de recuperação judicial ou extrajudicial,
              falência, liquidação, dissolução ou inequívoca situação de insolvência da outra parte;
            </span>
          </p>
          <p class="c2">
            <span class="c0">Parágrafo Terceiro: Comprovada a ocorrência de caso fortuito ou força maior.</span>
          </p>
          <p class="c2">
            <span class="c0">
              Parágrafo Quarto: Em quaisquer dessas hipóteses de rescisão contratual, é garantido às partes o acerto de
              contas pelos serviços já prestados, até a última data efetiva de serviços prestados.
            </span>
          </p>
          <p class="c2">
            <span class="c4 c1">9. DA RESPONSABILIDADE TRABALHISTA</span>
          </p>
          <p class="c2">
            <span class="c1">CLÁUSULA 15ª:</span>
            <span class="c0">
              &nbsp;Fica estipulado neste, que não se estabelece, por força do presente contrato, qualquer vínculo de
              natureza empregatícia ou de responsabilidade entre o CONTRATANTE e CONTRATADA e/ou seus sub-representados
              para a execução dos serviços, sendo a CONTRATADA única e exclusiva responsável por todas as obrigações e
              encargos decorrentes da legislação vigente, seja a trabalhista, previdenciária ou qualquer outra,
              obrigando-se, assim a CONTRATADA ao cumprimento das disposições legais no que se refere à remuneração da
              mão de obra que utilizar e/ou sub-contratar e a outras obrigações e encargos de qualquer natureza.
            </span>
          </p>
          <p class="c2">
            <span class="c1">CLÁUSULA 16ª:</span>
            <span class="c0">
              &nbsp;A CONTRATANTE não se responsabiliza por quaisquer dívidas trabalhistas, tributárias ou quaisquer
              outras que possua a CONTRATADA. Estas são de sua exclusiva e intransferível responsabilidade;
            </span>
          </p>
          <p class="c2">
            <span class="c1">CLÁUSULA 17ª:</span>
            <span class="c0">
              &nbsp;Eventuais condenações trabalhistas da CONTRATADA advindas da relação jurídica aqui estabelecida
              entre as partes, poderão ser descontadas do pagamento mensal devido à CONTRATADA. Caso o valor das
              condenações supere as importâncias devidas pela, a CONTRATADA ressarcirá a CONTRATANTE no valor da
              diferença, podendo ainda as verbas serem descontadas de pagamentos futuros.
            </span>
          </p>
          <p class="c2">
            <span class="c4 c1">10. DA RESPONSABILIDADE TRIBUTÁRIA</span>
          </p>
          <p class="c2">
            <span class="c1">CLÁUSULA 18ª:</span>
            <span class="c0">
              &nbsp;Caso a CONTRATANTE seja obrigado a pagar ou indenizar importância referente a tributos não pagos
              pela CONTRATADA, ficará este último obrigado a ressarcir todos valores despendidos, incluindo eventuais
              custas processuais e honorários advocatícios, no prazo de até 5 dias do efetivo pagamento.
            </span>
          </p>
          <p class="c2">
            <span class="c4 c1">11. DA RESPONSABILIDADE CIVIL</span>
          </p>
          <p class="c2">
            <span class="c1">CLÁUSULA 19ª:</span>
            <span class="c0">
              &nbsp;A CONTRATADO deverá reparar imediatamente qualquer erro, perda ou dano resultante do descumprimento
              e/ou do cumprimento das obrigações contratuais em desconformidade com o presente contrato, obrigando-se a
              ressarcir a CONTRATANTE ou terceiros prejudicados
            </span>
          </p>
          <p class="c2">
            <span class="c4 c1">12. DA CONFIDENCIALIDADE</span>
          </p>
          <p class="c2">
            <span class="c1">CLÁUSULA 20ª:</span>
            <span class="c0">
              &nbsp;As partes contratantes reconhecem que o vínculo estipulado neste contrato é baseado em relação de
              fidelidade e boa-fé recíproca e ficam a partir cientes que, no exercício de suas funções, poderão ter
              acesso a informações e materiais que não são de conhecimento do público em geral e que a parte reveladora
              considera ser de sua propriedade exclusiva e de caráter confidencial e, em caso de solicitação de algum
              material informativo ou confidencial relacionado com as atividades mencionadas neste contrato, fica com
              exclusiva responsabilidade de fornecer, a seu critério, a empresa CONTRATANTE, eximindo perante às
              autoridades e demais órgãos governamentais a responsabilidade da CONTRATADA em fornecer qualquer material
              ou dado informativo, tendo em vista se tratar de assunto de confidencialidade.
            </span>
          </p>
          <p class="c2">
            <span class="c1">CLÁUSULA 21ª:</span>
            <span class="c0">
              &nbsp;É vedado a qualquer das partes usar em benefício próprio ou de terceiros quaisquer informações
              privilegiadas para o desenvolvimento de atividade igual ou similar à desenvolvida pela outra parte, pelo
              período igual ou maior de 5 (cinco) anos, quando da resolução do contrato.
            </span>
          </p>
          <p class="c2">
            <span class="c1">CLÁUSULA 22ª:</span>
            <span class="c0">
              &nbsp;A obrigação de sigilo estabelecida nessa cláusula perdurará após a extinção deste contrato, pelo
              período de 10 (dez) anos.
            </span>
          </p>
          <p class="c2">
            <span class="c1">CLÁUSULA 23ª:</span>
            <span class="c0">
              &nbsp;Todas as tratativas, negociações, contratos, manuais, circulares, notificações, treinamentos,
              reuniões, certidões, documentos contábeis, ou quaisquer informações a respeito da atividade desenvolvida
              pela CONTRATANTE são estritamente confidenciais, não podendo ser divulgados por qualquer meio, mídia ou
              sob qualquer justificativa, com exceção das previstas em Lei, sob pena de aplicação de multa contratual,
              estipulada no presente instrumento.
            </span>
          </p>
          <p class="c2">
            <span class="c1">CLÁUSULA 24ª:</span>
            <span class="c0">
              &nbsp;O presente contrato e seus anexos, se houver, tornam-se também informação confidencial e por isso a
              sua existência e seu conteúdo não poderão ser revelados a terceiros, senão mediante autorização expressa
              da CONTRATANTE.
            </span>
          </p>
          <p class="c2">
            <span class="c1">CLÁUSULA 25ª:</span>
            <span class="c0">
              &nbsp;Em caso de dúvida sobre a confidencialidade de determinada informação, a CONTRATADA deverá mantê-la
              em absoluto sigilo até que o CONTRATANTE se manifeste expressamente a respeito.
            </span>
          </p>
          <p class="c2">
            <span class="c1">CLÁUSULA 26ª:</span>
            <span class="c0">
              &nbsp;A não observância das cláusulas de confidencialidade importará em responsabilidade cível e criminal
              pelas partes, as quais poderão ser dirimidas e apuradas em Juízo.
            </span>
          </p>
          <p class="c2">
            <span class="c4 c1">13. DO JUÍZO ARBITRAL</span>
          </p>
          <p class="c2">
            <span class="c1">CLÁUSULA 27ª:</span>
            <span class="c0">
              &nbsp;Este contrato é aplicável de acordo com as leis de jurisdição dos países onde essa transação é
              realizada e qualquer disputa deverá ser resolvida sob as regras da ICC Brasil para arbitragem, a menos que
              a parte lesada tome medidas legais em um tribunal de jurisdição.
            </span>
          </p>
          <p class="c2">
            <span class="c1">CLÁUSULA 28ª:</span>
            <span class="c0">
              &nbsp;Os contratantes reconhecem e concordam que qualquer divergência disputa na aplicação deste o acordo
              será resolvido, primeiramente amigavelmente, salvo se não for possível, o procedimento de arbitragem deve
              ser seguido.
            </span>
          </p>
          <p class="c2">
            <span class="c1">CLÁUSULA 29ª:</span>
            <span class="c0">
              &nbsp;Adicionalmente as partes podem definir a sede da arbitragem, o idioma, o número de árbitros, a forma
              de custeio, dentre outros.
            </span>
          </p>
          <p class="c2">
            <span class="c4 c1">14. DAS DISPOSIÇÕES GERAIS</span>
          </p>
          <p class="c2">
            <span class="c1">CLÁUSULA 30ª:</span>
            <span class="c0">
              &nbsp;A tolerância por qualquer uma das partes, no que tange ao cumprimento das obrigações da outra parte,
              não será considerada novação ou perdão, permanecendo as cláusulas deste contrato em pleno vigor, da forma
              aqui prevista.
            </span>
          </p>
          <p class="c2">
            <span class="c1">CLÁUSULA 31ª:</span>
            <span class="c0">
              &nbsp;O presente contrato e os direitos e obrigações dele decorrentes não poderão ser cedidos,
              transferidos ou sub-rogados por quaisquer das partes sem o prévio consentimento por escrito das outras
              partes;
            </span>
          </p>
          <p class="c7">
            <span class="c0">
              E por estarem assim justas e contratadas, as partes assinam o presente instrumento em duas vias de igual
              teor e forma, perante as testemunhas também abaixo assinadas, juntamente com profissional advogado
              identificado juntamente com sua credencial.
            </span>
          </p>
          <p class="c2">
            <span class="c8">Document version: 2.1</span>
          </p>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default connect(mapStateToProps, actions)(ContractAgentManagerDescriptionBR);
