import React, { Component } from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";

class InfoItem extends Component {
  static propTypes = {
    caption: PropTypes.any,
    text: PropTypes.any.isRequired,
    extraCaption: PropTypes.any,
    extraText: PropTypes.any,
    variant: PropTypes.string,
    color: PropTypes.string,
    center: PropTypes.bool,
    key: PropTypes.any,
  };

  render() {
    const { caption, text, extraCaption, extraText, variant, center, color, style } = this.props;
    return (
      <>
        {center ? (
          <div style={{ display: "flex", flexDirection: "column", paddingBottom: 16, ...style }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Typography variant="caption" color="textSecondary">
                {caption}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {extraCaption}
              </Typography>
            </div>
            {text && extraText ? (
              <div style={{ display: "flex", justifyContent: "space-between", whiteSpace: "pre-wrap" }}>
                <Typography variant={variant ? variant : "body1"} color={color ? color : "textPrimary"}>
                  {text}
                </Typography>
                <Typography variant={variant ? variant : "body1"} color={color ? color : "textPrimary"}>
                  {extraText}
                </Typography>
              </div>
            ) : (
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", whiteSpace: "pre-wrap" }}>
                <Typography variant={variant ? variant : "body1"} color={color ? color : "textPrimary"}>
                  {text}
                </Typography>
                <Typography variant={variant ? variant : "body1"} color={color ? color : "textPrimary"}>
                  {extraText}
                </Typography>
              </div>
            )}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              paddingBottom: 16,
              ...style,
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="caption" color="textSecondary">
                {caption}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {extraCaption}
              </Typography>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between", whiteSpace: "pre-wrap" }}>
              <Typography variant={variant ? variant : "body1"} color={color ? color : "textPrimary"}>
                {text}
              </Typography>
              <Typography variant={variant ? variant : "body1"} color={color ? color : "textPrimary"}>
                {extraText}
              </Typography>
            </div>
          </div>
        )}
      </>
    );
  }
}
export default withRouter(InfoItem);
