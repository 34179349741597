import { Button, Card, CardActionArea, CardContent, CircularProgress, Divider, List, ListItem, ListItemText, Menu, MenuItem, Typography } from "@material-ui/core";
import { default as ChevronRight, default as ChevronRightIcon } from "@material-ui/icons/ChevronRight";
import React, { Component } from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import Moment from "react-moment";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import { createNumberMask } from "redux-form-input-masks";
import * as actions from "../actions";
import { awaitingValidationContract, cancelContract, getContract, sendContractTransaction, terminateContract, validateContract } from "../actions/Contract";
import { getRedemptionPayment } from "../actions/Payment";
import CustomInputForm from "../custom/CustomInput";
import CustomSelectNormal from "../custom/CustomSelectNormal";
import FormDialog from "../custom/FormDialog";
import HeaderPageItem from "../custom/HeaderPageItem";
import InfoItem from "../custom/InfoItem";
import Number from "../custom/Number";
import PageContainer, { CardPageItem, PageItem } from "../custom/Page";
import { HashResult, HashType, networkNameByNetwork, Roles, validateCriptoAddress } from "../util";
import ContractDetail from "./components/ContractDetail";
import FormFifty from "./components/FormFifty";
import FormPayment from "./components/FormPayment";
import { QuestionDialog } from "./components/QuestionDialog";
import { TerminateContractDialog } from "./components/TerminateContractDialog";

class AdminContract extends Component {
  state = {
    contract: null,
    sendingFile: false,
    step: false,
    newPrevisionAmount: { previsionAmount: 4500 },
    btn: true,
    terminate: false,
    cancel: false,
    verifyCancel: false,
    modal: false,
    loading: true,
    sendingTransaction: false,
    transactionIdSent: null,
    forceSubmitTransaction: false,
    disableBt: true,
    popupAportForm: false,
  };

  componentDidMount() {
    this.loadContract();
  }

  async loadContract() {
    this.setState({ contract: null, loading: true });
    let contract = await getContract(this.props.match.params.id);
    if (contract.status == "ARCHIVE") {
      let payment = await getRedemptionPayment(contract.id);
      this.setState({ payment });
    }
    this.setState({ contract, loading: false });
  }

  validateTxBeforeSubmitAport = (formProps) => {
    const { forceSubmitTransaction } = this.state;
    return new Promise((resolve, reject) => {
      this.setState({ sendingTransaction: true });
      const networkOnly = formProps.network.split("/")[1];
      if (forceSubmitTransaction) {
        this.setState({ sendingTransaction: false });
        resolve();
      }
      validateCriptoAddress(formProps.transactionId)
        .then((data) => {
          if (data.result === HashResult.NOT_FOUND) {
            reject("app.transaction.invalid");
            this.setState({ sendingTransaction: false });
          } else if (data.type !== HashType.TRANSACTION) {
            reject("app.transaction.invalid.2");
            this.setState({ sendingTransaction: false });
          } else if (data.network !== networkOnly) {
            reject(<FormattedHTMLMessage id="app.transaction.invalid.3" values={{ values: data.network + "/" + data.token }} />);
            this.setState({ sendingTransaction: false });
          } else if (data.network !== networkOnly) {
            reject(<FormattedHTMLMessage id="app.transaction.invalid.3" values={{ values: data.network + "/" + data.token }} />);
            this.setState({ sendingTransaction: false });
          } else {
            this.setState({ sendingTransaction: false });
            resolve();
          }
        })
        .catch((error) => {
          if (!forceSubmitTransaction) {
            reject("app.transaction.invalid.1");
          } else {
            resolve();
          }
        });
    });
  };

  onAportSubmitOk = () => {
    console.log("onAportSubmitOk 1");
    this.props.reset();
    console.log("onAportSubmitOk 2");
    this.setState({
      popupAportForm: false,
      sendingTransaction: false,
      transactionIdSent: null,
      forceSubmitTransaction: false,
      transactionIdSent: null,
    });
    console.log("onAportSubmitOk 3");
    this.loadContract();
  };

  onAportSubmitError = (transactionIdSent) => {
    this.setState({
      sendingTransaction: false,
      forceSubmitTransaction: false,
      transactionIdSent,
    });
  };

  onSubmitAport = async (formProps) => {
    const { showSuccessMessage, showErrorMessage } = this.props;

    if (formProps.network === "NONE/NONE") {
      try {
        let data = await sendContractTransaction(this.state.contract.id, formProps);
        showSuccessMessage("app.editContract.aportValue");
        this.onAportSubmitOk();
        return;
      } catch (error) {
        showErrorMessage(error.response.data.message);
        this.onAportSubmitError(null);
        return;
      }
    }

    try {
      console.log("validateTxBeforeSubmitAport", formProps);
      await this.validateTxBeforeSubmitAport(formProps);
      try {
        console.log("then sendContractTransaction", this.state.contract.id);
        let data = await sendContractTransaction(this.state.contract.id, formProps);
        console.log("sendContractTransaction then data", data);
        try {
          showSuccessMessage("app.editContract.aportValue");
          this.onAportSubmitOk();
        } catch (e1) {
          console.log("e1", e1);
        }
      } catch (error) {
        console.log("sendContractTransaction catch error", error);
        showErrorMessage(error.response.data.message);
        console.log("sendContractTransaction catch error 2");
        try {
          this.onAportSubmitError(null);
        } catch (e2) {
          console.log("e2", e2);
        }
      }
    } catch (errorMessage) {
      console.log("validateTxBeforeSubmitAport catch error", errorMessage);
      showErrorMessage(errorMessage);
      this.onAportSubmitError(formProps.transactionId);
    }
  };

  terminate = (params) => {
    const { showSuccessMessage, showErrorMessage } = this.props;
    terminateContract(this.state.contract.id, params)
      .then((data) => {
        showSuccessMessage("app.contract.finished.successfully");
        this.setState({ contract: data, terminate: false });
      })
      .catch((e) => {
        showErrorMessage(e.message);
      });
  };

  cancel = () => {
    const { showErrorMessage, showSuccessMessage } = this.props;
    cancelContract(this.props.match.params.id)
      .then((data) => {
        showSuccessMessage("app.terminated.successfully");
        this.loadContract();
        this.setState({ cancel: false });
      })
      .catch((e) => {
        showErrorMessage(e.response ? e.response.data.message : e);
      });
  };

  goEdit = () => {
    const { history } = this.props;
    const { contract } = this.state;
    history.push("/editContract/" + contract.id);
  };

  validateContract() {
    const { contract } = this.state;
    const { showErrorMessage, showSuccessMessage } = this.props;
    validateContract(contract.id)
      .then((data) => {
        showSuccessMessage("app.contract.validated");
        this.loadContract();
      })
      .catch((error) => {
        showErrorMessage(error.response.data.message);
      });
  }

  awaitingValidationReturnedContract() {
    const { contract } = this.state;
    const { showErrorMessage, showSuccessMessage } = this.props;
    awaitingValidationContract(contract.id)
      .then((data) => {
        showSuccessMessage("app.awaitingValidation.returned");
        this.loadContract();
      })
      .catch((error) => {
        showErrorMessage(error.response.data.message);
      });
  }

  render() {
    const { handleSubmit, currentUser } = this.props;
    const { loading, contract, payment, modal, popupAportForm, sendingTransaction, transactionIdSent, menuAnchor, step, terminate, verifyCancel, cancel } = this.state;

    if (loading) {
      return (
        <div style={{ textAlign: "center" }}>
          <CircularProgress style={{ marginTop: "25%" }} />
        </div>
      );
    }

    const numberMask = createNumberMask({
      decimalPlaces: 2,
      locale: "pt-BR",

      onChange: (v) => {
        if (v < contract.amount) {
          this.setState({ btn: true });
        } else {
          this.setState({
            btn: false,
            newPrevisionAmount: { previsionAmount: v },
          });
        }
      },
    });

    return (
      <PageContainer>
        <HeaderPageItem
          title="app.manageContract"
          showBackButton
          destination={"/user/" + (contract.user ? contract.user.id : "")}
          onMore={(e) => this.setState({ menuAnchor: e.target })}
          values={contract.id}
        >
          <Menu onClose={(e) => this.setState({ menuAnchor: undefined })} open={menuAnchor !== undefined} anchorEl={menuAnchor}>
            <MenuItem disabled={contract.phase !== "EXECUTING"} component={Link} to={"/contract/" + contract.id} onClick={(e) => this.setState({ menuAnchor: undefined })}>
              <FormattedMessage id="app.contractDetails" />
            </MenuItem>

            {/* 
                    O menu de editar está sempre disponível porque lá dentro edita-se as observações.
                    O form encarrega-se de impedir mudanças impróprias como, por exemplo, tipo ou valor se o contrato já está ativo.
                    Condição original era:
                    disabled={contract.status !== "INACTIVE" || contract.phase == "VALIDATING"}
            */}
            {(currentUser.role == Roles.Admin || currentUser.role == Roles.FinanceManager) && [
              <MenuItem
                key="app.editContract"
                component={Link}
                to={"/editContract/" + contract.id}
                onClick={(e) => {
                  this.setState({ menuAnchor: undefined });
                }}
              >
                <FormattedMessage id="app.editContract" />
              </MenuItem>,

              <MenuItem
                key="app.addOrRemove.Fifty"
                disabled={contract.status == "ARCHIVE"}
                onClick={(e) => {
                  this.setState({ step: true, menuAnchor: undefined });
                }}
              >
                {!contract.splitAgent ? <FormattedMessage id="app.addOrRemove.Fifty" /> : <FormattedMessage id="app.editOrRemove.Fifty" />}
              </MenuItem>,

              <MenuItem
                key="app.cancelContract"
                disabled={contract.status !== "INACTIVE"}
                onClick={(e) => {
                  this.setState({ verifyCancel: true });
                  this.setState({ menuAnchor: undefined });
                }}
              >
                <FormattedMessage id="app.cancelContract" />
              </MenuItem>,
            ]}

            {currentUser.role == Roles.Admin && [
              <MenuItem
                key="app.finishContract"
                disabled={contract.phase !== "EXECUTING"}
                onClick={(e) => {
                  this.setState({ terminate: true });
                  this.setState({ menuAnchor: undefined });
                }}
              >
                <FormattedMessage id="app.finishContract" />
              </MenuItem>,

              contract.phase == "VALIDATING" && (
                <MenuItem
                  key="app.awaitingValidation.return"
                  onClick={(e) => {
                    this.awaitingValidationReturnedContract();
                    this.setState({ menuAnchor: undefined });
                  }}
                >
                  <FormattedMessage id="app.awaitingValidation.return" />
                </MenuItem>
              ),
            ]}
          </Menu>
        </HeaderPageItem>
        <FormDialog title={!contract.splitAgent ? "app.addOrRemove.Fifty" : "app.editOrRemove.Fifty"} open={step} variant="secondary" onClose={(e) => this.setState({ step: false })}>
          <FormFifty
            contract={contract}
            onActionComplete={(e) => {
              this.loadContract();
              this.setState({ step: false });
            }}
          />
        </FormDialog>
        <TerminateContractDialog
          title={"app.finishContract"}
          onCancel={() => {
            this.setState({ terminate: false });
          }}
          open={terminate}
          onOk={(choose) => () => this.terminate(choose)}
        >
          <FormattedMessage id="app.finishContract.text" />
        </TerminateContractDialog>

        <QuestionDialog
          title={<FormattedMessage id="app.cancelContract" />}
          onCancel={() => this.setState({ cancel: true, verifyCancel: false })}
          open={verifyCancel}
          onOk={() => {
            this.goEdit();
          }}
        >
          <>
            <InfoItem caption={<FormattedMessage id="app.estimatedAmount" />} text={<Number value={contract.previsionAmount} currency="LCT" />} />
            <InfoItem caption={<FormattedMessage id="app.aport.value" />} text={<Number value={contract.amount} currency="LCT" />} />
            <FormattedMessage id="app.cancelContract.text1" />
            <br />
            <br />
            <FormattedMessage id="app.cancelContract.text2" />
            <br />
            <br />
            <FormattedMessage id="app.cancelContract.text3" />
          </>
        </QuestionDialog>

        <QuestionDialog
          title={<FormattedMessage id="app.cancelContract" />}
          onCancel={() => this.setState({ cancel: false })}
          open={cancel}
          onOk={() => {
            this.cancel();
          }}
        >
          <FormattedMessage id="app.cancelContract.text" />
        </QuestionDialog>

        {Object.entries(contract).length > 0 ? (
          <PageItem>
            <ContractDetail contract={contract} user={this.props.currentUser} />
          </PageItem>
        ) : (
          <PageItem>LCContract not found or with no data.</PageItem>
        )}

        <PageItem>
          <Card style={{ marginBottom: 16 }}>
            <CardContent>
              <Typography variant="caption" color="textSecondary" component="p">
                <FormattedMessage id="app.aports" />
              </Typography>
              {contract.deposits.length > 0 ? (
                <List dense>
                  {contract.deposits.map((d) => (
                    <DepositListItem key={d.id} deposit={d} />
                  ))}
                </List>
              ) : (
                <Typography variant="body2">(N/A)</Typography>
              )}
              {contract.phase == "WAITING_APORT" && (
                <div style={{ textAlign: "center" }}>
                  <Button id="bShowAportPopup" variant="outlined" color="primary" style={{ minWidth: 250 }} onClick={(e) => this.setState({ popupAportForm: true })}>
                    <FormattedMessage id="app.type.network.2" />
                  </Button>
                </div>
              )}
            </CardContent>
          </Card>
        </PageItem>

        {contract.phase == "WAITING_APORT" && contract.deposits.length > 0 && (
          <div style={{ textAlign: "center", width: "inherit" }}>
            <Typography gutterBottom variant="body1" style={{ color: "red" }}>
              <FormattedMessage id="app.type.network.1" />
            </Typography>
            <Button id="bValidateContract" style={{ minWidth: 250, marginBottom: 16 }} variant="contained" color="primary" disabled={contract.amount <= 1} onClick={() => this.validateContract()}>
              <FormattedMessage id="app.lccontract.validate" />
            </Button>
          </div>
        )}

        <PageItem>
          {payment && (
            <>
              <CardPageItem raised style={{ marginTop: "10px" }}>
                <CardContent>
                  <Typography variant="caption" color="primary">
                    <FormattedMessage id="app.payments" />
                  </Typography>
                  <CardActionArea disabled={payment.payed} key={payment.id} onClick={(e) => this.setState({ modal: true })}>
                    <CardContent
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div key={payment.id} style={{ flexGrow: 1 }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              flexDirection: "column",
                            }}
                          >
                            <Typography color="primary">{payment.id}</Typography>
                            <Typography color="textSecondary">({payment.payed ? <FormattedMessage id="app.paid" /> : <FormattedMessage id="app.paid.not" />})</Typography>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              flexDirection: "column",
                            }}
                          >
                            <Typography color="textSecondary" variant="caption">
                              <FormattedMessage id="app.fine" />
                            </Typography>
                            <Typography color="textSecondary">
                              <Number value={payment.fine} currency="LCT" />
                            </Typography>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              flexDirection: "column",
                            }}
                          >
                            <Typography color="textSecondary">
                              <Number value={payment.value} currency="LCT" />
                            </Typography>

                            <Typography color="textSecondary">
                              <Moment date={payment.paymentDate} format="DD/MM/YYYY" />
                            </Typography>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingLeft: 16,
                        }}
                      >
                        <ChevronRightIcon color="action" />
                      </div>
                    </CardContent>
                  </CardActionArea>
                </CardContent>
              </CardPageItem>
              <FormDialog
                title="app.payment.edit"
                open={modal}
                variant="secondary"
                noHeader
                onClose={(e) => {
                  this.setState({ modal: false });
                }}
              >
                <FormPayment
                  type={payment}
                  onActionComplete={() => {
                    this.setState({ modal: false });
                    this.loadContract();
                  }}
                />
              </FormDialog>
            </>
          )}
        </PageItem>

        <FormDialog open={popupAportForm} title="app.type.network.2" variant="secondary" noHeader onClose={(e) => this.setState({ popupAportForm: false })}>
          {sendingTransaction ? (
            <div style={{ textAlign: "center" }}>
              <CircularProgress style={{ marginTop: "25%" }} />
            </div>
          ) : (
            <form onSubmit={handleSubmit(this.onSubmitAport)} style={{ marginTop: 16 }}>
              <Field
                name="network"
                label="app.type.network"
                component={CustomSelectNormal}
                values={[
                  { value: "BTC/BTC", input: networkNameByNetwork["BTC/BTC"] },
                  { value: "USDT/TRX", input: networkNameByNetwork["USDT/TRX"] },
                  { value: "USDT/ETH", input: networkNameByNetwork["USDT/ETH"] },
                  { value: "USDT/BSC", input: networkNameByNetwork["USDT/BSC"] },
                  { value: "ETH/ETH", input: networkNameByNetwork["ETH/ETH"] },
                  { value: "NONE/NONE", input: "Nenhuma" },
                ]}
                InputLabelProps={{ shrink: true }}
              />
              <Field name="transactionId" fullWidth component={CustomInputForm} label={"app.aport.transaction"} style={{ marginTop: 16 }} InputLabelProps={{ shrink: true }} />
              <Field name="amount" fullWidth component={CustomInputForm} {...numberMask} label={"app.aport.value"} style={{ marginTop: 16 }} InputLabelProps={{ shrink: true }} />
              <Button
                id="bSubmitAport"
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                style={{ minWidth: 250, marginTop: 16, marginBottom: 16 }}
                disabled={this.props.pristine || this.props.submitting || this.props.invalid}
              >
                <FormattedMessage id="app.sendTransaction" />
              </Button>
              <div style={{ marginTop: 48 }}>
                {transactionIdSent && (
                  <>
                    <Typography gutterBottom>
                      Verifique a transação manualmente.
                      <br />
                      Se a rede estiver incorreta, basta corrigir selecionando outra acima.
                      <br />
                      Como último recurso, utilize o botão para forçar o envio.
                      <br />
                    </Typography>
                    <Button id="bManuallyVerifyTransaction" component="a" target="_blank" fullWidth variant="outlined" href={"https://tokenview.com/en/search/" + transactionIdSent}>
                      Verifiy transaction manually
                    </Button>
                    <Button
                      id="bForceSubmitOrderForm"
                      fullWidth
                      variant="outlined"
                      color="primary"
                      size="large"
                      type="submit"
                      style={{ marginTop: 16 }}
                      disabled={this.props.pristine || this.props.submitting || this.props.invalid}
                      onClick={(e) => this.setState({ forceSubmitTransaction: true })}
                    >
                      Force save transaction
                    </Button>
                  </>
                )}
              </div>
            </form>
          )}
        </FormDialog>
      </PageContainer>
    );
  }
}

const explorerURL = {
  "USDT/TRX": "https://tronscan.org/#/transaction/",
  "USDT/ETH": "https://etherscan.io/tx/",
  "USDT/BSC": "https://bscscan.com/tx/",
  "BTC/BTC": "https://www.blockchain.com/pt/btc/tx/",
  "ETH/ETH": "https://etherscan.io/tx/",
  "NONE/NONE": "https://tokenview.com/en/search/",
};

function DepositListItem({ deposit, ...props }) {
  if (deposit.network !== "NONE/NONE") {
    props.component = "a";
    props.href = explorerURL[deposit.network] + deposit.transactionId;
    props.target = "_blank";
    props.button = true;
  }
  return (
    <ListItem disableGutters {...props}>
      <ListItemText
        primary={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Moment date={deposit.createdAt} format="DD/MM/YYYY" />
              {deposit.network !== "NONE/NONE" && (
                <Typography variant="caption" color="textSecondary" style={{ paddingLeft: 16 }}>
                  {deposit.network}
                </Typography>
              )}
            </div>
            <Number value={deposit.amount} currency="LCT" />
          </div>
        }
        secondary={<div>{deposit.transactionId}</div>}
      />
      {deposit.network !== "NONE/NONE" && <ChevronRight color="action" />}
    </ListItem>
  );
}

const validate = (values, props) => {
  const errors = {};
  if (!values.transactionId) {
    errors.transactionId = "app.transaction.report";
  } else if (values.transactionId.trim().length == 0) {
    errors.transactionId = "app.aport.invalid";
  }
  if (!values.network) {
    errors.network = "app.transaction.report";
  }
  if (!values.amount) {
    errors.amount = "app.transaction.report";
  }
  return errors;
};

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default compose(
  reduxForm({
    form: "transForm",
    validate,
  }),
  connect(mapStateToProps, actions)
)(AdminContract);
