import { Roles, isAgent, isAgentManager } from ".";
import { getContracts, getUsersBelowMe } from "../actions";

export function isCustomer(user) {
  return user.role !== Roles.Agent;
}

export function isDirectBelowId(user, id) {
  return user.agent?.id === id;
}

export function isAgentManagerBelowId(manager, id) {
  return isAgentManager(manager) && isDirectBelowId(manager, id);
}

export function isAgentManagerBelow(manager, user) {
  return isAgentManagerBelowId(manager, user.id);
}

export function isAgentBelowId(agent, id) {
  return isAgent(agent) && isDirectBelowId(agent, id);
}

export function isAgentBelow(agent, user) {
  return isAgentBelowId(agent, user.id);
}

export function isCustomerBelowId(customer, id) {
  return isCustomer(customer) && isDirectBelowId(customer, id);
}

export function isCustomerBelow(customer, user) {
  return isCustomerBelowId(customer, user.id);
}

export function compareContracts(c1, c2) {
  const cpStatus = c1.status - c2.status;
  const cpId = c2.id - c1.id;
  if (cpStatus !== 0) {
    return cpStatus;
  } else return cpId;
}

export function simplifyUser(user, users, userCallBack) {
  user.agent = users.find((u) => u.id === user.consultant?.id);
  if (!user.agent && user.consultant) {
    // Este caso ocorre quando o agent do user não é visível para o usuário atual.
    // Ou seja, ele não vai estar na lista users. Nesse caso, cria um objeto contendo pelo menos o id do agent, que já que é o único dado conhecido.
    user.agent = { id: user.consultant.id };
  }
  user.manager = users.find((u) => u.id === user.consultant?.consultantId);
  if (!user.manager && user.consultant?.consultantId) {
    // Este caso ocorre quando o manager do user não é visível para o usuário atual.
    // Ou seja, ele não vai estar na lista users. Nesse caso, cria um objeto contendo pelo menos o id do manager, que já que é o único dado conhecido.
    user.manager = { id: user.consultant.consultantId };
  }
  user.contracts = [];
  user.commissionContracts = [];
  user.managementContracts = [];
  user.splitContracts = [];
  if (user.contractAgent) {
    user.contractAgent = {
      id: user.contractAgent.id,
      type: user.contractAgent.type,
      status: user.contractAgent.status,
      managerComission: user.contractAgent.managerComission,
    };
  }
  for (const p in user) {
    if (
      ![
        "id",
        "name",
        "role",
        "email",
        "apt",
        "agent",
        "manager",
        "contractAgent",
        "contracts",
        "commissionContracts",
        "managementContracts",
        "splitContracts",
      ].includes(p)
    ) {
      delete user[p];
    }
  }
  if (userCallBack instanceof Function) {
    userCallBack(user);
  }
  return user;
}

export function simplifyUsers(users, userCallBack) {
  users.forEach((u) => simplifyUser(u, users, userCallBack));
}

export function simplifyContract(contract, users, preserveOriginalProps = false) {
  contract.customer = users.find((u) => u.id === contract.user.id);
  contract.user = contract.customer; // Para compatibilidade com métodos que procuram o "user" dentro do contrato.
  contract.agent = contract.customer.agent;
  if (![1, 25].includes(contract.customer.manager?.id)) {
    // Notar que o cliente pode ter um manager, mas o seu contrato não.
    // Isso porque os users 1 e 25 são válidos como manager na hierarquia, mas não são válidos para fins de cálculos do contrato.
    contract.manager = contract.customer.manager;
  }
  let splitAgent = users.find((u) => u.id === contract.splitAgent?.id);
  if (!splitAgent && contract.splitAgent?.id) {
    splitAgent = {
      id: contract.splitAgent.id,
    };
  }
  contract.splitAgent = splitAgent;
  contract.splitEffectivePercent = contract.splitPercent * contract.type.agentCommission;
  contract.type = {
    id: contract.type.id,
    agentCommission: contract.type.agentCommission,
    description: contract.type.description,
  };
  if (!preserveOriginalProps) {
    for (const p in contract) {
      if (
        ![
          "id",
          "customer",
          "user", // Para compatibilidade com métodos que procuram o "user" dentro do contrato.
          "agent",
          "manager",
          "splitAgent",
          "splitEffectivePercent",
          "amount",
          "type",
          "investmentId",
          "status",
          "phase",
          "executing",
          "statusRedeemPaid",
          "yield",
          "birthday",
          "initialDate",
          "dueDate",
          "updatedAt",
          "archiveDate",
          "canceledBy",
        ].includes(p)
      ) {
        delete contract[p];
      }
    }
  }
  return contract;
}

export function simplifyContracts(contracts, users) {
  contracts.forEach((c) => simplifyContract(c, users));
}

export function calculateGeneratedValues(contracts, executingOnly = true) {
  contracts.forEach((contract) => {
    contract.generated = {};
    if (contract.customer) contract.customer.contracts.push(contract);
    if (!executingOnly || contract.executing) {
      contract.generated = {
        return: contract.yield.val,
        management: contract.amount * contract.manager?.contractAgent?.managerComission,
        commission: contract.amount * ((contract.type.agentCommission - contract.splitEffectivePercent) / 100),
        split: contract.amount * (contract.splitEffectivePercent / 100),
      };
      if (contract.agent && contract.agent.id !== 1) contract.agent.commissionContracts.push(contract);
      // Os casos abaixo são especiais porque o manager no contrato pode não apontar para um user completo, mas sim, ser apenas id.
      // Isso ocorre qaundo o manager não é visível pelo usuário atual. Idem para o usuário que recebe split.
      if (contract.manager && contract.manager.managementContracts) contract.manager.managementContracts.push(contract);
      if (contract.splitAgent && contract.splitAgent.splitContracts) contract.splitAgent.splitContracts.push(contract);
    }
  });
}

export function calculateReceivedValues(users) {
  users.forEach((user) => {
    user.received = {
      return: user.contracts.filter((c) => c.executing).reduce((totalReturn, contract) => totalReturn + contract.generated.return, 0),
      management: user.managementContracts.reduce((totalManagement, contract) => totalManagement + contract.generated.management, 0),
      commission: user.commissionContracts.reduce((totalCommission, contract) => totalCommission + contract.generated.commission, 0),
      split: user.splitContracts.reduce((totalSplit, contract) => totalSplit + contract.generated.split, 0),
    };
  });
}

export function fillBellow(user, allUsers) {
  user.managers = filterAndFillBelow(allUsers, (u) => isAgentManagerBelow(u, user));
  user.agents = filterAndFillBelow(allUsers, (u) => isAgentBelow(u, user));
  user.customers = filterAndFillBelow(allUsers, (u) => isCustomerBelow(u, user));
}

export function filterAndFillBelow(allUsers, filterFunc) {
  const users = allUsers.filter(filterFunc);
  users.forEach((u) => {
    fillBellow(u, allUsers);
  });
  return users;
}

export async function getUsersBelowMeSimplified(usersToInclude, userCallBack) {
  let usersVisibleToMe = await getUsersBelowMe();
  if (usersToInclude && usersToInclude.length > 0) {
    usersVisibleToMe = [...usersToInclude, ...usersVisibleToMe];
  }
  usersVisibleToMe.sort((u1, u2) => u1.name.localeCompare(u2.name));
  simplifyUsers(usersVisibleToMe, userCallBack);
  return usersVisibleToMe;
}

export async function getUsersAndContractsBelowMe(currentUser, userCallBack) {
  const usersVisibleToMe = await getUsersBelowMeSimplified([currentUser], userCallBack);
  const contractsVisibleToMe = await getContracts();
  contractsVisibleToMe.sort((c1, c2) => compareContracts);
  simplifyContracts(contractsVisibleToMe, usersVisibleToMe);

  calculateGeneratedValues(contractsVisibleToMe);
  calculateReceivedValues(usersVisibleToMe);

  let managers = [];
  let agents = [];
  let customers = [];
  let top = [];
  let topRole = null;

  fillBellow(currentUser, usersVisibleToMe);
  top = [currentUser];
  if (isAgentManager(currentUser)) {
    managers = top;
    topRole = Roles.AgentsManager;
  } else if (isAgent(currentUser)) {
    agents = top;
    topRole = Roles.Agent;
  } else {
    customers = top;
    topRole = Roles.Customer;
  }

  return [managers, agents, customers, top, topRole];
}
