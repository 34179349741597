import React, { Component } from "react";
import { connect } from "react-redux";
import ContractAgentDescriptionBR from "./ContractAgentDescriptionBR";
import ContractAgentDescriptionEN from "./ContractAgentDescriptionEN";
import ContractAgentDescriptionES from "./ContractAgentDescriptionES";
import ContractAgentManagerDescriptionBR from "./ContractAgentManagerDescriptionBR";
import ContractAgentManagerDescriptionEN from "./ContractAgentManagerDescriptionEN";
import ContractAgentManagerDescriptionES from "./ContractAgentManagerDescriptionES";

class ContractAgentDescription extends Component {
  render() {
    const { contractUser, contractAgent, currentUser } = this.props;
    if (contractAgent.type === "MANAGER") {
      if (currentUser.locale === "br") {
        return <ContractAgentManagerDescriptionBR contractUser={contractUser} contractAgent={contractAgent} />;
      } else if (currentUser.locale === "en") {
        return <ContractAgentManagerDescriptionEN contractUser={contractUser} contractAgent={contractAgent} />;
      } else if (currentUser.locale === "es") {
        return <ContractAgentManagerDescriptionES contractUser={contractUser} contractAgent={contractAgent} />;
      }
    } else {
      if (currentUser.locale === "br") {
        return <ContractAgentDescriptionBR contractUser={contractUser} contractAgent={contractAgent} />;
      } else if (currentUser.locale === "en") {
        return <ContractAgentDescriptionEN contractUser={contractUser} contractAgent={contractAgent} />;
      } else if (currentUser.locale === "es") {
        return <ContractAgentDescriptionES contractUser={contractUser} contractAgent={contractAgent} />;
      }
    }
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default connect(mapStateToProps, null)(ContractAgentDescription);
