import React, { Component } from "react";
import { CardContent, Typography, Button, CircularProgress } from "@material-ui/core";
import PageContainer, { CardPageItem } from "../custom/Page";
import HeaderPageItem from "../custom/HeaderPageItem";
import { authorizeWithdrawal } from "../actions";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import * as actions from "../actions";

class AuthorizeWithdrawal extends Component {
  state = {
    error: undefined,
    loading: true
  };

  componentDidMount() {
    const {
      currentUser,
      match: {
        params: { token }
      }
    } = this.props;
    if (currentUser.id) {
      authorizeWithdrawal(token)
        .then(e => {
          this.setState({ loading: false, error: false });
        })
        .catch(e => {
          this.setState({
            error: e.response ? e.response.data.message : e,
            loading: false
          });
        });
    } else {
      this.setState({ loading: false });
    }
  }
  onSubmit = () => {
    const {
      history,
      match: { url }
    } = this.props;
    history.push("/login?continue=" + url);
  };

  goHome = () => {
    const { history } = this.props;
    history.push("/dashboard");
  };

  render() {
    const { error, loading } = this.state;
    const { currentUser } = this.props;
    if (loading) return <CircularProgress style={{ marginTop: "50%" }} />;
    return (
      <PageContainer>
        {currentUser.id ? (
          <HeaderPageItem title="app.confirmWithdrawl" showBackButton destination={"/history"} />
        ) : (
          <HeaderPageItem title="app.confirmWithdrawl" />
        )}

        <CardPageItem raised>
          {currentUser.id ? (
            <CardContent>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center"
                }}
              >
                {error ? (
                  <Typography gutterBottom variant="body1">
                    <FormattedMessage id={error} />
                  </Typography>
                ) : (
                  <>
                    <Typography gutterBottom variant="body1">
                      <FormattedMessage id="app.withdrawal.confirm" />
                    </Typography>

                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      style={{ marginTop: 16, marginBottom: 16 }}
                      onClick={() => this.goHome()}
                    >
                      <FormattedMessage id="app.navigationBar.home" />
                    </Button>
                  </>
                )}
              </div>
            </CardContent>
          ) : (
            <CardContent>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center"
                }}
              >
                <Typography gutterBottom variant="body1">
                  <FormattedMessage id="app.confirmWithdrawl.Text1" />
                </Typography>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{ marginTop: 16, marginBottom: 16 }}
                  onClick={() => this.onSubmit()}
                >
                  <FormattedMessage id="app.signin.button" />
                </Button>
              </div>
            </CardContent>
          )}
        </CardPageItem>
      </PageContainer>
    );
  }
}
function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me
  };
}
export default connect(mapStateToProps, actions)(AuthorizeWithdrawal);
