import React from "react";
import { withTheme } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const ExpansionPanelIcon = ({ theme }) => {
  return (
    <ExpandMoreIcon
      color="primary"
      style={{
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderRadius: "50%"
      }}
    />
  );
};

export default withTheme(ExpansionPanelIcon);
