import {
  AppBar,
  CardContent,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  Badge
} from "@material-ui/core";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/Settings";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getPendingContracts, getTerminatedContracts } from "../actions";
import AbtcAvatar from "../custom/Avatar";
import HeaderPageItem from "../custom/HeaderPageItem";
import PageContainer, { CardPageItem } from "../custom/Page";
import { getPendingKyc, getAnalyzingKyc } from "../actions/Kyc";
import { getKyc } from "../actions";
import { badgesKyc } from "../custom/BadgesKyc";
import { FormattedMessage } from "react-intl";

class KycDashboard extends Component {
  state = {
    signedContracts: [],
    fundedContracts: [],
    withdrawContracts: [],
    closedContracts: [],
    withdrawals: [],
    pendingKyc: [],
    analyzingKyc: [],
    pj: this.props.currentUser.isPj
  };

  loadKyc = () => {
    getKyc().then(data => {
      const userKyc = data;
      this.setState({ userKyc });
    });
  };
  componentDidMount() {
    this.loadKyc();

    getPendingContracts().then(data => {
      const signedContracts = data.filter(
        c =>
          c.phase == "WAITING_SIGN" &&
          c.fileUrl &&
          (c.customerName = "app.customerName")
      );
      const fundedContracts = data.filter(
        c =>
          c.phase == "WAITING_APORT" &&
          c.deposits.length > 0 &&
          (c.customerName = "app.customerName")
      );
      // Disparar a carga de dados dos usuários para cada contrato nos arrays acima.
      this.setState({ signedContracts, fundedContracts });
    });
    getTerminatedContracts().then(data => {
      const closedContracts = data.filter(
        c =>
          c.phase == "WAITING_WITHDRAW" && (c.customerName = "app.customerName")
      );
      this.setState({ closedContracts });
    });
    // getPendingWithdrawals().then(data => this.setState({ withdrawals: data }));
    getPendingKyc().then(data => this.setState({ pendingKyc: data }));
    getAnalyzingKyc().then(data => this.setState({ analyzingKyc: data }));
  }

  pendent = (badges, currentUser) => {
    if (currentUser.telefone == null && currentUser.telefone2 == null) {
      badges.qtd = badges.qtd + 1;
      badges.cor = "error";
    }
    if (!currentUser.emailConfirmed) {
      badges.qtd = badges.qtd + 1;
      badges.cor = "error";
    }
    return badges;
  };

  render() {
    const { currentUser } = this.props;
    const { userKyc, pj } = this.state;
    let badges = badgesKyc(pj, userKyc);
    badges = this.pendent(badges, currentUser);

    return (
      <PageContainer removeSpacing>
        <HeaderPageItem style={{ height: 210 }}>
          <AppBar
            position="relative"
            style={{
              height: 110,
              paddingTop: 30,
              display: "flex",
              alignItems: "center"
            }}
          >
            <IconButton
              style={{ position: "absolute", left: 0, top: 0 }}
              component={Link}
              to="/logout"
            >
              <ExitToAppIcon
                htmlColor="#23323C"
                style={{ transform: "rotate(180deg)" }}
              />
            </IconButton>
            <IconButton
              style={{ position: "absolute", right: 0, top: 0 }}
              component={Link}
              to="/more"
            >
              <Badge badgeContent={badges.qtd} color={badges.cor}>
                <SettingsIcon htmlColor="#23323C" />
              </Badge>
            </IconButton>
            <AbtcAvatar
              imageUrl={currentUser.photoUrl}
              disableLink
              size={160}
              avatarStyle={{ border: "8px rgba(254, 193, 44, 1) solid" }}
              style={{}}
            />
          </AppBar>
        </HeaderPageItem>

        <CardPageItem>
          <CardContent>
            <Typography
              variant="h6"
              style={{ width: "100%", textAlign: "center" }}
            >
              {currentUser.name}
              <br />
              (<FormattedMessage id="app.enuns.ROLE_KYC" />)
            </Typography>
          </CardContent>
        </CardPageItem>

        <CardPageItem raised>
          <CardContent>
            <Typography variant="caption" color="primary">
              <FormattedMessage id="app.analysingDocuments" />
            </Typography>
            <List>
              {this.state.analyzingKyc.map(c => (
                <ListItem key={c.id} component={Link} to={"/user/" + c.id}>
                  <ListItemText primary={c.name} />
                  <ChevronRight color="action" />
                </ListItem>
              ))}
            </List>
          </CardContent>
        </CardPageItem>
        <CardPageItem raised>
          <CardContent>
            <Typography variant="caption" color="primary">
              <FormattedMessage id="app.pendingDocuments" />
            </Typography>
            <List>
              {this.state.pendingKyc.map(c => (
                <ListItem key={c.id} component={Link} to={"/user/" + c.id}>
                  <ListItemText primary={c.name} />
                  <ChevronRight color="action" />
                </ListItem>
              ))}
            </List>
          </CardContent>
        </CardPageItem>
      </PageContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me
  };
}

export default connect(mapStateToProps, null)(KycDashboard);
