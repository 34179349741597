import React from "react";
import classNames from "classnames";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import IconButton from "@material-ui/core/IconButton";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles1 = (theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    color: "white",
    display: "flex",
    alignItems: "center",
  },
});

function MySnackbarContent(props) {
  const {
    classes,
    className,
    message,
    onClose,
    variant,
    onRefresh,
    showClose = true,
    showRefreshButton = false,
    ...other
  } = props;
  const Icon = variantIcon[variant];
  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {typeof message === "string" && message.startsWith("app.") ? <FormattedMessage id={message} /> : message}
        </span>
      }
      action={
        <>
          {showClose && (
            <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={onClose}>
              <CloseIcon className={classes.icon} />
            </IconButton>
          )}
          {showRefreshButton && (
            <Button color="primary" size="small" variant="contained" onClick={onRefresh}>
              <FormattedMessage id="app.update" />
            </Button>
          )}
        </>
      }
      {...other}
    />
  );
}

export default withStyles(styles1)(MySnackbarContent);
