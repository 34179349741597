import React from "react";
import { css } from "@emotion/core";
import { DotLoader as LoaderAnimation } from "react-spinners";
//@see
//https://www.npmjs.com/package/react-spinners

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  margin-top: 25%;
`;

class LoaderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }
  render() {
    return (
      <div className="sweet-loading">
        <LoaderAnimation className={override} sizeUnit={"px"} size={50} color={"#C58C34"} loading={this.state.loading} />
      </div>
    );
  }
}

export default LoaderComponent;
