export const CREATE_USER = "create_user";
export const SHOW_TOAST = "show_toast";
export const ACTIVATE_USER = "activate_user";
export const CLOSE_TOAST = "close_toast";
export const SUCCESS_MESSAGE = "success_message";
export const ERROR_MESSAGE = "error_message";
export const AUTH_USER = "auth_user";
export const SET_USER = "set_user";
export const SET_AUTHORITY = "set_authority";
export const CRYPTO_PAYMENT = "crypto_payment";
export const FIAT_PAYMENT = "fiat_payment";
export const COUPON_PAYMENT = "coupon_payment";
export const SET_CONFIG = "set_config";
export const AUTH_FETCH = "auth_fetch";
export const PAYMENT_FETCH = "payment_fetch";
export const COUPON_FETCH = "coupon_fetch";
export const UPDATE_BALANCE = "update_balance";
export const ENABLE_2FA = "enable_2fa";
export const DISABLE_2FA = "disable_2fa";
export const ADMIN_PAYMENT = "admin_payment";
export const ALL_PAYMENTS = "all_payments";
export const PENDING_PAYMENT = "pending_payment";
export const APPROVE_PAYMENT = "approve_payment";
export const CANCEL_PAYMENT = "cancel_payment";
export const REMOVE_PENDING_PAYMENT = "remove_pending_payment";
export const QUOTIENT = "quotient";
export const FEED_FETCH = "feed_fetch";
export const USER_ME = "get_me";
export const CLEAR_ME = "clear_me";
export const USER_PREF = "user_pref";
export const USER_FETCH = "user_fetch";
export const USER_KYC = "user_kyc";
export const PRE_AUTH = "pre_auth";
export const SET_VERIFY2FA = "set_verify2fa";
export const USER_ACC = "user_acc";
export const USER_ADDACC = "user_addAcc";
export const USER_DELACC = "user_delAcc";
export const GET_ALLBANK = "get_allBanks";
export const GET_EXCHANGEACC = "get_exchange_accounts";
export const USER_EW = "user_external_walllet";
export const USER_ADDEW = "user_add_external_wallet";
export const USER_DELEW = "user_del_external_wallet";
export const USER_GET_WALLET = "user_get_wallet";
export const USER_GET_WALLETS = "user_get_wallets";
export const USER_GET_TRANSACTIONS_REQUEST = "user_get_transactions_request";
export const USER_SET_TRANSACTIONS_REQUEST = "user_set_transactions_request";
export const USER_DEL_TRANSACTIONS_REQUEST = "user_del_transactions_request";
export const USER_AVATAR = "user_avatar";
export const VERIFY_WALLET = "verify_wallet";

export const GET_CURRENCIE = "get_currencie";
export const GET_ALL_CURRENCIE = "get_all_currencie";
export const SET_CURRENT_CURRENCIE = "set_current_currencie";
export const GET_CURRENT_CURRENCIE = "get_current_currencie";
export const GET_PAIRS = "get_pairs";

export const CREATE_ORDER = "create_order";
export const UPDATE_ORDER = "update_order";
export const GET_ORDERS = "get_orders";
export const GET_ALL_ORDERS = "get_all_orders";
export const GET_MARKET_STATUS = "get_market_status";
export const GET_SELL_ORDERS = "get_sell_orders";
export const GET_BUY_ORDERS = "get_buy_orders";
export const GET_LAST_TRADES = "get_last_trades";

export const ALL_NOTIFICATIONS = "all_notifications";
export const ADD_NOTIFICATION = "add_notification";

export const SEND_CHAT_MESSAGE = "send_chat_message";
export const LOAD_HISTORY_MESSAGE = "load_history_message";

export const GET_EXCHANGE_WALLETS = "get_exchange_wallets";
export const WALLET_FETCH = "wallet_fetch";
export const GET_USER_ADDRESS = "get_user_address";

export const CREATE_VIRTUAL_TRANSACTION = "create_virtual_transaction";
export const GET_ALL_VIRTUAL_TRANSACTION = "get_all_virtual_transaction";

export const THEME_CHANGED = "theme_changed";

export const SET_COIN = "set_coin";

export const GET_BATCHS = "get_batchs";
export const SET_BATCH = "set_batch";
export const DELETE_BATCH = "delete_batch";
export const FINALIZE_BATCH = "finalize_batch";
export const UPDATE_BATCH = "update_batch";
export const CREATE_ORDER_OTC = "create_order_otc";
export const GET_ORDERS_OTC = "get_orders_otc";
export const REJECT_ORDER_OTC = "reject_order_otc";
export const CONFIRM_ORDER_OTC = "confirm_order_otc";
export const EXECUTE_ORDER_OTC = "execute_order_otc";
export const BALANCE_OK_ORDER_OTC = "BALANCE_OK_ORDER_OTC";

export const GET_OTC_CONFIG = "get_otc_config";
export const UPDATE_OTC_CONFIG = "update_otc_config";

export const GET_USER = "get_user";

export const CREATE_POST = "create_post";
export const UPDATE_POST = "update_post";
export const GET_POST = "get_post";
export const DEL_POST = "del_post";
export const COMMENT_POST = "comment_post";
export const INTERACT_POST = "interact_post";
export const SHARE_POST = "share_post";
export const GET_POSTS_BY_PROFILE = "get_posts_by_profile";
export const GET_ME_POSTS = "get_me_posts";
export const RE_SEND_ACTIVATE_EMAIL = "re_send_activate_email";
export const RE_SEND_VERIFY_WALLET_EMAIL = "re_send_verify_wallet_email";

export const CREATE_PROFILE = "create_profile";
export const GET_PROFILE = "get_profile";
export const UPDATE_PROFILE = "update_profile";
export const DELETE_PROFILE = "delete_profile";
export const ME_PROFILE = "me_profile";
export const BLOCK_PROFILE = "block_profile";
export const FOLLOW_PROFILE = "follow_profile";
export const UNBLOCK_PROFILE = "unblock_profile";
export const UNFOLLOW_PROFILE = "unfollow_profile";
export const ADD_PHOTO_PROFILE = "add_photo_profile";

//comment
export const GET_COMMENT = "get_comment";
export const CREATE_COMMENT = "create_comment";
export const UPDATE_COMMENT = "update_comment";
export const DELETE_COMMENT = "delete_comment";
export const INTERACT_COMMENT = "interact_comment";
export const ADD_TRENDINGS_POSTS = "add_trendings_posts";
export const ADD_FOLLOWING_POSTS = "add_following_posts";
export const GET_TRENDINGS_POSTS = "get_trendings_posts";
export const GET_FOLLOWING_POSTS = "get_following_posts";
export const ADD_POSTS_BY_PROFILE = "add_posts_by_profile";
export const GET_FOLLOWERS_PROFILE = "get_followers_profile";
export const GET_FOLLOWING_PROFILE = "get_following_profile";
export const REMOVE_FOLLOWERS_LIST = "remove_followers_list";
export const REMOVE_FOLLOWING_LIST = "remove_following_list";
export const GET_BLOCKEDS_PROFILE = "get_blockeds_profile";
export const REMOVE_BLOCKEDS_LIST = "remove_blockeds_list";

//Socket
export const CONNECTION_CHANGED = "socket/connection-changed";
export const PORT_CHANGED = "socket/port-changed";
export const CONNECT_SOCKET = "socket/connect";
export const DISCONNECT_SOCKET = "socket/disconnect";

// Status related actions
export const STATUS_CHANGED = "status/status-changed";

// Message related actions
export const USER_CHANGED = "message/user-changed";
export const RECIPIENT_CHANGED = "message/recipient-changed";
export const OUTGOING_MESSAGE_CHANGED = "message/outgoing-message-changed";
export const MESSAGE_RECEIVED = "message/message-received";
export const MESSAGE_SENT = "message/message-sent";
export const CLIENT_UPDATE_RECEIVED = "message/client-update-received";
export const SEND_MESSAGE = "message/send-message";
export const ABANDON_CHAT = "message/abandon-chat";

// Transaction
export const CANCEL_REQUEST = "cancel_request";

//User
export const USER_LOGOUT = "user_logout";
export const CREATE_NEWSLETTER = "create_newsletter";
export const DELETE_NEWSLETTER = "delete_newsletter";
export const UPDATE_NEWSLETTER = "update_newsletter";
export const ALL_NEWSLETTER = "all_newsletter";
export const SEND_NEWSLETTER = "send_newsletter";
export const CREATE_TEMPLATE = "create_template";
export const UPDATE_TEMPLATE = "update_template";
export const ALL_TEMPLATE = "all_template";
export const LOAD_NEWSLETTER = "load_newsletter";
export const LOAD_TEMPLATE = "load_template";
export const ALL_EMAIL = "all_email";
export const ALL_USER = "all_user";
export const CLEAR_TEMPLATE = "clear_template";
export const ALL_INVITATIONS = "all_invitations";
export const CREATE_INVITATIONS = "create_invitations";
export const VALIDATE_CONTRACT_AGENT = "validate_contract_agent";

export const ALL_REQUESTS = "all_requests";
export const UPDATE_REQUEST = "update_request";
export const GET_REQUEST = "get_request";
export const GET_EXCHANGE_ACC = "get_exchange_acc";
export const GET_WALLET = "get_wallet";
export const ALL_EXCHANGE_TRANSACTIONS = "all_exchange_transactions";
export const ADD_EXCHANGE_TRANSACTION = "add_exchange_transaction";

export const CREATE_CURRENCY = "create_currency";
export const GET_CURRENCIES = "get_currencies";
export const UPDATE_CURRENCY = "update_currency";
export const LOAD_CURRENCY = "load_currency";

export const GET_ALL_PAIRS = "GET_ALL_PAIRS";
export const CREATE_PAIR = "CREATE_PAIR";

export const CREATE_BANK = "create_bank";
export const GET_ALL_BANKS = "get_all_banks";

export const GET_ACC_USER = "get_acc_user";

export const LOAD_USER = "load_user";
export const ALL_USERS = "all_users";
export const ALL_CUSTOMERS = "all_customers";
export const LOAD_KYC = "load_kyc";
export const UPDATE_KYC = "update_kyc";
export const ALL_WALLETS = "all_wallets";
export const UPDATE_WALLET = "update_wallet";
export const LOAD_WALLET = "load_wallet";
export const ADD_WALLET = "add_wallet";
export const LOAD_HOT_WALLET_BALANCE = "load_hot_wallet_balance";
export const GET_ALL_ACCOUNTS = "get_all_accounts";
export const LOAD_ACCOUNT = "load_account";
export const CREATE_ACCOUNT = "create_account";
export const UPDATE_ACCOUNT = "update_account";
export const ADMIN_USERS = "admin_users";
export const UPDATE_USER = "update_user";
export const CREATE_REASON = "create_reason";
export const GET_REASONS = "get_reasons";
export const UPDATE_REASON = "update_reason";
export const LOAD_REASON = "load_reason";
export const CLEAR_REASON = "clear_reason";

//Config
export const GET_CONFIG = "get_config";
export const UPDATE_CONFIG = "update_config";
