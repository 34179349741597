import { Button, Card, CardContent, IconButton, List, ListItem, ListItemText, Switch } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import CircularProgress from "@material-ui/core/CircularProgress";
import Slider from "@material-ui/core/Slider";
import Camera from "@material-ui/icons/CameraAlt";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Exit from "@material-ui/icons/ExitToApp";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import IconVisibilityOn from "@material-ui/icons/Visibility";
import IconVisibilityOff from "@material-ui/icons/VisibilityOff";
import React, { Component } from "react";
import AvatarEditor from "react-avatar-editor";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import * as actions from "../actions";
import { getKyc } from "../actions";
import performRequest from "../configAxios";
import { badgesKyc } from "../custom/BadgesKyc";
import FormDialog from "../custom/FormDialog";
import HeaderPageItem from "../custom/HeaderPageItem";
import PageContainer, { CardPageItem } from "../custom/Page";
import { Roles } from "../util/index";
import UserDetail from "./components/UserDetail";

class CustomerMore extends Component {
  forms = {
    none: 0,
    photo: 1,
  };

  state = {
    dialogOpen: this.forms.none,
    consultant: null,
    photoRotation: 0,
    photoZoom: 1,
    doc: 0,
    pj: this.props.currentUser.isPj,
  };

  handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      this.setState({
        photoFile: event.target.files[0],
        dialogOpen: this.forms.photo,
      });
    }
  };

  photoZoomChange = (e, value) => {
    this.setState({
      photoZoom: value,
    });
  };

  photoRotationChange = (dir) => {
    this.setState({
      photoRotation: this.state.photoRotation + 90 * dir,
    });
  };

  sendImage = (event) => {
    const { showErrorMessage, showSuccessMessage } = this.props;
    this.setState({ photoFile: undefined });
    this.avatarEditor.getImageScaledToCanvas().toBlob((blob) => {
      var formData = new FormData();
      formData.append("file", blob);
      performRequest("POST", "/user/photo", formData, true)
        .then((response) => {
          showSuccessMessage("app.send");
          this.setState({ dialogOpen: this.forms.none });
          this.props.getMe();
        })
        .catch((e) => {
          if (e.response) {
            showErrorMessage(e.response.data.message);
          } else {
            showErrorMessage(e);
          }
        });
    });
  };

  loadKyc = () => {
    getKyc().then((data) => {
      const userKyc = data;
      this.setState({ userKyc });
    });
  };

  componentDidMount() {
    this.props.getMe();
    this.loadKyc();
  }

  toogleHideValues = () => {
    const { currentUser, showErrorMessage, showSuccessMessage } = this.props;
    performRequest("PUT", "/user/me/hideValues", { hideValues: !currentUser.hideValues }, true)
      .then((response) => {
        if (response.data.data.hideValues) showSuccessMessage("Values hidden.");
        else showSuccessMessage("Values visible.");
        this.props.getMe();
      })
      .catch((e) => {
        if (e.response) {
          showErrorMessage(e.response.data.message);
        } else {
          showErrorMessage(e);
        }
      });
  };

  render() {
    const { currentUser } = this.props;
    const { pj, userKyc } = this.state;
    const badges = badgesKyc(pj, userKyc);

    if (!currentUser) {
      return <CircularProgress style={{ marginTop: "25%" }} />;
    } else {
      return (
        <PageContainer>
          <HeaderPageItem title="app.settings.title" showBackButton destination="/dashboard" />

          <CardPageItem>
            <Card>
              <UserDetail currentUser={currentUser} user={currentUser.id}></UserDetail>
            </Card>
          </CardPageItem>

          <CardPageItem>
            <CardContent>
              <List>
                {currentUser.role == Roles.Admin && (
                  <>
                    <ListItem button component={Link} to="/financial">
                      <ListItemText primary={<FormattedMessage id="app.financeActions" />} />
                      <ChevronRight color="action" />
                    </ListItem>
                    <ListItem button component={Link} to="/config">
                      <ListItemText primary={<FormattedMessage id="app.config" />} />
                      <ChevronRight color="action" />
                    </ListItem>
                    <ListItem button component={Link} to="/configContract">
                      <ListItemText primary={<FormattedMessage id="app.contractTypes" />} />
                      <ChevronRight color="action" />
                    </ListItem>
                    <ListItem button component={Link} to="/template">
                      <ListItemText primary={<FormattedMessage id="app.emailTemplates" />} />
                      <ChevronRight color="action" />
                    </ListItem>
                    <ListItem button component={Link} to="/configSegment">
                      <ListItemText primary={<FormattedMessage id="app.segments" />} />
                      <ChevronRight color="action" />
                    </ListItem>
                  </>
                )}
                {currentUser.role == Roles.Agent && (
                  <ListItem button onClick={this.toogleHideValues}>
                    <ListItemText primary={<FormattedMessage id="app.hideValues" />} secondary={<FormattedMessage id="app.hideValues.description" />} />
                    <Switch checked={currentUser.hideValues} color="primary" />
                  </ListItem>
                )}

                {!currentUser.emailConfirmed && (
                  <ListItem button component={Link} to={"/confirmEmailForm"}>
                    <ListItemText primary={<FormattedMessage id="app.email.confirm" />} />
                    <Badge invisible={false} color={"error"}>
                      <ChevronRight color="action" />
                    </Badge>
                  </ListItem>
                )}
                <ListItem button component={Link} to="/customerFormData">
                  <ListItemText primary={!currentUser.telefone && !currentUser.telefone2 ? <FormattedMessage id="app.editContactInfo.info" /> : <FormattedMessage id="app.editContactInfo" />} />
                  <Badge invisible={!(!currentUser.telefone && !currentUser.telefone2)} color="error">
                    <ChevronRight color="action" />
                  </Badge>
                </ListItem>
                {currentUser.role !== Roles.Admin && (
                  <>
                    <ListItem button component={Link} to={"/customerDocs"}>
                      <ListItemText primary={<FormattedMessage id="app.sendDocuments" />} />
                      <Badge badgeContent={badges.qtd} color={badges.cor}>
                        <ChevronRight color="action" />
                      </Badge>
                    </ListItem>
                    <ListItem button component={Link} to="/beneficiary">
                      <ListItemText primary={<FormattedMessage id="app.beneficiary" />} />
                      <ChevronRight color="action" />
                    </ListItem>
                  </>
                )}
                <ListItem button component={Link} to="/changePassword">
                  <ListItemText primary={<FormattedMessage id="app.changePassword" />} />
                  <ChevronRight color="action" />
                </ListItem>
                <ListItem button component={Link} to="/language">
                  <ListItemText primary={<FormattedMessage id="app.language.config" />} />
                  <ChevronRight color="action" />
                </ListItem>
                <input id="contained-button-file" style={{ display: "none" }} accept="image/*" type="file" onChange={this.handleFileChange} />
                <label htmlFor="contained-button-file">
                  <ListItem button>
                    <ListItemText primary={<FormattedMessage id="app.changeAvatar" />} />
                    <Camera color="action" />
                  </ListItem>
                </label>
                <ListItem button component={Link} to="/logout">
                  <ListItemText primary={<FormattedMessage id="app.logout" />} />
                  <Exit color="action" />
                </ListItem>
              </List>
            </CardContent>
          </CardPageItem>

          <FormDialog title="app.changeAvatar" variant="secondary" open={this.state.dialogOpen == this.forms.photo} onClose={() => this.setState({ dialogOpen: this.forms.none })}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {this.state.photoFile ? (
                <>
                  <AvatarEditor
                    ref={(ref) => (this.avatarEditor = ref)}
                    image={this.state.photoFile}
                    width={250}
                    height={250}
                    border={0}
                    borderRadius={250}
                    scale={this.state.photoZoom}
                    rotate={this.state.photoRotation}
                    color={[0, 0, 0, 0.8]} // RGBA
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 32,
                      paddingLeft: 20,
                    }}
                  >
                    <Slider value={this.state.photoZoom} min={1} max={4} onChange={this.photoZoomChange} style={{ width: 160 }} />
                    <IconButton variant="contained" color="primary" onClick={(e) => this.photoRotationChange(-1)}>
                      <RotateLeftIcon />
                    </IconButton>
                    <IconButton variant="contained" color="primary" onClick={(e) => this.photoRotationChange(1)}>
                      <RotateRightIcon />
                    </IconButton>
                  </div>
                  <Button variant="contained" color="primary" onClick={this.sendImage} style={{ marginTop: 32 }}>
                    <FormattedMessage id="app.button.save" />
                  </Button>
                </>
              ) : (
                <CircularProgress color="primary" style={{ marginTop: "25%" }} />
              )}
            </div>
          </FormDialog>
        </PageContainer>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default compose(
  connect(mapStateToProps, actions),
  reduxForm({
    form: "contractForm",
    enableReinitialize: true,
  })
)(CustomerMore);
