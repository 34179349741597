import { Button, CardActions, CardContent, IconButton, Menu, MenuItem, Typography } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import Restore from "@material-ui/icons/RestoreFromTrash";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import * as actions from "../actions";
import { deleteContractType, getContractType } from "../actions";
import FormDialog from "../custom/FormDialog";
import HeaderPageItem from "../custom/HeaderPageItem";
import PageContainer, { CardPageItem } from "../custom/Page";
import { QuestionDialog } from "./components/QuestionDialog";
import FormContractType from "./components/FormContractType";
import { FormattedMessage } from "react-intl";
class ConfigContract extends Component {
  state = {
    types: [],
    typesDeleted: [],
    modal: false,
    deleted: false,
    showDeleteModal: false,
    title: ""
  };
  componentDidMount() {
    this.loadContract();
  }

  loadContract = () => {
    getContractType().then(data => {
      const types = data.filter(t => t.deleted == false);
      const typesDeleted = data.filter(t => t.deleted == true);
      this.setState({ types, typesDeleted });
    });
  };

  delete = id => {
    const { showErrorMessage } = this.props;

    deleteContractType(id)
      .then(response => {
        this.setState({ showDeleteModal: false, selectedType: null });
        this.loadContract();
      })
      .catch(e => {
        showErrorMessage(e.response ? e.response.data.message : e);
      });
  };

  render() {
    return (
      <PageContainer>
        <HeaderPageItem
          title="app.contractTypes"
          showBackButton
          destination="/more"
          onMore={e => this.setState({ menuAnchor: e.target })}
        >
          <Menu
            onClose={e => this.setState({ menuAnchor: undefined })}
            open={!!this.state.menuAnchor}
            anchorEl={this.state.menuAnchor}
          >
            <MenuItem onClick={e => this.setState({ menuAnchor: undefined, deleted: true })}>
              <FormattedMessage id="app.inactiveTypes" />
            </MenuItem>
          </Menu>
        </HeaderPageItem>
        <FormDialog
          title="app.inactiveTypes"
          open={this.state.deleted}
          variant="secondary"
          noHeader
          onClose={e => this.setState({ deleted: false })}
        >
          {this.state.typesDeleted && (
            <Typography variant="caption" color="textPrimary">
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td align="center">
                      <FormattedMessage id="app.duration" />
                    </td>
                    <td align="center">
                      <FormattedMessage id="app.type" />
                    </td>
                    <td align="center"> %</td>
                    <td align="center">
                      <FormattedMessage id="app.description" />
                    </td>
                    <td align="center">
                      <FormattedMessage id="app.activate" />
                    </td>
                  </tr>
                  {this.state.typesDeleted.map(t => (
                    <Fragment key={t.id}>
                      <tr style={{ color: "textSecondary" }}>
                        <td align="center" style={{ color: "primary" }}>
                          <Typography variant="caption" color="textPrimary">
                            {t.durationInMonth}
                          </Typography>
                        </td>
                        <td align="center" style={{ color: "primary" }}>
                          <Typography variant="caption" color="textPrimary">
                            <FormattedMessage id={`app.enuns.${t.type}`} />
                          </Typography>
                        </td>
                        <td align="center" style={{ color: "primary" }}>
                          <Typography variant="caption" color="textPrimary">
                            {t.maxMonthlyYield}
                          </Typography>
                        </td>
                        <td align="center" style={{ color: "primary" }}>
                          <Typography variant="caption" color="textPrimary">
                            {t.description}
                          </Typography>
                        </td>

                        <td align="right">
                          <IconButton>
                            <Restore />
                          </IconButton>
                        </td>
                      </tr>
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </Typography>
          )}
        </FormDialog>
        <CardPageItem>
          <CardContent>
            <Typography variant="caption" color="textPrimary">
              {this.state.types && (
                <Typography variant="caption" color="textPrimary">
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td align="center">
                          <FormattedMessage id="app.duration" />
                        </td>
                        <td align="center">
                          <FormattedMessage id="app.type" />
                        </td>
                        <td align="center"> %</td>
                        <td align="center"> Comis. %</td>
                        <td align="center">
                          <FormattedMessage id="app.description" />
                        </td>
                        <td align="center">
                          <FormattedMessage id="app.edit" />
                        </td>
                        <td align="center">
                          <FormattedMessage id="app.delete" />
                        </td>
                      </tr>
                      {this.state.types.map(t => (
                        <Fragment key={t.id}>
                          <tr style={{ color: "textSecondary" }}>
                            <td align="center" style={{ color: "primary" }}>
                              <Typography variant="caption" color="textPrimary">
                                {t.durationInMonth}
                              </Typography>
                            </td>
                            <td align="center" style={{ color: "primary" }}>
                              <Typography variant="caption" color="textPrimary">
                                <FormattedMessage id={`app.enuns.${t.type}`} />
                              </Typography>
                            </td>
                            <td align="center" style={{ color: "primary" }}>
                              <Typography variant="caption" color="textPrimary">
                                {t.maxMonthlyYield}
                              </Typography>
                            </td>
                            <td align="center" style={{ color: "primary" }}>
                              <Typography variant="caption" color="textPrimary">
                                {t.agentCommission}
                              </Typography>
                            </td>
                            <td align="center" style={{ color: "primary" }}>
                              <Typography variant="caption" color="textPrimary">
                                {t.description}
                              </Typography>
                            </td>

                            <td align="right">
                              <IconButton
                                onClick={e => {
                                  this.setState({
                                    modal: true,
                                    selectedType: t,
                                    title: "app.editContract"
                                  });
                                }}
                              >
                                <Edit />
                              </IconButton>
                            </td>
                            <td align="right">
                              <IconButton
                                onClick={e => {
                                  this.setState({
                                    showDeleteModal: true,
                                    selectedType: t
                                  });
                                }}
                              >
                                <Delete />
                              </IconButton>
                            </td>
                          </tr>
                        </Fragment>
                      ))}
                    </tbody>
                  </table>
                </Typography>
              )}
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={e => {
                this.setState({ modal: true, title: "app.createContractType" });
              }}
            >
              <FormattedMessage id="app.createNew" />
            </Button>
          </CardActions>
        </CardPageItem>
        <FormDialog
          title={this.state.title}
          open={this.state.modal}
          variant="secondary"
          noHeader
          onClose={e => {
            this.setState({ modal: false, selectedType: null });
          }}
        >
          <FormContractType
            type={this.state.selectedType}
            onActionComplete={() => {
              this.setState({ modal: false, selectedType: null });
              this.loadContract();
            }}
          />
        </FormDialog>
        {this.state.selectedType && (
          <QuestionDialog
            title={"app.delete"}
            children={"app.delete.text"}
            onCancel={() => this.setState({ showDeleteModal: false, selectedType: null })}
            open={this.state.showDeleteModal}
            onOk={() => this.delete(this.state.selectedType.id)}
          ></QuestionDialog>
        )}
      </PageContainer>
    );
  }
}

export default compose(connect(null, actions))(ConfigContract);
