import { Button, CardContent, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import * as actions from "../../actions";
import { createTemplate, updateTemplate } from "../../actions/EmailTemplate";
import CustomInputForm from "../../custom/CustomInput";
import CustomSelect from "../../custom/CustomSelect";
import { FormattedMessage } from "react-intl";

class FormEmailTemplate extends Component {
	componentDidMount() {
		this.props.initialize(this.props.template);
	}

	onSubmit = (formProps) => {
		const { showErrorMessage, onActionComplete } = this.props;
		if (formProps.id) {
			updateTemplate(formProps.id, formProps)
				.then((response) => {
					onActionComplete();
				})
				.catch((e) => {
					showErrorMessage(e.response ? e.response.data.message : e);
				});
		} else {
			createTemplate(formProps)
				.then((response) => {
					onActionComplete();
				})
				.catch((e) => {
					showErrorMessage(e.response ? e.response.data.message : e);
				});
		}
	};

	render() {
		const { handleSubmit } = this.props;

		const types = [
			{
				input: <FormattedMessage id="app.enuns.ACCOUNT_ACTIVATE" />,
				value: "ACCOUNT_ACTIVATE",
			},
			{
				input: <FormattedMessage id="app.enuns.WITHDRAW_CONFIRM" />,
				value: "WITHDRAW_CONFIRM",
			},
			{
				input: <FormattedMessage id="app.enuns.WITHDRAW_PARTNER_VENDING_CONFIRM" />,
				value: "WITHDRAW_PARTNER_VENDING_CONFIRM",
			},
			{
				input: <FormattedMessage id="app.enuns.WITHDRAW_PARTNER_ACCOUNT_BALANCE_CONFIRM" />,
				value: "WITHDRAW_PARTNER_ACCOUNT_BALANCE_CONFIRM",
			},

			{
				input: <FormattedMessage id="app.enuns.WITHDRAW_PARTNER_VENDING_CONFIRMED" />,
				value: "WITHDRAW_PARTNER_VENDING_CONFIRMED",
			},
			{
				input: <FormattedMessage id="app.enuns.WITHDRAW_PARTNER_ACCOUNT_BALANCE_CONFIRMED" />,
				value: "WITHDRAW_PARTNER_ACCOUNT_BALANCE_CONFIRMED",
			},

			{
				input: <FormattedMessage id="app.enuns.WITHDRAW_PARTNER_VENDING_APPROVED" />,
				value: "WITHDRAW_PARTNER_VENDING_APPROVED",
			},
			{
				input: <FormattedMessage id="app.enuns.WITHDRAW_PARTNER_ACCOUNT_BALANCE_APPROVED" />,
				value: "WITHDRAW_PARTNER_ACCOUNT_BALANCE_APPROVED",
			},
			{
				input: <FormattedMessage id="app.enuns.REAPORT_CONFIRM" />,
				value: "REAPORT_CONFIRM",
			},
			{
				input: <FormattedMessage id="app.enuns.WITHDRAW_SENDED" />,
				value: "WITHDRAW_SENDED",
			},

			{
				input: <FormattedMessage id="app.enuns.REAPORT_SENDED" />,
				value: "REAPORT_SENDED",
			},
			{
				input: <FormattedMessage id="app.enuns.WITHDRAW_CONFIRMED" />,
				value: "WITHDRAW_CONFIRMED",
			},
			{
				input: <FormattedMessage id="app.enuns.WITHDRAW_PARTNER_CONFIRMED" />,
				value: "WITHDRAW_PARTNER_CONFIRMED",
			},
			{
				input: <FormattedMessage id="app.enuns.REAPORT_CONFIRMED" />,
				value: "REAPORT_CONFIRMED",
			},
			{
				input: <FormattedMessage id="app.enuns.CONTRACT_ACTIVATED" />,
				value: "CONTRACT_ACTIVATED",
			},
			{
				input: <FormattedMessage id="app.enuns.CONTRACT_CANCELED" />,
				value: "CONTRACT_CANCELED",
			},
			{
				input: <FormattedMessage id="app.enuns.CONTRACT_CONTRIBUTED" />,
				value: "CONTRACT_CONTRIBUTED",
			},
			{
				input: <FormattedMessage id="app.enuns.CONTRACT_FINISHED" />,
				value: "CONTRACT_FINISHED",
			},
			{
				input: <FormattedMessage id="app.enuns.CONTRACT_MANUALLY_FINISHED" />,
				value: "CONTRACT_MANUALLY_FINISHED",
			},
			{
				input: <FormattedMessage id="app.enuns.CONTRACT_OPEN" />,
				value: "CONTRACT_OPEN",
			},
			{
				input: <FormattedMessage id="app.enuns.CONTRACT_REDEMPTION" />,
				value: "CONTRACT_REDEMPTION",
			},
			{
				input: <FormattedMessage id="app.enuns.CONTRACT_SIGNED" />,
				value: "CONTRACT_SIGNED",
			},
			{
				input: <FormattedMessage id="app.enuns.CONTRACT_VALIDATED" />,
				value: "CONTRACT_VALIDATED",
			},
			{
				input: <FormattedMessage id="app.enuns.MESSAGE_TO_CUSTOMER" />,
				value: "MESSAGE_TO_CUSTOMER",
			},
			{
				input: <FormattedMessage id="app.enuns.REDEMPTION_MESSAGE" />,
				value: "REDEMPTION_MESSAGE",
			},
			{
				input: <FormattedMessage id="app.enuns.PAYMENTS_TODAY" />,
				value: "PAYMENTS_TODAY",
			},
			{
				input: <FormattedMessage id="app.enuns.CONTRACT_ACTIVATED_AGENT" />,
				value: "CONTRACT_ACTIVATED_AGENT",
			},
			{
				input: <FormattedMessage id="app.enuns.CONTRACT_CANCELED_AGENT" />,
				value: "CONTRACT_CANCELED_AGENT",
			},
			{
				input: <FormattedMessage id="app.enuns.CONTRACT_CONTRIBUTED_AGENT" />,
				value: "CONTRACT_CONTRIBUTED_AGENT",
			},
			{
				input: <FormattedMessage id="app.enuns.CONTRACT_FINISHED_AGENT" />,
				value: "CONTRACT_FINISHED_AGENT",
			},
			{
				input: <FormattedMessage id="app.enuns.CONTRACT_MANUALLY_FINISHED_AGENT" />,
				value: "CONTRACT_MANUALLY_FINISHED_AGENT",
			},
			{
				input: <FormattedMessage id="app.enuns.CONTRACT_OPEN_AGENT" />,
				value: "CONTRACT_OPEN_AGENT",
			},
			{
				input: <FormattedMessage id="app.enuns.CONTRACT_REDEMPTION_AGENT" />,
				value: "CONTRACT_REDEMPTION_AGENT",
			},
			{
				input: <FormattedMessage id="app.enuns.CONTRACT_SIGNED_AGENT" />,
				value: "CONTRACT_SIGNED_AGENT",
			},
			{
				input: <FormattedMessage id="app.enuns.CONTRACT_VALIDATED_AGENT" />,
				value: "CONTRACT_VALIDATED_AGENT",
			},
			{
				input: <FormattedMessage id="app.enuns.CUSTOMER_REGISTERED" />,
				value: "CUSTOMER_REGISTERED",
			},
		];
		const locales = [
			{ input: <FormattedMessage id="app.language.en" />, value: "en" },
			{ input: <FormattedMessage id="app.language.br" />, value: "br" },
			{ input: <FormattedMessage id="app.language.es" />, value: "es" },
		];
		return (
			<CardContent>
				<form onSubmit={handleSubmit(this.onSubmit)}>
					<Field
						name="subject"
						label="app.subject"
						InputLabelProps={{
							shrink: true,
						}}
						component={CustomInputForm}
						autoFocus
					/>
					<Field
						name="body"
						label="app.template"
						InputLabelProps={{
							shrink: true,
						}}
						multiline
						component={CustomInputForm}
					/>
					<Field name="type" label="app.type" component={CustomSelect} values={types} />
					<Field name="locale" label="app.language" component={CustomSelect} values={locales} />
					<Typography gutterBottom>
						<strong>
							<FormattedMessage id="app.template.authWithdraw.variables" />
						</strong>
						$address, $date, $amount, $token, $frontUrl
					</Typography>
					<Typography gutterBottom>
						<strong>
							<FormattedMessage id="app.template.withdrawAuthorized.variables" />
						</strong>
						$address, $date, $amount, $name, $fee, $valueWithFee, $partnerWithdraw, $dueDate
					</Typography>
					<Typography gutterBottom>
						<strong>
							<FormattedMessage id="app.template.withdrawSended.variables" />
						</strong>
						$address, $date, $amount, $valueWithFee
					</Typography>
					<Typography gutterBottom>
						<strong>
							<FormattedMessage id="app.template.activateAccount.variables" />
						</strong>
						$token, $frontUrl, $name
					</Typography>
					<Typography gutterBottom>
						<strong>
							<FormattedMessage id="app.template.contract.variables" />
						</strong>
						$id, $name, $agentId, $agentName, $contractId, $amount, $deposit, $previsionAmount, $initial,
						$dueDate, $type, $phase, $maxMonthlyYield, $duration, $totalYield, $canceledBy
					</Typography>
					<Typography gutterBottom>
						<strong>
							<FormattedMessage id="app.template.payment.variables" />
						</strong>
						$name, $email, $paymentDate, $paymentsToday
					</Typography>
					<Button
						fullWidth
						id="bSubmitTypeForm"
						variant="contained"
						color="primary"
						type="submit"
						disabled={this.props.pristine || this.props.submitting || this.props.invalid}
					>
						<FormattedMessage id="app.button.save" />
					</Button>
				</form>
			</CardContent>
		);
	}
}

const validate = (values) => {
	const errors = {};
	if (!values.subject) {
		errors.subject = "app.subject.error";
	} else if (values.subject.trim().length == 0) {
		errors.subject = "app.subject.invalid";
	}
	if (!values.body) {
		errors.body = "app.template.error";
	} else if (values.body.trim().length == 0) {
		errors.body = "app.template.invalid";
	}
	if (!values.type) {
		errors.type = "app.type.error";
	}
	if (!values.locale) {
		errors.locale = "app.locale.error";
	}
	return errors;
};

export default compose(
	reduxForm({
		form: "formEmailTemplate",
		validate,
	}),
	connect(null, actions)
)(FormEmailTemplate);
