import { Button, CardContent, CircularProgress, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, IconButton, Menu, MenuItem, Typography, withTheme } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Delete from "@material-ui/icons/Delete";
import Email from "@material-ui/icons/Email";
import * as moment from "moment";
import "moment/locale/es";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import Moment from "react-moment";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import * as actions from "../actions";
import {
  cancelWithdrawal,
  getAccount,
  getAccountById,
  getIncomePayments,
  getInfoPayments,
  getInfoStatement,
  getInfoWithdraw,
  getMyPendingWithdrawals,
  getPendingWithdrawalsInfo,
  getStatement,
  getWithdraw,
  resendEmail,
} from "../actions";
import ExpansionPanelIcon from "../custom/ExpansionPanelIcon";
import FixedPageItem from "../custom/FixedPageItem";
import FormDialog from "../custom/FormDialog";
import HeaderPageItem from "../custom/HeaderPageItem";
import InfoItem from "../custom/InfoItem";
import Number, { LCTDisplayModes, setLCTDisplayMode } from "../custom/Number";
import PageContainer, { CardPageItem } from "../custom/Page";
import { Roles } from "../util";

class UserStory extends Component {
  state = {
    saque: {},
    step: false,
    step1: false,
    loading: true,
    extract: [],
    startDate: null,
    account: {},
    withdraw: {},
    payments: [],
    pendingAuthWithdrawals: [],
    openContractsPayments: [],
    closedContractsPayments: [],
    userId: null,
  };
  currentMonth = moment();

  componentDidMount() {
    const { currentUser } = this.props;
    if ((currentUser.role == Roles.Admin || currentUser.role == Roles.FinanceManager) && this.props.match.params.id != undefined) {
      this.setState({ userId: this.props.match.params.id });
      this.loadMonth(this.props.match.params.id);
      this.loadAccountAdmin(this.props.match.params.id);
    } else {
      this.setState({ userId: currentUser.id });
      this.loadMonth(currentUser.id);
      this.loadAccount();
    }
    setLCTDisplayMode(LCTDisplayModes.Original);
  }

  loadMonth(id) {
    const { currentUser } = this.props;
    let inicio = this.currentMonth.startOf("month").format("YYYY-MM-DD");
    let fim = this.currentMonth.endOf("month").format("YYYY-MM-DD");
    if (currentUser.role == Roles.Admin || currentUser.role == Roles.FinanceManager) {
      getInfoStatement(id, inicio, fim).then((data) => {
        this.setState({
          extract: data,
          startDate: this.props.currentUser.createdAt,
        });
      });
    } else {
      getStatement(inicio, fim).then((data) => {
        this.setState({
          extract: data,
          startDate: this.props.currentUser.createdAt,
        });
      });
    }
  }

  loadAccountAdmin(userId) {
    getAccountById(userId).then((data) => {
      this.setState({ account: data });
    });
    getInfoWithdraw(userId).then((data) => {
      this.setState({ pendingAuthWithdrawals: data });
    });
    getPendingWithdrawalsInfo(userId).then((data) => {
      this.setState({ withdraw: data });
    });
    getInfoPayments(userId)
      .then((data) => {
        let incomingPaymentsTotal = 0;
        let futurePaymentsTotal = 0;
        let incomingPayments = [];
        let futurePayments = [];
        for (const p of data) {
          if (!p.payed) {
            if (p.type == "PROFIT") {
              incomingPaymentsTotal += p.value;
              incomingPayments.push(p);
            } else {
              futurePaymentsTotal += p.value;
              futurePayments.push(p);
            }
          }
        }
        this.setState({
          incomingPayments,
          futurePayments,
          incomingPaymentsTotal,
          futurePaymentsTotal,
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
      });
  }

  loadAccount() {
    getAccount().then((data) => {
      this.setState({ account: data });
    });
    getWithdraw().then((data) => {
      this.setState({ pendingAuthWithdrawals: data });
    });
    getMyPendingWithdrawals().then((data) => {
      this.setState({ withdraw: data });
    });
    getIncomePayments()
      .then((data) => {
        let incomingPaymentsTotal = 0;
        let futurePaymentsTotal = 0;
        let incomingPayments = [];
        let futurePayments = [];
        for (const p of data) {
          if (!p.payed) {
            if (p.type == "PROFIT") {
              incomingPaymentsTotal += p.value;
              incomingPayments.push(p);
            } else {
              futurePaymentsTotal += p.value;
              futurePayments.push(p);
            }
          }
        }
        this.setState({
          incomingPayments,
          futurePayments,
          incomingPaymentsTotal,
          futurePaymentsTotal,
          loading: false,
        });
        console.log("getIncomePayments", { incomingPayments, futurePayments });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
      });
  }

  cancel = (id) => {
    const { showSuccessMessage, showErrorMessage } = this.props;
    cancelWithdrawal(id)
      .then((data) => {
        showSuccessMessage("app.withdrawal.cancel");
        this.loadAccount();
      })
      .catch((e) => {
        showErrorMessage(e.response ? e.response.data.message : e);
      });
  };
  email = (id) => {
    const { showSuccessMessage, showErrorMessage } = this.props;
    resendEmail(id)
      .then((data) => {
        showSuccessMessage("app.email.resentSuccesfully");
        this.loadAccount();
      })
      .catch((e) => {
        showErrorMessage(e.response ? e.response.data.message : e);
      });
  };

  nextMonth() {
    const { currentUser } = this.props;
    this.currentMonth = moment(this.currentMonth.startOf("month").add(1, "M").format("YYYY-MM-DD"));
    let inicio = this.currentMonth.startOf("month").format("YYYY-MM-DD");
    let fim = this.currentMonth.endOf("month").format("YYYY-MM-DD");
    if (currentUser.role == Roles.Admin || currentUser.role == Roles.FinanceManager) {
      getInfoStatement(this.state.userId, inicio, fim).then((data) => {
        this.setState({
          extract: data,
        });
      });
    } else {
      getStatement(inicio, fim).then((data) => {
        this.setState({
          extract: data,
        });
      });
    }
  }

  previousMonth() {
    const { currentUser } = this.props;
    this.currentMonth = moment(this.currentMonth.startOf("month").subtract(1, "M").format("YYYY-MM-DD"));
    let inicio = this.currentMonth.startOf("month").format("YYYY-MM-DD");
    let fim = this.currentMonth.endOf("month").format("YYYY-MM-DD");
    if (currentUser.role == Roles.Admin || currentUser.role == Roles.FinanceManager) {
      getInfoStatement(this.state.userId, inicio, fim).then((data) => {
        this.setState({
          extract: data,
        });
      });
    } else {
      getStatement(inicio, fim).then((data) => {
        this.setState({
          extract: data,
        });
      });
    }
  }

  hasNext() {
    return this.currentMonth.isBefore(moment().startOf("month").add(1, "M"));
  }

  hasPrevious() {
    return this.currentMonth.isAfter(moment(this.state.startDate).endOf("month"));
  }

  render() {
    const { currentUser, history } = this.props;
    const { extract, account, withdraw, saque, payments, pendingAuthWithdrawals, loading, incomingPayments, futurePayments, incomingPaymentsTotal, futurePaymentsTotal } = this.state;

    if (loading) return <CircularProgress style={{ marginTop: "25%" }} />;

    return (
      <PageContainer>
        <HeaderPageItem
          showBackButton={currentUser.id != this.state.userId ? true : false}
          destination={history.location.state ? history.location.state.back : `/user/${this.state.userId}`}
          title="app.account"
          onMore={(e) => this.setState({ menuAnchor: e.target })}
        />

        <Menu onClose={(e) => this.setState({ menuAnchor: undefined })} open={!!this.state.menuAnchor} anchorEl={this.state.menuAnchor}>
          <MenuItem component={Link} to={"/withdraw"} onClick={(e) => this.setState({ menuAnchor: undefined })} disabled={currentUser.id == this.state.userId ? false : true}>
            <FormattedMessage id="app.newWithdraw" />
          </MenuItem>
        </Menu>

        <div
          style={{
            width: "100%",
          }}
        >
          <FormDialog title="app.confirm" open={this.state.step} variant="secondary" noHeader onClose={(e) => this.setState({ step: false })}>
            <Typography style={{ marginBottom: 16 }}>
              <FormattedMessage id="app.cancelWithdrawalQuestion" />
            </Typography>
            <Typography style={{ marginBottom: 16 }}>
              <FormattedMessage id="app.withdrawConfirm.text1" />
            </Typography>
            <InfoItem caption={<FormattedMessage id="app.camel.amount" />} text={<Number value={saque.amount} currency="LCT" hideValue={currentUser.hideValues} />} color="primary" />
            <InfoItem caption={<FormattedMessage id="app.address" />} text={saque.address} />

            <Button
              fullWidth
              variant="contained"
              color="primary"
              style={{ marginTop: 16, marginBottom: 16 }}
              onClick={(e) => {
                this.cancel(saque.id);
                this.setState({ step: false });
              }}
            >
              Ok
            </Button>
          </FormDialog>
          <FormDialog title="app.resendEmail.title" open={this.state.step1} variant="secondary" noHeader onClose={(e) => this.setState({ step1: false })}>
            <Typography style={{ marginBottom: 16 }}>
              <FormattedMessage id="app.resendEmail.text" />
            </Typography>

            <Button
              fullWidth
              variant="contained"
              color="primary"
              style={{ marginTop: 16, marginBottom: 16 }}
              onClick={(e) => {
                this.email(saque.id);
                this.setState({ step1: false });
              }}
            >
              Ok
            </Button>
          </FormDialog>

          {pendingAuthWithdrawals && pendingAuthWithdrawals.length > 0 && (
            <div style={{ width: "100%", marginTop: 4 }}>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpansionPanelIcon />}>
                  <Typography>
                    <FormattedMessage id="app.withdrawals.auth" />
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography variant="caption" color="textSecondary" style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td align="left">
                            <FormattedMessage id="app.date" />
                          </td>
                          <td align="center">
                            <FormattedMessage id="app.value" />
                          </td>
                          <td align="right">
                            <FormattedMessage id="app.cancel" />
                          </td>
                          <td align="right">
                            <FormattedMessage id="app.resend" />{" "}
                          </td>
                        </tr>
                        {pendingAuthWithdrawals.map((withdraw) => (
                          <tr key={withdraw.id} style={{ color: "textSecondary" }}>
                            <td align="left" style={{ color: "primary" }}>
                              <Moment date={withdraw.createdAt} format="DD/MM/YYYY" />
                            </td>

                            <td align="center">
                              <Number value={withdraw.amount} currency="LCT" hideValue={currentUser.hideValues} />
                            </td>
                            <td align="right">
                              <IconButton
                                onClick={(e) => {
                                  this.setState({ step: true, saque: withdraw });
                                }}
                              >
                                <Delete fontSize="small" />
                              </IconButton>
                            </td>
                            <td align="right">
                              <IconButton
                                onClick={(e) => {
                                  this.setState({ step1: true, saque: withdraw });
                                }}
                              >
                                <Email fontSize="small" />
                              </IconButton>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>
          )}

          {withdraw && withdraw.length > 0 && (
            <div style={{ width: "100%", marginTop: 4 }}>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpansionPanelIcon />}>
                  <Typography>
                    <FormattedMessage id="app.withdrawals.pending" />
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography variant="caption" color="textSecondary" style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td align="left">
                            <FormattedMessage id="app.date" />
                          </td>
                          <td align="center">
                            <FormattedMessage id="app.value" />
                          </td>
                          <td align="center">
                            <FormattedMessage id="app.fee" />
                          </td>
                          <td align="right">
                            <FormattedMessage id="app.receiving" />
                          </td>
                        </tr>
                        {withdraw.map((withdraw) => (
                          <tr key={withdraw.id} style={{ color: "textSecondary" }}>
                            <td align="left" style={{ color: "primary" }}>
                              <Moment date={withdraw.authorizeDate ? withdraw.authorizeDate : withdraw.updatedAt} format="DD/MM/YYYY" />
                            </td>

                            <td align="center">
                              <Number value={withdraw.amount} currency="LCT" hideValue={currentUser.hideValues} />
                            </td>
                            <td align="center">
                              <Number value={withdraw.fee} currency="LCT" hideValue={currentUser.hideValues} />
                            </td>
                            <td align="right">
                              <Number value={withdraw.valueWithFee} currency="LCT" hideValue={currentUser.hideValues} />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>
          )}

          {incomingPayments && incomingPayments.length > 0 && (
            <div style={{ width: "100%", marginTop: 4 }}>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpansionPanelIcon />}>
                  <Typography>
                    <FormattedMessage id="app.increaseToRelease" />
                    (
                    <Number value={incomingPaymentsTotal} currency="LCT" hideValue={currentUser.hideValues} />)
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography variant="caption" color="textSecondary" style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td align="left">LCContract</td>
                          <td align="center">
                            <FormattedMessage id="app.date" />
                          </td>
                          <td align="right">
                            <FormattedMessage id="app.value" />
                          </td>
                        </tr>
                        {incomingPayments.map((payment) => (
                          <tr key={payment.id} style={{ color: "textSecondary" }}>
                            <td align="left" style={{ color: "primary" }}>
                              C{payment.contract.id}
                            </td>
                            <td align="center" style={{ color: "primary" }}>
                              <Moment date={payment.paymentDate} format="DD/MM/YYYY" />
                            </td>
                            <td align="right">
                              <Number value={payment.value} currency="LCT" hideValue={currentUser.hideValues} />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>
          )}

          {futurePayments && futurePayments.length > 0 && (
            <div style={{ width: "100%", marginTop: 4 }}>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpansionPanelIcon />}>
                  <Typography>
                    <FormattedMessage id="app.futureReleases" />
                    (
                    <Number value={futurePaymentsTotal} currency="LCT" hideValue={currentUser.hideValues} />)
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography variant="caption" color="textSecondary" style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td align="left">LCContract</td>
                          <td align="center">
                            <FormattedMessage id="app.date" defaultMessage="Data" />
                          </td>
                          <td align="right">
                            <FormattedMessage id="app.value" defaultMessage="Value" />
                          </td>
                        </tr>
                        {futurePayments.map((payment) => {
                          const isCommission = ["BONUS", "BONUS_SPLIT", "BONUS_MANAGER", "BONUS_RECOMMENDANT"].includes(payment.type);
                          return (
                            <tr key={payment.id} style={{ color: "textSecondary" }}>
                              <td align="left" style={{ color: "primary" }}>
                                <Typography variant="caption">
                                  {isCommission ? (
                                    <Button component={Link} to={`/userContract/${payment.contract.id}?backTo=/history`} color="default" variant="outlined" size="small" endIcon={<ChevronRightIcon />}>
                                      <FormattedMessage id={payment.description} values={{ id: "" }} />
                                    </Button>
                                  ) : (
                                    <>C{payment.contract.id}</>
                                  )}
                                </Typography>
                              </td>
                              <td align="center" style={{ color: "primary" }}>
                                <Moment date={payment.paymentDate} format="DD/MM/YYYY" />
                              </td>
                              <td align="right">
                                <Number value={payment.value} currency="LCT" hideValue={currentUser.hideValues} />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>
          )}

          {payments && payments.length > 0 && (
            <div style={{ width: "100%", marginTop: 4 }}>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpansionPanelIcon />}>
                  <Typography>
                    <FormattedMessage id="app.nextPayments" />
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography variant="caption" color="textSecondary" style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td align="left">
                            <FormattedMessage id="app.date" />
                          </td>
                          <td align="right">
                            <FormattedMessage id="app.value" />
                          </td>
                        </tr>
                        {payments.map((payment) => (
                          <tr key={payment.id} style={{ color: "textSecondary" }}>
                            <td align="left" style={{ color: "primary" }}>
                              <Moment date={payment.paymentDate} format="DD/MM/YYYY" />
                            </td>
                            <td align="right">
                              <Number value={payment.value} currency="LCT" hideValue={currentUser.hideValues} />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>
          )}

          {/* <FixedPageItem position="top" height={45}> */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: 45,
              marginTop: 16,
            }}
          >
            <IconButton onClick={(e) => this.previousMonth()} disabled={!this.hasPrevious()}>
              <ChevronLeftIcon />
            </IconButton>

            <Typography variant="h6">{this.currentMonth.locale(currentUser.locale).format("MMMM YYYY")}</Typography>

            <IconButton onClick={(e) => this.nextMonth()} disabled={!this.hasNext()}>
              <ChevronRightIcon />
            </IconButton>
          </div>

          {/* </FixedPageItem> */}
          {extract && extract.length > 0 ? (
            <div style={{ width: "100%" }}>
              <CardPageItem>
                <CardContent
                  style={{
                    paddingTop: 0,
                  }}
                >
                  <Typography variant="caption" color="textSecondary" style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr style={{ color: "white" }}>
                          <td align="left">
                            <FormattedMessage id="app.date" />
                          </td>
                          <td align="left">
                            <FormattedMessage id="app.description" />
                          </td>
                          <td align="right">
                            <FormattedMessage id="app.value" />
                          </td>
                          <td align="right">
                            <FormattedMessage id="app.camel.balance" />
                          </td>
                        </tr>
                        {extract.map((extract) => {
                          const isCommission = ["COMMISSION"].includes(extract.origin);
                          const isAdd = ["PROFIT"].includes(extract.origin);
                          return (
                            <tr key={extract.id} style={{ color: "textSecondary" }}>
                              <td align="left" style={{ color: "primary" }}>
                                <Moment date={extract.statementDate} format="DD" />
                              </td>
                              <td align="left">
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                  style={{
                                    minHeight: 32,
                                    display: "Flex",
                                    width: "100%",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <div>
                                    <FormattedMessage id={extract.description} values={{ id: "" }} />
                                  </div>
                                  {isCommission ? (
                                    <Button component={Link} to={`/userContract/${extract.originId}?backTo=/history`} color="default" variant="outlined" size="small" endIcon={<ChevronRightIcon />}>
                                      C{extract.originId}
                                    </Button>
                                  ) : (
                                    isAdd && (
                                      <Button component={Link} to={`/contract/${extract.originId}?backTo=/history`} color="default" variant="outlined" size="small" endIcon={<ChevronRightIcon />}>
                                        C{extract.originId}
                                      </Button>
                                    )
                                  )}
                                </Typography>
                              </td>
                              <td align="right">
                                <Number
                                  style={{
                                    color: extract.type === "CREDIT" ? "green" : "red",
                                  }}
                                  value={extract.value}
                                  currency="LCT"
                                  display="none"
                                  hideValue={currentUser.hideValues}
                                />
                              </td>
                              <td align="right">
                                <Number value={extract.balance} currency="LCT" display="none" hideValue={currentUser.hideValues} />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </Typography>
                </CardContent>
              </CardPageItem>
            </div>
          ) : (
            <CardPageItem style={{ textAlign: "center" }}>
              <Typography>
                <FormattedMessage id="app.noStatement" />
              </Typography>
            </CardPageItem>
          )}
        </div>

        <FixedPageItem position="bottom" elevation={1} height={75} style={{ borderRadius: "10px 10px 0px 0px", padding: 12 }}>
          <InfoItem
            variant="h5"
            caption={<FormattedMessage id="app.balanceBlocked" />}
            extraCaption={<FormattedMessage id="app.camel.balanceAvailiable" />}
            text={<Number value={account.balanceBlocked ? account.balanceBlocked : 0} currency="LCT" hideValue={currentUser.hideValues} />}
            extraText={<Number value={account.balance ? account.balance : 0} currency="LCT" hideValue={currentUser.hideValues} />}
          />
        </FixedPageItem>
      </PageContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default compose(withTheme, connect(mapStateToProps, actions))(UserStory);
