import { Button, CardActions, CardContent, CircularProgress, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { CustomView, isIOS } from "react-device-detect";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import * as actions from "../actions";
import { loadBeneficiary, removeBeneficiary, saveBeneficiary } from "../actions";
import { CustomDateOfBirthPicker, default as CustomInputForm } from "../custom/CustomInput";
import { default as customSelect } from "../custom/CustomSelect";
import HeaderPageItem from "../custom/HeaderPageItem";
import PageContainer, { CardPageItem } from "../custom/Page";
import { QuestionDialog } from "./components/QuestionDialog";

export default function Beneficiary() {
  return (
    <PageContainer>
      <HeaderPageItem title="app.beneficiary" showBackButton={true} destination="/more" />
      <BeneficiaryContractPageItem />
      <BeneficiaryFormPageItem />
    </PageContainer>
  );
}

function BeneficiaryContractPageItem() {
  return (
    <CardPageItem>
      <CardContent>
        <Typography gutterBottom variant="h5" align="center">
          <FormattedMessage id="app.beneficiary.contract.title" />
        </Typography>
        <Typography gutterBottom>
          <FormattedMessage id="app.beneficiary.contract.text.1" />
        </Typography>
        <Typography gutterBottom>
          <FormattedMessage id="app.beneficiary.contract.text.2" />
        </Typography>
        <Typography gutterBottom>
          <FormattedMessage id="app.beneficiary.contract.text.3" />
        </Typography>
        <Typography gutterBottom>
          <FormattedMessage id="app.beneficiary.contract.text.4" />
        </Typography>
        <Typography gutterBottom>
          <FormattedMessage id="app.beneficiary.contract.text.5" />
        </Typography>
        <Typography gutterBottom>
          <FormattedMessage id="app.beneficiary.contract.text.6" />
        </Typography>
        <Typography gutterBottom>
          <FormattedMessage id="app.beneficiary.contract.text.7" />
        </Typography>
      </CardContent>
    </CardPageItem>
  );
}

function BeneficiaryFormPageItem_({ currentUser, handleSubmit, initialize, initialized, pristine, submitting, invalid, showSuccessMessage, showErrorMessage, ...props }) {
  const [loading, setLoading] = useState(true);
  const [hasBeneficiary, setHasBeneficiary] = useState(false);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);

  const documentTypes = [
    { value: "CPF", input: "app.cpf" },
    { value: "CC", input: "app.cc" },
    { value: "NIF", input: "app.nif" },
    { value: "PASSPORT", input: "app.passport" },
    { value: "GSTIN", input: "app.gstin" },
    { value: "RUC", input: "app.ruc" },
    { value: "SSN", input: "app.ssn" },
  ];

  useEffect(() => {
    load();
  }, []);

  async function load() {
    try {
      const beneficiary = await loadBeneficiary(currentUser.id);
      setBenefciary(beneficiary);
    } catch (e) {
      showErrorMessage(e.message);
    }
    setLoading(false);
  }

  async function save(formData) {
    try {
      setLoading(true);
      trim(formData);
      const beneficiary = await saveBeneficiary(currentUser.id, formData);
      setBenefciary(beneficiary, "app.beneficiary.message.success.save");
    } catch (e) {
      showErrorMessage(e.message);
    }
    setLoading(false);
  }

  async function remove() {
    try {
      setShowRemoveConfirmation(false);
      setLoading(true);
      const beneficiary = await removeBeneficiary(currentUser.id);
      setBenefciary(null, "app.beneficiary.message.success.remove");
    } catch (e) {
      showErrorMessage(e.message);
    }
    setLoading(false);
  }

  function setBenefciary(beneficiary, message = null) {
    setHasBeneficiary(!!beneficiary);
    initialize(beneficiary);
    if (message !== null) showSuccessMessage(message);
  }

  return (
    <>
      <CardPageItem raised style={{ marginBottom: 16 }}>
        <form onSubmit={handleSubmit(save)}>
          <CardContent>
            <Typography gutterBottom>
              <FormattedMessage id="app.beneficiary.form.text.1" />
            </Typography>
            <BeneficiaryField name="relationship" label="app.relationship" disabled={loading || submitting} />
            <BeneficiaryField name="name" label="app.name" disabled={loading || submitting} />
            <BeneficiaryField name="email" label="app.email" disabled={loading || submitting} />
            <BeneficiaryField name="phone" label="app.phone" disabled={loading || submitting} />
            <BeneficiaryField name="address" label="app.address" disabled={loading || submitting} />
            <BeneficiaryField name="documentType" label="app.documentType" select values={documentTypes} disabled={loading || submitting} />
            <BeneficiaryField name="documentId" label="app.documentId" disabled={loading || submitting} />
            <CustomView condition={isIOS}>
              <BeneficiaryField name="dateOfBirth" label="app.dateOfBirth" type="date" disabled={loading || submitting} />
            </CustomView>
            <CustomView condition={!isIOS}>
              <BeneficiaryField name="dateOfBirth" label="app.dateOfBirth" component={CustomDateOfBirthPicker} disabled={loading || submitting} />
            </CustomView>
            <BeneficiaryField name="observations" label="app.obs" multiline disabled={loading || submitting} required={false} />
          </CardContent>
          <CardActions>
            {loading || submitting ? (
              <>
                <FormattedMessage id="app.wait" /> <CircularProgress size={24} style={{ marginLeft: 16 }} />
              </>
            ) : (
              <>
                <Button fullWidth id="bSaveBeneficiary" variant="contained" color="primary" type="submit" disabled={pristine || submitting || invalid}>
                  <FormattedMessage id="app.button.save" />
                </Button>
                <Button fullWidth id="bRemoveBeneficiary" variant="outlined" color="default" onClick={() => setShowRemoveConfirmation(true)} disabled={loading || submitting || !hasBeneficiary}>
                  <FormattedMessage id="app.delete" />
                </Button>
              </>
            )}
          </CardActions>
        </form>
      </CardPageItem>
      <QuestionDialog title={<FormattedMessage id="app.beneficiary.removeDialog.title" />} open={showRemoveConfirmation} onOk={() => remove()} onCancel={() => setShowRemoveConfirmation(false)}>
        <FormattedMessage id="app.beneficiary.removeDialog.text" />
      </QuestionDialog>
    </>
  );
}

function BeneficiaryField({ name, label, required = true, select = false, ...props }) {
  return (
    <Field
      name={name}
      label={label}
      required={required}
      component={select ? customSelect : CustomInputForm}
      InputLabelProps={{
        shrink: true,
      }}
      {...props}
    />
  );
}

function trim(obj) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && obj[key].trim) obj[key] = obj[key].trim();
  });
}

function validate(values) {
  const requiredFields = ["relationship", "name", "email", "phone", "address", "documentType", "documentId", "dateOfBirth"];
  const errors = requiredFields.reduce((errors, fieldName) => {
    if (!values[fieldName] || values[fieldName].trim() === "") errors[fieldName] = "required";
    return errors;
  }, {});
  return errors;
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

const BeneficiaryFormPageItem = compose(connect(mapStateToProps, actions), reduxForm({ form: "BeneficiaryForm", validate }))(BeneficiaryFormPageItem_);
