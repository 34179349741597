export const country = [
  //  { value: "África do Sul", input: "África do Sul" },
  //  { value: "Albânia", input: "Albânia" },
  //  { value: "Alemanha", input: "Alemanha" },
  //  { value: "Andorra", input: "Andorra" },
  //  { value: "Angola", input: "Angola" },
  //  { value: "Anguilla", input: "Anguilla" },
  //  { value: "Antigua", input: "Antigua" },
  //  { value: "Arábia Saudita", input: "Arábia Saudita" },
  { value: "Argentina", input: "app.taxResidence.Argentina" },
  //  { value: "Armênia", input: "Armênia" },
  //  { value: "Aruba", input: "Aruba" },
  { value: "Austrália", input: "app.taxResidence.Austrália" },
  //  { value: "Áustria", input: "Áustria" },
  //  { value: "Azerbaijão", input: "Azerbaijão" },
  //  { value: "Bahamas", input: "Bahamas" },
  //  { value: "Bahrein", input: "Bahrein" },
  //  { value: "Bangladesh", input: "Bangladesh" },
  //  { value: "Barbados", input: "Barbados" },
  //  { value: "Bélgica", input: "Bélgica" },
  //  { value: "Benin", input: "Benin" },
  //  { value: "Bermudas", input: "Bermudas" },
  //  { value: "Botsuana", input: "Botsuana" },
  { value: "Brasil", input: "app.taxResidence.Brasil" },
  //  { value: "Brunei", input: "Brunei" },
  //  { value: "Bulgária", input: "Bulgária" },
  //  { value: "Burkina Fasso", input: "Burkina Fasso" },
  //  { value: "botão", input: "botão" },
  //  { value: "Cabo Verde", input: "Cabo Verde" },
  //  { value: "Camarões", input: "Camarões" },
  //  { value: "Camboja", input: "Camboja" },
  //  { value: "Canadá", input: "Canadá" },
  //  { value: "Cazaquistão", input: "Cazaquistão" },
  //  { value: "Chade", input: "Chade" },
  { value: "Chile", input: "app.taxResidence.Chile" },
  //  { value: "China", input: "China" },
  //  { value: "Cidade do Vaticano", input: "Cidade do Vaticano" },
  { value: "Colômbia", input: "app.taxResidence.Colômbia" },
  //  { value: "Congo", input: "Congo" },
  //  { value: "Coréia do Sul", input: "Coréia do Sul" },
  //  { value: "Costa do Marfim", input: "Costa do Marfim" },
  //  { value: "Costa Rica", input: "Costa Rica" },
  //  { value: "Croácia", input: "Croácia" },
  //  { value: "Dinamarca", input: "Dinamarca" },
  //  { value: "Djibuti", input: "Djibuti" },
  //  { value: "Dominica", input: "Dominica" },
  { value: "EUA", input: "app.taxResidence.EUA" },
  //  { value: "Egito", input: "Egito" },
  //  { value: "El Salvador", input: "El Salvador" },
  //  { value: "Emirados Árabes", input: "Emirados Árabes" },
  { value: "Equador", input: "app.taxResidence.Equador" },
  //  { value: "Eritréia", input: "Eritréia" },
  //  { value: "Escócia", input: "Escócia" },
  //  { value: "Eslováquia", input: "Eslováquia" },
  //  { value: "Eslovênia", input: "Eslovênia" },
  //  { value: "Espanha", input: "Espanha" },
  //  { value: "Estônia", input: "Estônia" },
  //  { value: "Etiópia", input: "Etiópia" },
  //  { value: "Fiji", input: "Fiji" },
  //  { value: "Filipinas", input: "Filipinas" },
  //  { value: "Finlândia", input: "Finlândia" },
  //  { value: "França", input: "França" },
  //  { value: "Gabão", input: "Gabão" },
  //  { value: "Gâmbia", input: "Gâmbia" },
  //  { value: "Gana", input: "Gana" },
  //  { value: "Geórgia", input: "Geórgia" },
  //  { value: "Gibraltar", input: "Gibraltar" },
  //  { value: "Granada", input: "Granada" },
  //  { value: "Grécia", input: "Grécia" },
  //  { value: "Guadalupe", input: "Guadalupe" },
  //  { value: "Guam", input: "Guam" },
  //  { value: "Guatemala", input: "Guatemala" },
  //  { value: "Guiana", input: "Guiana" },
  //  { value: "Guiana Francesa", input: "Guiana Francesa" },
  //  { value: "Guiné-bissau", input: "Guiné-bissau" },
  //  { value: "Haiti", input: "Haiti" },
  //  { value: "Holanda", input: "Holanda" },
  //  { value: "Honduras", input: "Honduras" },
  //  { value: "Hong Kong", input: "Hong Kong" },
  //  { value: "Hungria", input: "Hungria" },
  //  { value: "Iêmen", input: "Iêmen" },
  //  { value: "Ilhas Cayman", input: "Ilhas Cayman" },
  //  { value: "Ilhas Cook", input: "Ilhas Cook" },
  //  { value: "Ilhas Curaçao", input: "Ilhas Curaçao" },
  //  { value: "Ilhas Marshall", input: "Ilhas Marshall" },
  //  { value: "Ilhas Turks & Caicos", input: "Ilhas Turks & Caicos" },
  //  { value: "Ilhas Virgens (brit.)", input: "Ilhas Virgens (brit.)" },
  //  { value: "Ilhas Virgens(amer.)", input: "Ilhas Virgens(amer.)" },
  //  { value: "Ilhas Wallis e Futuna", input: "Ilhas Wallis e Futuna" },
  { value: "Índia", input: "app.taxResidence.Índia" },
  //  { value: "Indonésia", input: "Indonésia" },
  //  { value: "Inglaterra", input: "Inglaterra" },
  //  { value: "Irlanda", input: "Irlanda" },
  //  { value: "Islândia", input: "Islândia" },
  //  { value: "Israel", input: "Israel" },
  //  { value: "Itália", input: "Itália" },
  //  { value: "Jamaica", input: "Jamaica" },
  //  { value: "Japão", input: "Japão" },
  //  { value: "Jordânia", input: "Jordânia" },
  //  { value: "Kuwait", input: "Kuwait" },
  //  { value: "Latvia", input: "Latvia" },
  //  { value: "Líbano", input: "Líbano" },
  //  { value: "Liechtenstein", input: "Liechtenstein" },
  //  { value: "Lituânia", input: "Lituânia" },
  //  { value: "Luxemburgo", input: "Luxemburgo" },
  //  { value: "Macau", input: "Macau" },
  //  { value: "Macedônia", input: "Macedônia" },
  //  { value: "Madagascar", input: "Madagascar" },
  //  { value: "Malásia", input: "Malásia" },
  //  { value: "Malaui", input: "Malaui" },
  //  { value: "Mali", input: "Mali" },
  //  { value: "Malta", input: "Malta" },
  //  { value: "Marrocos", input: "Marrocos" },
  //  { value: "Martinica", input: "Martinica" },
  //  { value: "Mauritânia", input: "Mauritânia" },
  //  { value: "Mauritius", input: "Mauritius" },
  { value: "México", input: "app.taxResidence.México" },
  //  { value: "Moldova", input: "Moldova" },
  //  { value: "Mônaco", input: "Mônaco" },
  //  { value: "Montserrat", input: "Montserrat" },
  //  { value: "Nepal", input: "Nepal" },
  //  { value: "Nicarágua", input: "Nicarágua" },
  //  { value: "Niger", input: "Niger" },
  //  { value: "Nigéria", input: "Nigéria" },
  //  { value: "Noruega", input: "Noruega" },
  //  { value: "Nova Caledônia", input: "Nova Caledônia" },
  { value: "Nova Zelândia", input: "app.taxResidence.Nova Zelândia" },
  //  { value: "Omã", input: "Omã" },
  //  { value: "Palau", input: "Palau" },
  { value: "Panamá", input: "app.taxResidence.Panamá" },
  //  { value: "Papua-nova Guiné", input: "Papua-nova Guiné" },
  //  { value: "Paquistão", input: "Paquistão" },
  //  { value: "Peru", input: "Peru" },
  //  { value: "Polinésia Francesa", input: "Polinésia Francesa" },
  //  { value: "Polônia", input: "Polônia" },
  //  { value: "Porto Rico", input: "Porto Rico" },
  { value: "Portugal", input: "app.taxResidence.Portugal" },
  //  { value: "Qatar", input: "Qatar" },
  //  { value: "Quênia", input: "Quênia" },
  //  { value: "Rep. Dominicana", input: "Rep. Domiicana" },
  //  { value: "Rep. Tcheca", input: "Rep. Tcheca" },
  //  { value: "Reunion", input: "Reunion" },
  //  { value: "Romênia", input: "Romênia" },
  //  { value: "Ruanda", input: "Ruanda" },
  //  { value: "Rússia", input: "Rússia" },
  //  { value: "Saipan", input: "Saipan" },
  //  { value: "Samoa Americana", input: "Samoa Americana" },
  //  { value: "Senegal", input: "Senegal" },
  //  { value: "Serra Leone", input: "Serra Leone" },
  //  { value: "Seychelles", input: "Seychelles" },
  //  { value: "Singapura", input: "Singapura" },
  //  { value: "Síria", input: "Síria" },
  //  { value: "Sri Lanka", input: "Sri Lanka" },
  //  { value: "St. Kitts & Nevis", input: "St. Kitts & Nevis" },
  //  { value: "St. Lúcia", input: "St. Lúcia" },
  //  { value: "St. Vincent", input: "St. Vincent" },
  //  { value: "Sudão", input: "Sudão" },
  //  { value: "Suécia", input: "Suécia" },
  //  { value: "Suiça", input: "Suiça" },
  //  { value: "Suriname", input: "Suriname" },
  //  { value: "Tailândia", input: "Tailândia" },
  //  { value: "Taiwan", input: "Taiwan" },
  //  { value: "Tanzânia", input: "Tanzânia" },
  //  { value: "Togo", input: "Togo" },
  //  { value: "Trinidad & Tobago", input: "Trinidad & Tobago" },
  //  { value: "Tunísia", input: "Tunísia" },
  //  { value: "Turquia", input: "Turquia" },
  //  { value: "Ucrânia", input: "Ucrânia" },
  //  { value: "Uganda", input: "Uganda" },
  { value: "Uruguai", input: "app.taxResidence.Uruguai" },
  { value: "Venezuela", input: "app.taxResidence.Venezuela" },
  //  { value: "Vietnã", input: "Vietnã" },
  //  { value: "Zaire", input: "Zaire" },
  //  { value: "Zâmbia", input: "Zâmbia" },
  //  { value: "Zimbábue", input: "Zimbábue" },
];
