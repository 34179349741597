import React, { Component, Fragment } from "react";
import { Button, CardContent, CircularProgress, Typography, Grid } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { getWithdrawById } from "../actions/Withdrawal";
import HeaderPageItem from "../custom/HeaderPageItem";
import PageContainer, { CardPageItem } from "../custom/Page";
import InfoItem from "../custom/InfoItem";
import Number from "../custom/Number";
import Moment from "react-moment";
import performRequest from "../configAxios";
import { addDays } from "../util";

export default class DetailHistoryWithdrawal extends Component {
	state = {
		withdrawal: null,
		loading: true,
		edit: false,
		navWithdrawall: false,
		userWithdraw: null,
	};
	componentDidMount() {
		this.tab = parseInt(this.props.location.search.substring(1));
		this.tab = isNaN(this.tab) ? 0 : this.tab;

		getWithdrawById(this.props.match.params.id)
			.then((data) => {
				this.setState({
					withdrawal: data,
					loading: false,
				});
				if (this.state.withdrawal.loggedUserId) {
					this.getUserByID(this.state.withdrawal.loggedUserId);
				}
			})

			.catch((e) => {
				this.props.history.push("/404");
			});
	}

	getUserByID = (id) => {
		performRequest("GET", `/user/${id}`, null, true)
			.then((response) => {
				this.setState({ userWithdraw: response.data.data });
			})
			.catch((error) => {
				this.setState({ loading: false });
			});
		this.setState({ loading: false });
	};

	render() {
		const { withdrawal, loading, userWithdraw } = this.state;

		if (loading) return <CircularProgress style={{ marginTop: "25%" }} />;
		return (
			<PageContainer>
				<HeaderPageItem
					title="app.withdraw.nr"
					values={withdrawal.id}
					onClick={() => this.setState({ tabIndex: 1 })}
					showBackButton
					destination={"/historyWithdrawal?" + this.tab}
				/>
				<CardPageItem raised>
					<CardContent>
						{/* Status */}
						<Typography variant="h6" color="primary">
							<FormattedMessage id={`app.enuns.${withdrawal.status}`} />
						</Typography>

						{withdrawal.rejectionReason && (
							/* Motivo da Rejeição */
							<InfoItem
								caption={<FormattedMessage id="app.reason.rejection" />}
								text={withdrawal.rejectionReason}
							/>
						)}
						{/* Responsavel */}
						{userWithdraw && (
							<InfoItem
								caption={<FormattedMessage id="app.responsible" />}
								text={userWithdraw.name + " (ID: " + userWithdraw.id + ")"}
							/>
						)}

						{/* cliente */}
						<InfoItem
							caption={<FormattedMessage id="app.contract.canceledBy.customer" />}
							text={withdrawal.account.user.name}
						/>

						{withdrawal.status != "CANCELED" && withdrawal.status != "WAITING_AUTH" ? (
							// Data autorização e data de pedido
							<InfoItem
								caption={<FormattedMessage id="app.confirmed" />}
								text={
									<Moment
										date={
											withdrawal.authorizeDate ? withdrawal.authorizeDate : withdrawal.createdAt
										}
										format="DD/MM/YYYY"
									/>
								}
								extraCaption={<FormattedMessage id="app.data.order" />}
								extraText={<Moment date={withdrawal.createdAt} format="DD/MM/YYYY" />}
							/>
						) : (
							// Data pedido
							<InfoItem
								caption=""
								text=""
								extraCaption={<FormattedMessage id="app.data.order" />}
								extraText={<Moment date={withdrawal.createdAt} format="DD/MM/YYYY" />}
							/>
						)}
						{withdrawal.status == "REJECTED" ? (
							// Data rejeição e data de taxa
							<Fragment>
								<InfoItem
									caption={<FormattedMessage id="app.rejected" />}
									text={<Moment date={withdrawal.updatedAt} format="DD/MM/YYYY" />}
								/>
								{/* Valor solicitado taca */}
								<InfoItem
									caption={<FormattedMessage id="app.amount.requested" />}
									text={<Number value={withdrawal.amount} currency="LCT" />}
									extraCaption={<FormattedMessage id="app.fee" />}
									extraText={<Number value={withdrawal.fee} currency="LCT" />}
								/>
							</Fragment>
						) : withdrawal.status == "CANCELED" ? (
							<Fragment>
								{/* Valor solicitado e  taxa */}
								<InfoItem
									caption={<FormattedMessage id="app.amount.requested" />}
									text={<Number value={withdrawal.amount} currency="LCT" />}
									extraCaption={<FormattedMessage id="app.fee" />}
									extraText={<Number value={withdrawal.fee} currency="LCT" />}
								/>
								{/* Data cancelamento  e cotação efetiva*/}
								<InfoItem
									caption={<FormattedMessage id="app.enuns.CANCELED" />}
									text={<Moment date={withdrawal.updatedAt} format="DD/MM/YYYY" />}
								/>
							</Fragment>
						) : withdrawal.status == "WAITING_AUTH" ? (
							<Fragment>
								{/* Valor solicitado e taxa */}
								<InfoItem
									caption={<FormattedMessage id="app.amount.requested" />}
									text={<Number value={withdrawal.amount} currency="LCT" />}
									extraCaption={<FormattedMessage id="app.fee" />}
									extraText={<Number value={withdrawal.fee} currency="LCT" />}
								/>
							</Fragment>
						) : (
							withdrawal.status == "APPROVED" && (
								// data de aprovação e Data limite
								<Fragment>
									<InfoItem
										caption={<FormattedMessage id="app.enuns.APPROVED" />}
										text={<Moment date={withdrawal.withdrawDate} format="DD/MM/YYYY" />}
										extraCaption={<FormattedMessage id="app.limit" />}
										extraText={
											<Moment
												date={addDays(
													withdrawal.authorizeDate
														? withdrawal.authorizeDate
														: withdrawal.createdAt,
													4
												)}
												format="DD/MM/YYYY"
											/>
										}
									/>
									{/* valor solicitado e  taxa */}

									<InfoItem
										caption={<FormattedMessage id="app.amount.requested" />}
										text={<Number value={withdrawal.amount} currency="LCT" />}
										extraCaption={<FormattedMessage id="app.fee" />}
										extraText={<Number value={withdrawal.fee} currency="LCT" />}
									/>
									{/* Valor Pago e cotação efetiva */}
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Grid container direction="row" justify="flex-start">
											{/* Valor  pago*/}
											<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
												<Typography
													style={{ marginBottom: -10 }}
													variant="h6"
													gutterBottom
													color="primary"
												>
													<FormattedMessage id="app.amount.paid" />
												</Typography>
												<Typography variant="h6" gutterBottom color="primary">
													<Number value={withdrawal.amount - withdrawal.fee} currency="LCT" />
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									{/* BTC enviado e Estimativa em BTC */}
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Grid container direction="row">
											{/* BTC enviado*/}
											<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
												<Grid container direction="row" justify="flex-start">
													<Grid item>
														<Fragment>
															<InfoItem
																caption={<FormattedMessage id="app.btc.sent" />}
																text={<Number value={withdrawal.btc} currency="BTC" />}
															/>
														</Fragment>
													</Grid>
												</Grid>
											</Grid>
											{/* Cotação efetiva */}
											<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
												<Grid container direction="row" justify="flex-end">
													<Grid item>
														<InfoItem
															caption=""
															text=""
															extraCaption={<FormattedMessage id="app.price.effective" />}
															extraText={
																<Number
																	value={
																		withdrawal.btc
																			? (withdrawal.amount - withdrawal.fee) /
																			  withdrawal.btc
																			: 0
																	}
																	currency="LCT"
																/>
															}
														/>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Fragment>
							)
						)}

						{/* Carteira */}
						<InfoItem
							caption={<FormattedMessage id="app.wallet.destination" />}
							text={withdrawal.address}
						/>
						{
							//DIEGO valida se o checkbox da exchange Americansbitcoin está marcado
							withdrawal.partnerWithdraw == 1 ? (
								<Typography gutterBottom color="primary">
									<b>{<FormattedMessage id="app.warning" />}</b>
									{": "}
									{<FormattedMessage id="app.withdraw.partner" />}
								</Typography>
							) : (
								withdrawal.partnerWithdraw == 2 && (
									<Typography gutterBottom color="primary">
										<b>{<FormattedMessage id="app.warning" />}</b>
										{": "}
										{<FormattedMessage id="app.withdraw.reaport" />}
									</Typography>
								)
							)
						}
						{withdrawal.transactionId && (
							<>
								<InfoItem
									caption={<FormattedMessage id="app.transactionId" />}
									text={withdrawal.transactionId}
								/>

								<Button
									fullWidth
									variant="outlined"
									href={"https://www.blockchain.com/pt/btc/tx/" + withdrawal.transactionId}
									target="_blank"
								>
									<FormattedMessage id="app.transaction.blockchain" />
								</Button>
							</>
						)}
					</CardContent>
				</CardPageItem>
			</PageContainer>
		);
	}
}
