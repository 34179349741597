import React, { Component } from "react";
import {
  withStyles,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  InputBase,
  IconButton
} from "@material-ui/core";
import PageContainer, { PageItem } from "../custom/Page";
import SearchIcon from "@material-ui/icons/Search";
import performRequest from "../configAxios";
import Loader from "../custom/LoaderComponent";
import { Link } from "react-router-dom";
class AdminDeposits extends Component {
  state = {
    deposits: [],
    loading: true
  };

  componentDidMount() {
    this.getDeposits();
  }

  getDeposits = () => {
    performRequest("GET", `contract/deposits?status=PENDING`, null, true)
      .then(response => {
        this.setState({ deposits: response.data.data, loading: false });
      })
      .catch();
  };

  searchDeposits = name => {
    performRequest(
      "GET",
      `contract/deposits?status=PENDING&name=${name}`,
      null,
      true
    )
      .then(response => {
        this.setState({ deposits: response.data.data });
      })
      .catch();
  };

  render() {
    const { classes } = this.props;
    const { deposits, loading } = this.state;
    if (loading) return <Loader />;
    return (
      <PageContainer>
        <PageItem>
          <Card>
            <CardContent>
              <IconButton className={classes.iconButton} aria-label="Search">
                <SearchIcon />
              </IconButton>
              <InputBase
                className={classes.input}
                placeholder="app.deposit.search"
                onChange={e => this.searchDeposits(e.target.value)}
              />
              <List className={classes.root}>
                {deposits.map(c => {
                  return (
                    <ListItem
                      button
                      alignItems="flex-start"
                      key={c.id}
                      component={Link}
                      to={"/deposits/" + c.id}
                    >
                      <ListItemText
                        primaryTypographyProps={{ component: "div" }}
                        secondaryTypographyProps={{ component: "div" }}
                        primary={
                          <div
                            color="textSecondary"
                            style={{
                              display: "flex",
                              justifyContent: "space-between"
                            }}
                          >
                            <span>{c.contract.user.name}</span>
                          </div>
                        }
                        secondary={
                          <div>
                            <div
                              color="textSecondary"
                              style={{
                                display: "flex",
                                flexDirection: "column"
                              }}
                            >
                              <span> {`LCContract C${c.contract.id}`}</span>
                              <span> {c.transactionId}</span>
                            </div>
                          </div>
                        }
                      />
                    </ListItem>
                  );
                })}
              </List>
            </CardContent>
          </Card>
        </PageItem>
      </PageContainer>
    );
  }
}

const styles = theme => ({
  table: {
    marginTop: theme.spacing(3)
  },
  cardHeader: {
    textAlign: "center"
  },
  card: {
    marginTop: "20px"
  },
  content: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    margin: theme.spacing(1)
  },
  div: {
    maxWidth: 1000,
    padding: theme.spacing(4),
    margin: "auto"
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    display: "inline"
  },
  right: {
    textAlign: "right"
  },
  input: {
    marginLeft: 8,
    flex: 1
  },
  iconButton: {
    padding: 10
  }
});

export default withStyles(styles)(AdminDeposits);
