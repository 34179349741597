import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { getConfig2 } from "../actions/Config";

export class QuotationUpdaterObj {
  quotes = {
    LCTUSD: 0,
    LCTEUR: 0,
    LCTBRL: 0,
    LCTBTCSell: 0,
    LCTBTCBuy: 0,
    fiatCurrency: "",
    LCTFiat: 0
  };

  updateRateProgress = 0;
  fiatCurrency = "";
  onChange = null;
  onProgress = null;
  updateIntervalInSeconds = 15;
  intervalUpdateQuotation = null;
  lastUpdateRateTime = moment().subtract(this.updateIntervalInSeconds, "seconds");

  constructor(onChange, onProgress, fiatCurrency = "BRL") {
    this.fiatCurrency = fiatCurrency;
    this.onChange = onChange;
    this.onProgress = onProgress;
    if (this.onProgress) {
      this.autoUpdate();
    }
  }

  updateQuotation = fiatCurrency => {
    if (fiatCurrency) this.fiatCurrency = fiatCurrency;
    return new Promise((resolve, reject) => {
      getConfig2()
        .then(data => {
          this.quotes = {
            LCTUSD: data.lctUsd,
            LCTEUR: data.lctEur,
            LCTBRL: data.lctBrl,
            LCTBTCSell: data.sellLctXbt,
            LCTBTCBuy: data.buyLctXbt,
            fiatCurrency: this.fiatCurrency,
            LCTFiat: this.fiatCurrency
              ? data["lct" + this.fiatCurrency.charAt(0) + this.fiatCurrency.slice(1).toLowerCase()]
              : 0
          };
          resolve(this.quotes);
        })
        .catch(err => {
          reject(err);
        });
    });
  };

  forceUpdate = () => {
    this.updateQuotation().then(quotes => {
      if (this.onChange) this.onChange(this.quotes);
    });
  };

  autoUpdate = () => {
    const timeDiff = moment().diff(this.lastUpdateRateTime, "seconds");
    if (timeDiff >= this.updateIntervalInSeconds) {
      this.updateQuotation().then(quotes => {
        this.lastUpdateRateTime = moment();
        this.intervalUpdateQuotation = setTimeout(this.autoUpdate, 1000);
        if (this.onChange) this.onChange(this.quotes);
      });
    } else {
      this.intervalUpdateQuotation = setTimeout(this.autoUpdate, 1000);
    }
    if (this.onProgress) this.onProgress((timeDiff / this.updateIntervalInSeconds) * 100);
  };

  stopAutoUpdate = () => {
    clearTimeout(this.intervalUpdateQuotation);
  };
}

class QuotationUpdater extends Component {
  static propTypes = {
    onChange: PropTypes.any,
    onProgress: PropTypes.any,
    color: PropTypes.string,
    size: PropTypes.number,
    hidden: PropTypes.bool,
    style: PropTypes.any,
    key: PropTypes.any
  };

  state = {
    updateRateProgress: 0
  };

  quotationUpdaterObj = null;

  componentDidMount = () => {
    this.quotationUpdaterObj = new QuotationUpdaterObj(
      quotes => {
        if (this.props.onChange) this.props.onChange(quotes);
      },
      progress => {
        this.setState({ updateRateProgress: progress });
        if (this.props.onProgress) this.props.onProgress(this.state.updateRateProgress);
      },
      this.props.currency
    );
  };

  componentWillUnmount = () => {
    this.quotationUpdaterObj.stopAutoUpdate();
  };

  render = () => {
    const { hidden, style } = this.props;
    const color = this.props.color ? this.props.color : "#1E2832";
    const size = this.props.size ? this.props.size : 32;
    if (hidden) return null;
    else
      return (
        <div>
          <CircularProgress
            variant="static"
            size={size}
            value={100}
            style={{
              color,
              ...style,
              borderRadius: "50%",
              borderColor: "red",
              position: "absolute"
            }}
          />
          <CircularProgress
            variant="static"
            size={size}
            value={this.state.updateRateProgress}
            style={{ color: "primary", ...style }}
          />
        </div>
      );
  };
}

export default withRouter(QuotationUpdater);
