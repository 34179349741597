import {
  Button,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import * as momentTimezone from "moment-timezone";
import queryString from "query-string";
import React, { Component, Fragment, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import Moment from "react-moment";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "../actions";
import { getConfigurações } from "../actions";
import {
  cancelContract,
  downloadContract,
  downloadReceipt,
  editPrevisionAmount,
  generateContractPDF,
  generateContractReceipt,
  getActiveContractType,
  getContract,
  sendContractPDF,
  sendContractReceiptPDF,
  sendContractTransaction,
} from "../actions/Contract";
import HeaderPageItem from "../custom/HeaderPageItem";
import InfoItem from "../custom/InfoItem";
import Number from "../custom/Number";
import PageContainer, { CardPageItem } from "../custom/Page";
import { ContractPhases, ContractStates, downloadBase64, Roles } from "../util";
import ContractDetail from "./components/ContractDetail";
import { QuestionDialog } from "./components/QuestionDialog";

function AgentContract({ match, currentUser, getConfig }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [contract, setContract] = useState(null);
  const [showCancelPrompt, setShowCancelPrompt] = useState(false);

  useEffect(() => {
    effectOnce();
  }, []);

  async function effectOnce() {
    setLoading(true);
    try {
      await getConfig(); // Para <Number> funcionar.
      const contract = await getContract(match.params.id);
      contract.customer = contract.user;
      setContract(contract);
    } catch (e) {
      setError(e.response.data.message);
    }
    setLoading(false);
  }

  function doCancel() {
    setLoading(true);
    cancelContract(contract.id)
      .then(() => {
        effectOnce();
        //dispatchSuccessMessage("app.terminated.successfully");
      })
      .catch((e) => {
        setLoading(false);
        alert(e.response ? e.response.data.message : e);
        //dispatchErrorMessage(e.response ? e.response.data.message : e);
      });
  }

  if (loading) {
    return <CircularProgress style={{ marginTop: "25%" }} />;
  } else if (error) {
    return <>{error}</>;
  } else {
    return (
      <>
        <PageContainer>
          <ContractHeaderPageItem
            currentUser={currentUser}
            contract={contract}
            onCancelClick={() => setShowCancelPrompt(true)}
          />
          <CardPageItem>
            <CardContent>
              <ContractDetail contract={contract} user={currentUser} />
            </CardContent>
          </CardPageItem>
        </PageContainer>

        <QuestionDialog
          title={<FormattedMessage id="app.cancelContract" />}
          onCancel={() => setShowCancelPrompt(false)}
          open={showCancelPrompt}
          onOk={() => {
            setShowCancelPrompt(false);
            doCancel();
          }}
        >
          <FormattedMessage id="app.cancelContract.text" />
        </QuestionDialog>
      </>
    );
  }
}

export default connect((state) => ({ currentUser: state.user.user.me, config: state.config }), actions)(AgentContract);

function ContractHeaderPageItem({ currentUser, contract, backTo, onCancelClick }) {
  const isAgentOrManager = currentUser.role === Roles.Agent;
  const isEditable = contract.status === ContractStates.Inactive && contract.amount === 0;
  const [menuAnchor, setMenuAnchor] = useState(undefined);
  if (!backTo) {
    backTo = "/customer/" + contract.customer.id;
  }
  return (
    <HeaderPageItem
      title="app.contract.title"
      values={contract.id}
      showBackButton
      destination={backTo}
      onMore={isAgentOrManager && ((e) => setMenuAnchor(e.target))}
    >
      {isAgentOrManager && (
        <Menu onClose={(e) => setMenuAnchor(undefined)} open={!!menuAnchor} anchorEl={menuAnchor}>
          <MenuItem
            disabled={!isEditable}
            component={Link}
            to={"/editContract/" + contract.id}
            onClick={(e) => setMenuAnchor(undefined)}
          >
            <FormattedMessage id="app.editContract" />
          </MenuItem>

          <MenuItem
            disabled={!isEditable}
            onClick={(e) => {
              setMenuAnchor(undefined);
              onCancelClick();
            }}
          >
            <FormattedMessage id="app.cancelContract" />
          </MenuItem>

          <MenuItem
            disabled={contract.status === ContractStates.Inactive || contract.phase === ContractPhases.Canceled}
            component={Link}
            to={"/contractHistory/" + contract.id}
            onClick={(e) => setMenuAnchor(undefined)}
          >
            <FormattedMessage id="app.monthlyHistory" />
          </MenuItem>
        </Menu>
      )}
    </HeaderPageItem>
  );
}

class Contract extends Component {
  state = {
    contract: {},
    customer: {},
    types: [],
    sendingFile: false,
    sendingTransaction: false,
    transaction: null,
    accept: false,
    aportTime: false,
    values: { previsionAmount: 4500, type: null },
    config: {},
    btn: true,
    disableBt: false,
    dataOpen: momentTimezone(),
    dataClose: momentTimezone(),
    loading: true,
    error: null,
  };

  submitTransactionId = (formProps) => {
    this.setState({ sendingTransaction: true, disableBt: true });
    const { showErrorMessage, showSuccessMessage } = this.props;
    setTimeout(() => {
      sendContractTransaction(this.state.contract.id, formProps)
        .then((data) => {
          this.setState({
            sendingTransaction: false,
            transaction: data,
            disableBt: false,
          });

          showSuccessMessage("app.send");
          this.props.change("transactionId", "");
          this.loadContract();
        })
        .catch((e) => {
          this.setState({ sendingTransaction: false, disableBt: false });
          if (e.response) {
            showErrorMessage(e.response.data.message);
          } else {
            showErrorMessage(e);
          }
        });
    }, 2000);
  };

  generatePDF = (e) => {
    const { showErrorMessage } = this.props;
    generateContractPDF(this.state.contract.id)
      .then((data) => {
        downloadBase64(data, "app.lccontract" + this.state.contract.id + ".pdf");
      })
      .catch((error) => {
        showErrorMessage(error.message);
      });
  };

  sendSigned = (e) => {
    const { showErrorMessage } = this.props;
    if (e.target.files && e.target.files.length > 0) {
      this.setState({ sendingFile: true });
      const file = e.target.files[0];
      sendContractPDF(this.state.contract.id, file)
        .then((data) => {
          this.loadContract();
          this.setState({ sendingFile: false });
        })
        .catch((error) => {
          showErrorMessage(error.message);
          this.setState({ sendingFile: false });
        });
    }
  };

  generateReceipt = (e) => {
    const { showErrorMessage } = this.props;
    generateContractReceipt(this.state.contract.id)
      .then((data) => {
        const downloadLink = document.createElement("a");
        downloadLink.href = "data:application/pdf;base64," + data;
        downloadLink.download = "app.contract.receipt_" + this.state.contract.id + ".pdf";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      })
      .catch((error) => {
        showErrorMessage(error.message);
      });
  };

  sendReceipt = (e) => {
    const { showErrorMessage } = this.props;
    if (e.target.files && e.target.files.length > 0) {
      this.setState({ sendingFile: true });
      const file = e.target.files[0];
      sendContractReceiptPDF(this.state.contract.id, file)
        .then((data) => {
          this.loadContract();
          this.setState({ sendingFile: false });
        })
        .catch((error) => {
          showErrorMessage(error.message);
          this.setState({ sendingFile: false });
        });
    }
  };

  downloadContract = (e) => {
    const { showErrorMessage } = this.props;
    downloadContract(this.state.contract.id)
      .then((data) => {
        downloadBase64(data, "app.lccontract" + this.state.contract.id + ".pdf");
      })
      .catch((error) => {
        showErrorMessage(error.message);
      });
  };

  downloadReceipt = (e) => {
    const { showErrorMessage } = this.props;
    downloadReceipt(this.state.contract.id)
      .then((data) => {
        downloadBase64(data, "app.contract.receipt" + this.state.contract.id + ".pdf");
      })
      .catch((error) => {
        showErrorMessage(error.message);
      });
  };

  loadContract() {
    this.setState({ contract: {}, loading: true });
    getContract(this.props.match.params.id)
      .then((contracts) => {
        getConfigurações().then((config) => {
          let dataOpen = momentTimezone(config.startTime, "HH:mm:ss");
          dataOpen.tz(this.props.currentUser.timezone);
          let dataClose = momentTimezone(config.finishTime, "HH:mm:ss");
          dataClose.tz(this.props.currentUser.timezone);
          this.setState({
            contract: contracts,
            config: config,
            dataOpen: dataOpen,
            dataClose: dataClose,
            loading: false,
          });
        });
      })
      .catch((e) => {
        this.setState({ error: e.response.data.message, loading: false });
      });
  }

  edit = (id) => {
    const { showErrorMessage, showSuccessMessage } = this.props;
    const { values } = this.state;

    editPrevisionAmount(id, values)
      .then((data) => {
        showSuccessMessage("app.edit.sucesfully");
        this.loadContract();
      })
      .catch((e) => {
        showErrorMessage(e.response ? e.response.data.message : e);
      });
  };

  cancel = () => {
    const { showErrorMessage, showSuccessMessage } = this.props;
    cancelContract(this.props.match.params.id)
      .then((data) => {
        showSuccessMessage("app.terminated.successfully");
        this.loadContract();
        this.setState({ cancel: false });
      })
      .catch((e) => {
        showErrorMessage(e.response ? e.response.data.message : e);
      });
  };

  componentDidMount() {
    this.loadContract();

    getActiveContractType().then((data) => {
      this.setState({ types: data });
    });
  }

  render() {
    const { location, currentUser } = this.props;
    const { loading, contract, transaction, error } = this.state;

    if (loading) {
      return <CircularProgress style={{ marginTop: "25%" }} />;
    } else {
      if (error) {
        return <Typography>{error}</Typography>;
      }

      let backTo = queryString.parse(location.search).backTo;
      if (!backTo) {
        if (currentUser.role == Roles.Auditor) {
          backTo = "/user/" + this.state.contract.user.id;
        } else {
          backTo = "/customer/" + this.state.contract.user.id;
        }
      }

      if (contract.phase == "WAITING_APORT" && contract.amount > 0) {
        return (
          <Fragment>
            <PageContainer>
              <HeaderPageItem
                title="app.contract.title"
                values={contract.id}
                showBackButton
                destination={backTo}
                onMore={this.props.currentUser.role != "AUDITOR" && ((e) => this.setState({ menuAnchor: e.target }))}
              >
                {this.props.currentUser.role != "AUDITOR" && (
                  <Menu
                    onClose={(e) => this.setState({ menuAnchor: undefined })}
                    open={!!this.state.menuAnchor}
                    anchorEl={this.state.menuAnchor}
                  >
                    <MenuItem disabled={true}>
                      <FormattedMessage id="app.editContract" />
                    </MenuItem>

                    <MenuItem disabled={true}>
                      <FormattedMessage id="app.cancelContract" />
                    </MenuItem>
                  </Menu>
                )}
              </HeaderPageItem>
              <CardPageItem>
                <CardContent>
                  <Typography gutterBottom color="primary" variant="h6">
                    <FormattedMessage id="app.aport.validating.your" />
                  </Typography>
                  <Typography gutterBottom variant="h6">
                    <FormattedMessage id="app.aport.validating.your.text1" />
                  </Typography>
                </CardContent>
              </CardPageItem>
            </PageContainer>
          </Fragment>
        );
      } else {
        const amountLeft = contract.previsionAmount - contract.amount;
        const now = momentTimezone();
        now.tz(this.props.currentUser.timezone);
        return (
          <PageContainer>
            <HeaderPageItem
              title="app.contract.title"
              values={contract.id}
              showBackButton
              destination={backTo}
              onMore={this.props.currentUser.role != "AUDITOR" && ((e) => this.setState({ menuAnchor: e.target }))}
            >
              {this.props.currentUser.role != "AUDITOR" && (
                <Menu
                  onClose={(e) => this.setState({ menuAnchor: undefined })}
                  open={!!this.state.menuAnchor}
                  anchorEl={this.state.menuAnchor}
                >
                  <MenuItem
                    disabled={contract.amount > 0}
                    component={Link}
                    to={"/editContract/" + contract.id}
                    onClick={(e) => {
                      this.setState({ menuAnchor: undefined });
                    }}
                  >
                    <FormattedMessage id="app.editContract" />
                  </MenuItem>

                  <MenuItem
                    disabled={contract.amount > 0}
                    onClick={(e) => {
                      this.setState({ verifyCancel: true });
                      this.setState({ menuAnchor: undefined });
                    }}
                  >
                    <FormattedMessage id="app.cancelContract" />
                  </MenuItem>

                  <MenuItem
                    disabled={currentUser.id !== contract.user.consultantId}
                    component={Link}
                    to={"/contractHistory/" + contract.id}
                    onClick={(e) => this.setState({ menuAnchor: undefined })}
                  >
                    <FormattedMessage id="app.monthlyHistory" />
                  </MenuItem>
                </Menu>
              )}
            </HeaderPageItem>

            <QuestionDialog
              title={<FormattedMessage id="app.cancelContract" />}
              onCancel={() => this.setState({ cancel: false, verifyCancel: false })}
              open={this.state.verifyCancel}
              onOk={() => {
                this.setState({ cancel: true, verifyCancel: false });
                this.cancel();
              }}
            >
              <FormattedMessage id="app.cancelContract.text" />
            </QuestionDialog>

            {contract.status == "ACTIVE" ? (
              <CardPageItem>
                <CardContent>
                  <ContractDetail contract={contract} user={this.props.currentUser} />
                </CardContent>
              </CardPageItem>
            ) : contract.status == "TERMINATED" ? (
              <CardPageItem>
                <CardContent>
                  <ContractDetail contract={contract} user={this.props.currentUser} />
                </CardContent>
              </CardPageItem>
            ) : contract.status == "ARCHIVE" ? (
              <CardPageItem>
                <CardContent>
                  <ContractDetail contract={contract} user={this.props.currentUser} />
                </CardContent>
              </CardPageItem>
            ) : (
              <CardPageItem>
                <CardContent>
                  <Typography variant="h6" color="primary" gutterBottom>
                    <FormattedMessage id={`app.enuns.${contract.phase}`} />
                  </Typography>
                  {contract.phase == "WAITING_SIGN" ? (
                    <>
                      {this.state.sendingFile ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <CircularProgress style={{ marginBottom: 8 }} />
                          <Typography>Enviando arquivo...</Typography>
                        </div>
                      ) : (
                        <>
                          <input
                            ref={(r) => (this.inputPDF = r)}
                            id="inputPDF"
                            style={{ display: "none" }}
                            accept={this.docFileMask}
                            type="file"
                            onChange={this.sendSigned}
                          />
                          {contract.fileUrl ? (
                            <>
                              <Typography gutterBottom>
                                <FormattedMessage id="app.lccontract.submitted.successfully" />.
                              </Typography>
                              <Typography gutterBottom>
                                <FormattedMessage id="app.lccontract.waitingApproval.administration" />.
                              </Typography>
                              <Typography gutterBottom variant="caption" color="textSecondary">
                                <FormattedMessage id="app.lccontract.text1" />.
                              </Typography>
                              <Button
                                fullWidth
                                variant="outlined"
                                style={{ marginTop: 16, marginRight: 8 }}
                                onClick={this.downloadContract}
                              >
                                <FormattedMessage id="app.file.viewUpload" />
                              </Button>
                              <Button
                                fullWidth
                                variant="outlined"
                                style={{ marginTop: 16 }}
                                onClick={(e) => this.inputPDF.click()}
                              >
                                <FormattedMessage id="app.resend" />
                              </Button>
                            </>
                          ) : (
                            <>
                              <Typography gutterBottom>
                                <FormattedMessage id="app.customer.waitingAssign" />
                              </Typography>
                            </>
                          )}

                          <Typography variant="h6" gutterBottom style={{ marginTop: 32 }}>
                            <FormattedMessage id="app.contractDetails" />
                          </Typography>

                          <ContractDetail contract={contract} user={this.props.currentUser} />
                        </>
                      )}
                    </>
                  ) : contract.phase == "WAITING_APORT" ? (
                    <>
                      {this.state.sendingTransaction ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <CircularProgress style={{ marginBottom: 8 }} />
                          <Typography>
                            <FormattedMessage id="app.transaction.validating" />
                          </Typography>
                        </div>
                      ) : (
                        ""
                      )}
                      <Typography variant="h6" gutterBottom style={{ marginTop: 32 }}>
                        <FormattedMessage id="app.contractDetails" />
                      </Typography>

                      <ContractDetail contract={contract} user={this.props.currentUser}></ContractDetail>

                      {transaction && (
                        <HelpDialog
                          open={true}
                          onClose={(e) => this.setState({ transaction: null })}
                          title="app.contribution.informed"
                        >
                          {" "}
                          {/* "app.transaction.success" Aporte informado*/}
                          <InfoItem
                            caption={<FormattedMessage id="app.camel.amount" />}
                            text={<Number value={transaction.amount} currency="LCT" />}
                            color="primary"
                          />
                          <Divider />
                          <br />
                          <InfoItem
                            caption={<FormattedMessage id="app.btcAmount" />}
                            text={<Number value={transaction.transactionValue} currency="BTC" />}
                          />
                          <InfoItem
                            caption={<FormattedMessage id="app.transaction.miningFee" />}
                            text={<Number value={transaction.feeInXbt} currency="BTC" />}
                          />
                          <InfoItem
                            caption={<FormattedMessage id="app.transaction.transactionDate" />}
                            text={<Moment date={transaction.date} />}
                          />
                          <InfoItem caption={<FormattedMessage id="app.address222222222" />} text={contract.address} />
                          <InfoItem
                            caption={<FormattedMessage id="app.transactionId" />}
                            text={transaction.transactionId}
                          />
                          <Button
                            fullWidth
                            variant="outlined"
                            href={"https://www.blockchain.com/pt/btc/tx/" + transaction.transactionId}
                            target="_blank"
                          >
                            <FormattedMessage id="app.transaction.blockchain" />
                          </Button>
                        </HelpDialog>
                      )}
                    </>
                  ) : (
                    <>
                      <Typography gutterBottom style={{ marginBottom: 16 }}>
                        <FormattedMessage id="app.aport.complete.text1" />
                      </Typography>
                      <Typography gutterBottom style={{ marginBottom: 16 }}>
                        <FormattedMessage id="app.aport.complete.text2" />
                      </Typography>
                    </>
                  )}
                </CardContent>
              </CardPageItem>
            )}
          </PageContainer>
        );
      }
    }
  }
}

const HelpDialog = ({ open, onClose, title, children }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>{<FormattedMessage id={title} />}</DialogTitle>
    <DialogContent>{children}</DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Ok
      </Button>
    </DialogActions>
  </Dialog>
);
