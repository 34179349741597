import React, { Component } from "react";
import cookie from "react-cookies";
import { connect } from "react-redux";
import * as actions from "../actions";
import { Typography } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";

class Signout extends Component {
  componentDidMount() {
    cookie.remove("LCAuthToken", { path: "/" });
    this.props.clearMe();
    this.props.history.push("/login");
  }

  render() {
    return (
      <div
        style={{ display: "flex", flexDirection: "column", marginTop: "50%" }}
      >
        <CircularProgress style={{ marginBottom: 16 }} />
        <Typography style={{ textAlign: "center" }}>Saindo...</Typography>
      </div>
    );
  }
}

export default connect(null, actions)(Signout);
