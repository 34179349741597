import React, { Component, Fragment } from "react";
import { Button } from "@material-ui/core";
import { reduxForm, Field } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import CustomInputForm from "../../custom/CustomInput";
import * as actions from "../../actions";
import { updateKyc } from "../../actions/Kyc";
import { FormattedMessage } from "react-intl";

class FormDocumentReject extends Component {
	onSubmit = (formProps) => {
		const { onActionComplete, showErrorMessage, showSuccessMessage, id } = this.props;
		updateKyc(id, { status: "REJECTED", ...formProps })
			.then((response) => {
				showSuccessMessage("app.document.rejected");
				onActionComplete(response);
			})
			.catch((e) => {
				if (e.response) {
					showErrorMessage(e.response.data.message);
				} else {
					showErrorMessage(e);
				}
			});
	};

	render() {
		const { handleSubmit } = this.props;

		return (
			<Fragment>
				<form onSubmit={handleSubmit(this.onSubmit)}>
					<Field
						name="reason"
						label="app.reason"
						InputLabelProps={{
							shrink: true,
						}}
						inputProps={{
							maxLength: 2000,
						}}
						multiline
						component={CustomInputForm}
						autoFocus
					/>
					<Button
						id="bSubmitOrderForm"
						style={{ marginTop: 10 }}
						variant="contained"
						color="primary"
						size="large"
						type="submit"
						disabled={this.props.pristine || this.props.submitting || this.props.invalid}
					>
						<FormattedMessage id="app.button.save" />
					</Button>
				</form>
			</Fragment>
		);
	}
}

const validate = (values, props) => {
	const reasonLength = String(values.reason);
	const errors = {};
	if (!values.reason) {
		errors.reason = "app.reason.error";
	}
	if (reasonLength.length > 2000) {
		errors.reason = "app.reason.length";
	}

	return errors;
};

export default compose(
	reduxForm({
		form: "formReason",
		validate,
	}),
	connect(null, actions)
)(FormDocumentReject);
