import { Button, CardContent, MenuItem } from "@material-ui/core";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import { createNumberMask } from "redux-form-input-masks";
import * as actions from "../../actions";
import { createContractType, editContractType } from "../../actions";
import CustomInputForm from "../../custom/CustomInput";

// VERIFICADO LCTInputMask - precisou chamar o getConfig.

class FormContractType extends Component {
  onSubmit = (formProps) => {
    const { showErrorMessage, onActionComplete } = this.props;
    if (formProps.id) {
      editContractType(formProps.id, formProps)
        .then((response) => {
          onActionComplete();
        })
        .catch((e) => {
          showErrorMessage(e.response ? e.response.data.message : e);
        });
    } else {
      createContractType(formProps)
        .then((response) => {
          onActionComplete();
        })
        .catch((e) => {
          showErrorMessage(e.response ? e.response.data.message : e);
        });
    }
  };

  componentDidMount() {
    this.props.getConfig();
    this.props.initialize(this.props.type);
  }

  render() {
    const { handleSubmit, config } = this.props;
    if (!config.LCTInputMask) return null;
    const percentualMask = createNumberMask({
      suffix: "%",
      decimalPlaces: 2,
      locale: "pt-BR",
      allowNegative: false,
    });
    const lctMask = config.LCTInputMask();
    return (
      <CardContent>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <Field
            name="description"
            label="app.description"
            InputLabelProps={{
              shrink: true,
            }}
            component={CustomInputForm}
          />
          <Field
            name="durationInMonth"
            label="app.durationInMonth"
            InputLabelProps={{
              shrink: true,
            }}
            component={CustomInputForm}
          />
          <Field
            name="maxMonthlyYield"
            label="app.maxYield"
            InputLabelProps={{
              shrink: true,
            }}
            component={CustomInputForm}
            {...percentualMask}
          />
          <Field
            name="minMonthlyYield"
            label="app.minYield"
            InputLabelProps={{
              shrink: true,
            }}
            component={CustomInputForm}
            {...percentualMask}
          />
          <Field
            name="agentCommission"
            label="app.agentCommission"
            InputLabelProps={{
              shrink: true,
            }}
            component={CustomInputForm}
            {...percentualMask}
          />
          <Field
            name="minimumAmount"
            label="app.aport.min"
            InputLabelProps={{
              shrink: true,
            }}
            component={CustomInputForm}
            {...lctMask}
          />
          <Field
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            select
            name="type"
            label="app.modality"
            component={CustomInputForm}
          >
            <MenuItem value={"OPEN"}>
              <FormattedMessage id={`app.enuns.OPEN`} />
            </MenuItem>
            <MenuItem value={"CLOSED"}>
              <FormattedMessage id={`app.enuns.CLOSED`} />
            </MenuItem>
          </Field>

          <Button
            fullWidth
            id="bSubmitTypeForm"
            variant="contained"
            color="primary"
            type="submit"
            disabled={this.props.pristine || this.props.submitting || this.props.invalid}
          >
            <FormattedMessage id="app.button.save" />
          </Button>
        </form>
      </CardContent>
    );
  }
}

const validate = (values, props) => {
  const errors = {};
  if (!values.description) {
    errors.description = "app.field.fillIn";
  } else if (values.description.trim().length == 0) {
    errors.description = "app.description.invalid";
  }
  if (!values.durationInMonth) {
    errors.durationInMonth = "app.month.empty";
  } else if (values.durationInMonth.length == 0) {
    errors.durationInMonth = "app.month.invalid";
  } else if (isNaN(Number(values.durationInMonth))) {
    errors.durationInMonth = "app.month.invalid";
  } else if (values.durationInMonth > 1200) {
    errors.durationInMonth = "app.durationInMonth.invalid";
  }
  if (values.maxMonthlyYield == 0) {
    errors.maxMonthlyYield = "app.greaterThanZero";
  }
  if (!values.type) {
    errors.type = "app.field.fillIn";
  }
  return errors;
};

function mapStateToProps(state) {
  return {
    config: state.config,
  };
}

export default compose(
  reduxForm({
    form: "formContractType",
    validate,
  }),
  connect(mapStateToProps, actions)
)(FormContractType);
