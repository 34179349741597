import React, { Component } from "react";
import { PageItem } from "./Page";
import { AppBar, Toolbar, Typography, IconButton } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";

class HeaderPageItem extends Component {
  render() {
    const { title, showBackButton, destination, onBack, onMore, history, children, style, values, preserveTitle } = this.props;
    let _onBack = onBack || ((e) => history.push(destination));
    return (
      <PageItem style={style}>
        <AppBar>
          <Toolbar disableGutters style={{ justifyContent: "space-between" }}>
            <div style={{ width: 48 }}>
              {(showBackButton || onBack) && (
                <IconButton color="inherit" onClick={_onBack}>
                  <ChevronLeftIcon />
                </IconButton>
              )}
            </div>
            {title && (
              <Typography variant="h6" color="inherit">
                {preserveTitle ? title : <FormattedMessage id={title} values={{ id: values }} />}
              </Typography>
            )}

            {children}

            <div style={{ width: 48 }}>
              {onMore && (
                <IconButton color="inherit" onClick={onMore}>
                  <MoreVertIcon />
                </IconButton>
              )}
            </div>
          </Toolbar>
        </AppBar>
        <Toolbar />
      </PageItem>
    );
  }
}
export default withRouter(HeaderPageItem);
