import { Button, CardContent, CircularProgress, Typography, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import cookie from "react-cookies";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import * as actions from "../actions";
import customInput from "../custom/CustomInput";
import FormDialog from "../custom/FormDialog";
import suporte from "../static/suporte.png";

class Signin extends Component {
  state = {
    doubt: false,
    terms: false,
    policy: false,
    telefoneAdmin: "",
    loading: false,
  };
  onSubmit = (formProps) => {
    this.setState({ loading: true });
    this.props
      .signin(formProps)
      .then((response) => {
        cookie.save("LCAuthToken", response.token, { path: "/" });
        this.props.history.go(0);
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { handleSubmit, classes } = this.props;
    return (
      <>
        <CardContent
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: 300,
            padding: 16,
          }}
        >
          <div>
            <div style={{ textAlign: "center", marginTop: 48, marginBottom: 32 }}>
              <img src={process.env.PUBLIC_URL + "/logo/Logo_LC_BANK.png"} alt="logo" width="200" height="200" />
            </div>

            <div
              style={{
                display: "none",
                textAlign: "center",
                width: 350,
                padding: 24,
                marginTop: 20,
                marginBottom: 20,
                border: "1px solid #666",
              }}
            >
              <Typography variant="h6" gutterBottom>
                MAINTAINANCE
              </Typography>
              <Typography variant="body1" gutterBottom>
                We're updating our servers.
                <br />
                Please come back in a few minutes.
              </Typography>
            </div>

            <form onSubmit={handleSubmit(this.onSubmit)} style={{ display: "block" }}>
              <Field name="username" label="app.email" component={customInput} type="email" />
              <Field name="password" label="app.password" type="password" component={customInput} inputProps={{ maxLength: 20 }} />
              <br />
              <div className={classes.wrapper}>
                {this.state.loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  <Button color="primary" variant="contained" type="submit" fullWidth={true}>
                    <FormattedMessage id="app.signin.button" defaultMessage="Entrar" />
                  </Button>
                )}
              </div>
            </form>

            <div
              style={{
                marginTop: 32,
              }}
            >
              <Button fullWidth size="small" variant="text" style={{ marginTop: 16 }} component={Button} href="https://lux.capital/terms" target="_blank">
                <FormattedMessage id="app.termsAndConditions" />
              </Button>
              <Button fullWidth size="small" variant="text" style={{ marginTop: 16 }} component={Button} href="https://lux.capital/privacy" target="_blank">
                <FormattedMessage id="app.privacyPolicy" />
              </Button>
              <Button
                fullWidth
                size="small"
                variant="text"
                style={{ marginTop: 16 }}
                component={Button}
                onClick={() =>
                  this.setState({
                    doubt: true,
                  })
                }
              >
                <FormattedMessage id="app.signin.support.title" />
              </Button>
              <FormDialog
                title="app.signin.support.title"
                open={this.state.doubt}
                variant="secondary"
                noHeader
                onClose={(e) =>
                  this.setState({
                    doubt: false,
                  })
                }
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography gutterBottom variant="h6" style={{ marginTop: 64, marginBottom: 32 }}>
                    <FormattedMessage id="app.contactUs" />
                  </Typography>
                  <img src={suporte} width="60" height="60" alt="suporte" />
                  <Typography gutterBottom style={{ marginTop: 32 }}>
                    <FormattedMessage id="app.signin.support.secondText" />
                  </Typography>
                  <Typography gutterBottom>
                    <FormattedMessage id="app.help.email" />
                  </Typography>
                </div>
              </FormDialog>
            </div>
            <Typography style={{ textAlign: "center", color: "#999", marginTop: 32 }} variant="body2">
              <FormattedMessage id="app.version" defaultMessage="Versão" /> 2.6.67
            </Typography>
          </div>
        </CardContent>
      </>
    );
    // ATENÇÃO
    // No caso de erro na build no servidor (minified, etc.), a solução é copiar os arquivos package e package-lock do branch master.
  }
}

const styles = (theme) => ({
  cardHeader: {
    textAlign: "center",
  },
  card: {
    marginTop: "100px",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
});

const validate = (values) => {
  const errors = {};
  if (!values.username) {
    errors.username = "app.form.email.error";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+$/i.test(values.username)) {
    errors.username = "app.form.email.invalid";
  }
  if (!values.password) {
    errors.password = "app.password.error";
  }
  return errors;
};

function mapStateToProps(state) {
  return {
    auth: state.auth.authenticated,
    currentUser: state.user.user.me,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, actions),
  reduxForm({
    form: "signin",
    validate,
  })
)(Signin);
