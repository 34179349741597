import { CardContent, CircularProgress, Typography } from "@material-ui/core";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import * as actions from "../actions";
import performRequest from "../configAxios";
import AbtcAvatar from "../custom/Avatar";
import HeaderPageItem from "../custom/HeaderPageItem";
import PageContainer, { CardPageItem, PageItem } from "../custom/Page";
import wpp from "../static/whatsapp.png";
import { cnpjNormalizer, cpfNormalizer } from "../util";
import { phoneFormatter } from "../util/index";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import UserDetail from "./components/UserDetail";

class BusinessUserData extends Component {
	state = {
		loading: true,
		client: {},
	};

	componentDidMount() {
		performRequest("GET", `/user/${this.props.match.params.id}`, null, true)
			.then((response) => {
				this.setState({ loading: false, client: response.data.data });
			})
			.catch((e) => {
				this.props.history.push("/notfound");
			});
	}
	componentDidUpdate(prevProps) {
		if (this.props.match.params.id !== prevProps.match.params.id) {
			performRequest("GET", `/user/${this.props.match.params.id}`, null, true)
				.then((response) => {
					this.setState({ loading: false, client: response.data.data });
				})
				.catch((e) => {
					this.props.history.push("/notfound");
				});
		}
	}

	render() {
		const { client, loading } = this.state;
		const { currentUser } = this.props;
		if (loading) return <CircularProgress style={{ marginTop: "25%" }} />;
		return (
			<PageContainer>
				<PageItem>
					<HeaderPageItem title="app.user.detail" showBackButton destination="/users"></HeaderPageItem>
				</PageItem>

				<CardPageItem raised>
					<UserDetail currentUser={currentUser} user={client.id}></UserDetail>
				</CardPageItem>
			</PageContainer>
		);
	}
}

function mapStateToProps(state) {
	return {
		currentUser: state.user.user.me,
	};
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		form: "dashboardForm",
	})
)(BusinessUserData);
