import React, { Component, Fragment } from "react";
import {
  Button,
  CardContent,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Link,
  Menu,
  MenuItem, 
  TextField,
  Typography,
} from "@material-ui/core";
import InfoItem from "../../custom/InfoItem";
import Number from "../../custom/Number";
import Moment from "moment";
import {  reduxForm } from "redux-form";
import { getUsers } from "../../actions";
import HeaderPageItem from "../../custom/HeaderPageItem";
import PageContainer, { PageItem, CardPageItem } from "../../custom/Page";
import TableList from "./../components/TableList";
import { Roles,  ListUserColumns, phoneFormatter } from "../../util";
import {  newColumn, newColumnText } from "./../components/TableList";
import { FormattedMessage } from "react-intl";
import { withTheme } from "@material-ui/styles";

const ROLES_FOR_FULL_REPORT = [Roles.Admin, Roles.FinanceManager, Roles.Auditor];
const ROLES_FOR_BASIC_REPORT = [...ROLES_FOR_FULL_REPORT, Roles.BusinessManager];

class UsersFilter {
  value = "";
  //caption = "app.customer";
  caption = "app.role";
  options = [
    { label: "app.all", value: "" },
    { label: "app.enuns.ADMIN", value: Roles.Admin },
    { label: "app.enuns.CUSTOMER", value: Roles.Customer },
    { label: "app.enuns.AGENT", value: Roles.Agent },
    { label: "app.enuns.KYC", value: Roles.Kyc },
    { label: "app.enuns.BUSINESS_MANAGER", value: Roles.BusinessManager },
    { label: "app.enuns.FINANCE_MANAGER", value: Roles.FinanceManager },
    { label: "app.enuns.AGENTS_MANAGER", value: Roles.AgentsManager },
    { label: "app.enuns.AUDITOR", value: Roles.Auditor },
  ];
  apply = (users) => users.filter((data) => this.value == "" || data.role == this.value);
}
class UsersActiveFilter {
  value = "";
  //caption = "app.documents";
  caption = "app.state.apt";
  options = [
    { label: "app.all", value: "" },
    { label: "app.customer.apt", value: "true" },
    { label: "app.customer.inapt", value: "false" },
  ];
  apply = (users) => users.filter((data) => this.value == "" || data.filterApto == this.value);
}
class UsersEmailFilter {
  value = "";
  //caption = "app.email";
  caption = "app.confirmedEmail.title";
  options = [
    { label: "app.all", value: "" },
    { label: "app.email.confirmed", value: "true" },
    { label: "app.email.confirmed.no", value: "false" },
  ];
  apply = (users) => users.filter((data) => this.value == "" || data.filterEmail == this.value);
}

class UsersAgentFilter {
  value = "";
  //caption = "app.agents";
  caption = "app.agent";
  options = [];
  apply = (users) => users.filter((data) => this.value == "" || data.filterAgent == this.value);
}

class FilterSet {
  filters = [];
  constructor(filters) {
    this.filters = filters;
  }
  apply = (users) => this.filters.reduce((users, filter) => filter.apply(users), users);
}

class UserReportContent extends Component {
  state = {
		UserList: this.props.dataList,
		title: this.props.title,
		desc: this.props.desc,
		dataList:[],
    listUsersOriginal: [],
    filteredData: [],
    columns: [],
    agents: [],
    totalUsers: 0,
    totalInaptos: 0,
    totalAptos: 0,
    totalCustomers: 0,
    totalAgents: 0,
    totalKYC: 0,
    totalAgentManager: 0,
    totalComercialManager: 0,
    totalFinancialManager: 0,
    totalAuditor: 0,
    ListColumns: ListUserColumns,
    listAllColumns: true,
    openFilter: false,
    loading: true,
  };

  filterSets = [
    new FilterSet([new UsersFilter(), new UsersActiveFilter(), new UsersEmailFilter(), new UsersAgentFilter()]),
  ];
  componentDidMount() {
    this.setState({ listAllColumns: true });
    this.listAgents();
  }

  listAgents = () => {
    let agents = [];
    getUsers({ role: Roles.Agent }).then((response) => {
      agents.push({ label: "app.all", value: "" });
      response.forEach((data) => {
        agents.push({ label: data.name, value: data.name });
      });
      this.filterSets[0].filters[3].options = agents;
    });
    this.loadUsers();
  };
  // newColumn = (title, field, type, align, render) => ({
  // 	title,
  // 	field,
  // 	type,
  // 	cellStyle: {
  // 		textAlign: align,
  // 		fontSize: 12,
  // 		position: "sticky",
  // 	},
  // 	render,
  // });
  // newColumnText = (title, field, render) => this.newColumn(title, field, "string", "left", render);

  columnsFiltered = () => {
    let columns = [];
    this.state.ListColumns.map((column) => {
      if (column.name == "id" && column.value == true) {
        columns = columns.concat([
          newColumn("Id", "id", "numeric", "left", (rowData) => (
            <Link href={"/user/" + rowData.id} target="_blank">
              {rowData.id}
            </Link>
          )),
        ]);
      } else if (column.name == "name" && column.value == true) {
        columns = columns.concat([newColumnText(<FormattedMessage id="app.name" />, ".name")]);
      } else if (column.name == "txtRole" && column.value == true) {
        columns = columns.concat([newColumnText(<FormattedMessage id="app.role" />, "txtRole")]);
      } else if (column.name == "telefone" && column.value == true) {
        columns = columns.concat([newColumnText(<FormattedMessage id="app.phone" />, "telefone")]);
      } else if (column.name == "telefone2" && column.value == true) {
        columns = columns.concat([newColumnText(<FormattedMessage id="app.phone" />, "telefone2")]);
      } else if (column.name == "email" && column.value == true) {
        columns = columns.concat([newColumnText(<FormattedMessage id="app.email" />, "email")]);
      } else if (column.name == "txtEmailConfirmed" && column.value == true) {
        columns = columns.concat([newColumnText(<FormattedMessage id="app.email.confirmed" />, "txtEmailConfirmed")]);
      } else if (column.name == "txtActive" && column.value == true) {
        columns = columns.concat([newColumnText(<FormattedMessage id="app.apt" />, "txtActive")]);
      } else if (column.name == "txtCreatedAt" && column.value == true) {
        columns = columns.concat([newColumnText(<FormattedMessage id="app.dateRegister" />, "txtCreatedAt")]);
      } else if (column.name == "txtActivationDate" && column.value == true) {
        columns = columns.concat([newColumnText(<FormattedMessage id="app.date.active" />, "txtActivationDate")]);
      } else if (column.name == "txtDateOfBirth" && column.value == true) {
        columns = columns.concat([newColumnText(<FormattedMessage id="app.dateOfBirth" />, "txtDateOfBirth")]);
      } else if (column.name == "app.agent" && column.value == true) {
        columns = columns.concat([newColumnText(<FormattedMessage id="app.agent" />, "consultant.name")]);
      }
    });
    return columns;
  };

  loadUsers = () => {
   
      const {
        theme: { palette },
      } = this.props;

      this.state.UserList.forEach((data) => {
        //Abaixo são todas variaveis extras que foi preciso criar
        // Foi preciso criar essas variaveis pois os filtros tem problemas com tipos boleanos
        data.filterApto = data.apt ? "true" : "false";
        data.filterEmail = data.emailConfirmed ? "true" : "false";
        data.filterAgent = data.consultant ? data.consultant.name : "";
        // variaveis formatadas
        data.txtRole = <FormattedMessage id={`app.enuns.${data.role}`} />;
        data.txtActive = data.apt ? <FormattedMessage id="app.yes" /> : <FormattedMessage id="app.no" />;
        data.txtEmailConfirmed = data.emailConfirmed ? (
          <FormattedMessage id="app.yes" />
        ) : (
          <FormattedMessage id="app.no" />
        );
        data.txtCreatedAt = data.createdAt ? Moment(data.createdAt).format("DD/MM/YYYY HH:mm:ss") : null;
        data.telefone = data.telefone ? phoneFormatter(data.telefone) : "-";
        data.telefone2 = data.telefone2 ? phoneFormatter(data.telefone2) : "-";
        data.txtActivationDate = data.activationDate ? Moment(data.activationDate).format("DD/MM/YYYY HH:mm:ss") : null;
        data.txtDateOfBirth = data.dateOfBirth ? Moment(data.dateOfBirth).format("DD/MM/YYYY") : null;
      });

      const options = {
        exportButton: true,
        search: false,
        filtering: false,
        paging: false,
        exportDelimiter: "\t",
        padding: "dense",
        headerStyle: {
          fontSize: 12,
          color: "#FFBE2D",
          textAlignLast: "start",
          whiteSpace: "nowrap",
        },
        rowStyle: (rowData, index) => {
          if (index % 2) {
            return { whiteSpace: "nowrap", backgroundColor: palette.secondary.light };
          } else {
            return { whiteSpace: "nowrap", backgroundColor: palette.secondary.dark };
          }
        },
      };
      let dataList = this.filterSets[0].apply(this.state.UserList);
      this.setState({
        dataList: dataList,
        totalUsers: dataList.length,
        totalInaptos: this.totalList(dataList, "apt", false),
        totalAptos: this.totalList(dataList, "apt", true),
        totalCustomers: this.totalList(dataList, "role", Roles.Customer),
        totalAgents: this.totalList(dataList, "role", Roles.Agent),
        totalKYC: this.totalList(dataList, "role", Roles.Kyc),
        totalAgentManager: this.totalList(dataList, "role", Roles.AgentsManager),
        totalComercialManager: this.totalList(dataList, "role", Roles.BusinessManager),
        totalFinancialManager: this.totalList(dataList, "role", Roles.FinanceManager),
        totalAuditor: this.totalList(dataList, "role", Roles.Auditor),
        loading: false,
        columns: this.columnsFiltered(),
        options: options,
      });
    
  };

  /*
    Função para facilitar a filtragem
    list = lista,
    if1 = item do objeto
    if2 = objeto para comparar
  */
  totalList = (list, if1, if2) => {
    let total = 0;
    list.forEach((data) => {
      if (data[if1] == if2) {
        total++;
      }
    });
    return total;
  };

  /*
  ########## FILTRO DE COLUNAS #############
  Funções a baixo são do filtro de colunas
  */
  onChangeAllColumns = (event) => {
    this.setState({
      ListColumns: this.state.ListColumns.map((column) => {
        column.value = event.target.checked;
        return column;
      }),
      listAllColumns: event.target.checked,
    });
  };
  onChangeColumn = (event) => {
    let all = true;
    this.setState({
      ListColumns: this.state.ListColumns.map((column) => {
        if (column.name == event.target.name) {
          column.value = event.target.checked;
        }
        if (!column.value) {
          all = false;
        }
        return column;
      }),
      listAllColumns: all,
    });
  };
  render() {
    const { loading } = this.state;
    if (loading) return <CircularProgress style={{ marginTop: "25%" }} />;
    return (
      <Fragment>
        <PageContainer full>
          <HeaderPageItem
            title={this.props.title}
            showBackButton
            destination="/reports"
            onMore={(e) => this.setState({ menu: e.target })}
          >
            <Menu
              keepMounted
              anchorEl={this.state.menu}
              open={Boolean(this.state.menu)}
              onClose={() => {
                this.setState({ menu: null });
              }}
              component="li"
            >
              <MenuItem button onClick={() => this.setState({ openFilter: true, menu: null })}>
                <FormattedMessage id="app.report.filter" />
              </MenuItem>
              <MenuItem button onClick={() => this.setState({ openDialogColumns: true, menu: null })}>
                <FormattedMessage id="app.column.filter" />
              </MenuItem>
            </Menu>
          </HeaderPageItem>

          {/* Descrições informativas */}
          <CardPageItem raised double>
            <CardContent>
              <Typography gutterBottom color="primary" variant="h6">
                <FormattedMessage id="app.user.total" />
              </Typography>
              {/* L1 */}
              <Divider />
              <InfoItem
                caption={<FormattedMessage id="app.register" />}
                text={<Number value={this.state.totalUsers} />}
                extraCaption={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FormattedMessage id="app.customers" />
                  </div>
                }
                extraText={<Number value={this.state.totalCustomers} />}
              />
              {/* L2 */}
              <Divider />
              <InfoItem
                caption={<FormattedMessage id="app.users.apt" />}
                text={<Number value={this.state.totalAptos} />}
                extraCaption={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FormattedMessage id="app.users.unfit" />
                  </div>
                }
                extraText={<Number value={this.state.totalInaptos} />}
              />
            </CardContent>
          </CardPageItem>

          <CardPageItem raised double>
            <CardContent>
              <Typography gutterBottom color="primary" variant="h6">
                <FormattedMessage id="app.totalRole" />
              </Typography>
              {/* R1 */}
              <Divider />
              <InfoItem
                caption={<FormattedMessage id="app.comercialManager.total" />}
                text={<Number value={this.state.totalComercialManager} />}
                extraCaption={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FormattedMessage id="app.financialManager.total" />
                  </div>
                }
                extraText={<Number value={this.state.totalFinancialManager} />}
              />
              {/* R2 */}
              <Divider />
              <InfoItem
                caption={<FormattedMessage id="app.kyc.total" />}
                text={<Number value={this.state.totalKYC} />}
                extraCaption={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FormattedMessage id="app.auditor.total" />
                  </div>
                }
                extraText={<Number value={this.state.totalAuditor} />}
              />
              {/* R3 */}
              <Divider />
              <InfoItem
                caption={<FormattedMessage id="app.offices" />}
                text={<Number value={this.state.totalAgentManager} />}
                extraCaption={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FormattedMessage id="app.agents" />
                  </div>
                }
                extraText={<Number value={this.state.totalAgents} />}
              />
            </CardContent>
          </CardPageItem>
          {/* relatório de usuários */}
          <CardPageItem>
            <PageItem>
              <CardContent>
                <TableList
                  title={this.props.title}
                  columns={this.state.columns}
                  data={this.state.dataList}
                  options={this.state.options}
                  user={this.props.currentUser}
                />
              </CardContent>
            </PageItem>
          </CardPageItem>
        </PageContainer>
        <FilterDialog
          title={<FormattedMessage id="app.filter" />}
          open={this.state.openFilter}
          onClose={() => this.setState({ openFilter: false })}
          onAction={(filterSet) => {
            this.filterSets[0] = filterSet;
            this.setState({ openFilter: false });
            this.loadUsers();
          }}
          onChange={() => this.forceUpdate()}
          filterSet={this.filterSets[0]}
        />
        <Dialog open={this.state.openDialogColumns} fullWidth>
          <DialogContent>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                <FormattedMessage id="app.column.filter" />
              </FormLabel>
              <FormGroup></FormGroup>
              <FormControlLabel
                control={
                  <Checkbox checked={this.state.ListAllColumns} onChange={this.onChangeAllColumns} color="primary" />
                }
                label={<FormattedMessage id="app.all" />}
              />
              <Divider />
              <FormGroup>
                {this.state.ListColumns.map((column) => (
                  <FormControlLabel
                    key={column.name}
                    control={
                      <Checkbox
                        checked={column.value}
                        onChange={this.onChangeColumn}
                        name={column.name}
                        color="primary"
                      />
                    }
                    label={<FormattedMessage id={column.label} />}
                  />
                ))}
              </FormGroup>
            </FormControl>
            <Grid container direction="row" justify="flex-end" alignItems="center">
              <Button
                onClick={() => {
                  this.componentDidMount();
                  this.setState({ openDialogColumns: false });
                }}
                color="primary"
              >
                <FormattedMessage id="app.confirm" />
              </Button>
              <Button onClick={() => this.setState({ openDialogColumns: false })} color="primary">
                <FormattedMessage id="app.cancel" />
              </Button>
            </Grid>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

const FilterDialog = ({ open, onClose, title, children, onChange, onAction, filterSet }) => (
  // Componente que mostra um diálogo de edição de conjunto de filtros (FilterSet).
  // Basicamente, mostra controles do tipo select para cada filtro do conjunto.
  // Recebe o conjunto via props e devolve o conjunto modificado em onAction.
  <Dialog fullWidth={true} maxWidth="sm" open={open} onClose={onClose}>
    <DialogTitle>{title}</DialogTitle>

    <DialogContent>
      {filterSet.filters.map((filter) => (
        <Fragment key={filter.caption}>
          <TextField
            select
            label={<FormattedMessage id={filter.caption} />}
            value={filter.value}
            onChange={(e) => {
              filter.value = e.target.value;
              onChange(filter);
            }}
            style={{ width: "100%", paddingTop: "5%" }}
          >
            {filter.options.map((o) => (
              <MenuItem key={o.label} value={o.value}>
                <FormattedMessage id={o.label} />
              </MenuItem>
            ))}
          </TextField>
          <Divider />
        </Fragment>
      ))}
    </DialogContent>

    <DialogActions>
      <Button type="submit" onClick={(e) => onAction(filterSet)} color="primary">
        <FormattedMessage id="app.filter" />
      </Button>
      <Button onClick={onClose} color="default">
        <FormattedMessage id="app.close" />
      </Button>
    </DialogActions>
  </Dialog>
);

export default reduxForm({ form: "formUserReport" })(withTheme(UserReportContent));
