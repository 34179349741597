import { Button, CardActions, CardContent, IconButton, Typography } from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import * as actions from "../actions";
import { getTemplates } from "../actions/EmailTemplate";
import FormDialog from "../custom/FormDialog";
import HeaderPageItem from "../custom/HeaderPageItem";
import PageContainer, { CardPageItem } from "../custom/Page";
import FormEmailTemplate from "./components/FormEmailTemplate";
import { FormattedMessage } from "react-intl";

class ConfigEmailTemplate extends Component {
	state = {
		templates: [],
		modal: false,
		title: "",
	};
	componentDidMount() {
		this.loadTemplates();
	}

	loadTemplates = () => {
		getTemplates().then((data) => {
			data = data.sort((t1, t2) => (t1.type > t2.type ? 1 : -1));
			this.setState({ templates: data });
		});
	};

	render() {
		return (
			<PageContainer>
				<HeaderPageItem title="app.emailTemplates" showBackButton destination="/more" />
				<CardPageItem>
					<CardContent>
						<Typography variant="caption" color="textPrimary">
							{this.state.templates && (
								<Typography variant="caption" color="textPrimary">
									<table style={{ width: "100%" }}>
										<tbody>
											<tr>
												<td>
													<FormattedMessage id="app.type" />
												</td>
												<td>
													<FormattedMessage id="app.subject" />
												</td>
												<td>
													<FormattedMessage id="app.language" />
												</td>
												<td align="center">
													<FormattedMessage id="app.edit" />
												</td>
											</tr>
											{this.state.templates.map((t) => (
												<Fragment key={t.id}>
													<tr style={{ color: "textSecondary" }}>
														<td style={{ color: "primary" }}>
															<Typography variant="caption" color="textPrimary">
																<FormattedMessage id={`app.enuns.${t.type}`} />
															</Typography>
														</td>
														<td style={{ color: "primary" }}>
															<Typography variant="caption" color="textPrimary">
																{t.subject}
															</Typography>
														</td>
														<td style={{ color: "primary" }}>
															<Typography variant="caption" color="textPrimary">
																<FormattedMessage id={`app.enuns.${t.locale}`} />
															</Typography>
														</td>

														<td align="center">
															<IconButton
																onClick={(e) => {
																	this.setState({
																		modal: true,
																		selectedTemplate: t,
																		title: "app.editTemplate",
																	});
																}}
															>
																<Edit />
															</IconButton>
														</td>
													</tr>
												</Fragment>
											))}
										</tbody>
									</table>
								</Typography>
							)}
						</Typography>
					</CardContent>
					<CardActions>
						<Button
							fullWidth
							variant="contained"
							color="primary"
							onClick={(e) => {
								this.setState({ modal: true, title: "app.createNewTemplate" });
							}}
						>
							<FormattedMessage id="app.createNewTemplate" />
						</Button>
					</CardActions>
				</CardPageItem>
				<FormDialog
					title={this.state.title}
					open={this.state.modal}
					variant="secondary"
					noHeader
					onClose={(e) => {
						this.setState({ modal: false, selectedTemplate: null });
					}}
				>
					<FormEmailTemplate
						template={this.state.selectedTemplate}
						onActionComplete={() => {
							this.setState({ modal: false, selectedTemplate: null });
							this.loadTemplates();
						}}
					/>
				</FormDialog>
			</PageContainer>
		);
	}
}

export default compose(connect(null, actions))(ConfigEmailTemplate);
