import { Button, CardContent, MenuItem } from "@material-ui/core";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import * as actions from "../actions";
import { getActiveContractType, newContract } from "../actions/Contract";
import { getCustomer } from "../actions/User";
import CustomInputForm from "../custom/CustomInput";
import HeaderPageItem from "../custom/HeaderPageItem";
import PageContainer, { PageItem } from "../custom/Page";

// VERIFICADO LCTInputMask - precisou chamar o getConfig.

class AgentFormContract extends Component {
  state = {
    customer: {},
    contract: {},
    types: [],
    amountLeft: 0,
    save: false,
  };

  componentDidMount() {
    if (this.props.currentUser.contractAgent != null) {
      this.props.getConfig();
      if (this.props.currentUser.contractAgent.status == "ACTIVE") {
        if (this.props.currentUser.id == this.props.match.params.id) {
          this.props.change("userid", this.props.currentUser.name);
        } else {
          getCustomer(this.props.match.params.id)
            .then((data) => {
              this.setState({ customer: data });
              this.props.change("userid", this.state.customer.name);
            })
            .catch((e) => this.props.history.push("/404"));
        }
        getActiveContractType().then((data) => {
          this.setState({ types: data });
        });
      }
    } else {
      this.props.history.push("/404");
    }
  }

  onSubmit = (formProps) => {
    const contractParams = {
      investmentId: 1,
      userId:
        this.props.currentUser.id == this.props.match.params.id
          ? this.props.currentUser.id
          : this.props.match.params.id,
      typeId: formProps.typeId,
      previsionAmount: formProps.amount,
    };
    const { history, showErrorMessage } = this.props;
    let newContractId;
    newContract(contractParams)
      .then((data) => {
        newContractId = data.id;
        if (this.props.currentUser.id == this.props.match.params.id) {
          history.push("/contract/" + newContractId);
        } else {
          history.push("/userContract/" + newContractId);
        }
      })
      .catch((error) => {
        showErrorMessage(error.message);
      });
  };

  validateAmount = (value, allValues) => {
    const selectedType = this.state.types.filter((c) => c.id == allValues.typeId)[0];
    if (selectedType == undefined || allValues.typeId == undefined) return undefined;
    return value == undefined ? (
      true
    ) : value >= selectedType.minimumAmount ? undefined : (
      <FormattedMessage
        id="app.value.minimum"
        values={{ LCt: selectedType.minimumAmount, coin: this.props.config.LCTCode }}
      />
    );
  };

  render() {
    const { types, amountLeft } = this.state;
    const { handleSubmit, currentUser, config } = this.props;
    if (!config.LCTInputMask) return null;
    const lctMask = config.LCTInputMask((amountLeft) => {
      this.setState({ amountLeft });
    });
    return (
      <PageContainer>
        <PageItem>
          {this.props.currentUser.id == this.props.match.params.id ? (
            <HeaderPageItem title="app.newContract" showBackButton destination={"/contracts"} />
          ) : (
            <HeaderPageItem
              title="app.newContract"
              showBackButton
              destination={"/customer/" + this.props.match.params.id}
            />
          )}
        </PageItem>
        <PageItem>
          <CardContent>
            <form onSubmit={handleSubmit(this.onSubmit)}>
              <Field
                name="userid"
                label="app.customerName"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
                component={CustomInputForm}
              />
              <Field
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                select
                name="typeId"
                label="app.contractType"
                component={CustomInputForm}
                onChange={(v) => {
                  this.setState({
                    selectedType: this.state.types.filter((c) => c.id == v.target.value),
                  });
                }}
              >
                {types.map((contract) => (
                  <MenuItem key={contract.id} value={contract.id}>
                    {contract.description}
                  </MenuItem>
                ))}
              </Field>
              <Field
                name="amount"
                label="app.estimatedAmount"
                InputLabelProps={{
                  shrink: true,
                }}
                component={CustomInputForm}
                {...lctMask}
                validate={this.validateAmount}
              />

              <Button
                fullWidth
                id="bSubmitOrderForm"
                style={{ marginTop: 16 }}
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={this.props.pristine || this.props.submitting || this.props.invalid}
              >
                <FormattedMessage id="app.createContract" />
              </Button>
              {this.state.save && (
                <Button
                  style={{ marginTop: 16 }}
                  variant="outlined"
                  fullWidth
                  href={this.state.contract.fileUrl}
                  target="_blank"
                >
                  <FormattedMessage id="app.createContract" />
                </Button>
              )}
            </form>
          </CardContent>
        </PageItem>
      </PageContainer>
    );
  }
}
const validate = (values, props) => {
  const errors = {};
  if (!values.userid) {
    errors.userid = "app.value.report";
  }
  if (!values.typeId) {
    errors.typeId = "app.form.contractType.error";
  }

  return errors;
};

function mapStateToProps(state) {
  return {
    config: state.config,
    currentUser: state.user.user.me,
  };
}

export default compose(
  reduxForm({
    form: "customerForm",
    validate,
  }),
  connect(mapStateToProps, actions)
)(AgentFormContract);
