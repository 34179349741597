import React, { Component } from "react";
import { CardContent, Typography } from "@material-ui/core";
import { compose } from "redux";
import { connect } from "react-redux";
import * as actions from "../actions";
import { reduxForm } from "redux-form";
import PageContainer, { CardPageItem } from "../custom/Page";
import HeaderPageItem from "../custom/HeaderPageItem";
import { phoneFormatter } from "../util/index";
import AbtcAvatar from "../custom/Avatar";
import wpp from "../static/whatsapp.png";
import suporte from "../static/suporte.png";
import { FormattedMessage } from "react-intl";

class CustomerHelp extends Component {
  forms = {
    none: 0,
    photo: 1,
  };
  state = {
    dialogOpen: this.forms.none,
    consultant: null,
    photoRotation: 0,
    photoZoom: 1,
    admin: null,
    telefoneAdmin: "",
  };

  render() {
    const { currentUser, consultant } = this.props;
    return (
      <PageContainer>
        <HeaderPageItem title="app.help" />

        <CardPageItem>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {currentUser.role == "AGENT" && !currentUser.consultant ? (
              <>
                <Typography gutterBottom variant="h6" style={{ marginBottom: 32 }}>
                  <FormattedMessage id="app.contactUs" />
                </Typography>
                <img src={suporte} width="160" height="160" alt=" " />
              </>
            ) : (
              <Typography gutterBottom variant="h6">
                <FormattedMessage id="app.signin.support.title" />
              </Typography>
            )}
          </div>
        </CardPageItem>

        {consultant ? (
          <>
            <CardPageItem raised>
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography gutterBottom variant="body1">
                    <FormattedMessage id="app.contactAgent" />
                  </Typography>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginTop: 16,
                    }}
                  >
                    <AbtcAvatar imageUrl={consultant.photoUrl} disableLink size={160} />
                    <Typography gutterBottom variant="body1">
                      {consultant.name}
                    </Typography>
                  </div>
                  <Typography gutterBottom>{consultant.email}</Typography>
                  <Typography gutterBottom>
                    {phoneFormatter(consultant.telefone)}{" "}
                    {consultant.isWhats && (
                      <a href={"https://wa.me/55" + consultant.telefone} target="_blank" rel="noopener noreferrer">
                        <img src={wpp} width="14" height="14" alt="wpp" />
                      </a>
                    )}
                  </Typography>
                  {consultant.telefone2 && (
                    <Typography gutterBottom>
                      {phoneFormatter(consultant.telefone2)}{" "}
                      {consultant.isWhats2 && (
                        <a href={"https://wa.me/55" + consultant.telefone2} target="_blank" rel="noopener noreferrer">
                          <img src={wpp} width="14" height="14" alt="wpp" />
                        </a>
                      )}
                    </Typography>
                  )}
                </div>
              </CardContent>
            </CardPageItem>
            <CardPageItem raised>
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography gutterBottom variant="body1">
                    <FormattedMessage id="app.contactUs" />
                  </Typography>
                  <Typography gutterBottom style={{ marginTop: 16 }}>
                    <FormattedMessage id="app.signin.support.secondText" />
                  </Typography>
                  <Typography gutterBottom>
                    <FormattedMessage id="app.help.email" />
                  </Typography>
                </div>
              </CardContent>
            </CardPageItem>
          </>
        ) : (
          <>
            <CardPageItem>
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography gutterBottom style={{ marginTop: 16 }}>
                    <FormattedMessage id="app.signin.support.secondText" />
                  </Typography>
                  <Typography gutterBottom>
                    <FormattedMessage id="app.help.email" />
                  </Typography>
                </div>
              </CardContent>
            </CardPageItem>
          </>
        )}
      </PageContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
    consultant: state.user.user.me.consultant,
  };
}

export default compose(
  connect(mapStateToProps, actions),
  reduxForm({
    form: "contractForm",
    enableReinitialize: true,
  })
)(CustomerHelp);
