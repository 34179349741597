import axios from "axios";
import { API_URL } from "./config";
import cookie from "react-cookies";

const UNAUTHORIZED = 401;
const FORBIDDEN = 403;
axios.interceptors.response.use(
  response => response,
  error => {
    if (!error.response) {
      return Promise.reject("app.server.connect.error");
    }
    const {
      status,
      data: { path }
    } = error.response;
    if (status == UNAUTHORIZED && path != "/auth") {
      window.location.href = `/logout`;
      return;
    }
    if (status == FORBIDDEN) {
      window.location.href = "/accessDenied";
      return;
    }
    return Promise.reject(error);
  }
);

export default (method, url, params, auth, baseUrl) => {
  const body = method == "get" ? "params" : "data";

  const config = {
    method,
    url,
    baseURL: baseUrl || API_URL,
    [body]: params || {}
  };

  config.headers = {
    "Accept-Language": cookie.load("lclocale") ? cookie.load("lclocale") : "en"
  };

  if (auth) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${cookie.load("LCAuthToken")}`
    };
  }

  return axios.request(config);
};
