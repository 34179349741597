import {
  AppBar,
  Button,
  CardActionArea,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/Settings";
import moment from "moment";
import React, { Component } from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import Moment from "react-moment";
import * as rssParser from "react-native-rss-parser";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "../actions";
import { getContractTotal, getCustomerContracts, getKyc, getTerminatedContracts } from "../actions";
import AbtcAvatar from "../custom/Avatar";
import { badgesKyc } from "../custom/BadgesKyc";
import HeaderPageItem from "../custom/HeaderPageItem";
import Number from "../custom/Number";
import PageContainer, { CardPageItem, PageItem } from "../custom/Page";
import { DialogManager, getDataFromServer } from "../util";
import ContractWidget from "./components/ContractWidget";
import { HelpDialog } from "./components/HelpDialog";
import SvgPremiumAvatar from "../static/premium_avatar.svg";

class CustomerDashboard extends Component {
  state = {
    progress: 0,
    loading: true,
    contractsTerminated: [],
    openPopupContract: false,
    contractAttention: null,
    total: {
      amount: 0,
      balance: 0,
      totalBalance: 0,
      monthObjective: 1,
    },
    badges: {
      cor: "",
      qtd: 0,
    },
    currentYieldVal: 0,
    estimatedYieldVal: 0,
    doc: 0,
    pj: this.props.currentUser.isPj,
    rss: undefined,
  };

  progressInterval = null;
  progressStartInterval = null;

  dm = new DialogManager(() => this.forceUpdate());

  loadKyc = () => {
    getKyc().then((data) => {
      this.setState({ userKyc: data });
    });
  };

  componentDidMount() {
    const { currentUser } = this.props;
    const currentMonth = moment();
    this.showPopupContract();

    this.loadKyc();
    getTerminatedContracts().then((data) => {
      const contractsTerminated = data.filter((c) => c.user.id == currentUser.id);
      this.setState({ contractsTerminated, loading: false });
    });

    getContractTotal(currentMonth.month() + 1, currentMonth.year()).then((data) => {
      this.setState({ contractTotal: data });
    });

    getDataFromServer("GET", "/ext/blogFeed", null, true)
      .then((xml) => rssParser.parse(xml))
      .then((rss) => {
        const languageCheck = currentUser.locale === "es" ? "/es/" : currentUser.locale === "br" ? "/pt/" : "capital/post";
        rss = rss.items.filter((item) => item.links[0].url.includes(languageCheck)).slice(0, 5);
        this.setState({ rss });
      });
  }

  showPopupContract = () => {
    getCustomerContracts(this.props.currentUser.id, false).then((data) => {
      const c = data.filter((c) => c.phase == "WAITING_SIGN" || c.phase == "VALIDATING");
      if (c.length > 0) {
        this.setState({ openPopupContract: true, contractAttention: c[0].id });
      } else {
        this.setState({ openPopupContract: false });
      }
    });
  };

  componentWillUnmount() {
    clearTimeout(this.progressStartInterval);
    clearInterval(this.progressInterval);
  }

  pendent = (badges, currentUser) => {
    if (currentUser.telefone == null && currentUser.telefone2 == null) {
      badges.qtd = badges.qtd + 1;
      badges.cor = "error";
    }
    if (!currentUser.emailConfirmed) {
      badges.qtd = badges.qtd + 1;
      badges.cor = "error";
    }
    return badges;
  };

  render() {
    const { currentUser } = this.props;
    const { contractTotal, userKyc, pj, loading, contractAttention } = this.state;
    let badges = 0;
    badges = badgesKyc(pj, userKyc);
    badges = this.pendent(badges, currentUser);
    const blogLang = currentUser.locale === "es" ? "es/" : currentUser.locale === "br" ? "pt/" : "";
    if (loading) return <CircularProgress style={{ marginTop: "25%" }} />;
    return (
      <PageContainer>
        <HeaderPageItem style={{ height: 210 }}>
          <AppBar
            position="relative"
            style={{
              height: 110,
              paddingTop: 30,
              display: "flex",
              alignItems: "center",
            }}
            color={currentUser.premium ? "secondary" : "primary"}
          >
            <IconButton style={{ position: "absolute", left: 0, top: 0 }} component={Link} to="/logout" color={currentUser.premium ? "primary" : "secondary"}>
              <ExitToAppIcon style={{ transform: "rotate(180deg)" }} />
            </IconButton>
            <IconButton style={{ position: "absolute", right: 0, top: 0 }} component={Link} to="/more" color={currentUser.premium ? "primary" : "secondary"}>
              <Badge badgeContent={badges.qtd} color={badges.cor}>
                <SettingsIcon />
              </Badge>
            </IconButton>
            <AbtcAvatar
              imageUrl={currentUser.photoUrl}
              disableLink
              size={160}
              avatarStyle={currentUser.premium ? {} : { border: "8px rgba(254, 193, 44, 1) solid" }}
              style={{}}
            />
            {currentUser.premium && <img src={SvgPremiumAvatar} style={{ position: "relative", width: 176, height: 176, top: -169 }} />}
          </AppBar>
        </HeaderPageItem>

        <Dialog open={this.state.openPopupContract}>
          <DialogTitle>
            <Typography variant="h6" color="primary">
              <FormattedHTMLMessage id="app.warning" />
            </Typography>
          </DialogTitle>

          <DialogContent>
            <Typography variant="subtitle2">
              <FormattedHTMLMessage id="app.contract.waitingAssign.id" values={{ id: contractAttention }} />
            </Typography>

            <Button fullWidth variant="contained" color="primary" style={{ marginTop: 16 }} component={Link} to={"/contract/" + contractAttention}>
              <FormattedHTMLMessage id="app.contract.view" />
            </Button>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ openPopupContract: false })} color="primary">
              <FormattedMessage id="app.contract.later" />
            </Button>
          </DialogActions>
        </Dialog>

        <PageItem>
          <Typography variant="h6" style={{ textAlign: "center" }} color={currentUser.premium ? "primary" : undefined}>
            {currentUser.name}
          </Typography>
        </PageItem>

        {currentUser.status == "PENDING" ? (
          <CardPageItem
            style={{
              marginTop: 32,
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <CardContent>
              <Typography variant="h6" color="primary">
                <FormattedHTMLMessage id="app.dashboard.user.pending" />
              </Typography>
            </CardContent>
          </CardPageItem>
        ) : currentUser.status == "INACTIVE" ? (
          <CardPageItem
            style={{
              marginTop: 32,
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <CardContent>
              <Typography variant="h6" color="primary">
                <FormattedHTMLMessage id="app.dashboard.user.inactive" />
              </Typography>
            </CardContent>
          </CardPageItem>
        ) : (
          <ContractWidget contractTotal={contractTotal} currentUser={currentUser} timezone={this.props.currentUser.timezone} />
        )}

        <HelpDialog open={this.dm.isOpen("HELP_TOTAL")} onClose={(e) => this.dm.close()} title="app.dashboard.total.title">
          <FormattedMessage id="app.dashboard.total" />
        </HelpDialog>

        {this.state.contractsTerminated && this.state.contractsTerminated.length > 0 && (
          <>
            <CardContent style={{ paddingBottom: 0 }}>
              <Typography variant="body1">
                <FormattedMessage id="app.dashboard.expiredContracts" />
              </Typography>
            </CardContent>
            {this.state.contractsTerminated.map((c) => (
              <CardPageItem key={c.id} raised>
                <CardActionArea component={Link} to={"/contract/" + c.id}>
                  <CardContent
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ flexGrow: 1 }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="body1" color="primary">
                          {"LCContract C" + c.id}
                        </Typography>

                        <Typography variant="body1" color="primary">
                          <Number value={c.amount} currency="LCT" />
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <Typography variant="caption" color="textSecondary">
                            {c.status !== "TERMINATED" ? <FormattedMessage id="app.activationDate" /> : <FormattedMessage id="app.contract.finishedIn" />}:
                          </Typography>
                          <Typography>
                            <Moment format="DD/MM/YYYY">{c.status !== "TERMINATED" ? c.createdAt : c.updatedat}</Moment>
                          </Typography>
                        </div>

                        {c.status !== "TERMINATED" ? (
                          <>
                            <div>
                              <Typography variant="caption" color="textSecondary">
                                <FormattedMessage id="app.contract.increase" />:
                              </Typography>
                              <Typography>
                                <Number value={c.yield.val} currency="LCT" />
                              </Typography>
                            </div>

                            <div>
                              <Typography variant="caption" color="textSecondary">
                                %
                              </Typography>
                              <Typography>
                                <Number value={c.yield.per} display="percent" />
                              </Typography>
                            </div>
                          </>
                        ) : (
                          <div>
                            <Typography variant="caption" color="textSecondary">
                              <FormattedMessage id="app.contract.finished.text" />
                            </Typography>
                            <Typography>
                              <FormattedMessage id="app.contract.redemptionRequest" />
                            </Typography>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: 16,
                      }}
                    >
                      <ChevronRight color="action" />
                    </div>
                  </CardContent>
                </CardActionArea>
              </CardPageItem>
            ))}
          </>
        )}
        {this.state.rss && (
          <CardPageItem raised>
            <CardContent>
              <Typography gutterBottom variant="body1" style={{ textAlign: "center" }}>
                <FormattedMessage id="app.dashboard.blog.title" />
              </Typography>
              <List dense>
                {this.state.rss.map((item) => (
                  <ListItem button component="a" target="_blank" href={item.links[0].url} key={item.id}>
                    <ListItemText primary={item.title} secondary={<Moment fromNow date={item.published} />} />
                    <ListItemSecondaryAction>
                      <ChevronRight />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </CardPageItem>
        )}
        <PageItem style={{ textAlign: "center", marginTop: 16, marginBottom: 16 }}>
          <Typography variant="body2" color="textSecondary">
            <FormattedMessage id="app.dashboard.text1" />
          </Typography>
          <Button variant="outlined" color="primary" target="_blank" href={`https://www.lux.capital/${blogLang}blog`}>
            <FormattedMessage id="app.access.blog" />
          </Button>
        </PageItem>
      </PageContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default connect(mapStateToProps, actions)(CustomerDashboard);
