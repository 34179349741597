import { createMuiTheme } from "@material-ui/core";
import paletteLight from "./paletteLight";
import paletteDark from "./paletteContrast";
import paletteDark2 from "./paletteDark";

export const customPalettes = {
  none: {},
  light: paletteLight.palette,
  contrast: paletteDark.palette,
  dark: paletteDark2.palette,
};

export function createCustomTheme(palette) {
  palette = palette || {};
  let tmpTheme = createMuiTheme({
    palette: palette,
    breakpoints: {
      values: {
        xs: 320,
        sm: 375,
        md: 768,
        lg: 1024,
        xl: 1366,
      },
    },

    props: {
      sideBarWidth: 300,
      maxPageWidth: 700,
      bottomBarHeight: 56,
    },
    mixins: {
      toolbar: {
        minHeight: 50,
      },
    },
    typography: {
      primary: {
        color: palette.text.primary,
      },
    },
    overrides: {
      MuiTabs: {
        root: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      },
      MuiTab: {
        root: {
          minWidth: "unset!important",
          textTransform: "capitalize",
          fontSize: 14,
        },
      },
      MUIDataTable: {
        responsiveScroll: {
          //maxHeight: "1080px"
        },
      },
      MuiPaper: {
        elevation0: {
          // Estes são cards que não aparecem, servem apenas para organziar os elementos na página.
          backgroundColor: palette.background.default,
        },
        // elevation1 usa a cor "paper". São os cards que aparecem (raised).
        elevation8: {
          // Elevação 8 é usada em elementos que são fixos, não rolam. Basicamente, são os título das janelas.
          backgroundColor: palette.secondary.main,
        },
      },
      MuiBottomNavigation: {
        root: {
          width: "100%",
          position: "fixed",
          bottom: 0,
          zIndex: 10,
          // Barra de navegação inferior.
          backgroundColor: palette.secondary.main,
          borderRadius: "10px 10px 0px 0px",
        },
      },
      MuiList: {
        padding: {
          paddingTop: 0,
        },
      },
      MuiListItemText: {
        root: {
          color: palette.text.primary,
        },
      },
      MuiCard: {
        root: {
          borderRadius: 10,
        },
      },
      MuiButton: {
        root: {
          borderRadius: 10,
        },
      },
      MuiAppBar: {
        root: {
          borderRadius: "0px 0px 10px 10px",
        },
      },
    },
  });
  // A atribuição abaixo serve para retirar os dois atributos "@media" do tema.
  // Atribuímos as propriedades que desejamos remover para um nome qualquer ("remover..."),
  // atribuímos o resto das propriedades para "abtcTheme" e usamos (retornamos) apenas abtcTheme.
  let {
    [`@media (min-width:0px) and (orientation: landscape)`]: remover1,
    [`@media (min-width:600px)`]: remover2,
    ...customTheme
  } = tmpTheme;
  return customTheme;
}
