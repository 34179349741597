export const badgesKyc = (typePj, userKyc) => {
  let red = 0;
  let pend = 0;
  let badges = {
    cor: "default",
    qtd: 0
  };

  if (userKyc) {
    let docs = userKyc.filter(k => k.documentType == "ID");
    badges.docId = { status: "" };
    if (docs.length > 0) badges.docId = docs[0];

    let docsAddress = userKyc.filter(k => k.documentType == "ADDRESS");
    badges.docAd = { status: "" };
    if (docsAddress.length > 0) badges.docAd = docsAddress[0];

    let docsSocial = userKyc.filter(k => k.documentType == "SOCIAL_CONTRACT");
    badges.docSc = { status: "" };
    if (docsSocial.length > 0) badges.docSc = docsSocial[0];

    let docsSelfie = userKyc.filter(k => k.documentType == "ID_WITH_PHOTO");
    badges.docSelfie = { status: "" };
    if (docsSelfie.length > 0) badges.docSelfie = docsSelfie[0];

    let docsAnexo = userKyc.filter(k => k.documentType == "ATTACHMENT");
    badges.docAnx = { status: "" };
    if (docsAnexo.length > 0) badges.docAnx = docsAnexo[0];

    if (!typePj) {
      if (badges.docAd.status == "PENDING" || badges.docAd.status == "ANALYZING") pend++;
      if (badges.docAd.status == "" || badges.docAd.status == "REJECTED") red++;
    } else {
      if (badges.docSc.status == "PENDING" || badges.docSc.status == "ANALYZING") pend++;
      if (badges.docSc.status == "" || badges.docSc.status == "REJECTED") red++;
    }
    if (badges.docId.status == "PENDING" || badges.docId.status == "ANALYZING") pend++;
    if (badges.docId.status == "" || badges.docId.status == "REJECTED") red++;
    if (badges.docSelfie.status == "PENDING" || badges.docSelfie.status == "ANALYZING") pend++;
    if (badges.docSelfie.status == "" || badges.docSelfie.status == "REJECTED") red++;
    if (badges.docAnx.status == "PENDING" || badges.docAnx.status == "ANALYZING") pend++;
    if (badges.docAnx.status == "REJECTED") red++;
    if (red > 0) {
      badges.qtd = red;
      badges.cor = "error";
    } else if (pend > 0) {
      badges.qtd = pend;
      badges.cor = "primary";
    }
  }

  return badges;
};
