import { Button, Card, CardContent, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import Moment from "react-moment";
import FormDialog from "../../custom/FormDialog";
import InfoItem from "../../custom/InfoItem";
import Number from "../../custom/Number";
import LCContract from "./LCContract";
import { isAgentManager, Roles, calcProportionalYield, isAgent, capitalCaseNormalizer } from "../../util";
import { getConfigurações } from "../../actions";

class ContractDetail extends Component {
  state = {
    lccontract: false,
    baseYield: 0,
  };

  componentDidMount() {
    getConfigurações().then((config) => this.setState({ baseYield: config.baseYield }));
  }

  render() {
    const { contract, user } = this.props;
    const { baseYield } = this.state;
    const role = {
      agent: user.role == Roles.Agent,
      admin: user.role == Roles.Admin,
      finance: user.role == Roles.FinanceManager,
      client: user.role == Roles.Customer,
    };
    const contract_ = {
      status: contract.status,
      phase: contract.phase,
      canceledBy: contract.canceledBy,
      modal: contract.type.type,
      description: contract.type.description,
      duration: contract.type.durationInMonth,
      type: contract.type,
      prevision: contract.previsionAmount,
      volume: contract.amount,
      limit: contract.type.maxMonthlyYield,
      datecreate: contract.createdAt,
      dateopen: contract.initialDate,
      dateclose: contract.dueDate,
      lastDate: contract.updatedAt,
      split: contract.splitAgent,
      splitForManager: contract.user.consultant?.contractAgent?.splitForManager ?? 0,
      percAgent: contract.type.agentCommission * contract.segment.commissionModifier,
      customer: capitalCaseNormalizer(contract.user.name),
      customerId: contract.user.id,
      agent: contract.user.agent ?? { id: contract.user.consultantId, name: capitalCaseNormalizer(contract.user.consultantName) },
      comments: contract.comments,
      addendum: contract.addendum,
      segment: contract.segment,
    };
    contract_.actualMonthlyYield = calcProportionalYield(contract_, baseYield);

    const managerName = capitalCaseNormalizer(contract.user.consultant.manager?.name);
    const managerId = contract.user.consultant.manager?.id;
    const managerCommission = contract.user.consultant.manager?.contractAgent.managerComission ?? 0;

    const baseCommission = (contract.type.agentCommission * contract.segment.commissionModifier) / 100;

    const managerSplitPerc = contract.user.consultant.contractAgent?.splitForManager ?? 0;
    const managerSplitCommission = baseCommission * managerSplitPerc;

    const agentCommissionBeforeSplit = baseCommission - managerSplitCommission;

    const splitName = capitalCaseNormalizer(contract.splitAgent?.name);
    const splitId = contract.splitAgent?.id;
    const splitPerc = contract.splitPercent ?? 0;
    const splitCommission = agentCommissionBeforeSplit * splitPerc;

    const agentCommissionBeforeRecommender = agentCommissionBeforeSplit - splitCommission;

    const recommenderName = capitalCaseNormalizer(contract.user.recommender?.name);
    const recommenderId = contract.user.recommender?.id;
    const recommenderPerc = contract.user.recommender?.percent ?? 0;
    const recommenderCommission = agentCommissionBeforeRecommender * recommenderPerc;

    const agentName = capitalCaseNormalizer(contract.user.consultant.name);
    const agentId = contract.user.consultant.id;
    const agentCommission = agentCommissionBeforeRecommender - recommenderCommission;

    return (
      <>
        <Card style={{ marginBottom: 16 }}>
          <CardContent>
            {/* Situation */}
            <InfoItem color="primary" caption="LCContract" text={<FormattedMessage id={`app.enuns.${contract_.phase}`} />} />
            {/* Cacellation info + date */}
            {contract_.canceledBy && (
              <>
                <InfoItem
                  color="primary"
                  caption={<FormattedMessage id="app.contract.finishedBy" />}
                  text={contract_.canceledBy == "CUSTOMER" ? <FormattedMessage id="app.contract.canceledBy.customer" /> : <FormattedMessage id="app.contract.canceledBy.company" />}
                  extraCaption={<FormattedMessage id="app.contract.finishedAt" />}
                  extraText={<Moment date={contract_.lastDate} format="DD/MM/YYYY" />}
                />
              </>
            )}
            {/* Customer */}
            <InfoItem caption={<FormattedMessage id="app.customer" />} text={contract_.customer + " (" + contract_.customerId + ")"} />
            {/* Description + status */}
            <InfoItem caption={<FormattedMessage id="app.description" />} text={contract_.description} extraCaption="Status" extraText={<FormattedMessage id={`app.enuns.${contract_.status}`} />} />
            {/* Aport amount + prevision */}
            <InfoItem
              caption={<FormattedMessage id="app.aport.value" />}
              text={contract_.volume ? <Number value={contract_.volume} currency="LCT" /> : "N/A"}
              extraCaption={<FormattedMessage id="app.estimatedAmount" />}
              extraText={<Number value={contract_.prevision} currency="LCT" />}
            />
            {/* Activation date + creation date*/}
            <InfoItem
              caption={<FormattedMessage id="app.activationDate" />}
              text={contract_.dateopen ? <Moment date={contract_.dateopen} format="DD/MM/YYYY" /> : "N/A"}
              extraCaption={<FormattedMessage id="app.createdAt" />}
              extraText={contract_.datecreate ? <Moment date={contract_.datecreate} format="DD/MM/YYYY" /> : "N/A"}
            />
            {/* Due date + total yield to date */}
            <InfoItem
              caption={<FormattedMessage id="app.dueDate" />}
              text={contract_.dateclose ? <Moment date={contract_.dateclose} format="DD/MM/YYYY" /> : "N/A"}
              extraCaption={<FormattedMessage id="app.totalIncrease" />}
              extraText={
                contract.yield.per ? (
                  <>
                    <Number value={contract.yield.val} currency="LCT" /> (
                    <Number value={contract.yield.per} display="percent" />)
                  </>
                ) : (
                  <>N/A</>
                )
              }
            />
          </CardContent>
        </Card>

        {/* Segment + internal comments */}
        {(role.admin || role.finance) && (
          <Card style={{ marginBottom: 16 }}>
            <CardContent>
              <InfoItem
                caption={<FormattedMessage id="app.editContract.investmentId" />}
                text={
                  <>
                    {contract_.segment.description} (<Number value={contract_.actualMonthlyYield} display="percent" />)
                  </>
                }
              />
              <InfoItem caption={<FormattedMessage id="app.editContract.comments" />} text={contract_.comments ?? "-"} />
            </CardContent>
          </Card>
        )}

        {/* Commissions */}
        {user.id !== contract.user.id && (
          <Card style={{ marginBottom: 16 }}>
            <CardContent>
              {managerCommission !== 0 && (role.admin || role.finance || isAgentManager(user)) && (
                <InfoItem
                  caption={"Management commission"}
                  text={
                    <>
                      <Number value={managerCommission * 100} display="percent" /> (
                      <Number value={managerCommission * contract_.volume} currency="LCT" />) - {managerName} ({managerId})
                    </>
                  }
                />
              )}
              {(role.admin || role.finance) && (
                <InfoItem
                  caption={"Base commission from contratc type"}
                  text={
                    <>
                      <Number value={baseCommission * 100} display="percent" /> (
                      <Number value={baseCommission * contract_.volume} currency="LCT" />)
                    </>
                  }
                />
              )}
              {managerSplitCommission !== 0 && (role.admin || role.finance || isAgentManager(user)) && (
                <InfoItem
                  caption={
                    <>
                      Manager split (<Number value={managerSplitPerc * 100} display="percent" />)
                    </>
                  }
                  text={
                    <>
                      <Number value={managerSplitCommission * 100} display="percent" /> (
                      <Number value={managerSplitCommission * contract_.volume} currency="LCT" />) - {managerName} ({managerId})
                    </>
                  }
                />
              )}
              {splitCommission !== 0 && (role.admin || role.finance || isAgentManager(user)) && (
                <InfoItem
                  caption={
                    <>
                      Split (<Number value={splitPerc * 100} display="percent" />)
                    </>
                  }
                  text={
                    <>
                      <Number value={splitCommission * 100} display="percent" /> (
                      <Number value={splitCommission * contract_.volume} currency="LCT" />) - {splitName} ({splitId})
                    </>
                  }
                />
              )}
              {recommenderCommission !== 0 && (role.admin || role.finance || isAgentManager(user) || isAgent(user)) && (
                <InfoItem
                  caption={
                    <>
                      Recommender (<Number value={recommenderPerc * 100} display="percent" />)
                    </>
                  }
                  text={
                    <>
                      <Number value={recommenderCommission * 100} display="percent" /> (
                      <Number value={recommenderCommission * contract_.volume} currency="LCT" />) - {recommenderName} ({recommenderId})
                    </>
                  }
                />
              )}
              <InfoItem
                caption={"Effective agent commission"}
                text={
                  <>
                    <Number value={agentCommission * 100} display="percent" /> (
                    <Number value={agentCommission * contract_.volume} currency="LCT" />) - {agentName} ({agentId})
                  </>
                }
              />
            </CardContent>
          </Card>
        )}

        {/* Show contract text */}
        <div style={{ textAlign: "center" }}>
          <Button variant="outlined" color="primary" style={{ minWidth: 250 }} onClick={() => this.setState({ lccontract: true })}>
            <FormattedMessage id="app.contract.show" />
          </Button>
        </div>

        {/* Contract text dialog */}
        <FormDialog title="LCContract" open={this.state.lccontract} variant="secondary" onClose={(e) => this.setState({ lccontract: false })}>
          <Typography gutterBottom component="div">
            <LCContract
              amount={contract_.volume > 0 ? contract_.volume : contract_.prevision}
              duration={contract_.duration}
              type={contract_.modal}
              max={contract_.duration * contract_.actualMonthlyYield}
              lang={user.locale}
              addendum={contract_.addendum}
            />
          </Typography>
        </FormDialog>
      </>
    );
  }
}

export default ContractDetail;
