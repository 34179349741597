import { GET_CONFIG, UPDATE_CONFIG } from "../actions/types";

export default function(state = {}, action) {
  switch (action.type) {
    case GET_CONFIG:
    case UPDATE_CONFIG:
      return action.payload;
    default:
      return state;
  }
}
