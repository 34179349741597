import React, { Component } from "react";
import {
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  InputBase,
  IconButton,
  Typography,
  Button,
  ListItemSecondaryAction
} from "@material-ui/core";
import { compose } from "redux";
import { connect } from "react-redux";
import * as actions from "../actions";
import PageContainer, { CardPageItem } from "../custom/Page";
import HeaderPageItem from "../custom/HeaderPageItem";
import Add from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import performRequest from "../configAxios";
import { capitalCaseNormalizer } from "../util";
import AbtcAvatar from "../custom/Avatar";
import FormDialog from "../custom/FormDialog";
import { changeAgent } from "../actions";
import wpp from "../static/whatsapp.png";
import { phoneFormatter, cpfNormalizer, cnpjNormalizer } from "../util/index";
import { FormattedMessage } from "react-intl";

class AgentNewClient extends Component {
  state = {
    customers: [],
    add: false,
    client: []
  };

  componentDidMount() {}

  searchUsers = name => {
    if (name.trim().length > 0) {
      performRequest("GET", `user/freeCustomers?name=${name}`, null, true)
        .then(response => {
          this.setState({ customers: response.data.data });
        })
        .catch();
    } else {
      this.setState({ customers: [] });
    }
  };

  addClient = id => {
    changeAgent(id).then(data => {
      this.props.history.push("/customers");
    });
  };

  render() {
    const { customers, client } = this.state;

    return (
      <PageContainer>
        <HeaderPageItem
          title="app.newCustomer"
          showBackButton
          destination={"/customers"}
        />
        <CardPageItem>
          <Card>
            <CardContent>
              <IconButton aria-label="Search">
                <SearchIcon />
              </IconButton>
              <InputBase
                placeholder="app.newCustomers"
                onChange={e => this.searchUsers(e.target.value)}
              />
              <List>
                {customers.map(c => {
                  return (
                    <ListItem
                      button
                      alignItems="flex-start"
                      key={c.id}
                      onClick={() => this.setState({ add: true, client: c })}
                    >
                      <ListItemAvatar>
                        <AbtcAvatar imageUrl={c.photoUrl} disableLink />
                      </ListItemAvatar>
                      <ListItemText
                        primaryTypographyProps={{ component: "div" }}
                        secondaryTypographyProps={{ component: "div" }}
                        primary={
                          <div
                            color="textSecondary"
                            style={{
                              display: "flex",
                              justifyContent: "space-between"
                            }}
                          >
                            <span>{capitalCaseNormalizer(c.name)}</span>
                          </div>
                        }
                        secondary={
                          <div>
                            <span>{c.email}</span>
                            <div
                              color="textSecondary"
                              style={{
                                display: "flex",
                                justifyContent: "space-between"
                              }}
                            />
                          </div>
                        }
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          onClick={() =>
                            this.setState({ add: true, client: c })
                          }
                        >
                          <Add />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
              </List>
            </CardContent>
          </Card>
        </CardPageItem>
        <FormDialog
          title="app.customer.add"
          open={this.state.add}
          variant="secondary"
          onClose={e => this.setState({ add: false })}
        >
          <CardContent>
            <Typography
              gutterBottom
              variant="body1"
              style={{ marginBottom: 16 }}
            >
              <FormattedMessage id="app.customer.checkData" />:
            </Typography>
            <CardContent style={{ marginBottom: 16 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center"
                }}
              >
                <AbtcAvatar imageUrl={client.photoUrl} disableLink size={60} />
              </div>
              <Typography gutterBottom variant="body1">
                {client.name}{" "}
              </Typography>

              <Typography gutterBottom color="textSecondary" variant="caption">
                <FormattedMessage id="app.address" />
              </Typography>
              <Typography gutterBottom>
                {client.address}, {client.city} - {client.state} -{" "}
                {client.country}
              </Typography>
              <Typography gutterBottom color="textSecondary" variant="caption">
                {client.isPj ? "CNPJ" : "CPF"}
              </Typography>
              <Typography gutterBottom>
                {!client.isPj
                  ? cpfNormalizer(client.documentId)
                  : cnpjNormalizer(client.documentId)}
              </Typography>
              <Typography gutterBottom color="textSecondary" variant="caption">
                E-mail
              </Typography>
              <Typography gutterBottom>{client.email}</Typography>
              <Typography gutterBottom color="textSecondary" variant="caption">
                <FormattedMessage id="app.phone" />
              </Typography>
              <Typography gutterBottom>
                {phoneFormatter(client.telefone)}{" "}
                {client.isWhats && (
                  <img src={wpp} width="14" height="14" alt=" " />
                )}
              </Typography>

              {client.telefone2 && (
                <>
                  <Typography
                    gutterBottom
                    color="textSecondary"
                    variant="caption"
                  >
                    <FormattedMessage id="app.phone" /> 2
                  </Typography>
                  <Typography gutterBottom>
                    {phoneFormatter(client.telefone2)}{" "}
                    {client.isWhats2 && (
                      <img src={wpp} width="14" height="14" alt=" " />
                    )}
                  </Typography>
                </>
              )}
            </CardContent>
            <Typography
              gutterBottom
              variant="body1"
              style={{ marginBottom: 16 }}
            >
              <FormattedMessage id="app.customer.isThisYour" />?
            </Typography>
            <Button
              style={{ marginBottom: 16 }}
              variant="contained"
              fullWidth
              color="primary"
              onClick={() => {
                this.addClient(client.id);
                this.setState({ add: false });
              }}
            >
              <FormattedMessage id="app.customer.addAs" />
            </Button>
            <Button
              style={{ marginBottom: 16 }}
              variant="outlined"
              fullWidth
              onClick={() => {
                this.setState({ add: false });
              }}
            >
              <FormattedMessage id="app.no" />
            </Button>
          </CardContent>
        </FormDialog>
      </PageContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me
  };
}

export default compose(connect(mapStateToProps, actions)(AgentNewClient));
