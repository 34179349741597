import React, { Component } from "react";
import {
  withStyles,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography
} from "@material-ui/core";
import { compose } from "redux";
import { connect } from "react-redux";
import * as actions from "../actions";
import { reduxForm } from "redux-form";
import PageContainer, { PageItem } from "../custom/Page";
import performRequest from "../configAxios";
import AbtcAvatar from "../custom/Avatar";
import { FormattedMessage } from "react-intl";

class More extends Component {
  state = {
    contracts: []
  };

  componentDidMount() {
    performRequest("GET", `contract`, null, true)
      .then(response => {
        this.setState({ contracts: response.data.data });
      })
      .catch();
  }

  render() {
    const { classes } = this.props;
    const { contracts } = this.state;
    return (
      <PageContainer>
        <PageItem>
          <Card>
            <CardContent>
              <Typography>
                <FormattedMessage id="app.more" /> (
                <FormattedMessage id="app.agent" />)
              </Typography>
              <List className={classes.root}>
                {contracts.map(c => (
                  <ListItem button alignItems="flex-start">
                    <ListItemAvatar>
                      <AbtcAvatar
                        alt="Remy Sharp"
                        src="/static/images/avatar/1.jpg"
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primaryTypographyProps={{ component: "div" }}
                      secondaryTypographyProps={{ component: "div" }}
                      primary={
                        <div
                          color="textSecondary"
                          style={{
                            display: "flex",
                            justifyContent: "space-between"
                          }}
                        >
                          <span>LCContract C{c.id}</span>{" "}
                          <span color="secondary">R${c.amount}</span>
                        </div>
                      }
                      secondary={
                        <div>
                          <span>{c.user.name}</span>
                          <div
                            color="textSecondary"
                            style={{
                              display: "flex",
                              justifyContent: "space-between"
                            }}
                          >
                            <span>Out/2018</span>{" "}
                            <span color="secondary">{c.status}</span>
                          </div>
                        </div>
                      }
                    />
                  </ListItem>
                ))}
                <ListItem button alignItems="flex-start">
                  <ListItemAvatar>
                    <AbtcAvatar
                      alt="Remy Sharp"
                      src="/static/images/avatar/1.jpg"
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primaryTypographyProps={{ component: "div" }}
                    secondaryTypographyProps={{ component: "div" }}
                    primary={
                      <div
                        color="textSecondary"
                        style={{
                          display: "flex",
                          justifyContent: "space-between"
                        }}
                      >
                        <span>LCContract 001</span>{" "}
                        <span color="secondary">RS250K</span>
                      </div>
                    }
                    secondary={
                      <div>
                        <span>Renan dos Santos Hartwig</span>
                        <div
                          color="textSecondary"
                          style={{
                            display: "flex",
                            justifyContent: "space-between"
                          }}
                        >
                          <span>Out/2018</span>{" "}
                          <span color="secondary">Aberto</span>
                        </div>
                      </div>
                    }
                  />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </PageItem>
      </PageContainer>
    );
  }
}

const styles = theme => ({
  table: {
    marginTop: theme.spacing(3)
  },
  cardHeader: {
    textAlign: "center"
  },
  card: {
    marginTop: "20px"
  },
  content: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    margin: theme.spacing(1)
  },
  div: {
    maxWidth: 1000,
    padding: theme.spacing(4),
    margin: "auto"
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    display: "inline"
  },
  right: {
    textAlign: "right"
  },
  input: {
    marginLeft: 8,
    flex: 1
  },
  iconButton: {
    padding: 10
  }
});

const validate = values => {
  const errors = {};
  if (!values.type) {
    errors.type = "Tipo deve ser informado";
  }
  if (!values.active) {
    errors.active = "Status deve ser informado";
  }
  if (!values.startDate) {
    errors.startDate = "Periodo inicial deve ser selecionado";
  }
  if (!values.finalDate) {
    errors.finalDate = "Periodo final deve ser selecionado";
  }
  return errors;
};

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, actions),
  reduxForm({
    form: "contractForm",
    enableReinitialize: true,
    validate
  })
)(More);
