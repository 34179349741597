import React, { Component } from "react";
import { CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { getUsers } from "../actions";
import UserReportContent from "./components/UserReportContent";

class UserReport extends Component {
	state = {
		loading: true,
		dataList: [],
	};

	componentDidMount() {
		if (
			this.props.currentUser.role == "ADMIN" ||
			this.props.currentUser.role == "KYC" ||
			this.props.currentUser.role == "FINANCE_MANAGER"
		) {
			getUsers("").then((d) => {
				this.setState({ dataList: d, loading: false });
			});
		} else {
			getUsers({ taxresidence: this.props.currentUser.taxResidence }).then((d) => {
				this.setState({ dataList: d, loading: false });
			});
		}
	}

	render() {
		const { currentUser } = this.props;
		const { loading } = this.state;

		if (loading) return <CircularProgress style={{ marginTop: "25%" }} />;
		return <UserReportContent title={"app.user.report"} dataList={this.state.dataList} desc={"app.user.report"} />;
	}
}

function mapStateToProps(state) {
	return {
		currentUser: state.user.user.me,
	};
}

export default connect(mapStateToProps)(UserReport);
