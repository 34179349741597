import { Button, CardContent, CircularProgress, MenuItem, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import { createNumberMask } from "redux-form-input-masks";
import * as actions from "../../actions";
import { createContractAgent, editContractAgent, patchContractAgent, restartContractAgent } from "../../actions";
import CustomInputForm from "../../custom/CustomInput";
import customSelect from "../../custom/CustomSelect";
import FormDialog from "../../custom/FormDialog";
import { AgreementTypes, Roles } from "../../util";
import ContractAgentDescription from "./ContractAgentDescription";

// VERIFICADO LCTInputMask - precisou chamar o getConfig.

class FormContractAgent extends Component {
  state = {
    editContract: false, // False = novo contrato, True = editar contrato
    agreementType: null,
    contractDetail: false,
    segments: [],
  };

  componentDidMount() {
    const { initialize, customer, getConfig } = this.props;
    getConfig();
    actions.getInvestments().then((segments) => {
      segments.sort((a, b) => a.id - b.id);
      this.setState({ segments });
    });
    if (customer.contractAgent) {
      initialize({
        id: customer.contractAgent.id,
        goal: customer.contractAgent.goal,
        managerComission: customer.contractAgent.managerComission * 100.0,
        splitForManager: 100.0 - customer.contractAgent.splitForManager * 100.0,
        type: customer.contractAgent.type,
        defaultInvestmentId: customer.contractAgent.defaultInvestmentId,
      });
      this.setState({ editContract: true, agreementType: customer.contractAgent.type });
    } else {
      initialize({
        goal: 10000.0,
        managerComission: 0.2,
        splitForManager: 100.0,
        defaultInvestmentId: 1,
      });
    }
  }

  onSubmit = (formProps) => {
    const { currentUser, customer } = this.props;

    const data = {
      ...formProps,
      managerComission: formProps.managerComission / 100.0,
      splitForManager: (100.0 - formProps.splitForManager) / 100.0,
    };

    if ([Roles.Admin, Roles.FinanceManager].includes(currentUser.role)) {
      if (!customer.contractAgent) {
        this.onSave(data);
      } else if (customer.contractAgent.status == "PENDING" || customer.contractAgent.status == "ACTIVE") {
        this.onEdit(data);
      } else {
        this.onRestart(data);
      }
    } else if (currentUser.role === Roles.Agent && currentUser.contractAgent.type === AgreementTypes.Manager) {
      this.onPath(data);
    } else {
      const { showErrorMessage } = this.props;
      showErrorMessage("Permisson denied.");
    }
  };

  onSave = (formProps) => {
    const { onActionComplete, showErrorMessage, showSuccessMessage, customer } = this.props;
    createContractAgent(customer.id, formProps)
      .then((response) => {
        onActionComplete(response.data.data);
        showSuccessMessage("app.contractAgent.created");
      })
      .catch((e) => {
        if (e.response) {
          showErrorMessage(e.response.data.message);
        } else {
          showErrorMessage(e);
        }
      });
  };

  onEdit = (formProps) => {
    const { onActionComplete, showErrorMessage, showSuccessMessage, customer } = this.props;
    editContractAgent(customer.id, formProps)
      .then((response) => {
        onActionComplete(response.data.data);
        showSuccessMessage("app.contractAgent.edited");
      })
      .catch((e) => {
        if (e.response) {
          showErrorMessage(e.response.data.message);
        } else {
          showErrorMessage(e);
        }
      });
  };

  onPath = (formProps) => {
    const { onActionComplete, showErrorMessage, showSuccessMessage, customer } = this.props;
    patchContractAgent(customer.id, formProps)
      .then((response) => {
        onActionComplete(response.data.data);
        showSuccessMessage("app.contractAgent.edited");
      })
      .catch((e) => {
        if (e.response) {
          showErrorMessage(e.response.data.message);
        } else {
          showErrorMessage(e);
        }
      });
  };

  onRestart = (formProps) => {
    const { onActionComplete, showErrorMessage, showSuccessMessage, customer } = this.props;

    restartContractAgent(customer.id, formProps)
      .then((response) => {
        onActionComplete(response.data.data);
        showSuccessMessage("app.contractAgent.restarted");
      })
      .catch((e) => {
        if (e.response) {
          showErrorMessage(e.response.data.message);
        } else {
          showErrorMessage(e);
        }
      });
  };

  render() {
    const { handleSubmit, customer, config, currentUser } = this.props;
    const { agreementType } = this.state;
    if (!customer || !config.LCTInputMask) return <CircularProgress style={{ marginTop: "25%" }} />;
    const lctMask = config.LCTInputMask();
    const percentMask = createNumberMask({
      suffix: "%",
      decimalPlaces: 2,
      locale: "pt-BR",
      allowNegative: false,
    });

    const AgentName = () => (
      <>
        <Typography gutterBottom color="textSecondary" variant="caption">
          <FormattedMessage id="app.name" /> (<FormattedMessage id={`app.enuns.${customer.role}`} />)
        </Typography>
        <Typography gutterBottom>
          {customer.name} ({customer.id})
        </Typography>
      </>
    );

    const FieldAgreementType = () => (
      <Field
        name="type"
        label="app.type"
        component={customSelect}
        values={[
          { value: AgreementTypes.Agent, input: "Agente" },
          { value: AgreementTypes.Manager, input: "Gestor de agentes (escritório)" },
        ]}
        InputLabelProps={{ shrink: true }}
        onChange={(e) => this.setState({ agreementType: e.target.value })}
      />
    );

    const FieldSplitForManager = () => (
      <Field
        name="splitForManager"
        label="app.contractAgent.comissionPercentage"
        InputLabelProps={{
          shrink: true,
        }}
        component={CustomInputForm}
        autoFocus
        {...percentMask}
      />
    );

    const FieldManagerComission = () => (
      <Field
        name="managerComission"
        label="app.contractAgent.managerComission"
        InputLabelProps={{
          shrink: true,
        }}
        component={CustomInputForm}
        autoFocus
        {...percentMask}
      />
    );

    const FieldAgreementDefaultInvestmentId = () => (
      <Field
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        select
        name="defaultInvestmentId"
        label="app.editContract.investmentId"
        component={CustomInputForm}
      >
        {this.state.segments.map((seg) => (
          <MenuItem key={seg.id} value={seg.id} style={{ display: "flex", justifyContent: "space-between" }}>
            <span>{seg.description} </span> <span>{seg.baseYield.toFixed(2)}%</span>
          </MenuItem>
        ))}
      </Field>
    );

    const AgreementStatusReadReset = () => {
      if (!customer.contractAgent) {
        return null;
      }
      return (
        <div>
          <Typography gutterBottom color="textSecondary" variant="caption">
            <FormattedMessage id="app.status" />
          </Typography>
          <Typography gutterBottom>
            <FormattedMessage id={`app.enuns.${customer.contractAgent.status}`} />
          </Typography>
          <Button variant="outlined" onClick={() => this.setState({ contractDetail: true })}>
            <FormattedHTMLMessage id="app.contractAgent.read" />
          </Button>
          <FormDialog title="app.contractAgent" open={this.state.contractDetail} variant="secondary" onClose={(e) => this.setState({ contractDetail: false })}>
            <CardContent>
              <ContractAgentDescription contractUser={customer} contractAgent={customer.contractAgent} />
            </CardContent>
          </FormDialog>
          <Button
            style={{ marginLeft: 10 }}
            variant="outlined"
            onClick={() =>
              this.onRestart({
                id: customer.contractAgent.id,
                goal: customer.contractAgent.goal,
                managerComission: customer.contractAgent.managerComission,
                splitForManager: customer.contractAgent.splitForManager,
                defaultInvestmentId: customer.contractAgent.defaultInvestmentId,
              })
            }
          >
            <FormattedHTMLMessage id="app.contractAgent.restart" />
          </Button>
        </div>
      );
    };

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <AgentName />
        {currentUser.role === Roles.Agent && agreementType === AgreementTypes.Agent ? (
          <FieldSplitForManager />
        ) : (
          <>
            <FieldAgreementType />
            {agreementType === AgreementTypes.Agent ? <FieldSplitForManager /> : agreementType === AgreementTypes.Manager ? <FieldManagerComission /> : null}
            <FieldAgreementDefaultInvestmentId />
            <AgreementStatusReadReset />
          </>
        )}
        <Button
          id="bSubmitOrderForm"
          style={{ marginTop: 10 }}
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          disabled={this.props.submitting || this.props.invalid || this.props.pristine}
        >
          <FormattedMessage id={!customer.contractAgent ? "app.button.save" : "app.edit"} />
        </Button>
      </form>
    );
  }
}

const validate = (values, props) => {
  const errors = {};
  if (!values.goal) {
    errors.goal = "app.goal.inform";
  }
  if (!values.managerComission) {
    errors.managerComission = "app.managerComission.inform";
  }
  if (!values.defaultInvestmentId) {
    errors.defaultInvestmentId = "app.defaultInvestmentId.inform";
  }
  return errors;
};

function mapStateToProps(state) {
  return {
    config: state.config,
    currentUser: state.user.user.me,
  };
}

export default compose(
  reduxForm({
    form: "FormUpdateAddress",
    validate,
  }),
  connect(mapStateToProps, actions)
)(FormContractAgent);
