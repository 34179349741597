import { Button } from "@material-ui/core";
import React, { Component, Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import * as actions from "../../actions";
import { updateWithdrawalFee } from "../../actions";
import CustomInputForm from "../../custom/CustomInput";

// VERIFICADO LCTInputMask - precisou chamar o getConfig.

class FormUpdateFee extends Component {
  onSubmit = (formProps) => {
    const {
      onActionComplete,
      showErrorMessage,
      showSuccessMessage,
      withdrawal: { id },
    } = this.props;
    updateWithdrawalFee(id, formProps)
      .then((response) => {
        showSuccessMessage("app.update");
        onActionComplete(response);
      })
      .catch((e) => {
        if (e.response) {
          showErrorMessage(e.response.data.message);
        } else {
          showErrorMessage(e);
        }
      });
  };

  componentDidMount() {
    const { initialize, withdrawal, getConfig } = this.props;
    getConfig();
    initialize({ fee: withdrawal.fee });
  }

  render() {
    const { handleSubmit, config } = this.props;

    if (!config.LCTInputMask) return null;
    const lctMask = config.LCTInputMask();
    return (
      <Fragment>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <Field
            name="fee"
            label="app.rate.update"
            InputLabelProps={{
              shrink: true,
            }}
            component={CustomInputForm}
            autoFocus
            {...lctMask}
          />
          <Button
            id="bSubmitOrderForm"
            style={{ marginTop: 10 }}
            variant="contained"
            color="primary"
            size="large"
            type="submit"
          >
            <FormattedMessage id="app.button.save" />
          </Button>
        </form>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    config: state.config,
  };
}

export default compose(
  reduxForm({
    form: "formUpdateFee",
  }),
  connect(mapStateToProps, actions)
)(FormUpdateFee);
