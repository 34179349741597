import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { Button, Typography, IconButton } from "@material-ui/core";
import { CustomInput, CustomSelect } from "../../custom";
import * as actions from "../../actions";
import performRequest from "../../configAxios";
import { FormattedMessage } from "react-intl";
import { Roles, resizeImageFile } from "../../util";
import InputFile from "../../custom/InputFile";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import AvatarEditor from "react-avatar-editor";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormDialog from "../../custom/FormDialog";
class FormAttachment extends Component {
  state = {
    attachment: true,
    dialogImageOpen: false,
    fileName: "",
    file: null,
    fileError: true,
    formProps: null,
    photoRotation: 0,
    types: [],
    type: null,
  };

  componentDidMount() {
    const { currentUser } = this.props;
    if (currentUser.role == Roles.Kyc || currentUser.role == Roles.Admin) {
      this.setState({
        attachment: false,
        types: [
          { value: "ID", input: "app.enuns.ID" },
          { value: "ADDRESS", input: "app.enuns.ADDRESS" },
          { value: "SOCIAL_CONTRACT", input: "app.enuns.SOCIAL_CONTRACT" },
          { value: "ID_WITH_PHOTO", input: "app.enuns.ID_WITH_PHOTO" },
          { value: "ATTACHMENT", input: "app.enuns.ATTACHMENT" },
        ],
      });
    }
  }

  sendImage = (event) => {
    const { onActionComplete, showErrorMessage, showSuccessMessage, user } = this.props;
    const { attachment, photoFile } = this.state;
    resizeImageFile(photoFile, 2000, 2000, this.state.photoRotation, 70).then((blob) => {
      var formData = new FormData();
      formData.append("file", blob);
      formData.append("description", this.state.formProps.description);
      formData.append("type", attachment ? "ATTACHMENT" : this.state.formProps.type);
      performRequest("POST", `/user/kyc/admin/${user.id}`, formData, true)
        .then((response) => {
          this.setState({ formProps: null, type: null, dialogImageOpen: false, photoRotation: 0 });
          showSuccessMessage("app.successfully.added");
          onActionComplete();
        })
        .catch((e) => {
          if (e.response) {
            showErrorMessage(e.response.data.message);
          } else {
            showErrorMessage(e);
          }
        });
    });
  };
  photoRotationChange = (dir) => {
    let rot = Math.abs(this.state.photoRotation + 90 * dir);
    if (rot === 360) {
      rot = 0;
    }
    this.setState({
      photoRotation: rot,
    });
  };
  onSubmit = (formProps) => {
    const { onActionComplete, showErrorMessage, showSuccessMessage, user } = this.props;
    const { attachment } = this.state;
    let file = formProps.file[0];
    if (file.type.startsWith("image/")) {
      this.setState({
        photoFile: file,
        formProps: formProps,
        type: attachment ? "ATTACHMENT" : formProps.type,
        dialogImageOpen: true,
      });
    } else {
      var formData = new FormData();
      formData.append("file", file);
      formData.append("description", formProps.description);
      formData.append("type", attachment ? "ATTACHMENT" : formProps.type);
      performRequest("POST", `/user/kyc/admin/${user.id}`, formData, true)
        .then((response) => {
          showSuccessMessage("app.successfully.added");
          onActionComplete();
        })
        .catch((e) => {
          if (e.response) {
            showErrorMessage(e.response.data.message);
          } else {
            showErrorMessage(e);
          }
        });
    }
  };

  onChangeType = (e) => {
    this.setState({
      type: this.state.types.find((element) => element.value == e.target.value),
    });
  };

  validaFile = (files) => {
    if (files) {
      if (files.target.files.length > 0) {
        let file = files.target.files[0];
        if (file.type.startsWith("image/")) {
          this.setState({ fileName: file.name, fileError: false });
        } else if (file.type.startsWith("application/pdf")) {
          this.setState({ fileName: file.name, fileError: false });
        } else {
          this.setState({ fileName: file.name, fileError: true });
        }
      } else {
        this.setState({ fileName: "", fileError: false });
      }
    }
  };

  render() {
    const { handleSubmit } = this.props;
    const { attachment, photoFile } = this.state;
    const photoObj = photoFile ? URL.createObjectURL(photoFile) : null;
    return (
      <div>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <Field name="description" label="app.description" component={CustomInput} multiline={true} />

          {!attachment && (
            <Field
              name="type"
              label="app.type"
              component={CustomSelect}
              values={this.state.types}
              onChange={this.onChangeType}
            />
          )}
          {/* {this.state.type != null && ( */}
          <div style={{ display: "flex", alignItems: "center", marginTop: 10, marginBottom: 10 }}>
            <Field
              name="file"
              label="app.file"
              component={InputFile}
              type="file"
              onChange={(e) => this.validaFile(e)}
              accept=".pdf, .jpg, .png, .jpeg"
            />

            <div>
              {this.state.fileName.length > 0 &&
                (this.state.fileError ? (
                  <Typography style={{ paddingLeft: 10, color: "red" }}>
                    <FormattedMessage id="app.upload.error2" />{" "}
                  </Typography>
                ) : (
                  <Typography style={{ paddingLeft: 10 }}>{this.state.fileName}</Typography>
                ))}
            </div>
          </div>
          {/* )} */}
          <Button
            id="bSubmitOrderForm"
            style={{ marginTop: 10 }}
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            onClick={() => this.onChange}
            disabled={this.props.pristine || this.props.submitting || this.props.invalid || this.state.fileError}
          >
            <FormattedMessage id="app.button.save" />
          </Button>
        </form>
        <FormDialog
          title="app.changeAvatar"
          variant="secondary"
          open={this.state.dialogImageOpen}
          onClose={() => this.setState({ dialogImageOpen: false })}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {photoFile ? (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 16,
                    width: "100%",
                  }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={(e) => this.photoRotationChange(1)}
                    style={{ marginRight: 16 }}
                  >
                    <RotateRightIcon />
                    Girar
                  </Button>
                  <Button variant="contained" color="primary" onClick={this.sendImage}>
                    <FormattedMessage id="app.button.save" />
                  </Button>
                </div>
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: 0,
                    paddingTop: "100%",
                    backgroundColor: "black",
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      left: 0,
                      top: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#222222",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={photoObj}
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        transform: "rotate(" + this.state.photoRotation + "deg)",
                      }}
                    />
                  </div>
                </div>
              </>
            ) : (
              <CircularProgress color="primary" style={{ marginTop: "25%" }} />
            )}
          </div>
        </FormDialog>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.description) {
    errors.description = "app.description.error";
  }
  if (!values.type) {
    errors.type = "app.type.error";
  }
  if (!values.file) {
    errors.file = "app.upload.error";
  }
  return errors;
};

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default compose(
  reduxForm({
    form: "formAttachment",
    validate,
  }),
  connect(mapStateToProps, actions)
)(FormAttachment);
