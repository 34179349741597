import {
  Button,
  CardContent,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import * as actions from "../actions";
import { getUsers } from "../actions";
import performRequest from "../configAxios";
import AbtcAvatar from "../custom/Avatar";
import HeaderPageItem from "../custom/HeaderPageItem";
import PageContainer, { CardPageItem } from "../custom/Page";
import { ListRoles, capitalCaseNormalizer } from "../util";

class AdminUsers extends Component {
  state = {
    originalUsers: [],
    users: [],
    customers: [],
    loading: true,
    openDialogFilter: false,
    listRoles: ListRoles,
    listAllRoles: true,
    filterPremium: false,
    searchName: "",
    searchUserId: "",
  };

  componentDidMount() {
    this.loadUsers();
  }

  loadUsers = () => {
    getUsers()
      .then((response) => {
        this.setState({
          originalUsers: response,
          users: response,
          loading: false,
        });
      })
      .catch();
  };

  doSearch = (name, userId) => {
    const nameFilter = name && name.trim().length > 0 ? `name=${name}&` : "";
    const userIdFilter = userId ? `userId=${userId}` : "";
    if (nameFilter !== "" || userIdFilter !== "") {
      performRequest("GET", `user?${nameFilter}${userIdFilter}`, null, true)
        .then((response) => {
          this.setState({
            originalUsers: response.data.data,
            users: response.data.data,
          });
        })
        .catch();
    } else {
      this.loadUsers();
    }
  };

  searchTimeoutId = null;

  searchUsersByNameEmail = (name) => {
    this.setState({ searchName: name, searchUserId: "" });
    clearTimeout(this.searchTimeoutId);
    this.searchTimeoutId = setTimeout(() => {
      this.doSearch(name, undefined);
    }, 1000);
  };

  searchUsersById = (userId) => {
    this.setState({ searchName: "", searchUserId: userId });
    clearTimeout(this.searchTimeoutId);
    this.searchTimeoutId = setTimeout(() => {
      this.doSearch(undefined, userId);
    }, 1000);
  };

  onChangeRole = (event) => {
    let all = true;
    this.setState({
      listRoles: this.state.listRoles.map((role) => {
        if (role.name == event.target.name) {
          role.value = event.target.checked;
        }
        if (!role.value) {
          all = false;
        }
        return role;
      }),
      listAllRoles: all,
    });
  };

  onChangeAllRoles = (event) => {
    this.setState({
      listRoles: this.state.listRoles.map((role) => {
        role.value = event.target.checked;
        return role;
      }),
      listAllRoles: event.target.checked,
    });
  };

  onChangeFilter = () => {
    let newList = [];
    this.state.originalUsers.forEach((user) => {
      if (user.premium === this.state.filterPremium) {
        this.state.listRoles.forEach((role) => {
          if (role.name == user.role && role.value == true) {
            newList.push(user);
          }
        });
      }
    });
    this.setState({ users: newList });
  };

  render() {
    const { users, loading, searchName, searchUserId } = this.state;

    if (loading) return <CircularProgress style={{ marginTop: "25%" }} />;

    return (
      <>
        <PageContainer>
          <HeaderPageItem title="app.users" onMore={(e) => this.setState({ menuAnchor: e.target })}>
            <Menu onClose={(e) => this.setState({ menuAnchor: undefined })} open={!!this.state.menuAnchor} anchorEl={this.state.menuAnchor}>
              <MenuItem onClick={(e) => this.setState({ openDialogFilter: true, menuAnchor: undefined })}>
                <FormattedMessage id="app.filter" />
              </MenuItem>
            </Menu>
          </HeaderPageItem>

          <CardPageItem raised>
            <CardContent>
              <div style={{ display: "grid", gridTemplateColumns: "3fr 1fr", gridGap: 8, marginTop: 16 }}>
                <TextField
                  id="searchName"
                  label={<FormattedMessage id="app.search.nameemail" />}
                  variant="outlined"
                  size="small"
                  value={searchName}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => this.searchUsersByNameEmail(e.target.value)}
                />
                <TextField
                  id="searchId"
                  label={<FormattedMessage id="app.search.id" />}
                  variant="outlined"
                  size="small"
                  value={searchUserId}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => this.searchUsersById(e.target.value)}
                />
              </div>
            </CardContent>
          </CardPageItem>

          <CardPageItem>
            <CardContent>
              <List>
                {users.length > 0 ? (
                  users.map((c) => {
                    return (
                      <ListItem button alignItems="flex-start" key={c.id} component={Link} to={"/user/" + c.id}>
                        <ListItemAvatar>
                          <AbtcAvatar imageUrl={c.photoUrl} disableLink />
                        </ListItemAvatar>
                        <ListItemText
                          primaryTypographyProps={{ component: "div" }}
                          secondaryTypographyProps={{ component: "div" }}
                          primary={
                            <div
                              color="textSecondary"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography color={c.premium ? "primary" : undefined}>{capitalCaseNormalizer(c.name)}</Typography>
                              <span> {"Id: " + c.id}</span>
                            </div>
                          }
                          secondary={
                            <div>
                              <div
                                color="textSecondary"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span>{c.email}</span>
                                {c.premium && (
                                  <Typography color="primary" variant="caption">
                                    <FormattedMessage id={"app.premium"} />
                                  </Typography>
                                )}
                              </div>
                              <span>
                                (<FormattedMessage id={"app.enuns." + c.role} />)
                              </span>
                              <span style={{ color: "red" }}> {!c.apt ? <FormattedMessage id={`app.inapt`} /> : ""}</span>
                            </div>
                          }
                        />
                      </ListItem>
                    );
                  })
                ) : (
                  <Typography gutterBottom color="textSecondary" variant="caption">
                    <FormattedMessage id="app.users.not.filter" />
                  </Typography>
                )}
              </List>
            </CardContent>
          </CardPageItem>
        </PageContainer>

        <Dialog open={this.state.openDialogFilter} fullWidth>
          <DialogContent>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                <FormattedMessage id="app.profile.filter" />
              </FormLabel>
              <FormGroup></FormGroup>
              <FormControlLabel
                key={"all"}
                control={<Checkbox checked={this.state.listAllRoles} onChange={this.onChangeAllRoles} color="primary" />}
                label={<FormattedMessage id="app.all" />}
              />
              <Divider />
              <FormGroup>
                {this.state.listRoles.map((role) => (
                  <FormControlLabel
                    key={role.name}
                    control={<Checkbox checked={role.value} onChange={this.onChangeRole} name={role.name} color="primary" />}
                    label={<FormattedMessage id={role.label} />}
                  />
                ))}
              </FormGroup>
            </FormControl>

            <FormControl component="fieldset">
              <Divider />
              <FormLabel component="legend">
                <FormattedMessage id="app.premium" />
              </FormLabel>
              <FormGroup></FormGroup>
              <FormControlLabel
                key={"premium"}
                control={<Checkbox checked={this.state.filterPremium} onChange={(e) => this.setState({ filterPremium: e.target.checked })} color="primary" />}
                label={<FormattedMessage id="app.premium" />}
              />
            </FormControl>

            <Grid container direction="row" justify="flex-end" alignItems="center">
              <Button
                onClick={() => {
                  this.onChangeFilter();
                  this.setState({ openDialogFilter: false });
                }}
                color="primary"
              >
                <FormattedMessage id="app.confirm" />
              </Button>
              <Button onClick={() => this.setState({ openDialogFilter: false })} color="primary">
                <FormattedMessage id="app.cancel" />
              </Button>
            </Grid>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default compose(connect(mapStateToProps, actions))(AdminUsers);
