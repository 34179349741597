import React, { Component } from "react";
import { numberFormatter } from "../../util";

class LCContractBR extends Component {
  render() {
    let { duration, amount, type, max, addendum, final } = this.props;
    max = max.toFixed(2).replace(".", ","); // Formatar para 2 casas decimais
    return (
      <>
        <div className="doc" style={{ textAlign: "justify" }}>
          <p>
            <span>Adendo aos Termos de Uso da Plataforma</span>
          </p>
          <p>
            <span>
              A aceita&ccedil;&atilde;o deste presente adendo implica em expressa concord&acirc;ncia do USU&Aacute;RIO ratificando &agrave;s cl&aacute;usulas e condi&ccedil;&otilde;es previstas no
              documento intitulado TERMOS E CONDI&Ccedil;&Otilde;ES DE USO da plataforma, bem como dos seguintes termos abaixo constantes
            </span>
            <span>:</span>
          </p>
          <ol>
            <li>
              <p className="bold">OBJETO</p>
              <ol>
                <li>
                  <span>
                    Constitui por OBJETO do presente LCContract o estabelecimento dos regramentos espec&iacute;ficos atinentes &agrave; cust&oacute;dia de ativos digitais conforme termos que seguem.
                  </span>
                </li>
                <li>
                  <span>A MODALIDADE de cust&oacute;dia &eacute; do tipo {type == "OPEN" ? "ABERTA" : "FECHADA"}, conforme regras definidas nos TERMOS E CONDI&Ccedil;&Otilde;ES DE USO.</span>
                </li>
                <li>
                  <span>A DURA&Ccedil;&Atilde;O do presente LCContract &eacute; de {duration} meses, a contar da data do aceite deste adendo.</span>
                </li>
                <li>
                  <span>O VOLUME CUSTODIADO corresponde a {numberFormatter(amount)}, integralizado ap&oacute;s o aceite do presente LCCONTRACT.</span>
                </li>
                <li>
                  {/*<span>O resultado esperado &eacute; vari&aacute;vel podendo chegar, sem garantias, ao LIMITE m&aacute;ximo de {max}% ao final da dura&ccedil;&atilde;o.</span>*/}
                  <span>O resultado esperado &eacute; vari&aacute;vel.</span>
                  <ol>
                    <li>
                      <span>
                        Esse resultado &eacute; calculado parcialmente a cada m&ecirc;s sobre o VOLUME CUSTODIADO, o qual n&atilde;o inclui resultados do pr&oacute;prio m&ecirc;s ou de meses
                        anteriores.
                      </span>
                    </li>
                    <li>
                      <span>
                        Os resultados aferidos mensalmente pelas opera&ccedil;&otilde;es do presente LCCONTRACT n&atilde;o est&atilde;o abrangidos pelos seus termos, sendo alocados diretamente no
                        extrato da conta do USU&Aacute;RIO, na plataforma, conforme disposto nos TERMOS E CONDI&Ccedil;&Otilde;ES DE USO.
                      </span>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <p className="bold">DO FUNCIONAMENTO DO LCCONTRACT</p>
              <ol>
                <li>
                  <span>A regras gerais do funcionamento do LCCONTRACTS, est&atilde;o definidos no TERMO E CONDI&Ccedil;&Otilde;ES DE USO.</span>
                </li>
                <li>
                  <span>Aceitando o presente o USU&Aacute;RIO ratifica todas as cl&aacute;usulas e condi&ccedil;&otilde;es descritas no TERMO E CONDI&Ccedil;&Otilde;ES DE USO.</span>
                </li>
                <li>
                  <span>
                    O USU&Aacute;RIO autoriza, desde j&aacute;, a utiliza&ccedil;&atilde;o dos ativos digitais custodiados em opera&ccedil;&otilde;es selecionadas a crit&eacute;rio da Lux Capital,
                    LLC.
                  </span>
                </li>
                <li>
                  <span>
                    Este LCCONTRACT ser&aacute; ativado um dia &uacute;til (D+1) ap&oacute;s a confirma&ccedil;&atilde;o do aporte pelo usu&aacute;rio, conforme condi&ccedil;&otilde;es do TERMO E
                    CONDI&Ccedil;&Otilde;ES DE USO.
                  </span>
                </li>
                <li>
                  <span>O VOLUME CUSTODIADO permanecer&aacute; sob cust&oacute;dia durante toda vig&ecirc;ncia deste LCCONTRACT, n&atilde;o podendo ser resgatado antes desse prazo.</span>
                  <ol>
                    <li>
                      <span>
                        No caso excepcional de necessidade de resgate antecipado do VOLUME CUSTODIADO, o USU&Aacute;RIO dever&aacute; proceder de acordo com o estabelecido conforme disposto no TERMO E
                        CONDI&Ccedil;&Otilde;ES DE USO.
                      </span>
                    </li>
                  </ol>
                </li>
                <li>
                  <span>
                    O acr&eacute;scimo de volume de ativos digitais originado pelas opera&ccedil;&otilde;es do presente LCCONTRACT poder&aacute; ser visualizado no extrato da conta do USU&Aacute;RIO e
                    ficar&aacute; dispon&iacute;vel para uso nos prazos cab&iacute;veis, conforme a MODALIDADE aqui contratada.
                  </span>
                </li>
                <li>
                  <span>
                    Ao terminar a vig&ecirc;ncia deste LCCONTRACT o volume total custodiado deixar&atilde;o de ser utilizados em opera&ccedil;&otilde;es, ficando o respectivo volume dispon&iacute;vel
                    para RESGATE por parte do USU&Aacute;RIO.
                  </span>
                  <ol>
                    <li>
                      <span>
                        Ap&oacute;s realiza&ccedil;&atilde;o de Resgate da integralidade dos ativos digitais custodiados pela Lux Capital, LLC, ser&atilde;o alocados no extrato da conta do
                        USU&Aacute;RIO dentro de quatro dias &uacute;teis (D+4).
                      </span>
                    </li>
                    <li>
                      <span>Cabe ao USU&Aacute;RIO estar ciente da data de t&eacute;rmino da vig&ecirc;ncia para proceder o RESGATE de seu volume de ativos digitais.</span>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <p className="bold">
                O USU&Aacute;RIO concorda com as disposi&ccedil;&otilde;es contidas neste instrumento, bem como ratifica as disposi&ccedil;&otilde;es contidas no TERMO E CONDI&Ccedil;&Otilde;ES DE
                USO.
              </p>
            </li>
            {addendum && (
              <li>
                <p className="bold">ADENDOS</p>
                <br />
                <div style={{ marginLeft: 30 }}>
                  <p style={{ whiteSpace: "pre-line" }}>{addendum}</p>
                </div>
              </li>
            )}
          </ol>
        </div>
      </>
    );
  }
}

export default LCContractBR;
