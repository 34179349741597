import React from "react";
import { TextField, FormHelperText, FormGroup } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { DatePicker } from "material-ui-pickers";

export const CustomDateOfBirthPicker = (props) => {
  const {
    input,
    className,
    name,
    label,
    disabled,
    autoComplete,
    autoFocus,
    inputProps,
    InputLabelProps,
    placeholder,
    disableFuture,
    disablePast,
    meta: { visited, error },
  } = props;

  const onChange = (date) => {
    date ? input.onChange(date.format("YYYY-MM-DD")) : input.onChange(null);
  };

  return (
    <FormGroup>
      <DatePicker
        value={input.value}
        {...input}
        openTo="year"
        format="DD/MM/YYYY"
        views={["year", "month", "day"]}
        invalidLabel={""}
        invalidDateMessage={""}
        //type="date"
        variant={"outlined"}
        margin={"normal"}
        className={className}
        id={name}
        label={<FormattedMessage id={label} />}
        autoComplete={autoComplete}
        onChange={onChange || input.onChange}
        disabled={disabled}
        autoFocus={autoFocus}
        inputProps={inputProps}
        InputLabelProps={InputLabelProps}
        placeholder={placeholder}
        disableFuture={disableFuture}
        disablePast={disablePast}
        error={visited && error ? true : false}
      />
      {visited && error && (
        <FormHelperText id="name-error-text" style={{ marginBottom: 16 }}>
          <span
            style={{
              color: "white",
              padding: 8,
              backgroundColor: "red",
              borderRadius: 10,
            }}
          >
            <FormattedMessage id={error} />
          </span>
        </FormHelperText>
      )}
    </FormGroup>
  );
};

export const CustomDatePicker = (props) => {
  const {
    input,
    className,
    name,
    label,
    disabled,
    autoComplete,
    InputProps,
    autoFocus,
    InputLabelProps,
    placeholder,
    disableFuture = false,
    disablePast = false,
    meta: { visited, error },
  } = props;

  const onChange = (date) => {
    date ? input.onChange(date.format("YYYY-MM-DD")) : input.onChange(null);
  };

  return (
    <FormGroup>
      <DatePicker
        value={input.value}
        {...input}
        disableFuture={disableFuture}
        disablePast={disablePast}
        openTo="year"
        format="DD/MM/YYYY"
        views={["year", "month", "day"]}
        invalidLabel={""}
        invalidDateMessage={""}
        //type="date"
        variant={"outlined"}
        margin={"normal"}
        className={className}
        id={name}
        label={<FormattedMessage id={label} />}
        autoComplete={autoComplete}
        onChange={onChange || input.onChange}
        disabled={disabled}
        autoFocus={autoFocus}
        InputProps={InputProps}
        InputLabelProps={InputLabelProps}
        placeholder={placeholder}
        error={visited && error ? true : false}
      />
      {visited && error && (
        <FormHelperText id="name-error-text" style={{ marginBottom: 16 }}>
          <span
            style={{
              color: "white",
              padding: 8,
              backgroundColor: "red",
              borderRadius: 10,
            }}
          >
            {error}
          </span>
        </FormHelperText>
      )}
    </FormGroup>
  );
};

export const CustomMonthPicker = (props) => {
  const {
    input,
    className,
    name,
    label,
    disabled,
    autoComplete,
    InputProps,
    autoFocus,
    InputLabelProps,
    placeholder,
    meta: { visited, error },
  } = props;

  const onChange = (date) => {
    date ? input.onChange(date.format("YYYY-MM-01")) : input.onChange(null);
  };

  return (
    <FormGroup>
      <DatePicker
        value={input.value}
        {...input}
        openTo="year"
        format="MMMM/YYYY"
        views={["year", "month"]}
        invalidLabel={""}
        invalidDateMessage={""}
        variant={"outlined"}
        margin={"normal"}
        className={className}
        id={name}
        label={<FormattedMessage id={label} />}
        autoComplete={autoComplete}
        onChange={onChange || input.onChange}
        disabled={disabled}
        autoFocus={autoFocus}
        InputProps={InputProps}
        InputLabelProps={InputLabelProps}
        placeholder={placeholder}
        error={visited && error ? true : false}
      />
      {visited && error && (
        <FormHelperText id="name-error-text" style={{ marginBottom: 16 }}>
          <span
            style={{
              color: "white",
              padding: 8,
              backgroundColor: "red",
              borderRadius: 10,
            }}
          >
            <FormattedMessage id={error} />
          </span>
        </FormHelperText>
      )}
    </FormGroup>
  );
};

const customInput = ({
  input,
  className,
  name,
  onClick,
  label,
  labelValues,
  select,
  type,
  multiline,
  disabled,
  autoComplete,
  InputProps,
  autoFocus,
  rows,
  onKeyUp,
  rowsMax,
  inputProps,
  children,
  InputLabelProps,
  errorValues,
  meta: { touched, error },
  maxLength,
  required,
}) => {
  if (maxLength) {
    inputProps = { ...inputProps, maxLength };
  }
  return (
    <FormGroup>
      <TextField
        {...input}
        variant={"outlined"}
        margin={"normal"}
        className={className}
        id={name}
        label={<FormattedMessage id={label} values={labelValues} />}
        select={select}
        autoComplete={autoComplete}
        type={type}
        multiline={multiline}
        onChange={input.onChange}
        onKeyUp={onKeyUp}
        disabled={disabled}
        onClick={onClick}
        autoFocus={autoFocus}
        rows={rows}
        children={children}
        rowsMax={rowsMax}
        inputProps={inputProps}
        InputProps={InputProps}
        InputLabelProps={InputLabelProps}
        error={touched && error ? true : false}
        maxLength={maxLength}
        required={required}
      />
      {touched && error && (
        <FormHelperText id="name-error-text" style={{ marginBottom: 16 }}>
          <span
            style={{
              color: "white",
              padding: 8,
              backgroundColor: "red",
              borderRadius: 10,
            }}
          >
            <FormattedMessage id={error} values={errorValues} />
            {/* Precisa ser repensado a estrutura pois o erro  já existia a algum tempo pelo que parece */}
            {/* <FormattedMessage id={error.props.children[0].props.id} values={errorValues}/> */}
          </span>
        </FormHelperText>
      )}
    </FormGroup>
  );
};

export default customInput;
