import { Button, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Menu, MenuItem, Tab, Tabs, Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import IconOpenInBrowser from "@material-ui/icons/OpenInBrowser";
import { withTheme } from "@material-ui/styles";
import moment from "moment-business-days";
import React, { Component, Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import * as actions from "../actions";
import { getPendingWithdrawals, getRedemptionPayment, getStatusRedeemContracts, getWaitingAuthWithdrawals, getWithdrawHistory } from "../actions";
import FormDialog from "../custom/FormDialog";
import HeaderPageItem from "../custom/HeaderPageItem";
import PageContainer, { CardPageItem, PageItem } from "../custom/Page";
import { addDays, difDateSignal, difDateSignal2, diffDatedays, dateFromServer } from "../util";
import { Roles } from "../util/";
import AdminWithdrawalPopUp from "./components/AdminWithdrawalPopUp";
import DetailHistoryWithdrawalPopUp from "./components/DetailHistoryWithdrawalPopUp";
import AbtcMaterialTable, { newColumn, newColumnBTC, newColumnCLPD, newColumnDate, newColumnText, newColumnUSDt, newColumnValue, newColumnMoney, newColumnBRZ } from "./components/TableList";

// Classe que define um filtro a ser aplicado a um array de Withdrawals.
class PartnerFilter {
  // Valor atual do filtro.
  value = "";
  // Nome do filtro, a ser mostrado na janela de edição dele.
  caption = "app.withdraw.destination";
  // Valores possíveis para esse filtro, cada um com um título e um valor.
  options = [
    { label: "app.all", value: "" },
    { label: "app.customer", value: "0" },
    { label: "app.partner", value: "1" },
    { label: "app.reaport", value: "2" },
    { label: "AltaVista", value: "3" },
  ];
  // Método que aplica o filtro a um array de Withdrawals. Assume que cada objeto Withdrawal possui pelo menos o campo partnerWithdraw.
  apply = (withdrawals) => withdrawals.filter((w) => this.value == "" || w.partnerWithdraw.toString() == this.value);
}

class DeadlineFilter {
  value = "";
  caption = "app.date.filter";
  options = [
    { label: "app.all", value: "" },
    { label: "app.delayed", value: "-1" },
    { label: "app.expiration.today", value: "0" },
    { label: "app.no.time", value: "1" },
  ];
  // Importante: o método abaixo assume que cada objeto Withdrawal possui pelo menos o campo daysToLimit,que não é padrão.
  // Este campo não vem do backend, precisa ser calculado no frontend.
  apply = (withdrawals) => withdrawals.filter((w) => this.value == "" || Math.sign(w.daysToLimit) == this.value);
}

class StatusFilter {
  value = "";
  caption = "app.status";
  options = [
    { label: "app.all", value: "" },
    { label: "app.enuns.APPROVED", value: "APPROVED" },
    { label: "app.enuns.CANCELED", value: "CANCELED" },
    { label: "app.enuns.REJECTED", value: "REJECTED" },
  ];
  // O método abaixo assume que cada objeto Withdrawal possui pelo menos o campo status.
  apply = (withdrawals) => withdrawals.filter((w) => this.value == "" || w.status == this.value);
}
// filtro pelo domicilio fiscal
class TaxResidenceFilter {
  value = "";
  caption = "app.taxResidence";
  options = [
    { label: "app.all", value: "" },
    { label: "app.taxResidence.Brasil", value: "Brasil" },
    { label: "app.taxResidence.Colômbia", value: "Colômbia" },
    { label: "app.taxResidence.Argentina", value: "Argentina" },
    { label: "app.taxResidence.Austrália", value: "Austrália" },
    { label: "app.taxResidence.Uruguai", value: "Uruguai" },
    { label: "app.taxResidence.Chile", value: "Chile" },
    { label: "app.taxResidence.Venezuela", value: "Venezuela" },
    { label: "app.taxResidence.México", value: "México" },
    { label: "app.taxResidence.Equador", value: "Equador" },
    { label: "app.taxResidence.Índia", value: "Índia" },
    { label: "app.taxResidence.Nova Zelândia", value: "Nova Zelândia" },
    { label: "app.taxResidence.Panamá", value: "Panamá" },
    { label: "app.taxResidence.EUA", value: "EUA" },
  ];
  apply = (withdrawals) => withdrawals.filter((w) => this.value == "" || w.account.user.taxResidence == this.value);
}

// Classe que armazena um conjunto de filtros e oferece o método para aplicar todos de uma vez.
// Os filtros são aplicados na ordem em que foram passados no contructor.
class FilterSet {
  // Armazena internamente o array de filtros.
  filters = [];
  // O constuctor recebe um array de filtros. Eles serão aplicados na ordem em que aparecerem no array.
  constructor(filters) {
    this.filters = filters;
  }
  // Aplica todos filtros, chamando o método apply de cada um sobre o array já filtrado pelo filtro anterior.
  apply = (withdrawals) => this.filters.reduce((withdrawals, filter) => filter.apply(withdrawals), withdrawals);
}

class HistoryWithdrawal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tabIndex: 0,
      originalData: [],
      filteredData: [],
      openDialogFilter: false,
      escondeBotao: false,
      dialogWithdrawal: false,
      dialogDetailWithdrawal: false,
      selectWithdrawal: null,
      currentPage: 0,
      filterTaxResidence: " ",
    };
  }

  filterSets = [
    new FilterSet([new PartnerFilter(), new DeadlineFilter(), new TaxResidenceFilter()]),
    new FilterSet([new PartnerFilter(), new StatusFilter(), new TaxResidenceFilter()]),
    new FilterSet([new PartnerFilter()]),
    new FilterSet([new DeadlineFilter()]),
  ];

  tablePaging = {
    paging: true,
    paginationType: "normal", // "stepped"
    pageSize: 10,
    pageSizeOptions: [10, 25, 50],
  };

  tableOptions = {
    paging: false,
    toolbar: false,
    draggable: false,
    doubleHorizontalScroll: true,
    headerStyle: {
      fontSize: 12,
      color: "#FFBE2D",
      backgroundColor: "unset",
      textAlignLast: "start",
      whiteSpace: "nowrap",
    },
    rowStyle: (rowDAta, index) => ({
      whiteSpace: "nowrap",
      backgroundColor: rowDAta.daysToLimit < 0 ? "#D4290D" : index % 2 ? this.props.theme.palette.secondary.light : this.props.theme.palette.secondary.dark,
      color: rowDAta.daysToLimit == 0 || rowDAta.daysToLimit == 1 ? "#D4290D" : rowDAta.daysToLimit == 2 ? "#FFFF00" : rowDAta.daysToLimit < 0 ? "width" : null,
    }),
  };

  tableActions = [
    (rowData) => ({
      icon: () => <IconOpenInBrowser color="primary" />,
      tooltip: <FormattedMessage id="app.open" />, // TODO: localização
      onClick: (e, rd) => {
        this.state.tabIndex == 0
          ? //abre os popups e salva o saque pra enviar pro popup
            this.setState({ dialogWithdrawal: true, selectWithdrawal: rd })
          : this.setState({
              dialogDetailWithdrawal: true,
              selectWithdrawal: rd,
            });
      },
    }),
  ];

  tableLocalization = {
    body: {
      emptyDataSourceMessage: "app.withdrawals.no",
    },
    header: {
      actions: "",
    },
  };

  tableColumnsPending = () => {
    return [
      // TODO: deixar apenas as colunas especificadas na 6tarefa. Se precisar de campos que não existem no objeto Withdrawal,
      // eles devem ser criados na momento que os saques são carregados, em loadTabData.
      newColumnText("id", "id"),
      newColumnText("app.name", "account.user.name"),
      newColumnText("app.email", "account.user.email"),
      newColumnText("app.taxResidence", "account.user.taxResidence"),
      newColumnDate("app.data.order", "createdAt"),
      newColumnDate("app.confirmed", "authorizeDate"),
      newColumn("app.limit.days", "daysToLimit", "numeric", "center"),
      newColumnMoney("app.amount.requested", "amount"),
      newColumnMoney("app.amount.payable", "payable"),
      newColumn("app.withdraw.destination", "partner", "string", "center"),
    ];
  };

  tableColumnsHistory = () => {
    return [
      // TODO: deixar apenas as colunas especificadas na 6tarefa. Se precisar de campos que não existem no objeto Withdrawal,
      // eles devem ser criados na momento que os saques são carregados, em loadTabData.
      newColumnText("id", "id"),
      newColumnText("app.name", "account.user.name"),
      newColumnText("app.email", "account.user.email"),
      newColumnText("app.taxResidence", "account.user.taxResidence"),
      newColumnDate("app.data.order", "createdAt"),
      newColumnDate("app.confirmed", "authorizeDate"),
      newColumnText("app.status", "statusWithdraw"),
      newColumnDate("app.enuns.APPROVED", "withdrawDate"),
      newColumnDate("app.rejected", "dateRejected"),
      newColumnDate("app.enuns.CANCELED", "dateCanceled"),
      newColumn("app.limit.days", "daysToLimit", "numeric", "center"),
      newColumnMoney("app.amount.requested", "amount"),
      newColumn("app.withdraw.destination", "partner", "string", "center"),
    ];
  };

  tableColumnsIntentions = [
    // TODO: deixar apenas as colunas especificadas na 6tarefa. Se precisar de campos que não existem no objeto Withdrawal,
    // eles devem ser criados na momento que os saques são carregados, em loadTabData.
    newColumnText("id", "id"),
    newColumnText("app.name", "account.user.name"),
    newColumnText("app.email", "account.user.email"),
    newColumnText("app.taxResidence", "account.user.taxResidence"),
    newColumnDate("app.data.order", "createdAt"),
    newColumnMoney("app.amount.requested", "amount"),
    newColumnText("app.status", "statusWithdraw"),
    newColumn("app.withdraw.destination", "partner", "string", "center"),
  ];

  tableColumnsRedemptions = [
    // TODO: deixar apenas as colunas especificadas na 6tarefa. Se precisar de campos que não existem no objeto Withdrawal,
    // eles devem ser criados na momento que os saques são carregados, em loadTabData.
    newColumnText("id", "id"),
    newColumnText("app.name", "user.name"),
    newColumnText("app.email", "user.email"),
    newColumnText("app.taxResidence", "user.taxResidence"),
    newColumnMoney("app.amount.requested", "amount"),
    newColumnText("app.redemption", "redeemText"),
    newColumnText("app.phase", "phaseText"), //<FormattedMessage id={`app.enuns.${customer.documentType}`} />
    newColumnText("app.status", "statusText"),
    newColumnDate("app.payday", "paymentDate"),
  ];

  componentDidMount() {
    const { getConfig } = this.props;
    getConfig();
    this.setState({
      escondeBotao: this.regraBotao(),
      selectWithdrawal: this.state.selectWithdrawal ? this.state.selectWithdrawal : 0,
    });
    // As duas linhas abaixo servem para obter o índice da aba a ser mostrada a partir da URL (número após o "?").
    let tab = parseInt(this.props.location.search.substring(1));
    tab = isNaN(tab) ? 0 : tab;
    // Carrega os dados para a aba do índice informado.
    this.loadTabData(tab);
  }

  /*
  * 2 funções,
    Uma para saber quantos contratos expiram hoje 
    Outra para saber o valor total delas
  */
  expiresToday = (data) => {
    const { config } = this.props;
    //redemptionDelay
    let count = 0;
    data.forEach((w) => {
      //o numero de dias deve vir do config
      if (diffDatedays(w.updatedAt) == config.redemptionDelay) {
        count++;
      }
    });
    return count;
  };

  totalExpiredToday = (data) => {
    const { config } = this.props;
    let amount = 0;
    data.forEach((w) => {
      //o numero de dias deve vir do config
      if (diffDatedays(w.updatedAt) == config.redemptionDelay) {
        amount += w.amount;
      }
    });
    return amount;
  };

  regraBotao = () => {
    const { currentUser } = this.props;
    const { tabIndex } = this.state;
    let value = false;
    if (tabIndex == 1) {
      value = false;
    } else if (tabIndex == 3) {
      value = true;
    } else if (currentUser.role == Roles.Auditor) {
      value = true;
    } else {
      value = false;
    }
    return value;
  };

  loadTabData = (tab) => {
    this.tablePaging.initialPage = this.state.currentPage;
    // Armazena o índice da aba em tabIndex e liga a flag que indica carregamento.
    this.setState({
      tabIndex: tab,
      loading: true,
    });
    // Seleciona a função de carga, conforme a aba indicada.
    const getWithdrawalsFunc =
      tab == 0 ? getPendingWithdrawals : tab == 1 ? getWithdrawHistory : tab == 2 ? getWaitingAuthWithdrawals : tab == 3 ? getStatusRedeemContracts : getWaitingAuthWithdrawals;

    // Carrega os dados do backend usando a função selecionada acima.
    getWithdrawalsFunc().then((data) => {
      let useData = data;
      let dataFilter = data;

      if (tab != 3) {
        dataFilter = data.filter((d) => d.account.user.taxResidence == this.props.currentUser.taxResidence);
      } else {
        dataFilter = data.filter((d) => d.user.taxResidence == this.props.currentUser.taxResidence);
      }
      if (this.props.currentUser.role == "ADMIN" || this.props.currentUser.role == "FINANCE_MANAGER") {
        useData = data;
      } else {
        useData = dataFilter;
      }

      useData.forEach((w) => {
        w.limitDate = addDays(w.authorizeDate ? w.authorizeDate : w.createdAt, 4);
        w.daysToLimit = tab == 0 ? difDateSignal(w.limitDate) : difDateSignal2(w.authorizeDate ? w.authorizeDate : w.createdAt, w.limitDate);
        w.payable = w.amount - w.fee;
        w.partner =
          w.partnerWithdraw === 0 ? (
            <>
              {w.partnerwithdrawtype == 0 ? (
                <FormattedMessage id="BTC/BTC" />
              ) : w.partnerwithdrawtype == 1 ? (
                <FormattedMessage id="USDT/TRX" />
              ) : w.partnerwithdrawtype == 2 ? (
                <FormattedMessage id="USDT/ETH" />
              ) : null}
            </>
          ) : w.partnerWithdraw === 1 ? (
            <FormattedMessage id="app.withdraw.partner" />
          ) : w.partnerWithdraw === 2 ? (
            <Typography variant="caption" color="primary">
              <FormattedMessage id="app.reaport" />
            </Typography>
          ) : w.partnerWithdraw === 3 ? (
            <>AltaVista</>
          ) : w.partnerWithdraw === 4 ? (
            <FormattedMessage id="app.withdraw.bankingchannel.optionlabel" />
          ) : null;

        w.authorizeDate = w.authorizeDate == null ? dateFromServer(w.createdAt) : dateFromServer(w.authorizeDate);
        w.statusWithdraw = <FormattedMessage id={`app.enuns.${w.status}`} />;
        w.withdrawDate = w.withdrawDate ? dateFromServer(w.withdrawDate) : " ";
        w.dateRejected = w.status == "REJECTED" ? dateFromServer(w.updatedAt) : null;
        w.dateCanceled = w.status == "CANCELED" ? dateFromServer(w.updatedAt) : null;
        w.reaport = w.partnerWithdraw == 2 ? <FormattedMessage id="app.reaport" /> : null;
        // variaveis do Resgate
        w.phaseText = <FormattedMessage id={`app.enuns.${w.phase}`} />;
        w.statusText = <FormattedMessage id={`app.enuns.${w.status}`} />;
        w.redeemText = <FormattedMessage id={`app.enuns.${w.statusRedeemPaid}`} />;
        w.paymentDate = <CircularProgress size={10} />;
        if (tab == 3)
          getRedemptionPayment(w.id).then((r) => {
            w.paymentDate = dateFromServer(r.paymentDate);
            this.forceUpdate();
          });
      });
      // Armazena os dados no estado.
      this.setState({
        // Array original de Withdrawals (sem filtrar).
        originalData: useData,
        // Array filtrado pela aplicação do conjunto de filtros correspodnente à aba selecionada.
        filteredData: this.filterSets[tab].apply(useData),
        filterTaxResidence: tab > 1 ? " " : this.filterSets[tab].filters[2].value, // Para pegar o taxresidence e formatar a coluna
        loading: false, // Desliga a flag que indica carregamento.
        //fecha os popups
        dialogWithdrawal: false,
        dialogDetailWithdrawal: false,
        escondeBotao: this.regraBotao(),
      });
    });
  };

  SummaryPending = ({}) => {
    // Sumário para a aba de pendentes.
    // TODO: revisar se mostra todos dados pedidos na tarefa.
    const { originalData, filteredData, filterTaxResidence } = this.state;
    const dueToday = originalData.filter((w) => w.daysToLimit == 0);
    const late = originalData.filter((w) => w.daysToLimit < 0);

    const newDataItem = (label, arr) => ({
      label,
      invalidTotal: <FormattedMessage id="app.withdraw.invalidTotal" />,
      count: arr.length,
      valueTotalUSDT: arr.reduce((sum, w) => sum + w.valueWithFee, 0),
    });
    return (
      <>
        <CardPageItem double>
          <CardContent>
            <AbtcMaterialTable
              data={[
                //newDataItem(<FormattedMessage id="app.withdrawals.all" />, originalData),
                newDataItem(<FormattedMessage id="app.withdrawls.listed" />, filteredData),
              ]}
              columns={[
                newColumnText(<FormattedMessage id="app.description" />, "label"),
                newColumn(<FormattedMessage id="app.amount" />, "count", "numeric", "center"),
                // newColumnBTC(<FormattedMessage id="app.total" />, "valueTotalBTC"),
                filterTaxResidence == ""
                  ? newColumn(<FormattedMessage id="app.total" />, "invalidTotal", "text", "right")
                  : filterTaxResidence == "Colômbia" || filterTaxResidence == "EUA"
                  ? newColumnUSDt("app.total", "valueTotalUSDT")
                  : filterTaxResidence == "Chile"
                  ? newColumnCLPD("app.total", "valueTotalUSDT")
                  : filterTaxResidence == "Brasil"
                  ? newColumnBRZ("app.total", "valueTotalUSDT")
                  : newColumnValue("app.total", "valueTotalUSDT"),
              ]}
              options={{
                ...this.tableOptions,
                sorting: false,
                padding: "dense",
                rowStyle: { whiteSpace: "nowrap" },
              }}
              user={this.props.currentUser}
            />
          </CardContent>
        </CardPageItem>
        <CardPageItem double>
          <CardContent>
            <AbtcMaterialTable
              data={[
                //  newDataItem(<FormattedMessage id="app.expiration.today" />, dueToday),
                newDataItem(<FormattedMessage id="app.delayed" />, late),
              ]}
              columns={[
                newColumnText(<FormattedMessage id="app.description" />, "label"),
                newColumn(<FormattedMessage id="app.amount" />, "count", "numeric", "center"),
                filterTaxResidence == ""
                  ? newColumn(<FormattedMessage id="app.total" />, "invalidTotal", "text", "right")
                  : filterTaxResidence == "Colômbia" || filterTaxResidence == "EUA"
                  ? newColumnUSDt("app.total", "valueTotalUSDT")
                  : filterTaxResidence == "Chile"
                  ? newColumnCLPD("app.total", "valueTotalUSDT")
                  : filterTaxResidence == "Brasil"
                  ? newColumnBRZ("app.total", "valueTotalUSDT")
                  : newColumnValue("app.total", "valueTotalUSDT"),
              ]}
              options={{
                ...this.tableOptions,
                sorting: false,
                padding: "dense",
                rowStyle: { whiteSpace: "nowrap" },
              }}
              user={this.props.currentUser}
            />
          </CardContent>
        </CardPageItem>
      </>
    );
  };

  SummaryHistory = () => {
    // Sumário para a aba de histórico.
    // TODO: revisar se mostra todos dados pedidos na tarefa.
    const { originalData, filteredData, filterTaxResidence } = this.state;
    const newDataItem = (label, arr) => ({
      label,
      invalidTotal: <FormattedMessage id="app.withdraw.invalidTotal" />,
      count: arr.length,
      valueTotalUSDT: arr.reduce((sum, w) => sum + w.valueWithFee, 0),
    });
    return (
      <>
        <CardPageItem>
          <CardContent>
            <AbtcMaterialTable
              data={[
                //newDataItem("Todos saques", originalData),
                newDataItem("Saques listados", filteredData),
              ]}
              columns={[
                newColumnText("app.description", "label"),
                newColumn("app.amount", "count", "numeric", "center"),
                filterTaxResidence == ""
                  ? newColumn(<FormattedMessage id="app.total" />, "invalidTotal", "text", "right")
                  : filterTaxResidence == "Colômbia" || filterTaxResidence == "EUA"
                  ? newColumnUSDt("app.total", "valueTotalUSDT")
                  : filterTaxResidence == "Chile"
                  ? newColumnCLPD("app.total", "valueTotalUSDT")
                  : filterTaxResidence == "Brasil"
                  ? newColumnBRZ("app.total", "valueTotalUSDT")
                  : newColumnValue("app.total", "valueTotalUSDT"),
              ]}
              options={{
                ...this.tableOptions,
                sorting: false,
                padding: "dense",
                rowStyle: { whiteSpace: "nowrap" },
              }}
              user={this.props.currentUser}
            />
          </CardContent>
        </CardPageItem>
      </>
    );
  };

  SummaryRedemption = () => {
    // Sumário para a aba de resgates.
    // TODO: revisar se mostra todos dados pedidos na tarefa.
    const { originalData, filteredData, filterTaxResidence } = this.state;
    const newDataItem = (label, arr) => ({
      label,
      invalidTotal: <FormattedMessage id="app.withdraw.invalidTotal" />,
      count: arr.length,
      valueTotalUSDT: arr.reduce((sum, w) => sum + w.amount, 0),
    });
    const newDataItemExpired = (label, arr) => ({
      label,
      count: this.expiresToday(arr),
      valueTotalUSDT: this.totalExpiredToday(arr),
    });
    return (
      <>
        <CardPageItem>
          <CardContent>
            <AbtcMaterialTable
              data={[
                //newDataItem("Todos resgates", originalData),
                newDataItem("Resgates listados", filteredData),
              ]}
              columns={[
                newColumnText("app.description", "label"),
                newColumn("app.amount", "count", "numeric", "center"),
                //ta "impossivel calcular" sempre pois os resgates ainda não tem filtro.
                newColumn(<FormattedMessage id="app.total" />, "invalidTotal", "text", "right"),
                // : filterTaxResidence == "Colômbia" || filterTaxResidence == "EUA"
                // ? newColumnUSDt("app.total", "valueTotalUSDT")
                // : filterTaxResidence == "Chile"
                // ? newColumnCLPD("app.total", "valueTotalUSDT")
                // : filterTaxResidence == "Brasil"
                // ? newColumnBRZ("app.total", "valueTotalUSDT")
                // : newColumnValue("app.total", "valueTotalUSDT"),
              ]}
              options={{
                ...this.tableOptions,
                sorting: false,
                padding: "dense",
                rowStyle: { whiteSpace: "nowrap" },
              }}
              user={this.props.currentUser}
            />
          </CardContent>
        </CardPageItem>
      </>
    );
  };

  TabContent = ({ columns, Summary }) => {
    // Gera o conteúdo da aba, mostrando o sumário (se foi informado) e a tabela com os dados do estado e colunas informadas.
    // OBSERVAÇÃO: não aplica cores às linhas da tabela, porque o código ficou genéerico, usado para todas abaas e também
    // porque a de cada linha é dada via configuração (objeto tableOptions) e não via array de dados.
    // TODO: criar uma forma de personalizar cada linha ou célula conforme os dados apresentados. Mantive as regras de cor comentadas abaixo.

    return (
      <>
        {Summary && <Summary />}

        <CardPageItem>
          <CardContent>
            <AbtcMaterialTable
              data={this.state.filteredData}
              columns={columns}
              onChangePage={(e) => this.setState({ currentPage: e })}
              options={{ ...this.tableOptions, ...this.tablePaging }}
              user={this.props.currentUser}
              actions={this.state.escondeBotao == false ? this.tableActions : null}
              localization={this.tableLocalization}
            />
          </CardContent>
        </CardPageItem>
      </>
    );
  };

  render() {
    const { tabIndex, loading, selectWithdrawal } = this.state;
    if (loading) return <CircularProgress style={{ marginTop: "25%" }} />;
    return (
      <>
        <PageContainer full>
          <HeaderPageItem title="app.withdrawals" onMore={tabIndex == 3 ? false : (e) => this.setState({ menu: e.target })}>
            <Menu
              keepMounted
              anchorEl={this.state.menu}
              open={Boolean(this.state.menu)}
              onClose={() => {
                this.setState({ menu: null });
              }}
              component="li"
            >
              <MenuItem button onClick={() => this.setState({ openDialogFilter: true, menu: null })}>
                <FormattedMessage id="app.filter" />
              </MenuItem>
            </Menu>
          </HeaderPageItem>
          <PageItem>
            <Tabs
              style={{ height: 25, maxHeight: 25 }}
              indicatorColor="primary"
              textColor="primary"
              value={tabIndex}
              onChange={(event, value) => {
                this.loadTabData(value);
              }}
              centered
            >
              <Tab label={<FormattedMessage id="app.pending" onClick={() => this.setState({ tabIndex: 0 })} />} />
              <Tab label={<FormattedMessage id="app.camel.history" onClick={() => this.setState({ tabIndex: 1 })} />} />
              <Tab label={<FormattedMessage id="app.intentions" onClick={() => this.setState({ tabIndex: 2 })} />} />
              <Tab label={<FormattedMessage id="app.redemptions" onClick={() => this.setState({ tabIndex: 3 })} />} />
            </Tabs>
          </PageItem>
          {loading ? (
            <div style={{ width: "100%", textAlign: "center", paddingTop: 50 }}>
              <CircularProgress />
            </div>
          ) : (
            <>
              {tabIndex === 0 && <this.TabContent columns={this.tableColumnsPending()} Summary={this.SummaryPending} />}
              {tabIndex === 1 && <this.TabContent columns={this.tableColumnsHistory()} Summary={this.SummaryHistory} />}
              {tabIndex === 2 && <this.TabContent columns={this.tableColumnsIntentions} />}
              {tabIndex === 3 && <this.TabContent columns={this.tableColumnsRedemptions} Summary={this.SummaryRedemption} />}
            </>
          )}
        </PageContainer>

        <FilterDialog
          title={<FormattedMessage id="app.filter" />}
          open={this.state.openDialogFilter}
          onClose={() => this.setState({ openDialogFilter: false })}
          onAction={(filterSet) => {
            this.filterSets[this.state.tabIndex] = filterSet;
            this.setState({ openDialogFilter: false });
            this.loadTabData(this.state.tabIndex);
          }}
          onChange={() => this.forceUpdate()}
          filterSet={this.filterSets[this.state.tabIndex]}
        />
        {/* Popup de saque (pendentes) */}
        <FormDialog
          title="app.withdraw.nr"
          values={selectWithdrawal.id}
          open={this.state.dialogWithdrawal}
          variant="secondary"
          noHeader
          onClose={() => {
            this.loadTabData(this.state.tabIndex);
          }}
        >
          <DialogContent>
            <AdminWithdrawalPopUp
              selectWithdrawal={this.state.selectWithdrawal}
              onActionComplete={() => {
                this.loadTabData(this.state.tabIndex);
              }}
            ></AdminWithdrawalPopUp>
          </DialogContent>
        </FormDialog>
        {/* Popup de detalhes do saque (historico) */}
        <FormDialog
          title="app.withdraw.nr"
          values={selectWithdrawal.id}
          open={this.state.dialogDetailWithdrawal}
          variant="secondary"
          noHeader
          onClose={() => {
            this.loadTabData(this.state.tabIndex);
          }}
        >
          <DialogContent>
            <DetailHistoryWithdrawalPopUp
              selectWithdrawal={this.state.selectWithdrawal}
              onActionComplete={() => {
                this.loadTabData(this.state.tabIndex);
              }}
            ></DetailHistoryWithdrawalPopUp>
          </DialogContent>
        </FormDialog>
      </>
    );
  }
}

const FilterDialog = ({ open, onClose, title, children, onChange, onAction, filterSet }) => (
  // Componente que mostra um diálogo de edição de conjunto de filtros (FilterSet).
  // Basicamente, mostra controles do tipo select para cada filtro do conjunto.
  // Recebe o conjunto via props e devolve o conjunto modificado em onAction.
  <Dialog fullWidth={true} maxWidth="sm" open={open} onClose={onClose}>
    <DialogTitle>{title}</DialogTitle>

    <DialogContent>
      {filterSet.filters.map((filter) => (
        <Fragment key={filter.caption}>
          <TextField
            select
            label={<FormattedMessage id={filter.caption} />}
            value={filter.value}
            onChange={(e) => {
              filter.value = e.target.value;
              onChange(filter);
            }}
            style={{ width: "100%", paddingTop: "5%" }}
          >
            {filter.options.map((o) => (
              <MenuItem key={o.label} value={o.value}>
                <FormattedMessage id={o.label} />
              </MenuItem>
            ))}
          </TextField>
          <Divider />
        </Fragment>
      ))}
    </DialogContent>

    <DialogActions>
      <Button type="submit" onClick={(e) => onAction(filterSet)} color="primary">
        <FormattedMessage id="app.filter" />
      </Button>
      <Button onClick={onClose} color="default">
        <FormattedMessage id="app.close" />
      </Button>
    </DialogActions>
  </Dialog>
);

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
    config: state.config,
  };
}

export default compose(
  connect(mapStateToProps, actions),
  reduxForm({
    form: "dashboardForm",
    enableReinitialize: true,
  })
)(withTheme(HistoryWithdrawal));
