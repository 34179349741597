import React from "react";
import { Dialog, DialogTitle, DialogContent, Button, DialogActions } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

export const GenericDialog = ({ open, onCancel, title, children, onOk }) => (
	<Dialog open={open} onClose={onCancel}>
		<DialogTitle>{title}</DialogTitle>
		<DialogContent>{children}</DialogContent>
		<DialogActions>
			<Button onClick={onOk} color="primary">
				<FormattedMessage id="app.yes" />
			</Button>
			<Button onClick={onCancel} color="primary">
				<FormattedMessage id="app.no" />
			</Button>
		</DialogActions>
	</Dialog>
);
