import React, { Component } from "react";
import {
  Typography,
  IconButton,
  CircularProgress,
  Paper,
  withTheme
} from "@material-ui/core";
import { connect } from "react-redux";
import * as actions from "../actions";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import { withRouter } from "react-router-dom";
import { getContract, getContractMonthHistory } from "../actions/Contract";
import moment from "moment";
import Moment from "react-moment";
import {
  LineChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { FormattedMessage } from "react-intl";
import { ArrayisEmpty, isEmptyObject } from "../util"

class CustomerContract extends Component {
  state = {
    id: null,
    contract: {},
    month: {},
    menuAnchor: undefined,
    tabIndex: 0,
    originalMonth:null,
    currentMonth:null,
  };
  componentDidMount() {
    let date = moment((moment().date() + "/" + this.props.match.params.month + "/" + this.props.match.params.year), "DD/MM/YYYY");
    this.setState({ id: this.props.match.params.id,currentMonth: date, originalMonth: date})
    this.loadContractData(this.props.match.params.id, date);
  }
  ;

  loadContractData = (id, month) => {
    this.setState({ month: {} });
    let temp, temp2;
    getContract(id)
      .then(data => {
        this.setState({ contract: data });
      })
    getContractMonthHistory(id, month.month() + 1, month.year())
      .then(data2 => {
            //this.currentMonth = month;
            data2.daysInTotal = month.daysInMonth();
            this.setState({ month: data2, currentMonth: month });
        });
  };


  nextMonth() {
      this.loadContractData(
        this.state.id,
        this.state.currentMonth.add(1, "M")
      );
  }

  previousMonth() {
    this.loadContractData(
      this.state.id,
      this.state.currentMonth.subtract(1, "M")
    );
  }

  hasNext() {
    if (this.state.currentMonth.isBefore(moment().startOf("month"))) {
      
    }
    return this.state.currentMonth.isBefore(moment().startOf("month"));
  }

  hasPrevious() {
    if (this.state.contract) {
      //return this.setState({ currentMonth: this.state.currentMonth.isAfter(moment(this.state.contract.initialDate).endOf("month"))})
      return this.state.currentMonth.isAfter(
        moment(this.state.contract.initialDate).endOf("month")
      );
    }
  }

  isLoading() {
    const { contract, month } = this.state;
    if ( isEmptyObject(month)|| isEmptyObject(contract)) {
      return true;
    }
    return false;
  }
  render() {
    const {
      theme: { palette }
    } = this.props;
    const { contract, month } = this.state;
    if (this.isLoading()) {
      return <CircularProgress style={{ marginTop: "25%" }} />;
    } 
    let chartData = [];
        let historyFirst = !ArrayisEmpty(month.history)? moment(month.history[0].createdAt).date(): moment();
        let ok = false;
        let dEstim = 0;
        for (let d = 0; d < month.daysInTotal; d++) {
          const historyIndex = d - historyFirst + 1;
          ok = historyIndex >= 0;
          if (ok) {
            dEstim++;
            chartData.push({
              id: contract.id,
              day: d + 1,
              estim: month.isCurrent
                ? contract.yield.val -
                  month.total.val +
                  (month.estimated.val / month.daysInTotal) * dEstim
                : null,
              yield_per:
                historyIndex >= 0 && historyIndex < month.history.length
                  ? month.history[historyIndex].yield.per
                  : null,
              balance:
                historyIndex >= 0 && historyIndex < month.history.length
                  ? month.history[historyIndex].balance
                  : null
            });
          }
        }
      return (
        <Paper
          square
          elevation={0}
          style={{
            position: "absolute",
            zIndex: 9999,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%"
          }}
        >
          <div style={{ maxHeight: 48 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <IconButton
                onClick={e => this.previousMonth()}
                disabled={!this.hasPrevious()}
              >
                <ChevronLeftIcon />
              </IconButton>

              <Typography variant="h6">
                <FormattedMessage id="app.graph.of" />
                <Moment date={this.state.currentMonth} format="MMMM/YYYY" />
              </Typography>

              <IconButton
                onClick={e => this.nextMonth()}
                disabled={!this.hasNext()}
              >
                <ChevronRightIcon />
              </IconButton>
            </div>
          </div>
          {/* Não pegou a tradução */}
          <Typography
            component="div"
            variant="caption"
            color="secondary"
            style={{ flexGrow: 1 }}
          >
            <div style={{ height:'90vh' , width:'100vw' }}  > 
              <div style ={{ height:'100%',  width:'100%' }} > 
                <ResponsiveContainer width={"100%"} height={"100%"}>
                  <LineChart
                      data={chartData}
                      margin={{ top: 20, right: 20, left: 20, bottom: 20 }}
                      style={{ width:"100%", height:"98%"}}
                    >
                      <XAxis dataKey="day" />
                      <YAxis yAxisId="per" width={20} domain={[0, 0.5]} />
                      <YAxis
                        yAxisId="val"
                        width={20}
                        orientation="right"
                        domain={["dataMin", "dataMax"]}
                      />
                      <Tooltip />
                      <Line
                        yAxisId="per"
                        type="monotone"
                        dataKey="yield_per"
                        name="Acréscimo"
                        stroke={palette.text.secondary}
                        dot={false}
                      />
                      <Line
                        yAxisId="val"
                        type="monotone"
                        dataKey="estim"
                        name="Saldo"
                        stroke={palette.text.secondary}
                        strokeDasharray="5 5"
                        dot={false}
                      />
                      <Line
                        yAxisId="val"
                        type="monotone"
                        dataKey="balance"
                        name="Saldo"
                        stroke={palette.primary.main}
                        dot={false}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>
          </Typography>

          <IconButton
            size="small"
            onClick={e => this.props.history.goBack()}
            style={{ position: "absolute", right: 0, bottom: 0 }}
          >
            <FullscreenIcon htmlColor={palette.text.secondary} />
          </IconButton>
        </Paper>
      );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me
  };
}

export default withTheme(
  withRouter(connect(mapStateToProps, actions)(CustomerContract))
);
