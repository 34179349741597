import { Button, Card, CardContent, IconButton, Typography } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import React, { Component, Fragment } from "react";
import { FormattedMessage } from "react-intl";
import Moment from "react-moment";
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import * as actions from "../actions";
import { getAllOperations } from "../actions";
import performRequest from "../configAxios";
import FormDialog from "../custom/FormDialog";
import HeaderPageItem from "../custom/HeaderPageItem";
import PageContainer, { CardPageItem } from "../custom/Page";
import FormMonthlyOperation from "./components/FormMonthlyOperation";
import { QuestionDialog } from "./components/QuestionDialog";

class AdminFinancial extends Component {
  state = {
    op: false,
    operations: [],
    modal: false,
    deleted: false,
    showDeleteModal: false,
  };

  /*
  TODO: Descobrir o que faz. Provavelmente deve ser removido (incluindo a contraparte no backend).
  liberar = () => {
    const { showErrorMessage, showSuccessMessage } = this.props;
    freeBalance()
      .then(response => {
        showSuccessMessage("app.balance.unavailable");
      })
      .catch(e => {
        showErrorMessage(e.response ? e.response.data.message : e);
      });
  };
*/

  componentDidMount() {
    this.loadOp();
  }

  loadOp = () => {
    getAllOperations().then((data) => {
      this.setState({ operations: data, op: false });
    });
  };

  /*
  TODO: Descobrir o que faz. Provavelmente deve ser removido (incluindo a contraparte no backend).
  delete = id => {
    const { showErrorMessage } = this.props;

    delOperation(id)
      .then(response => {
        this.setState({ showDeleteModal: false, selectedType: null });
        this.loadOp();
      })
      .catch(e => {
        showErrorMessage(e.response ? e.response.data.message : e);
      });
  };
*/

  runDailyPayment = () => {
    this.setState({ filling: true });
    performRequest("POST", "/contract-statement/runDailyPayment", {}, true).then((data) => {
      console.log(data);
    });
  };

  runTodaysPayments = () => {
    this.setState({ filling: true });
    performRequest("POST", "/payment/runTodaysPayments", {}, true).then((data) => {
      console.log(data);
    });
  };

  runVerifyExpiredContracts = () => {
    this.setState({ filling: true });
    performRequest("POST", "/contract-statement/runVerifyExpiredContracts", {}, true).then((data) => {
      console.log(data);
    });
  };

  runConsultantPayments = () => {
    this.setState({ filling: true });
    performRequest("POST", "/payment/runConsultantPayments", {}, true).then((data) => {
      console.log(data);
    });
  };

  testDailyYield = () => {
    this.setState({ filling: true });
    performRequest("POST", "/contract-statement/testDailyYield/51", {}, true).then((data) => {
      console.log(data);
    });
  };

  runRecalculateContracts = () => {
    this.setState({ filling: true });
    performRequest("GET", "/user/runRecalculateContracts", {}, true).then((data) => {
      console.log(data);
    });
  };

  render() {
    return (
      <PageContainer>
        <HeaderPageItem title="app.financeActions" showBackButton destination="/more" />
        <CardPageItem raised>
          <CardContent>
            <Typography gutterBottom color="primary">
              <FormattedMessage id="app.company.monthly.ceiling" />
            </Typography>
            <Button
              fullWidth
              id="bSubmitTypeForm"
              variant="outlined"
              color="default"
              onClick={() => this.setState({ op: true })}
              style={{ marginBottom: 16 }}
            >
              <FormattedMessage id="app.newReport" />
            </Button>
            <Typography variant="caption" color="textPrimary">
              {this.state.operations && (
                <Typography variant="caption" color="textPrimary">
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td align="center">
                          <FormattedMessage id="app.ceiling.value" />
                        </td>
                        <td align="center">
                          <FormattedMessage id="app.date" />
                        </td>
                      </tr>
                      {this.state.operations.map((o) => (
                        <Fragment key={o.id}>
                          <tr style={{ color: "textSecondary" }}>
                            <td align="center" style={{ color: "primary" }}>
                              <Typography variant="caption" color="textPrimary">
                                {o.capacity}
                              </Typography>
                            </td>
                            <td align="center" style={{ color: "primary" }}>
                              <Typography variant="caption" color="textPrimary">
                                <Moment date={o.operationDate} format="MMMM/YYYY" />
                              </Typography>
                            </td>

                            <td align="right">
                              <IconButton
                                onClick={(e) => {
                                  this.setState({ op: true, selectedType: o });
                                }}
                              >
                                <Edit />
                              </IconButton>
                            </td>
                            <td align="right">
                              <IconButton
                                onClick={(e) => {
                                  this.setState({
                                    showDeleteModal: true,
                                    selectedType: o,
                                  });
                                }}
                              >
                                <Delete />
                              </IconButton>
                            </td>
                          </tr>
                        </Fragment>
                      ))}
                    </tbody>
                  </table>
                </Typography>
              )}
            </Typography>
          </CardContent>
        </CardPageItem>

        <CardPageItem>
          <Typography gutterBottom color="error">
            ATENÇÃO: só utilize os recursos abaixo se souber exatamente o que está fazendo!
          </Typography>
          <Typography variant="h6">Executar rotinas diárias manualmente.</Typography>

          <Card>
            <CardContent>
              <Typography gutterBottom color="primary">
                Acrescentar rendimento do dia aos contratos
              </Typography>
              <Typography gutterBottom variant="body2">
                runDailyPayment
              </Typography>
              <Typography gutterBottom variant="body2">
                Esta rotina calcula e paga o valor do rendimento de hoje para todos contratos. O valor é calculado
                conforme o pecentual alvo informado no investimento associado a cada contrato.
              </Typography>
              <Typography gutterBottom variant="body2">
                <b>Obs.:</b> não há problema em executar essa rotina mais de uma vez no dia. Mas <b>atenção:</b> ela
                pode gerar valor diferente no extrato do contrato, causando confusão para o cliente que já viu o valor
                anterior.
              </Typography>
              <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "space-between", marginTop: 8 }}>
                <Typography gutterBottom variant="body2">
                  Horário de execução automática: <b>22h</b> diário
                </Typography>
                <Button variant="outlined" onClick={this.runDailyPayment}>
                  Executar agora
                </Button>
              </div>
            </CardContent>
          </Card>

          <Card style={{ marginTop: 8 }}>
            <CardContent>
              <Typography gutterBottom color="primary">
                Realizar pagamentos agendados até hoje
              </Typography>
              <Typography gutterBottom variant="body2">
                runTodaysPayments
              </Typography>
              <Typography gutterBottom variant="body2">
                Esta rotina paga os pagamentos agendados para a data de hoje, ou anterior, que estejam ainda nem aberto
                (PAYED = false).
              </Typography>
              <Typography gutterBottom variant="body2">
                <b>Obs.:</b> não há problema em executar essa rotina mais de uma vez no dia. Os pagamentos são marcados
                com PAYED = true e não serão pagos novamente.
              </Typography>
              <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "space-between", marginTop: 8 }}>
                <Typography gutterBottom variant="body2">
                  Horário de execução automática: <b>00h30</b> diário
                </Typography>
                <Button variant="outlined" onClick={this.runTodaysPayments}>
                  Executar agora
                </Button>
              </div>
            </CardContent>
          </Card>

          <Card style={{ marginTop: 8 }}>
            <CardContent>
              <Typography gutterBottom color="primary">
                Fechar contratos terminados
              </Typography>
              <Typography gutterBottom variant="body2">
                runVerifyExpiredContracts
              </Typography>
              <Typography gutterBottom variant="body2">
                Esta rotina verifica os contratos identificando aqueles no estado ativo cuja data de vencimento já
                passou, terminando eles, ou seja, ficam prontos para pedido de resgate pelo cliente.
              </Typography>
              <Typography gutterBottom variant="body2">
                <b>Obs.:</b> não há problema em executar essa rotina mais de uma vez no dia. Os contratos já terminaos
                por ela serão ignorados, visto que não estão mais no estado ativo.
              </Typography>
              <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "space-between", marginTop: 8 }}>
                <Typography gutterBottom variant="body2">
                  Horário de execução automática: <b>00h</b> diário
                </Typography>
                <Button variant="outlined" onClick={this.runVerifyExpiredContracts}>
                  Executar agora
                </Button>
              </div>
            </CardContent>
          </Card>

          <Card style={{ marginTop: 8 }}>
            <CardContent>
              <Typography gutterBottom color="primary">
                Gerar pagamentos de comissões para o mês atual
              </Typography>
              <Typography gutterBottom variant="body2">
                runConsultantPayments
              </Typography>
              <Typography gutterBottom variant="body2">
                Esta rotina verifica os contratos identificando aqueles no estado ativo e gerando o respectivo pagamento
                correspondete à comissão, agendado para a data de aniversário do contrato.
              </Typography>
              <Typography gutterBottom variant="body2">
                <b style={{ color: "red" }}>ATENÇÃO:</b> existe uma situação onde rodar essa rotina em outro dia pode
                causar problema: no caso de ter havido troca de agente ou mudança de split. Fora esse caso, não há
                problema técnico em rodar esta rotina mais de uma vez no mês.
              </Typography>
              <Typography gutterBottom variant="body2">
                Exemplo: A é agente de X. No dia 1º, foi gerado pagamento de comissão para A para dia 20; No dia 10, A é
                trocado por B como agente de X; No dia 12, esta rotina é executada novamente e é gerado pagamento de
                comissão para B para dia 20 - mas o pagamento previamente agendado para A, da mesma comissão, não é
                modificado.
              </Typography>
              <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "space-between", marginTop: 8 }}>
                <Typography gutterBottom variant="body2">
                  Horário de execução automática: <b>00h</b> mensal, dia 1º
                </Typography>
                <Button variant="outlined" onClick={this.runConsultantPayments}>
                  Executar agora
                </Button>
              </div>
            </CardContent>
          </Card>
        </CardPageItem>

        {process.env.NODE_ENV !== "production" && (
          <CardPageItem>
            <CardContent>
              <Typography gutterBottom variant="h6">
                Rotinas de teste.
              </Typography>
              <CardPageItem raised>
                <CardContent>
                  <Typography gutterBottom color="primary">
                    Teste de pagamento diário de rendimento.
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    Simula os pagamentos, dia a dia, para o contrato C51 desde 5 dias antes data inicial até 5 dias
                    depois de sua data final.
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    É um contrato de 30K por 3 meses, 3% a.m., ativo de 11/09/2020 a 11/12/2020.
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    Os valores gerados a cada dia são mostrados no console do servidor.
                  </Typography>
                  <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end", marginTop: 8 }}>
                    <Button variant="outlined" onClick={this.testDailyYield}>
                      Executar teste agora
                    </Button>
                  </div>
                </CardContent>
              </CardPageItem>
            </CardContent>
          </CardPageItem>
        )}

        <FormDialog
          title="app.company.monthly.ceiling"
          open={this.state.op}
          variant="secondary"
          noHeader
          onClose={(e) => this.setState({ op: false })}
        >
          <FormMonthlyOperation onActionComplete={this.loadOp} selected={this.state.selectedType} />
        </FormDialog>

        {this.state.selectedType && (
          <QuestionDialog
            title={"app.delete"}
            onCancel={() => this.setState({ showDeleteModal: false, selectedType: null })}
            open={this.state.showDeleteModal}
            onOk={() => this.delete(this.state.selectedType.id)}
          >
            <FormattedMessage id="app.delete.text" />
          </QuestionDialog>
        )}
      </PageContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    initialValues: state.config,
  };
}

export default compose(
  connect(mapStateToProps, actions),
  reduxForm({
    form: "configForm",
    enableReinitialize: true,
  })
)(AdminFinancial);
