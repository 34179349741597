import React, { Component } from "react";
import { CardContent, Typography, CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import * as actions from "../actions";
import PageContainer, { CardPageItem } from "../custom/Page";
import HeaderPageItem from "../custom/HeaderPageItem";
import {
  getContract,
  downloadReceipt,
  downloadContract
} from "../actions/Contract";
import FixedPageItem from "../custom/FixedPageItem";
import { FormattedMessage } from "react-intl";
import ContractDetail from "./components/ContractDetail";

class CustomerContractDetail extends Component {
  state = { contract: {}, my: null, client: null, active: null };

  componentDidMount() {
    const { currentUser } = this.props;
    this.setState({ contract: {} });
    getContract(this.props.match.params.id).then(data => {
      let my = currentUser.id == data.user.id;
      let client = currentUser.id !== data.user.id;
      let active = data.status == "ACTIVE";
      this.setState({ contract: data, my, client, active });
    });
  }

  downloadReceipt = e => {
    const { showErrorMessage } = this.props;
    downloadReceipt(this.state.contract.id)
      .then(data => {
        const downloadLink = document.createElement("a");
        downloadLink.href = "data:application/pdf;base64," + data;
        downloadLink.download =
          "app.contract.receipt" + this.state.contract.id + ".pdf";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      })
      .catch(error => {
        showErrorMessage(error.message);
      });
  };

  downloadContract = e => {
    const { showErrorMessage } = this.props;
    downloadContract(this.state.contract.id)
      .then(data => {
        const downloadLink = document.createElement("a");
        downloadLink.href = "data:application/pdf;base64," + data;
        downloadLink.download = "Contrato_" + this.state.contract.id + ".pdf";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      })
      .catch(error => {
        showErrorMessage(error.message);
      });
  };

  render() {
    const { contract, my, client, active } = this.state;

    if (!contract) {
      return <CircularProgress style={{ marginTop: "25%" }} />;
    } else {
      return (
        <PageContainer>
          <HeaderPageItem
            title="app.contract.title"
            values={contract.id}
            showBackButton
            destination={
              active && client
                ? "/contract/" + contract.id
                : active && my
                ? "/contract/" + contract.id
                : client
                ? "/customer/" + contract.user.id
                : my
                ? "/contracts"
                : ""
            }
          />
          {/* onBack={this.props.history.goBack} */}
          <FixedPageItem
            position="top"
            height={45}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Typography variant="h6">
              <FormattedMessage id="app.contractDetails" />
            </Typography>
          </FixedPageItem>

          <CardPageItem>
            <CardContent>
              {contract.id ? (
                <>
                  <ContractDetail
                    contract={contract}
                    user={this.props.currentUser}
                  />
                </>
              ) : (
                <></>
              )}
            </CardContent>
          </CardPageItem>
        </PageContainer>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me
  };
}

export default connect(mapStateToProps, actions)(CustomerContractDetail);
