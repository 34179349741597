import { Button, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Menu, MenuItem, Tab, Tabs, Typography, withTheme } from "@material-ui/core";
import moment from "moment";
import React, { Component } from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "../actions";
import { getContractTotal, getCustomerContracts } from "../actions/Contract";
import HeaderPageItem from "../custom/HeaderPageItem";
import { LCTDisplayModes, setLCTDisplayMode } from "../custom/Number";
import PageContainer, { CardPageItem, PageItem } from "../custom/Page";
import { Roles } from "../util";
import ContractPageItem from "./ContractPageItem";
import ContractChart from "./components/ContractChart";
import ContractWidget from "./components/ContractWidget";

class CustomerContracts extends Component {
  state = {
    tabIndex: 0,
    contracts: [],
    chartShowPer: false,
    loading: true,
    chartShowBalance: true,
    chartData: [],
    totalPerc: 0,
    disableContractAgent: false,
    newLCContract: false,
    openWarning: false,
    btContractAgent: false,
    openPopupContract: false,
    contractAttention: null,
  };

  currentMonth = moment();

  componentDidMount() {
    this.props.getMe();
    const { currentUser } = this.props;
    this.loadContractList(false);
    this.showPopupContract();
    setLCTDisplayMode(LCTDisplayModes.Original);
    if (currentUser.role == Roles.Agent) {
      if (currentUser.contractAgent) {
        if (currentUser.contractAgent.status == "ACTIVE") {
          this.setState({ disableContractAgent: true, newLCContract: true, openWarning: false });
        } else if (currentUser.contractAgent.status == "PENDING") {
          this.setState({ btContractAgent: true, openWarning: true });
        } else {
          this.setState({ openWarning: true });
        }
      } else {
        this.setState({ newLCContract: false, openWarning: true });
      }
    }
  }

  showPopupContract = () => {
    getCustomerContracts(this.props.currentUser.id, false).then((data) => {
      const c = data.filter((c) => c.phase == "WAITING_SIGN" || c.phase == "VALIDATING");
      if (c.length > 0) {
        this.setState({ openPopupContract: true, contractAttention: c[0].id });
      } else {
        this.setState({ openPopupContract: false });
      }
    });
  };

  changeTab = (event, value) => {
    this.setState({
      tabIndex: value,
    });
    this.loadContractList(value !== 0);
  };

  loadContractList = (history) => {
    this.setState({ contracts: [] });
    getCustomerContracts(this.props.currentUser.id, history).then((data) => {
      data.sort((c1, c2) => c1.id - c2.id);
      this.setState({ contracts: data, loading: false });
    });
    getContractTotal(this.currentMonth.month() + 1, this.currentMonth.year()).then((data) => {
      this.generateChartData(data);
    });
  };

  generateChartData = (data) => {
    const { currentUser } = this.props;
    let chartData;
    if (data.history && data.history.length > 0) {
      chartData = [];
      const daysInTotal = moment().daysInMonth();
      let historyFirst = moment(data.history[0].date).date();
      let ok = false;
      let total = 0;
      const dailyAvg = data.estimated.val / (1 + daysInTotal - historyFirst);
      const historyIndexZERO = historyFirst;
      const estimatedZERO = dailyAvg * historyIndexZERO;
      chartData.push({
        day: "",
        estim: estimatedZERO,
        yield_per: (data.history[0].totalYield / currentUser.openContractsAmount) * 100,
        balance: data.history[0].totalYield,
      });
      for (let d = 0; d < daysInTotal; d++) {
        const historyIndex = d - historyFirst + 1;
        ok = historyIndex >= 0;
        if (ok) {
          total += data.history[historyIndex] ? data.history[historyIndex].totalYield : 0;
          const estimated = dailyAvg * (historyIndex + 1);
          chartData.push({
            day: d + 1,
            estim: estimated,
            yield_per: historyIndex >= 0 && historyIndex < data.history.length ? (data.history[historyIndex].totalYield / currentUser.openContractsAmount) * 100 : null,
            balance: historyIndex >= 0 && historyIndex < data.history.length ? total : null,
          });
        }
      }
    }
    this.setState({ chartData, contractTotal: data });
  };

  render() {
    const { currentUser } = this.props;
    const { tabIndex, contracts, contractTotal, chartData, loading, openPopupContract, contractAttention } = this.state;
    if (loading) return <CircularProgress style={{ marginTop: "25%" }} />;
    return (
      <PageContainer>
        <HeaderPageItem title="app.contracts.title" onMore={this.props.currentUser.role == "AGENT" ? (e) => this.setState({ menuAnchor: e.target }) : undefined} />
        <Dialog open={this.state.openPopupContract}>
          <DialogTitle>
            <Typography variant="h6" color="primary">
              <FormattedHTMLMessage id="app.warning" />
            </Typography>
          </DialogTitle>

          <DialogContent>
            <Typography variant="subtitle2">
              <FormattedHTMLMessage id="app.contract.waitingAssign.id" values={{ id: contractAttention }} />
            </Typography>

            <Button fullWidth variant="contained" color="primary" style={{ marginTop: 16 }} component={Link} to={"/contract/" + contractAttention}>
              <FormattedHTMLMessage id="app.contract.view" />
            </Button>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ openPopupContract: false })} color="primary">
              <FormattedMessage id="app.contract.later" />
            </Button>
          </DialogActions>
        </Dialog>
        {this.props.currentUser.role == "AGENT" && (
          <Menu onClose={(e) => this.setState({ menuAnchor: undefined })} open={!!this.state.menuAnchor} anchorEl={this.state.menuAnchor}>
            <MenuItem
              component={Link}
              to={"/newContract/" + this.props.currentUser.id}
              disabled={this.props.currentUser.apt && this.state.newLCContract ? false : true}
              onClick={(e) => this.setState({ menuAnchor: undefined })}
            >
              <FormattedMessage id="app.contracts.button.new" />
            </MenuItem>
            {this.state.disableContractAgent && (
              <MenuItem component={Link} to={"/contractAgent/" + this.props.currentUser.id} onClick={(e) => this.setState({ menuAnchor: undefined })}>
                <FormattedHTMLMessage id="app.contractAgent" />
              </MenuItem>
            )}
          </Menu>
        )}
        {currentUser.status == "PENDING" ? (
          <CardPageItem
            style={{
              marginTop: 16,
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <CardContent>
              <Typography variant="h6" color="primary">
                <FormattedHTMLMessage id="app.dashboard.user.pending" />
              </Typography>
            </CardContent>
          </CardPageItem>
        ) : currentUser.status == "INACTIVE" ? (
          <CardPageItem
            style={{
              marginTop: 16,
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <CardContent>
              <Typography variant="h6" color="primary">
                <FormattedHTMLMessage id="app.dashboard.user.inactive" />
                <br />
                {currentUser.role != Roles.Agent && <FormattedHTMLMessage id="app.contactAgent" />}
              </Typography>
            </CardContent>
          </CardPageItem>
        ) : (
          <>
            <ContractWidget contractTotal={contractTotal} currentUser={this.props.currentUser} timezone={this.props.currentUser.timezone} showToday />
            <CardPageItem raised>
              <CardContent style={{ padding: 8 }}>
                <ContractChart chartData={chartData} />
              </CardContent>
            </CardPageItem>
          </>
        )}
        <PageItem>
          <Tabs variant="fullWidth" indicatorColor="primary" textColor="primary" value={tabIndex} onChange={this.changeTab}>
            <Tab label={<FormattedMessage id="app.camel.current" />} />
            <Tab label={<FormattedMessage id="app.camel.history" />} />
          </Tabs>
        </PageItem>

        {!contracts ? (
          <CardPageItem
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CircularProgress style={{ marginTop: "25%" }} />
          </CardPageItem>
        ) : contracts.length == 0 ? (
          <CardPageItem
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography>
              <FormattedMessage id={`app.contracts.${tabIndex == 0 ? "open" : "closed"}.empty`} />.
            </Typography>
          </CardPageItem>
        ) : (
          <>
            {contracts.map((c) => (
              <ContractPageItem key={c.id} contract={c} user={currentUser.id} />
            ))}
          </>
        )}

        <Dialog open={this.state.openWarning}>
          <DialogTitle>
            <Typography variant="h6" color="primary">
              <FormattedHTMLMessage id="app.warning" />
            </Typography>
          </DialogTitle>
          {this.state.btContractAgent ? (
            <DialogContent>
              <Typography variant="subtitle2">
                <FormattedHTMLMessage id="app.contractAgent.pending" />
              </Typography>

              <Button fullWidth variant="contained" color="primary" style={{ marginTop: 16 }} component={Link} to={"/contractAgent/" + this.props.currentUser.id}>
                <FormattedHTMLMessage id="app.contractAgent" />
              </Button>
            </DialogContent>
          ) : (
            <DialogContent>
              <Typography variant="subtitle2">
                <FormattedHTMLMessage id="app.contractAgent.detail.text1" />
              </Typography>
              <Typography variant="subtitle2">
                <FormattedMessage id="app.administrator.contact.please" />
              </Typography>
            </DialogContent>
          )}
          <DialogActions>
            <Button onClick={() => this.setState({ openWarning: false })} color="primary">
              <FormattedMessage id="app.close" />
            </Button>
          </DialogActions>
        </Dialog>
      </PageContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default withTheme(connect(mapStateToProps, actions)(CustomerContracts));
