import React, { Component } from "react";
import { Button, CardContent, Card, CardActions } from "@material-ui/core";
import { reduxForm, Field } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import * as actions from "../actions";
import HeaderPageItem from "../custom/HeaderPageItem";
import PageContainer, { PageItem } from "../custom/Page";
import { FormattedMessage } from "react-intl";
import customSelect from "../custom/CustomSelect";
import CustomSelectNormal from "../custom/CustomSelectNormal"
import { TimeZones } from "../util/Dates";
class ChangeLanguageForm extends Component {
	componentDidMount() {
		this.props.initialize({ locale: this.props.user.locale, timezone: this.props.user.timezone });
	}

	onSubmit = (formProps) => {
		const { updateLanguage } = this.props;
		updateLanguage(formProps);
	};

	render() {
		const { handleSubmit } = this.props;

		const languages = [
			{ value: "en", input: "app.language.en" },
			{ value: "br", input: "app.language.br" },
			{ value: "es", input: "app.language.es" },
		];

		return (
			<PageContainer>
				<PageItem>
					<HeaderPageItem title="app.language" showBackButton destination="/more" />
				</PageItem>
				<PageItem>
					<Card>
						<CardContent>
							<form onSubmit={handleSubmit(this.onSubmit)}>
								<Field
									InputLabelProps={{
										shrink: true,
									}}
									name="locale"
									label="app.language"
									component={customSelect}
									values={languages}
								/>
								<Field
									InputLabelProps={{
										shrink: true,
									}}
									name="timezone"
									label="app.timezone"
									component={CustomSelectNormal}
									values={TimeZones}
								/>
								<CardActions>
									<Button
										fullWidth
										id="bSubmitOrderForm"
										variant="contained"
										color="primary"
										type="submit"
									>
										<FormattedMessage id="app.button.save" />
									</Button>
								</CardActions>
							</form>
						</CardContent>
					</Card>
				</PageItem>
			</PageContainer>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.user.user.me,
	};
}

export default compose(
	reduxForm({
		form: "changeLanguageForm",
	}),
	connect(mapStateToProps, actions)
)(ChangeLanguageForm);
