import { EventTracker, Palette } from "@devexpress/dx-react-chart";
import { Chart, Legend, PieSeries, Tooltip } from "@devexpress/dx-react-chart-material-ui";
import { Button, CardContent, CircularProgress, Typography } from "@material-ui/core";
import { withTheme } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { getContracts } from "../actions";
import HeaderPageItem from "../custom/HeaderPageItem";
import InfoItem from "../custom/InfoItem";
import Number from "../custom/Number";
import PageContainer, { CardPageItem } from "../custom/Page";
import messages_br from "../translations/br.json";
import messages_en from "../translations/en.json";
import messages_es from "../translations/es.json";

const messages = {
  en: messages_en,
  br: messages_br,
  es: messages_es,
};

const colorScheme = [
  "#DC143C",
  "#FF6347",
  "#20B2AA",
  "#FF1493",
  "#00CED1",
  "#FF69B4",
  "#FFC0CB",
  "#FF4500",
  "#FFD700",
  "#FF69B4",
  "#8A2BE2",
  "#7FFF00",
  "#DC143C",
  "#FF8C00",
  "#FFD700",
  "#4B0082",
  "#FF0000",
  "#00FF00",
  "#0000FF",
  "#FFFF00",
  "#FF00FF",
  "#00FFFF",
  "#FFA500",
  "#800080",
  "#008000",
  "#000080",
  "#FF4500",
  "#FFD700",
  "#FF69B4",
  "#8A2BE2",
  "#7FFF00",
  "#FF8C00",
  "#8A2BE2",
  "#4B0082",
  "#ADFF2F",
  "#7FFF00",
  "#DC143C",
  "#FF8C00",
  "#4B0082",
  "#ADFF2F",
  "#FF6347",
  "#20B2AA",
  "#FF1493",
  "#00CED1",
  "#FFC0CB",
  "#FF4500",
  "#FFD700",
  "#FF69B4",
  "#8A2BE2",
  "#7FFF00",
  "#FFD700",
  "#FF69B4",
  "#8A2BE2",
  "#7FFF00",
  "#DC143C",
  "#FF8C00",
  "#4B0082",
  "#ADFF2F",
  "#FF6347",
  "#20B2AA",
  "#FF1493",
  "#00CED1",
  "#FFC0CB",
  "#FF4500",
  "#DC143C",
  "#FF8C00",
  "#4B0082",
  "#ADFF2F",
  "#FF6347",
  "#20B2AA",
  "#FF1493",
  "#00CED1",
  "#FFC0CB",
  "#FF4500",
  "#ADFF2F",
  "#FF6347",
  "#20B2AA",
  "#FF1493",
  "#00CED1",
  "#FFC0CB",
  "#FF4500",
  "#FFD700",
  "#FF69B4",
  "#8A2BE2",
  "#7FFF00",
  "#DC143C",
  "#FF8C00",
  "#4B0082",
  "#ADFF2F",
  "#FF6347",
  "#20B2AA",
  "#FF1493",
  "#00CED1",
  "#FFC0CB",
  "#FF4500",
  "#FFD700",
  "#FF69B4",
  "#8A2BE2",
  "#7FFF00",
  "#DC143C",
  "#FF8C00",
  "#4B0082",
  "#ADFF2F",
  "#FF6347",
  "#20B2AA",
  "#FF1493",
  "#00CED1",
  "#FFC0CB",
];

function ReportAgentPortfolio({ currentUser, theme, taxResidence }) {
  const initialFilterSet = new FilterSet([new AgentFilter([])]);

  const [filterSet, setFilterSet] = useState(initialFilterSet);
  const [sourceReportData, setReportData] = useState(null);
  const [totals, setTotals] = useState({});
  const [chartData, setChartData] = useState([]);
  const [showingAgents, setShowingAgents] = useState(true);
  const [chartValueField, setChartValueField] = useState("amount");

  useEffect(() => {
    loadAndConvertContracts();
  }, []);

  useEffect(() => {
    filterAndReduceContracts();
  }, [filterSet]);

  async function loadAndConvertContracts() {
    const params = {
      phase: ["EXECUTING"],
      taxResidence,
    };
    let rawContracts = await getContracts(params);
    setReportData(rawContracts.map(mapContractToReportData));

    let ids = new Set();
    let agents = rawContracts
      .filter((c) => !ids.has(c.user.consultant.id) && ids.add(c.user.consultant.id))
      .map((c) => ({ name: `${c.user.consultant.name} (${c.user.consultant.id})`, id: c.user.consultant.id }));

    filterSet.filters[0].options = [{ label: messages[currentUser.locale]["app.all"], value: "" }].concat(agents.map((a) => ({ label: `${a.name} (${a.id})`, value: a.id })));
    setFilterSet({ ...filterSet });
  }

  function filterAndReduceContracts() {
    if (sourceReportData !== null) {
      let filteredReportData = filterSet.apply(sourceReportData);

      let ids = new Set();
      let uniqueAgents = filteredReportData.filter((c) => !ids.has(c.agentId) && ids.add(c.agentId)).map(mapReportDataToChartData("AGENT"));

      ids = new Set();
      let uniqueCustomers = filteredReportData.filter((c) => !ids.has(c.userId) && ids.add(c.userId)).map(mapReportDataToChartData("CUSTOMER"));

      let totals = reduceTotals(filteredReportData);
      totals.agents = uniqueAgents.length;
      totals.customers = uniqueCustomers.length;
      setTotals(totals);

      uniqueAgents.forEach((a) => Object.assign(a, reduceTotals(filteredReportData.filter((c) => c.agentId === a.id))));
      uniqueCustomers.forEach((u) => Object.assign(u, reduceTotals(filteredReportData.filter((c) => c.userId === u.id))));

      uniqueAgents.sort((a, b) => b.amount - a.amount);
      uniqueCustomers.sort((a, b) => b.amount - a.amount);

      let showingAgents = uniqueAgents.length > 1;
      setShowingAgents(showingAgents);

      if (showingAgents) {
        setChartData(uniqueAgents);
      } else {
        setChartData(uniqueCustomers);
      }
    }
  }

  function reduceTotals(filteredReportData) {
    return filteredReportData.reduce(
      (prevTotals, c) => ({
        count: prevTotals.count + 1,
        amount: prevTotals.amount + c.amount,
        manager: prevTotals.manager + c.managerValue,
        agent: prevTotals.agent + c.agentValue,
      }),
      {
        count: 0,
        amount: 0,
        manager: 0,
        agent: 0,
      }
    );
  }

  function mapReportDataToChartData(type) {
    let idField = type === "AGENT" ? "agentId" : "userId";
    let nameField = type === "AGENT" ? "agentName" : "userName";
    return function (c, index) {
      return { index, id: c[idField], name: c[nameField] };
    };
  }

  function mapContractToReportData(c) {
    let agentLimit = 1;
    let agreementAgent = c.user.consultant?.contractAgent;
    if (agreementAgent && agreementAgent.status === "ACTIVE" && agreementAgent.type === "AGENT") {
      agentLimit = 1 - agreementAgent.splitForManager;
    }

    let managerPercent = 0;
    let agreementManager = c.user.consultant?.manager?.contractAgent;
    if (agreementManager && agreementManager.status === "ACTIVE" && agreementManager.type === "MANAGER") {
      managerPercent = agreementManager.managerComission;
    }

    let userPercent = (c.maxMonthlyYield * c.segment.baseYield) / (100 * 100);
    let typePercent = (c.type.agentCommission * c.segment.commissionModifier) / 100;
    let agentPercent = agentLimit * typePercent;
    let splitPercent = c.splitAgent === null ? null : c.splitPercent * agentPercent;
    let recommenderPercent = null;
    if (splitPercent !== null) {
      agentPercent -= splitPercent;
    }
    if (recommenderPercent !== null) {
      agentPercent -= recommenderPercent;
    }

    return {
      id: c.id,
      phase: c.phase,
      initialDate: c.initialDate,
      dueDate: c.dueDate,
      typeDescription: c.type.description,
      amount: c.amount,

      userId: c.user.id,
      userName: c.user.name,
      userPercent: userPercent,
      userValue: userPercent * c.amount,

      typePercent,
      typeValue: typePercent * c.amount,
      agentLimit,

      agentId: c.user.consultant.id,
      agentName: c.user.consultant.name,
      agentPercent,
      agentValue: agentPercent * c.amount,

      managerId: c.user.consultant.manager.id,
      managerName: c.user.consultant.manager.name,
      managerPercent,
      managerValue: managerPercent * c.amount,

      splitId: c.splitAgent,
      splitName: "(" + c.splitAgent + ")",
      splitPercent,
      splitValue: splitPercent === null ? null : splitPercent * c.amount,

      recommenderId: null,
      recommenderName: null,
      recommenderPercent,
      recommenderValue: recommenderPercent === null ? null : recommenderPercent * c.amount,
    };
  }

  function onChartClick(target) {
    if (target.targets.length === 0) {
      return;
    }
    setChartData(null);
    if (showingAgents) {
      let index = target.targets[0].point;
      let chartDataEntry = chartData[index];
      filterSet.filters[0].value = chartDataEntry.id;
      setFilterSet({ ...filterSet });
    } else {
      filterSet.filters[0].value = "";
      setFilterSet({ ...filterSet });
    }
  }

  return (
    <>
      <PageContainer>
        <HeaderPageItem title="Portfolio report" showBackButton destination="/reports" />

        <CardPageItem raised>
          <CardContent style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div style={{ display: "grid", gridAutoFlow: "column", justifyContent: "start", alignItems: "start", columnGap: 16 }}>
              <InfoItem center caption={<FormattedMessage id={`app.agent`} />} text={<>{filterSet.filters[0].options.find((o) => o.value === filterSet.filters[0].value)?.label}</>} />
            </div>
            <div style={{ display: "grid", gridAutoFlow: "column", justifyContent: "start", alignItems: "start", columnGap: 16 }}>
              <InfoItem
                center
                caption={<FormattedMessage id={`app.customers`} />}
                text={
                  <>
                    <Number value={totals.customers} />
                  </>
                }
              />
              <InfoItem
                center
                caption={<FormattedMessage id={`app.contracts`} />}
                text={
                  <>
                    <Number value={totals.count} />
                  </>
                }
              />
            </div>
            <div style={{ display: "grid", gridAutoFlow: "column", justifyContent: "start", alignItems: "start", columnGap: 16 }}>
              <Button variant="contained" color="secondary" onClick={(e) => setChartValueField("amount")}>
                <InfoItem
                  center
                  caption={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormattedMessage id="app.custodian.total" currency="LCT" />
                    </div>
                  }
                  text={<Number value={totals.amount} currency="LCT" />}
                />
              </Button>
              <Button variant="contained" color="secondary" onClick={(e) => setChartValueField("manager")}>
                <InfoItem
                  center
                  caption={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormattedMessage id="app.comissionManager.lct" />
                    </div>
                  }
                  text={<Number value={totals.manager} currency="LCT" />}
                />
              </Button>
              <Button variant="contained" color="secondary" onClick={(e) => setChartValueField("agent")}>
                <InfoItem
                  center
                  caption={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormattedMessage id="app.agent" />
                    </div>
                  }
                  text={<Number value={totals.agent} currency="LCT" />}
                />
              </Button>
            </div>
          </CardContent>
        </CardPageItem>

        <CardPageItem>
          {chartData === null ? (
            <div style={{ textAlign: "center" }}>
              <CircularProgress size={24} />
            </div>
          ) : (
            <>
              <Typography style={{ textAlign: "center", marginTop: 16 }}>
                <b>{chartValueField} distribution</b>
              </Typography>
              <Chart data={chartData} rootComponent={ChartRootComponent}>
                <Palette scheme={colorScheme} />
                <PieSeries valueField={chartValueField} argumentField="index" />
                <EventTracker onClick={onChartClick} />
                <Tooltip contentComponent={TooltipContent} />
                <Legend position="bottom" rootComponent={LegendRootComponent} itemComponent={LegendItemComponent} labelComponent={LegendLabelComponent} markerComponent={LegendMarkerComponent} />
              </Chart>
            </>
          )}
        </CardPageItem>
      </PageContainer>
    </>
  );

  function ChartRootComponent({ children }) {
    return <div style={{ display: "grid", gridTemplateRows: "0px 300px auto", gap: 16 }}>{children}</div>;
  }

  function TooltipContent({ targetItem }) {
    let chartDataEntry = chartData[targetItem.point];
    return (
      <div>
        <Number value={chartDataEntry[chartValueField] / totals[chartValueField]} display="percentage" />
        <br />
        {chartDataEntry.name}
        <br />
        {chartValueField}: <Number value={chartDataEntry[chartValueField]} currency="LCT" />
      </div>
    );
  }

  function LegendRootComponent({ children }) {
    let nameHeader = showingAgents ? "Agent" : "Customer";
    return (
      <div>
        <table cellPadding="4">
          <thead>
            <tr>
              <th></th>
              <th>Percent</th>
              <th align="left">{nameHeader}</th>
              <th align="right">Amount</th>
              <th align="right">Management</th>
              <th align="right">Agent</th>
              <th align="center">LCContracts</th>
            </tr>
          </thead>
          <tbody>{children}</tbody>
        </table>
      </div>
    );
  }

  function LegendItemComponent({ children }) {
    return <tr>{children}</tr>;
  }

  function LegendMarkerComponent(props) {
    return (
      <td>
        <Legend.Marker {...props} />
      </td>
    );
  }

  function LegendLabelComponent({ text }) {
    let chartDataEntry = chartData[parseInt(text)];
    return (
      <>
        <td align="right">
          <Number value={chartDataEntry.amount / totals.amount} display="percentage" />
        </td>
        <td>{chartDataEntry.name}</td>
        <td align="right">
          <Number value={chartDataEntry.amount} fractionDigits={2} />
        </td>
        <td align="right">
          <Number value={chartDataEntry.manager} fractionDigits={2} />
        </td>
        <td align="right">
          <Number value={chartDataEntry.agent} fractionDigits={2} />
        </td>
        <td align="center">{chartDataEntry.count}</td>
      </>
    );
  }
}

class AgentFilter {
  value = "";
  caption = "app.agent";
  options = [];
  constructor(options) {
    this.options = options;
  }
  apply = (contracts) => contracts.filter((c) => this.value === "" || c.agentId === this.value);
}

class FilterSet {
  filters = [];
  constructor(filters) {
    this.filters = filters;
  }
  apply = (contracts) => this.filters.reduce((contracts, filter) => filter.apply(contracts), contracts);
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default connect(mapStateToProps)(withTheme(ReportAgentPortfolio));
