import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";

export const TerminateContractDialog = ({
  open,
  onCancel,
  title,
  children,
  onOk
}) => (
  <Dialog open={open} onClose={onCancel}>
    <DialogTitle>
      <FormattedMessage id={title} />
    </DialogTitle>
    <DialogContent>
      <DialogContentText>{children}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onOk({ canceledBy: "COMPANY" })} color="primary">
        <FormattedMessage id="app.companyDecision" />
      </Button>
      <Button onClick={onOk({ canceledBy: "CUSTOMER" })} color="primary">
        <FormattedMessage id="app.customerDecision" />
      </Button>
      <Button onClick={onCancel} color="primary">
        <FormattedMessage id="app.no" />
      </Button>
    </DialogActions>
  </Dialog>
);
