import React, { Component } from "react";
import { Typography, Dialog, DialogContent, IconButton, AppBar, Toolbar, withStyles, Fab } from "@material-ui/core";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import PropTypes from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";

const styles = (theme) => ({
  noPadding: {
    paddingRight: "0px!important",
  },
  paperMinHeight: {
    minHeight: "calc(95%)",
  },
});

/**
 * @augments {Component<{}>}
 */
class FormDialog extends Component {
  static propTypes = {
    /**
     * Título do popup. Obrigatório. Se variant for "card", passe uma string vazia.
     */
    title: PropTypes.string.isRequired,
    /**
     * Indica se o popup está aberto ou fechado. Obrigatório.
     */
    open: PropTypes.bool.isRequired,
    /**
     * Tipo de popup:
     * - "primary": cabeçalho com botão fechar.
     * - "secondary": cabeçalho com botão voltar.
     * - "card": sem cabeçalho.
     * - "default": idem a "primary".
     */
    variant: PropTypes.oneOf(["primary", "secondary", "default", "card", "semPaddingTop"]),
    /**
     * Remove o padding no topo da janela.
     */
    removePadding: PropTypes.bool,
    /**
     * Ícone para o Float Action Button. Se for passado, um FAB será exibido com o ícone informado.
     * Se não for passado, nenhum FAB será mostrado.
     */
    fabIcon: PropTypes.any,
    /**
     * Mostra o FAB no topo, sobreposto à barra de título, ao invés de na base da janela. Ver fabIcon.
     */
    fabTop: PropTypes.bool,
    /**
     * Callback para o clique no FAB (ver fabIcon).
     */
    onFabClick: PropTypes.func,
    /**
     * Cabeçalho sem destaque (na cor do fundo).
     */
    noHeader: PropTypes.bool,
  };

  render() {
    const {
      title,
      variant,
      open,
      removePadding,
      fabIcon: FabIcon,
      fabTop,
      onClose,
      onFabClick,
      noHeader,
      children,
      values,
      classes,
    } = this.props;

    let fullScreen = this.props.fullScreen;
    if (typeof fullScreen == "undefined") fullScreen = !isWidthUp("lg", this.props.width);

    const dialogContentStyle = {
      card: {
        padding: 0,
        margin: 0,
      },
      default: { paddingTop: 20 },
      semPaddingTop: {
        paddingTop: 0,
      },
    };

    return (
      <Dialog
        className={classes.noPadding}
        classes={{ paper: classes.paperMinHeight }}
        maxWidth="md"
        fullWidth
        disableBackdropClick
        disableEscapeKeyDown
        fullScreen={fullScreen}
        open={open}
        onClose={onClose}
      >
        {variant !== "card" && (
          <AppBar position="relative" color={noHeader ? "secondary" : "primary"}>
            <Toolbar disableGutters style={{ justifyContent: "space-between" }}>
              <div style={{ width: 48 }}>
                {variant == "secondary" && (
                  <IconButton color="inherit" onClick={onClose}>
                    <ChevronLeftIcon />
                  </IconButton>
                )}
              </div>
              <Typography variant="h6" color="inherit">
                <div style={{ textAlign: "center" }}>
                  <FormattedHTMLMessage id={title} values={{ id: values }} />
                </div>
              </Typography>
              <div style={{ width: 48 }}>
                {variant == "primary" && (
                  <IconButton color="inherit" onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                )}
              </div>
            </Toolbar>
          </AppBar>
        )}
        <DialogContent
          style={
            variant == "card"
              ? dialogContentStyle.card
              : removePadding
              ? dialogContentStyle.semPaddingTop
              : dialogContentStyle.default
          }
        >
          {children}
        </DialogContent>
        {FabIcon && (
          <Fab
            size="large"
            color={fabTop ? "secondary" : "primary"}
            onClick={onFabClick}
            style={{
              position: "absolute",
              top: fabTop ? 32 : "unset",
              bottom: fabTop ? "unset" : 32,
              right: 32,
              zIndex: 9999,
            }}
          >
            <FabIcon />
          </Fab>
        )}
      </Dialog>
    );
  }
}

export default withWidth()(withStyles(styles)(FormDialog));
