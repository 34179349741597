import { VALIDATE_CONTRACT_AGENT } from "../actions/types";

const INITIAL_STATE = {
  clauses: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case VALIDATE_CONTRACT_AGENT:
      return { ...state, clauses: action.payload };
    default:
      return state;
  }
}
