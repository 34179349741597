import {
  Button,
  CardContent,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Link,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { withTheme } from "@material-ui/styles";
import moment from "moment-business-days";
import React, { Component, Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import HeaderPageItem from "../../custom/HeaderPageItem";
import InfoItem from "../../custom/InfoItem";
import Number from "../../custom/Number";
import PageContainer, { CardPageItem, PageItem } from "../../custom/Page";
import messages_br from "../../translations/br.json";
import messages_en from "../../translations/en.json";
import messages_es from "../../translations/es.json";
//import { getConfig2, getAtiveContracts, getStatusRedeemContracts, getRedeemContracts } from "../actions";
import { getLCTCodeByTaxResidence } from "../../actions/Config";
import { ListContractColumns, Roles, hasActualRole } from "../../util";
import TableList from "./../components/TableList";

const UNIT_FONT_SIZE = "60%";
const DARK_FONT_COLOR = "#999";

class CustomerFilter {
  value = "";
  caption = "app.customer";
  options = [];

  apply = (contracts) => contracts.filter((c) => this.value == "" || c.customerId == this.value);
}

class AgentFilter {
  value = "";
  caption = "app.agent";
  options = [];
  apply = (contracts) => contracts.filter((c) => this.value == "" || c.agentId == this.value);
}

class TypeFilter {
  value = "";
  caption = "app.type";
  options = [];
  apply = (contracts) => contracts.filter((c) => this.value == "" || c.type == this.value);
}

class CreationMonthYearFilter {
  value = "";
  caption = "app.contractReport.createdAt";
  options = [];
  apply = (contracts) => contracts.filter((c) => this.value == "" || moment(c.createdAt).format("MM-YYYY") == this.value);
}

class InitialMonthYearFilter {
  value = "";
  caption = "app.activationDate";
  options = [];
  apply = (contracts) => contracts.filter((c) => this.value == "" || moment(c.activatedDate).format("MM-YYYY") == this.value);
}

class DueMonthYearFilter {
  value = "";
  caption = "app.dueDate";
  options = [];
  apply = (contracts) => contracts.filter((c) => this.value == "" || moment(c.rescueDate).format("MM-YYYY") == this.value);
}

class FilterSet {
  filters = [];
  constructor(filters) {
    this.filters = filters;
  }
  apply = (f) => this.filters.reduce((f, filter) => filter.apply(f), f);
}

class ContractReportContent extends Component {
  state = {
    loading: true,
    dataList: this.props.dataList,
    title: this.props.title,
    desc: this.props.desc,
    columns: [],
    options: [],
    openDialogFilter: false,
    openDialogColumns: false,
  };

  filterSets = new FilterSet([
    new CustomerFilter(),
    new AgentFilter(),
    new TypeFilter(),
    new CreationMonthYearFilter(),
    new InitialMonthYearFilter(),
    new DueMonthYearFilter(),
  ]);

  componentDidMount() {
    this.setState({ loading: true });
    this.listLCcontracts(this.props.dataList);
    this.listFilters(this.props.dataList);
    this.setState({ loading: false });
  }

  totalCustodian = () => {
    var total = 0;
    this.state.dataList.forEach((a) => {
      total += a.valCustodyLCT;
    });
    return total;
  };

  totalMonthlyLimit = (money = "") => {
    var total = 0;
    this.state.dataList.forEach((a) => {
      total += this.convertPorcentToLCT(a.monthlyLimit, a.valCustodyLCT, money);
    });
    return total;
  };

  totalComission = (money = "") => {
    return this.state.dataList.reduce((acc, a) => acc + a.commissionLCT + a.commissionManagerLCT, 0);
  };

  newColumn = (title, field, type, align, rolesAllowed, render) => ({
    title,
    field,
    type,
    cellStyle: {
      textAlign: align,
      fontSize: 12,
      position: "sticky",
    },
    headerStyle: {
      textAlignLast: align,
      fontSize: 12,
    },
    render,
  });

  newColumnText = (title, field, rolesAllowed, render) => this.newColumn(title, field, "string", "left", rolesAllowed, render);

  newColumnBRL = (title, field, rolesAllowed) =>
    this.newColumn(title, field, "numeric", "right", rolesAllowed, (rowData) => (
      <>
        <Number value={rowData[field]} currency={getLCTCodeByTaxResidence(this.props.taxResidence)} />
      </>
    ));

  newColumnLCT = (title, field, rolesAllowed) =>
    this.newColumn(title, field, "numeric", "right", rolesAllowed, (rowData) => (
      <>
        <Number value={rowData[field]} currency={getLCTCodeByTaxResidence(this.props.taxResidence)} />
      </>
    ));

  newColumnPer = (title, field, rolesAllowed) =>
    this.newColumn(title, field, "numeric", "right", rolesAllowed, (rowData) => (
      <>
        {rowData[field].toFixed(2)} <span style={{ fontSize: UNIT_FONT_SIZE, color: DARK_FONT_COLOR }}>%</span>
      </>
    ));

  newColumnDate = (title, field, rolesAllowed) => this.newColumn(title, field, "date", "left", rolesAllowed);

  /*
     Função que que cria uma nova lista de customers e agents 
     eliminando os repetidos para ser utilizada nos filtros
  */
  listFilters = (listData) => {
    const {
      currentUser: { locale },
    } = this.props;
    const messages = {
      en: messages_en,
      br: messages_br,
      es: messages_es,
    };
    let valid = true;
    let listAgents = listData;
    let listCustomers = listData;
    let listType = [
      { label: messages[locale]["app.all"], value: "" },
      { label: messages[locale]["app.enuns.OPEN"], value: "OPEN" },
      { label: messages[locale]["app.enuns.CLOSED"], value: "CLOSED" },
    ];

    //                  AGENTS
    //Ordena em ordem alfabetica os nomes dos agentes
    listAgents = listAgents.sort((a, b) => {
      return a.user.consultantName < b.user.consultantName ? -1 : a.user.consultantName > b.user.consultantName ? 1 : 0;
    });
    //Reduz a lista apenas para id e nome do agente e descarta os repetidos
    listAgents = listAgents.reduce(
      (prevElem, elem) => {
        valid = true;
        prevElem.map((e) => {
          if (e.value == elem.user.consultantId) {
            valid = false;
          }
        });
        if (valid) {
          return [
            ...prevElem,
            {
              label: elem.user.consultantName,
              value: elem.user.consultantId + "",
            },
          ];
        } else {
          return [...prevElem];
        }
      },
      [
        {
          label: messages[locale]["app.all"],
          value: "",
        },
      ]
    );
    valid = true;
    //                  CUSTOMER
    //Ordena em ordem alfabetica os nomes dos customers
    listCustomers = listCustomers.sort((a, b) => {
      return a.user.name < b.user.name ? -1 : a.user.name > b.user.name ? 1 : 0;
    });
    //Reduz a lista apenas para id e nome do customer e descarta os repetidos
    listCustomers = listCustomers.reduce(
      (prevElem, elem) => {
        valid = true;
        prevElem.map((e) => {
          if (e.value == elem.user.id) {
            valid = false;
          }
        });
        if (valid) {
          return [
            ...prevElem,
            {
              label: elem.user.name,
              value: elem.user.id + "",
            },
          ];
        } else {
          return [...prevElem];
        }
      },
      [
        {
          label: messages[locale]["app.all"],
          value: "",
        },
      ]
    );

    // CREATION MONTH/YEAR
    // Obtém um array só com as datas de ativação em formato string (notar que mantém apenas mês e ano, padronizando o dia em 01 e descarta os nulos).
    let listCreationMonthYear = listData.map((c) => (c.createdAt ? moment(c.createdAt).format("YYYY-MM-01") : null)).filter((d) => d);
    // Elimina strings duplicadas.
    listCreationMonthYear = [...new Set(listCreationMonthYear)];
    // Ordena pela data.
    listCreationMonthYear.sort((d1, d2) => moment(d1) - moment(d2));
    // Gera array de opções para o filtro.
    listCreationMonthYear = [
      { label: messages[locale]["app.all"], value: "" },
      ...listCreationMonthYear.map((d) => ({ label: moment(d).format("MMMM YYYY"), value: moment(d).format("MM-YYYY") })),
    ];

    // INITIAL MONTH/YEAR
    // Obtém um array só com as datas de ativação em formato string (notar que mantém apenas mês e ano, padronizando o dia em 01 e descarta os nulos).
    let listInitialMonthYear = listData.map((c) => (c.initialDate ? moment(c.initialDate).format("YYYY-MM-01") : null)).filter((d) => d);
    // Elimina strings duplicadas.
    listInitialMonthYear = [...new Set(listInitialMonthYear)];
    // Ordena pela data.
    listInitialMonthYear.sort((d1, d2) => moment(d1) - moment(d2));
    // Gera array de opções para o filtro.
    listInitialMonthYear = [
      { label: messages[locale]["app.all"], value: "" },
      ...listInitialMonthYear.map((d) => ({ label: moment(d).format("MMMM YYYY"), value: moment(d).format("MM-YYYY") })),
    ];

    // DUE MONTH/YEAR
    // Obtém um array só com as datas de resgate em formato string (notar que mantém apenas mês e ano, padronizando o dia em 01 e descarta os nulos).
    let listDueMonthYear = listData.map((c) => (c.dueDate ? moment(c.dueDate).format("YYYY-MM-01") : null)).filter((d) => d);
    // Elimina strings duplicadas.
    listDueMonthYear = [...new Set(listDueMonthYear)];
    // Ordena pela data.
    listDueMonthYear.sort((d1, d2) => moment(d1) - moment(d2));
    // Gera array de opções para o filtro.
    listDueMonthYear = [
      { label: messages[locale]["app.all"], value: "" },
      ...listDueMonthYear.map((d) => ({ label: moment(d).format("MMMM YYYY"), value: moment(d).format("MM-YYYY") })),
    ];

    //Seta a lista de customers, agentes, types e Month/Year ordenados e sem repetições no filtro
    this.filterSets.filters[0].options = listCustomers;
    this.filterSets.filters[1].options = listAgents;
    this.filterSets.filters[2].options = listType;
    this.filterSets.filters[3].options = listCreationMonthYear;
    this.filterSets.filters[4].options = listInitialMonthYear;
    this.filterSets.filters[5].options = listDueMonthYear;
  };

  //copiado branch do diego EscolherColunas
  columnsFiltered = () => {
    const { currentUser } = this.props;
    const userRoute = hasActualRole([Roles.Admin, Roles.Auditor, Roles.Kyc, Roles.FinanceManager], currentUser) ? "/user/" : "/customer/";
    return ListContractColumns.filter((c) => c.value && c.roles.includes(this.props.currentUser.role)).map((c) => {
      if (c.name == "id") {
        return this.newColumn(c.label, "id", "numeric", "left", [], (rowData) => (
          <Link href={"/userContract/" + rowData.id} target="_blank">
            {"C " + rowData.id}
          </Link>
        ));
      } else if (c.name == "customer") {
        return this.newColumn(c.label, "customer", "string", "left", [], (rowData) => (
          <>
            <Link href={userRoute + rowData.customerId} target="_blank">
              {rowData.customer}{" "}
            </Link>
            <span style={{ color: DARK_FONT_COLOR }}>{rowData.customerId}</span>
          </>
        ));
      } else if (c.name == "agent") {
        return this.newColumnText(c.label, "agent", [], (rowData) => (
          <Link href={userRoute + rowData.agentId} target="_blank">
            {rowData.agent}{" "}
          </Link>
        ));
      } else if (c.name == "agentManager") {
        return this.newColumnText(c.label, "agentManager", [], (rowData) => (
          <Link href={userRoute + rowData.agentManagerId} target="_blank">
            {rowData.agentManager}{" "}
          </Link>
        ));
      } else if (c.name == "situation") {
        return this.newColumn(c.label, "phase", "string", "left");
      } else if (c.name == "custodianLct") {
        return this.newColumnLCT(c.label, "valCustodyLCT");
      } else if (c.name == "type") {
        return this.newColumn(c.label, "type", "string", "left");
      } else if (c.name == "createdAt") {
        return this.newColumnDate(c.label, "createdAt");
      } else if (c.name == "activation") {
        return this.newColumnDate(c.label, "activatedDate");
      } else if (c.name == "rescue") {
        return this.newColumnDate(c.label, "rescueDate");
      } else if (c.name == "durationInMonth") {
        return this.newColumn(c.label, "durationMonths", "numeric", "right");
      } else if (c.name == "segmentName") {
        return this.newColumn(c.label, "segmentName");
      } else if (c.name == "limitMonthyPercent") {
        return this.newColumnPer(c.label, "monthlyLimit");
      } else if (c.name == "limitMonthyLct") {
        return this.newColumnLCT(c.label, "monthlyLimitLCT");
      } else if (c.name == "comissionPercent") {
        return this.newColumnPer(c.label, "commission");
      } else if (c.name == "comissionLct") {
        return this.newColumnLCT(c.label, "commissionLCT");
      } else if (c.name == "comissionManagerPercent") {
        return this.newColumnPer(c.label, "commissionManager");
      } else if (c.name == "comissionManagerLct") {
        return this.newColumnLCT(c.label, "commissionManagerLCT");
      }
    });
  };

  listLCcontracts = (data) => {
    const {
      theme: { palette },
    } = this.props;

    let dataList = data.map((a) => {
      const monthlyLimit = (a.type.maxMonthlyYield / 100) * (a.segment.baseYield / 100);
      const monthlyLimitLCT = a.amount * monthlyLimit;
      const commission = (a.type.agentCommission / 100) * a.segment.commissionModifier;
      const commissionLCT = a.amount * commission;
      const commissionManager = a.user.consultant.manager?.contractAgent?.managerComission ?? 0;
      const commissionManagerLCT = a.amount * commissionManager;
      return {
        id: a.id,
        customer: a.user.name,
        customerId: a.user.id,
        agent: a.user.consultantName, // Nome do agente
        agentId: a.user.consultantId,
        agentManager: a.user.agentManagerName, // Nome do escritório
        agentManagerId: a.user.agentManagerId,
        valCustodyLCT: a.amount ? a.amount : a.previsionAmount, //Valor custodiado BRZ
        createdAt: a.createdAt ? moment(a.createdAt).toDate() : "-", // DataAbertura
        activatedDate: a.initialDate ? moment(a.initialDate).toDate() : "-", // DataAtivação
        rescueDate: a.dueDate ? moment(a.dueDate).toDate() : "-", // data fim contrato
        type: a.type.type, //tipo de contrato
        phase: this.props.situation ? a.phase == "VALIDATING" ? "Aguardando cliente" : "Aguardando admin" : <FormattedMessage id={`app.enuns.${a.phase}`} />, //fase do contrato
        durationMonths: a.type.durationInMonth, // duração em meses
        segmentName: a.segment.description,

        monthlyLimit: monthlyLimit * 100, // limite mensal percentual de acrescimo mensal maximo
        monthlyLimitLCT, // limite mensal em BRZ
        commission: commission * 100, // Comissão do agente
        commissionLCT, // Comissão do agente
        commissionManager: commissionManager * 100, // Comissão do escritório
        commissionManagerLCT, // Comissão do escritório
      };
    });

    const options = {
      exportButton: true,
      search: false,
      filtering: false,
      paging: false,
      exportDelimiter: "\t",
      padding: "dense",
      headerStyle: {
        fontSize: 12,
        color: "#FFBE2D",
        textAlignLast: "start",
        whiteSpace: "nowrap",
      },
      rowStyle: (rowData, index) => {
        if (index % 2) {
          return { whiteSpace: "nowrap", backgroundColor: palette.secondary.light };
        } else {
          return { whiteSpace: "nowrap", backgroundColor: palette.secondary.dark };
        }
      },
    };

    this.setState({
      columns: this.columnsFiltered(),
      dataList: this.filterSets.apply(dataList),
      options,
    });
  };

  convertMoneyToReal = (money) => {
    return money;
  };

  convertPorcentToLCT = (porcent, value, money = null) => {
    let total = (value / 100) * porcent;
    return total;
  };

  lastAport = (aports, item) => {
    let aport = aports[aports.length - 1];
    return aport[item];
  };

  onChangeColumn = (event) => {
    ListContractColumns.find((c) => c.name === event.target.name).value = event.target.checked;
    this.forceUpdate();
  };

  onChangeAllColumns = (event) => {
    ListContractColumns.forEach((column) => (column.value = event.target.checked));
    this.forceUpdate();
  };

  allColumnsChecked = () => ListContractColumns.find((c) => !c.value) === undefined;

  render() {
    const { currentUser, title } = this.props;
    const { loading, dataList } = this.state;
    const isAdmin = currentUser.role === Roles.Admin;
    if (loading) return <CircularProgress style={{ marginTop: "25%" }} />;
    const totalCustody = this.totalCustodian();
    const totalYield = this.totalMonthlyLimit();
    const totalYieldPercent = totalYield / totalCustody;
    const totalCommission = this.totalComission();
    const totalCommissionPercent = totalCommission / totalCustody;
    const totalCost = totalYield + totalCommission;
    const totalCostPercent = totalCost / totalCustody;
    return (
      <>
        <PageContainer full>
          <HeaderPageItem
            title={title}
            showBackButton
            destination="/reports"
            //onMore={e => this.setState({ openDialogFilter: true })}
            onMore={(e) => this.setState({ menu: e.target })}
          >
            <Menu
              keepMounted
              anchorEl={this.state.menu}
              open={Boolean(this.state.menu)}
              onClose={() => {
                this.setState({ menu: null });
              }}
              component="li"
            >
              <MenuItem button onClick={() => this.setState({ openDialogFilter: true, menu: null })}>
                <FormattedMessage id="app.report.filter" />
              </MenuItem>
              <MenuItem button onClick={() => this.setState({ openDialogColumns: true, menu: null })}>
                <FormattedMessage id="app.column.filter" />
              </MenuItem>
            </Menu>
          </HeaderPageItem>

          {isAdmin ? (
            <>
              <CardPageItem raised double>
                <CardContent>
                  <Typography gutterBottom color="primary" variant="h6">
                    <FormattedMessage id="app.contracts" />
                  </Typography>
                  <Divider />
                  <InfoItem
                    caption={<FormattedMessage id={`app.contracts.${this.state.desc}`} />}
                    text={<Number value={dataList.length} />}
                    extraCaption={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <FormattedMessage id="app.total" />
                      </div>
                    }
                    extraText={<Number value={totalCustody} currency={getLCTCodeByTaxResidence(this.props.taxResidence)} />}
                  />
                  <Divider />
                  <InfoItem
                    caption={<FormattedMessage id="app.yield" />}
                    text={
                      <>
                        <Number value={totalYield} currency={getLCTCodeByTaxResidence(this.props.taxResidence)} />{" "}
                        <Typography variant="caption" color="textSecondary">
                          (<Number value={totalYieldPercent} display="percentage" /> of total)
                        </Typography>
                      </>
                    }
                  />
                </CardContent>
              </CardPageItem>

              <CardPageItem raised double>
                <CardContent>
                  <Typography gutterBottom color="primary" variant="h6">
                    <FormattedMessage id="app.cost.monthly" />
                  </Typography>
                  <Divider />
                  <>
                    <Divider />
                    <InfoItem
                      caption={<FormattedMessage id="app.contract.commission" />}
                      text={
                        <>
                          <Number value={totalCommission} currency={getLCTCodeByTaxResidence(this.props.taxResidence)} />{" "}
                          <Typography variant="caption" color="textSecondary">
                            (<Number value={totalCommissionPercent} display="percentage" /> of total)
                          </Typography>
                        </>
                      }
                    />

                    <Divider />
                    <InfoItem
                      caption={<FormattedMessage id="app.cost.monthly.total" />}
                      text={
                        <>
                          <Number value={totalCost} currency={getLCTCodeByTaxResidence(this.props.taxResidence)} />{" "}
                          <Typography variant="caption" color="textSecondary">
                            (<Number value={totalCostPercent} display="percentage" /> of total)
                          </Typography>
                        </>
                      }
                    />
                  </>
                </CardContent>
              </CardPageItem>
            </>
          ) : (
            <CardPageItem raised>
              <CardContent>
                <Typography gutterBottom color="primary" variant="h6">
                  <FormattedMessage id="app.contracts" />
                </Typography>
                <div style={{ display: "grid", gridAutoFlow: "column", justifyContent: "start", columnGap: 16 }}>
                  <InfoItem caption={<FormattedMessage id={`app.contracts.${this.state.desc}`} />} text={<Number value={dataList.length} />} />
                  <InfoItem
                    caption={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <FormattedMessage id="app.custodian.total" />
                      </div>
                    }
                    text={<Number value={totalCustody} currency={getLCTCodeByTaxResidence(this.props.taxResidence)} />}
                  />
                </div>
              </CardContent>
            </CardPageItem>
          )}

          {!(this.state.openDialogFilter || this.state.openDialogColumns || Boolean(this.state.menu)) && (
            <PageItem>
              <TableList title={this.state.title} columns={this.state.columns} data={dataList} options={this.state.options} user={this.props.currentUser} />
            </PageItem>
          )}
        </PageContainer>

        <FilterDialog
          title={<FormattedMessage id="app.filter" />}
          open={this.state.openDialogFilter}
          onClose={() => this.setState({ openDialogFilter: false })}
          onAction={(filterSet) => {
            this.filterSets = filterSet;
            this.setState({ openDialogFilter: false });
            this.componentDidMount();
          }}
          onChange={() => this.forceUpdate()}
          filterSet={this.filterSets}
        />

        <Dialog open={this.state.openDialogColumns} fullWidth>
          <DialogContent>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                <FormattedMessage id="app.column.filter" />
              </FormLabel>
              <FormGroup></FormGroup>
              <FormControlLabel
                control={<Checkbox checked={this.allColumnsChecked()} onChange={this.onChangeAllColumns} color="primary" />}
                label={<FormattedMessage id="app.all" />}
              />
              <Divider />
              <FormGroup>
                {ListContractColumns.filter((c) => c.roles.includes(this.props.currentUser.role)).map((column) => (
                  <FormControlLabel
                    key={column.name}
                    control={<Checkbox checked={column.value} onChange={this.onChangeColumn} name={column.name} color="primary" />}
                    label={<FormattedMessage id={column.label} />}
                  />
                ))}
              </FormGroup>
            </FormControl>
            <Grid container direction="row" justify="flex-end" alignItems="center">
              <Button
                onClick={() => {
                  this.componentDidMount();
                  this.setState({ openDialogColumns: false });
                }}
                color="primary"
              >
                <FormattedMessage id="app.confirm" />
              </Button>
              <Button onClick={() => this.setState({ openDialogColumns: false })} color="primary">
                <FormattedMessage id="app.cancel" />
              </Button>
            </Grid>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

const FilterDialog = ({ open, onClose, title, children, onChange, onAction, filterSet }) => (
  // Componente que mostra um diálogo de edição de conjunto de filtros (FilterSet).
  // Basicamente, mostra controles do tipo select para cada filtro do conjunto.
  // Recebe o conjunto via props e devolve o conjunto modificado em onAction.
  <Dialog fullWidth={true} maxWidth="sm" open={open} onClose={onClose}>
    <DialogTitle>{title}</DialogTitle>

    <DialogContent>
      {filterSet.filters.map((filter) => (
        <Fragment key={filter.caption}>
          <TextField
            variant="outlined"
            select
            label={<FormattedMessage id={filter.caption} />}
            value={filter.value}
            onChange={(e) => {
              filter.value = e.target.value;
              onChange(filter);
            }}
            style={{ width: "100%", marginTop: 16 }}
          >
            {filter.options.map((o) => (
              <MenuItem key={o.label} value={o.value}>
                {o.label}
              </MenuItem>
            ))}
          </TextField>
          <Divider />
        </Fragment>
      ))}
    </DialogContent>

    <DialogActions>
      <Button type="submit" onClick={(e) => onAction(filterSet)} color="primary">
        <FormattedMessage id="app.filter" />
      </Button>
      <Button onClick={onClose} color="default">
        <FormattedMessage id="app.close" />
      </Button>
    </DialogActions>
  </Dialog>
);

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default connect(mapStateToProps)(withTheme(ContractReportContent));
