import { Button, CardContent, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Radio, RadioGroup, Typography } from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import Email from "@material-ui/icons/Email";
import React, { Component, Fragment } from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import * as actions from "../actions";
import { getAccount, requestWithdrawal } from "../actions";
import CustomInputForm from "../custom/CustomInput";
import FormDialog from "../custom/FormDialog";
import HeaderPageItem from "../custom/HeaderPageItem";
import InfoItem from "../custom/InfoItem";
import Number from "../custom/Number";
import PageContainer, { CardPageItem } from "../custom/Page";
import { HashResult, HashType, networkByPartnerWithdrawType, networkNameByPartnerWithdrawType, partnerWithdrawTypeByNetwork, validateCriptoAddress } from "../util";

// VERIFICADO LCTInputMask - precisou chamar o getConfig.

function FormCheckbox({ input, label, ...props }) {
  return <Checkbox label={label} checked={input.value ? true : false} onChange={input.onChange} {...props} />;
}

class FormWithdraw extends Component {
  state = {
    step: 0,
    walletAddress: false,
    typeAddress: false,
    account: {},
    amount: 0,
    LCTBTCSell: 0,
    LCTBTCBuy: 0,
    LCTFiat: 0,
    address: null,
    addressTemp: null,
    checked: false,
    disabledOk: false,
    partnerWithdraw: undefined,
    partnerWithdrawType: undefined,
    partnerWithdrawTypeTemp: 0,
    validpartnerWithdraw: false,
    partnerComment: null,
    loading: false,
  };

  componentDidMount() {
    const { getConfig } = this.props;
    getConfig();
    this.updateAccount();
  }

  updateAccount = () => {
    const { user } = this.props;
    getAccount().then((data) => {
      const account = data;
      this.setState({ account, partnerComment: user.partnerComment });
    });
  };

  accept = () => {
    const { address, partnerWithdraw, partnerWithdrawType, amount, partnerComment } = this.state;
    const { getMe, showSuccessMessage, showErrorMessage, history } = this.props;
    this.setState({ disabledOk: true });
    var date = new Date();
    requestWithdrawal({
      address,
      partnerWithdraw,
      partnerWithdrawType,
      partnerComment,
      amount,
      saveAddress: true,
      authorizeDate: date,
    })
      .then((response) => {
        getMe();
        showSuccessMessage("app.email.confirmation.sent");
        history.push("/history");
      })
      .catch((e) => {
        showErrorMessage(e.response ? e.response.data.message : e);
      });
  };

  onSubmit = (formProps) => {
    //this.props.change();
    this.setState({ step: 1 });
  };

  validateAmount = (value) => {
    if (value > this.state.account.balance) {
      return <FormattedMessage id="app.balance.unavailable" />;
    }
    if (value < this.props.config.minCustomerWithdraw) {
      return (
        <>
          <FormattedMessage id="app.form.amount.error.min" />
          {this.props.config.minCustomerWithdraw}
          {this.props.config.LCTCode}
        </>
      );
    }
  };

  renderSecondStep = () => {
    const { address, amount, disabledOk } = this.state;
    return (
      <FormDialog title="app.withdraw.email.confirm" open={true} variant="secondary" noHeader onClose={(e) => this.setState({ step: 0 })}>
        <Grid container direction="row" justify="center" alignItems="center">
          <IconButton>
            <Email style={{ fontSize: 50 }} />
          </IconButton>
        </Grid>

        <Typography style={{ marginBottom: 16 }}>
          <FormattedMessage id="app.withdraw.email.confirm.text1" />
        </Typography>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          style={{ marginTop: 16, marginBottom: 16 }}
          disabled={disabledOk || this.props.pristine || this.props.submitting || this.props.invalid}
          onClick={(e) => this.accept()}
        >
          Ok
        </Button>
      </FormDialog>
    );
  };

  typeAddress = () => {
    this.setState({ typeAddress: true });
  };

  setAddress = (data, errorMessage) => {
    const { showSuccessMessage, showErrorMessage } = this.props;
    const { addressTemp, partnerWithdrawTypeTemp } = this.state;
    let network = data?.network === "ETH" ? "ETH ou BSC" : data?.network + "/" + data?.token;
    if (errorMessage) {
      showErrorMessage(<FormattedHTMLMessage id={errorMessage} values={{ values: network }} />);
      this.setState({ loading: false });
    } else {
      showSuccessMessage(<FormattedHTMLMessage id="app.address.valid" values={{ values: network }} />);
      this.setState({
        loading: false,
        walletAddress: false,
        address: addressTemp,
        partnerWithdrawType: partnerWithdrawTypeTemp,
      });
    }
  };

  confirmAddress = () => {
    this.setState({ loading: true });
    const { addressTemp, partnerWithdrawTypeTemp } = this.state;
    const withdrawTokenNetwork = networkByPartnerWithdrawType[partnerWithdrawTypeTemp].split("/");
    const token = withdrawTokenNetwork[0];
    let network = withdrawTokenNetwork[1] === "BSC" ? "ETH" : withdrawTokenNetwork[1];
    validateCriptoAddress(addressTemp)
      .then((data) => {
        if (data.result === HashResult.NOT_FOUND) {
          this.setAddress(data, "app.address.invalid");
        } else if (data.type !== HashType.ADDRESS) {
          this.setAddress(data, "app.address.invalid.2");
        } else if (network !== data.network) {
          this.setAddress(data, "app.address.invalid.3");
        } else {
          this.setAddress(data);
        }
      })
      .catch((e) => {
        this.setAddress(null, "app.address.invalid.1");
      });
  };

  render() {
    const { handleSubmit, user, config } = this.props;
    const { account, loading, step, partnerWithdraw, partnerWithdrawType, typeAddress, walletAddress, address, partnerWithdrawTypeTemp } = this.state;

    if (!config.LCTInputMask) return null;
    const lctMask = config.LCTInputMask((amount) => this.setState({ amount }));
    if (step > 0) {
      return this.renderSecondStep();
    }
    return (
      <PageContainer>
        <HeaderPageItem title="app.withdraw" showBackButton destination={"/history"} />
        <CardPageItem>
          <CardContent>
            <form onSubmit={handleSubmit(this.onSubmit)}>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  marginTop: 16,
                }}
              >
                <InfoItem caption={<FormattedMessage id="app.camel.balance" />} text={<Number value={account.balance} currency="LCT" hideValue={user.hideValues} />} />
              </div>
              <Field fullWidth component={CustomInputForm} name="amount" label="app.camel.amount" InputLabelProps={{ shrink: true }} {...lctMask} validate={this.validateAmount} />
              <br />
              <br />
              <br />
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  <FormattedMessage id="app.withdraw.destination" />
                </FormLabel>
                <RadioGroup
                  value={partnerWithdraw}
                  onChange={(e) => {
                    this.setState({ partnerWithdraw: e.target.value });
                    if (e.target.value == 0) {
                      this.setState({
                        address: user.withdrawAddress,
                        partnerWithdrawType: partnerWithdrawTypeByNetwork[user.withdrawAddressNetwork],
                        checked: true,
                      });
                    } else if (e.target.value == 1) {
                      this.setState({ address: config.partnerAddress, partnerWithdrawType: 0, checked: true });
                    } else if (e.target.value == 2) {
                      this.setState({ address: config.address, partnerWithdrawType: 0, checked: true });
                    } else if (e.target.value == 3) {
                      this.setState({ address: "PARTNER-ALTAVISTA", partnerWithdrawType: 0, checked: true });
                    } else if (e.target.value == 4) {
                      this.setState({ address: "BANKING-CHANNEL", partnerWithdrawType: 0, checked: true });
                    }
                  }}
                >
                  {user.taxResidence == "Brasil" ? (
                    <FormControlLabel value="1" control={<Radio color="primary" />} label={<FormattedMessage id="app.withdraw.partner" />} />
                  ) : user.taxResidence == "Colômbia" || user.taxResidence == "Uruguai" ? (
                    <FormControlLabel value="3" control={<Radio color="primary" />} label={<FormattedMessage id="app.withdraw.partner2" />} />
                  ) : (
                    <FormControlLabel value="4" control={<Radio color="primary" />} label={<FormattedMessage id="app.withdraw.bankingchannel.optionlabel" />} />
                  )}

                  <FormControlLabel value="0" control={<Radio color="primary" />} label={<FormattedMessage id="app.wallet.your" />} />

                  <FormControlLabel value="2" control={<Radio color="primary" />} label={<FormattedMessage id="app.reaport" />} />
                </RadioGroup>
              </FormControl>
              <br />
              <br />
              <br />
              {partnerWithdraw == 0 ? (
                <>
                  <Typography style={{ marginTop: 16 }} gutterBottom color="primary">
                    <FormattedMessage id="app.withdraw.text" />
                  </Typography>

                  <Typography>{address ?? <FormattedMessage id="app.noAddress" />}</Typography>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="caption" gutterBottom>
                      {address ? networkNameByPartnerWithdrawType[partnerWithdrawType] : ""}
                    </Typography>
                    <IconButton
                      onClick={(e) => {
                        this.props.change("address", undefined);
                        this.setState({ typeAddress: true });
                      }}
                    >
                      <Edit size="small" />
                    </IconButton>
                  </div>

                  <FormDialog title="app.withdraw.type.confirm" open={typeAddress} variant="secondary" noHeader onClose={(e) => this.setState({ typeAddress: false })}>
                    <CardContent>
                      <Typography gutterBottom variant="h6" color="primary">
                        <FormattedMessage id="app.withdraw.text0" />
                      </Typography>
                      <Typography gutterBottom variant="body1">
                        <FormattedHTMLMessage id="app.qrcode.text3" />
                      </Typography>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{ marginTop: 8, marginBottom: 8 }}
                        onClick={(e) =>
                          this.setState({
                            partnerWithdrawTypeTemp: 1,
                            typeAddress: false,
                            walletAddress: true,
                          })
                        }
                      >
                        {networkNameByPartnerWithdrawType[1]}
                      </Button>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{ marginTop: 8, marginBottom: 8 }}
                        onClick={(e) =>
                          this.setState({
                            partnerWithdrawTypeTemp: 2,
                            typeAddress: false,
                            walletAddress: true,
                          })
                        }
                      >
                        {networkNameByPartnerWithdrawType[2]}
                      </Button>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{ marginTop: 8, marginBottom: 8 }}
                        onClick={(e) =>
                          this.setState({
                            partnerWithdrawTypeTemp: 4,
                            typeAddress: false,
                            walletAddress: true,
                          })
                        }
                      >
                        {networkNameByPartnerWithdrawType[4]}
                      </Button>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{ marginTop: 8, marginBottom: 8 }}
                        onClick={(e) =>
                          this.setState({
                            partnerWithdrawTypeTemp: 3,
                            typeAddress: false,
                            walletAddress: true,
                          })
                        }
                      >
                        {networkNameByPartnerWithdrawType[3]}
                      </Button>
                      {user.taxResidence == "Brasil" && (
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          style={{ marginTop: 8, marginBottom: 8 }}
                          onClick={(e) => {
                            this.setState({
                              partnerWithdrawTypeTemp: 0,
                              typeAddress: false,
                              walletAddress: true,
                            });
                          }}
                        >
                          {networkNameByPartnerWithdrawType[0]}
                        </Button>
                      )}
                    </CardContent>
                  </FormDialog>
                  <FormDialog title="app.withdraw.address.confirm" open={walletAddress} variant="secondary" noHeader onClose={(e) => this.setState({ walletAddress: false })}>
                    <Typography gutterBottom variant="h6" color="primary">
                      <FormattedMessage id="app.wallet.inform" />
                    </Typography>
                    {partnerWithdrawTypeTemp !== 0 && (
                      <Typography gutterBottom variant="body1">
                        <FormattedHTMLMessage id="app.qrcode.text1.2" />
                        <br />
                        <b>{networkNameByPartnerWithdrawType[partnerWithdrawTypeTemp]}</b>
                      </Typography>
                    )}
                    <Field
                      fullWidth
                      component={CustomInputForm}
                      name="address"
                      label="app.wallet.address"
                      InputLabelProps={{ shrink: true }}
                      // disabled={this.state.checked}
                      onChange={(e) => {
                        this.state.addressTemp = e.target.value;
                      }}
                    />
                    {loading ? (
                      <Typography>
                        <FormattedMessage id="app.wallet.validating" />
                      </Typography>
                    ) : (
                      <Button fullWidth variant="contained" color="primary" style={{ marginTop: 16, marginBottom: 16 }} onClick={(e) => this.confirmAddress()}>
                        Ok
                      </Button>
                    )}
                  </FormDialog>
                </>
              ) : partnerWithdraw == 1 ? (
                <Fragment>
                  {user.taxResidence == "Brasil" ? (
                    <>
                      <Typography style={{ marginTop: 16 }} gutterBottom>
                        <FormattedHTMLMessage id="app.withdraw.text2" />
                      </Typography>
                    </>
                  ) : (
                    "" // <>
                    //   <Typography style={{ marginTop: 16 }} gutterBottom>
                    //     Su dinero será enviado a DigiPlata. Recuerde que la cantidad enviada será en Bitcoin al precio
                    //     actual.
                    //   </Typography>
                    // </>
                  )}
                </Fragment>
              ) : partnerWithdraw == 2 ? (
                <Fragment>
                  <Typography gutterBottom>
                    <FormattedMessage id="app.withdraw.text3" />
                  </Typography>
                  <Typography gutterBottom color="error">
                    <FormattedMessage id="app.withdraw.text3.1" />
                  </Typography>
                  <Typography gutterBottom color="error">
                    <FormattedMessage id="app.withdraw.text3.2" />
                  </Typography>
                </Fragment>
              ) : partnerWithdraw == 3 ? (
                <Fragment>
                  <Typography gutterBottom>
                    <FormattedMessage id="app.withdraw.text.partner2" />
                  </Typography>
                  <Typography gutterBottom>
                    <FormattedMessage id="app.withdraw.text.partner3" />
                  </Typography>
                  <Field
                    fullWidth
                    multiline
                    maxLength={500}
                    component={CustomInputForm}
                    name="partnerComment"
                    label="app.wallet.partnerComment"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                      this.state.partnerComment = e.target.value;
                    }}
                  />
                </Fragment>
              ) : partnerWithdraw == 4 ? (
                <Fragment>
                  <Typography color="primary" gutterBottom>
                    <FormattedHTMLMessage id="app.withdraw.bankingchannel.text1" />
                  </Typography>
                  <Typography gutterBottom>
                    <FormattedHTMLMessage id="app.withdraw.bankingchannel.text2" />
                  </Typography>
                  <Typography color="error" gutterBottom>
                    <FormattedHTMLMessage id="app.withdraw.bankingchannel.text3" />
                  </Typography>
                  <Field
                    fullWidth
                    multiline
                    maxLength={500}
                    component={CustomInputForm}
                    name="partnerComment"
                    label="app.withdraw.bankingchannel.commentlabel"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                      this.state.partnerComment = e.target.value;
                    }}
                  />
                </Fragment>
              ) : null}

              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                style={{ marginTop: 16, marginBottom: 16 }}
                disabled={!this.state.checked || this.props.pristine || this.props.submitting || this.props.invalid}
              >
                <FormattedMessage id="app.next" />
              </Button>
            </form>
          </CardContent>
        </CardPageItem>
        <CardPageItem />
      </PageContainer>
    );
  }
}

const validate = (values, props) => {
  const errors = {};
  if (!values.amount) {
    errors.amount = "app.form.amount.error";
  }
  if (!values.address) {
    errors.address = "app.form.address.error";
  }
  return errors;
};

function mapStateToProps(state) {
  const user = state.user.user.me;
  return {
    user,
    config: state.config,
    initialValues: { partnerComment: user.partnerComment },
  };
}

export default compose(
  connect(mapStateToProps, actions),
  reduxForm({
    form: "withdrawForm",
    enableReinitialize: true,
    validate,
  })
)(FormWithdraw);
