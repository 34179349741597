import { Button, CardContent, CircularProgress, IconButton, Link, MenuItem, TextField, Typography } from "@material-ui/core";
import IconCurrent from "@material-ui/icons/CalendarToday";
import IconNext from "@material-ui/icons/SkipNext";
import IconPrevious from "@material-ui/icons/SkipPrevious";
import { withTheme } from "@material-ui/styles";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { connect } from "react-redux";
import { getAgentPayments, getUsersBelowMe } from "../actions";
import HeaderPageItem from "../custom/HeaderPageItem";
import InfoItem from "../custom/InfoItem";
import Number from "../custom/Number";
import PageContainer, { CardPageItem } from "../custom/Page";
import messages_br from "../translations/br.json";
import messages_en from "../translations/en.json";
import messages_es from "../translations/es.json";
import { Roles, hasActualRole } from "../util";
import { TableList } from "./components/TableList";

function ReportAgentsFinancial({ currentUser, theme, taxResidence }) {
  const [agent, setAgent] = useState(currentUser);
  const [startDate, setStartDate] = useState(moment().startOf("month"));
  const [finalDate, setFinalDate] = useState(moment().endOf("month"));
  const [agents, setAgents] = useState(null);
  const [payments, setPayments] = useState(null);
  const [totals, setTotals] = useState({});
  const [debounceLoadPaymentsId, setDebounceLoadPaymentsId] = useState(null);
  const [showTable, setShowTable] = useState(true);

  const options = {
    exportButton: false,
    search: false,
    filtering: false,
    paging: false,
    exportDelimiter: "\t",
    padding: "dense",
    headerStyle: {
      fontSize: 12,
      color: "#FFBE2D",
      textAlignLast: "start",
      whiteSpace: "nowrap",
    },
    rowStyle: (rowData, index) => {
      if (index % 2) {
        return { whiteSpace: "nowrap", backgroundColor: theme.palette.secondary.light };
      } else {
        return { whiteSpace: "nowrap", backgroundColor: theme.palette.secondary.dark };
      }
    },
  };

  const UNIT_FONT_SIZE = "60%";
  const DARK_FONT_COLOR = "#999";
  const userRoute = hasActualRole([Roles.Admin, Roles.Auditor, Roles.Kyc, Roles.FinanceManager], currentUser) ? "/user/" : "/customer/";

  const columns = [
    newColumn("app.date", "updatedAt", "date", "center", (rowData) => <Moment date={rowData.updatedAt} format="L" />),
    newColumn("app.description", "description", "string", "left", (rowData) => <>{rowData.description} </>),
    newColumn("app.value", "value", "numeric", "right", (rowData) => <Number value={rowData.value} currency="LCT" display="none" />),
    newColumn("app.status", "payed", "string", "center", (rowData) => <>{rowData.payed ? "ok" : "pending"}</>),
    newColumn("LCContract", "contract.id", "numeric", "center", (rowData) => (
      <Link href={"/userContract/" + rowData.contract.id} target="_blank">
        {"C" + rowData.contract.id}
      </Link>
    )),
    newColumn("app.customer", "contract.name", "string", "left", (rowData) => (
      <>
        <Link href={userRoute + rowData.contract.userId} target="_blank">
          {rowData.contract.name}{" "}
        </Link>
        <small style={{ color: DARK_FONT_COLOR }}>({rowData.contract.userId})</small>
      </>
    )),
  ];

  useEffect(() => {
    loadAgents();
  }, []);

  useEffect(() => {
    debounceLoadPayments();
  }, [agent, startDate, finalDate]);

  async function loadAgents() {
    let allUsersBelowMe = await getUsersBelowMe();
    let directBelowMe = allUsersBelowMe.filter((u) => u.consultant.id === currentUser.id);
    let myAgents = directBelowMe.filter((u) => u.role === Roles.Agent);
    myAgents = [currentUser].concat(myAgents);
    myAgents = myAgents.sort((u1, u2) => compApt(u1, u2) ?? compName(u1, u2));
    setAgents(myAgents);
  }

  async function loadPayments() {
    setPayments(null);
    let agentPayments = await getAgentPayments(agent.id, startDate.format("YYYY-MM-DD"), finalDate.format("YYYY-MM-DD"));
    agentPayments = agentPayments.filter((p) => !["PROFIT", "FUTURE_PROFIT", "REDEMPTION"].includes(p.type));
    agentPayments.forEach((p) => {
      p.paymentDate = moment(p.paymentDate);
      p.updatedAt = p.updatedAt ? moment(p.updatedAt) : moment(p.paymentDate);
    });
    agentPayments.sort((p1, p2) => p1.updatedAt - p2.updatedAt);
    setPayments(agentPayments);
    countTotals(agentPayments);
    setShowTable(agentPayments.length < 1000);
  }

  function debounceLoadPayments() {
    clearTimeout(debounceLoadPaymentsId);
    setDebounceLoadPaymentsId(0);
    setTotals({});
    setShowTable(false);
    if (!startDate.isValid() || !finalDate.isValid()) return;
    setPayments(null);
    let timeoutId = setTimeout(() => {
      setDebounceLoadPaymentsId(null);
      loadPayments();
    }, 1000);
    setDebounceLoadPaymentsId(timeoutId);
  }

  function addIf(p, type) {
    return p.type === type ? p.value : 0;
  }

  function countTotals(agentPayments) {
    let totals = agentPayments.reduce(
      (t, p) => ({
        count: t.count + 1,
        agent: t.agent + addIf(p, "BONUS"),
        split: t.split + addIf(p, "BONUS_SPLIT"),
        recommender: t.recommender + addIf(p, "BONUS_RECOMMENDANT"),
        manager: t.manager + addIf(p, "BONUS_MANAGER"),
        managerSplit: t.managerSplit + addIf(p, "BONUS_MANAGER_SPLIT"),
        total: t.total + p.value,
      }),
      { count: 0, agent: 0, split: 0, recommender: 0, manager: 0, managerSplit: 0, total: 0 }
    );
    setTotals(totals);
  }

  function onAgentChange(e) {
    setAgent(agents.find((a) => a.id === e.target.value));
  }

  function onStartDateChange(e) {
    setPayments(null);
    let date = moment(e.target.value);
    if (date.isValid()) {
      setStartDate(date);
    }
  }

  function onFinalDateChange(e) {
    setPayments(null);
    let date = moment(e.target.value);
    if (date.isValid()) {
      setFinalDate(moment(e.target.value));
    }
  }

  function onMonthClick(option) {
    setPayments(null);
    switch (option) {
      case "CURRENT":
        setStartDate(moment().startOf("month"));
        setFinalDate(moment().endOf("month"));
        break;
      case "PREVIOUS":
        setStartDate(moment(startDate).subtract(1, "month").startOf("month"));
        setFinalDate(moment(finalDate).subtract(1, "month").endOf("month"));
        break;
      case "NEXT":
        setStartDate(moment(startDate).add(1, "month").startOf("month"));
        setFinalDate(moment(finalDate).add(1, "month").endOf("month"));
        break;
    }
  }

  let dateLabel = `${startDate.format("L")} - ${finalDate.format("L")}`;
  if (startDate.isSame(moment(startDate).startOf("month")) && finalDate.isSame(moment(startDate).endOf("month"))) {
    dateLabel = startDate.format("MMMM");
  }
  return (
    <>
      <PageContainer full>
        <HeaderPageItem title="Agents financial view" preserveTitle showBackButton destination="/reports" />

        <CardPageItem raised double>
          <CardContent style={{ display: "flex", flexWrap: "wrap" }}>
            <div>
              <div style={{ display: "grid", gridAutoFlow: "column", justifyContent: "start", alignItems: "start", columnGap: 16 }}>
                <InfoItem caption="manager" text={<Number value={totals.manager} currency="LCT" />} />
                <InfoItem caption="manager split" text={<Number value={totals.managerSplit} currency="LCT" />} />
              </div>
              <div style={{ display: "grid", gridAutoFlow: "column", justifyContent: "start", alignItems: "start", columnGap: 16 }}>
                <InfoItem caption="agent" text={<Number value={totals.agent} currency="LCT" />} />
                <InfoItem caption="split" text={<Number value={totals.split} currency="LCT" />} />
                <InfoItem caption="recommender" text={<Number value={totals.recommender} currency="LCT" />} />
              </div>
            </div>
            <div style={{ display: "flex", flexGrow: 1, flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
              <Typography variant="caption" color="textSecondary" style={{ textAlign: "center" }}>
                {agent.name} ({agent.id})
                <br />
                {dateLabel}
              </Typography>
              <Typography variant="h4" color="textPrimary">
                <Number value={totals.total} currency="LCT" />
              </Typography>
            </div>
          </CardContent>
        </CardPageItem>

        <CardPageItem raised double>
          <CardContent style={{ display: "grid", gridTemplateColumns: "1fr 1fr 0.8fr", gap: 16 }}>
            <TextField
              select
              variant="outlined"
              label="Agent"
              value={agent.id}
              onChange={onAgentChange}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ gridColumn: "1 / -1" }}
            >
              {agents === null ? (
                <CircularProgress size={24} />
              ) : (
                agents.map((a) => (
                  <MenuItem key={a.id} value={a.id}>
                    {a.name} ({a.id})
                  </MenuItem>
                ))
              )}
            </TextField>
            <TextField
              id="startDate"
              label="Start date"
              variant="outlined"
              type="date"
              value={startDate.format("YYYY-MM-DD")}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={onStartDateChange}
            />
            <TextField
              id="finalDate"
              label="Final date"
              variant="outlined"
              type="date"
              value={finalDate.format("YYYY-MM-DD")}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={onFinalDateChange}
            />
            <div>
              <IconButton onClick={(e) => onMonthClick("PREVIOUS")}>
                <IconPrevious />
              </IconButton>
              <IconButton onClick={(e) => onMonthClick("NEXT")}>
                <IconNext />
              </IconButton>
              <IconButton onClick={(e) => onMonthClick("CURRENT")}>
                <IconCurrent />
              </IconButton>
            </div>
          </CardContent>
        </CardPageItem>

        <CardPageItem>
          {payments === null ? (
            <div style={{ textAlign: "center", marginTop: 32 }}>{debounceLoadPaymentsId !== null ? <>...</> : <CircularProgress size={24} />}</div>
          ) : (
            <div style={{ marginBottom: 16 }}>
              {showTable ? (
                <TableList title={<Typography variant="caption">{totals.count} records</Typography>} preserveTitle columns={columns} data={payments} options={options} user={currentUser} />
              ) : (
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: 32 }}>
                  <Typography>There's a high number of records in the table.</Typography>
                  <Typography gutterBottom>To show may take a while.</Typography>
                  <Button variant="contained" color="secondary" onClick={(e) => setShowTable(true)}>
                    Show table
                  </Button>
                </div>
              )}
            </div>
          )}
        </CardPageItem>
      </PageContainer>
    </>
  );
}

function compApt(u1, u2) {
  return u1.apt === u2.apt ? null : u1.apt ? -1 : 1;
}

function compName(u1, u2) {
  const n1 = u1.name.toUpperCase();
  const n2 = u2.name.toUpperCase();
  return n1 === n2 ? null : n1 < n2 ? -1 : 1;
}

function newColumn(title, field, type, align, render) {
  return {
    title,
    field,
    type,
    cellStyle: {
      textAlign: align,
      fontSize: 12,
      position: "sticky",
    },
    headerStyle: {
      textAlignLast: align,
      fontSize: 12,
    },
    render,
  };
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default connect(mapStateToProps)(withTheme(ReportAgentsFinancial));
