import React, { Component } from "react";
import { CardContent, Button, Typography } from "@material-ui/core";
import PageContainer, { CardPageItem } from "../custom/Page";
import HeaderPageItem from "../custom/HeaderPageItem";
import { resendConfirmationEmail } from "../actions";
import { FormattedMessage } from "react-intl";
import { compose } from "redux";
import { connect } from "react-redux";
import * as actions from "../actions";

class ConfirmEmailForm extends Component {
  onSubmit = () => {
    const { history, showSuccessMessage } = this.props;
    resendConfirmationEmail()
      .then(data => {
        showSuccessMessage("app.confirmEmail");
        history.push("/more");
      })
      .catch(e => {});
  };

  render() {
    return (
      <PageContainer>
        <HeaderPageItem
          title="app.confirmedEmail.title"
          showBackButton
          destination={"/more"}
        />
        <CardPageItem>
          <CardContent>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center"
              }}
            >
              <Typography gutterBottom variant="body1">
                <FormattedMessage id="app.confirmEmail.Text1" />
                <br></br>
                <FormattedMessage id="app.confirmEmail.Text2" />
              </Typography>
              <Button
                fullWidth
                style={{ marginTop: 10 }}
                variant="contained"
                color="primary"
                type="submit"
                onClick={this.onSubmit}
              >
                <FormattedMessage id="app.email.confirm" />
              </Button>
            </div>
          </CardContent>
        </CardPageItem>
      </PageContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me
  };
}

export default compose(connect(mapStateToProps, actions))(ConfirmEmailForm);
