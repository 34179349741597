import { CircularProgress, Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import Number from "../../custom/Number";
import { DialogManager } from "../../util";
import { HelpDialog } from "./HelpDialog";
import moment from "moment-business-days";
import * as momentTimezone from "moment-timezone";
import Moment from "react-moment";
import AnimatedNumber from "react-animated-number/build/AnimatedNumber";
import { CardPageItem, PageItem } from "../../custom/Page";
import CardContent from "@material-ui/core/CardContent";
import ValueInfoItem from "../../custom/ValueInfoItem";
import Paper from "@material-ui/core/Paper";

class ContractWidget extends Component {
  state = {
    date: momentTimezone("22:00:00", "hh:mm:ss"),
    loading: true,
  };
  componentDidMount() {
    this.onTimezone();
  }
  dm = new DialogManager(() => this.forceUpdate());
  onTimezone = () => {
    let data = this.state.date.clone();
    data.tz(this.props.timezone);
    this.setState({
      date: data,
      loading: false,
    });
  };
  render() {
    const { contractTotal, showToday, currentUser } = this.props;
    if (!contractTotal || this.state.loading)
      return (
        <div style={{ width: "100%", textAlign: "center", padding: 20 }}>
          <CircularProgress size={24} />
        </div>
      );
    return (
      <>
        <CardPageItem double>
          <Grid container>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography variant="caption" color="textSecondary">
                <b>{currentUser.openContracts}</b> <FormattedMessage id="app.openContracts" />
              </Typography>
              <Typography variant="h6" style={{ marginBottom: 20 }}>
                <Moment format="MMMM, YYYY" />
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ValueInfoItem
                caption={<FormattedMessage id="app.totalIncrease" />}
                value={currentUser.totalYield}
                center
                variant="h6"
                currency="LCT"
              />
            </Grid>
            <Grid item xs={6}>
              <ValueInfoItem
                caption={<FormattedMessage id="app.custodian.total" />}
                value={currentUser.openContractsAmount}
                center
                variant="h6"
                color="primary"
                currency="LCT"
              />
            </Grid>
          </Grid>
        </CardPageItem>

        <CardPageItem double>
          <Grid container>
            <Grid item xs={6}>
              <ValueInfoItem
                caption={<FormattedMessage id="app.contract.monthlyIncrease" />}
                value={contractTotal.total.val}
                center
                variant="h6"
                currency="LCT"
              />
              <ValueInfoItem
                caption={<FormattedMessage id="app.contract.monthlyEstimated" />}
                value={contractTotal.estimated.val}
                center
                variant="h6"
                currency="LCT"
              />
            </Grid>
            <Grid item xs={6} style={{ display: "flex", justifyContent: "center", paddingTop: 8 }}>
              <Typography
                variant="h6"
                color="primary"
                style={{
                  position: "absolute",
                  marginTop: 35,
                  textAlign: "center",
                  width: 100,
                }}
              >
                <Number value={contractTotal.total.per} display="percent" />
              </Typography>
              <Typography
                variant="caption"
                color="textSecondary"
                style={{
                  position: "absolute",
                  marginTop: 62,
                  textAlign: "center",
                  width: 100,
                }}
              >
                <FormattedMessage id="app.contract.widget.of" defaultValue="de " />
                <Number value={contractTotal.estimated.per} display="percent" />
              </Typography>
              <CircularProgress
                variant="static"
                value={100}
                size={120}
                color="secondary"
                style={{ position: "absolute" }}
              />
              <CircularProgress
                variant="static"
                value={contractTotal.total.per * (100 / contractTotal.estimated.per)}
                size={120}
              />
            </Grid>
          </Grid>
        </CardPageItem>

        {showToday && (
          <PageItem>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Typography variant="caption" color="textSecondary" component="div">
                <FormattedMessage id="app.hourOfDailyIncome2" />
              </Typography>
              {contractTotal.today.val != 0 ? (
                <Typography
                  variant="h6"
                  color="primary"
                  style={{ width: 250, display: "flex", justifyContent: "space-between" }}
                >
                  <Number value={contractTotal.today.val} currency="LCT" />
                  <Number value={contractTotal.today.per} display="percent" />
                </Typography>
              ) : (
                <Typography variant="caption">
                  <FormattedMessage
                    id="app.updateTime"
                    values={{ time: this.state.date.format("HH:mm:ss").toString() }}
                  />
                </Typography>
              )}
            </div>
          </PageItem>
        )}

        <HelpDialog
          open={this.dm.isOpen("HELP_CHART")}
          onClose={(e) => this.dm.close()}
          title="app.contract.widget.dialog.title"
        >
          <FormattedMessage id="app.contract.widget.text" />
          <br />
          <br />
          <FormattedMessage id="app.contract.widget.text2" />
          <br />
          <br />
          <FormattedMessage id="app.contract.widget.text3" />
          <b>
            {" "}
            <Number value={contractTotal.estimated.per} display="percent" />
          </b>
          , <FormattedMessage id="app.contract.widget.text4" />
          <b>
            {" "}
            <Number value={contractTotal.total.per} display="percent" />{" "}
          </b>
          .
        </HelpDialog>
      </>
    );
  }
}

ContractWidget.propTypes = {
  contractTotal: PropTypes.object,
  showToday: PropTypes.bool,
  currentUser: PropTypes.object,
};

export default ContractWidget;
