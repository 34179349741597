import { createStore, applyMiddleware } from "redux";
import { routerMiddleware } from "connected-react-router";
import reduxThunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { createBrowserHistory } from "history";
import cookie from "react-cookies";

import rootReducer from "./reducers";

export const history = createBrowserHistory();

let env = process.env.NODE_ENV || "development";

const logger = createLogger({
  predicate: (getState, action) =>
    !action.type.includes("@@router") && !action.type.includes("@@redux-form")
});

let middllewares;
if (env == "development") {
  middllewares = [reduxThunk, routerMiddleware(history), logger];
} else {
  middllewares = [reduxThunk, routerMiddleware(history)];
}

export default () => {
  let store = createStore(
    rootReducer(history),
    {
      auth: { authenticated: cookie.load("LCAuthToken") }
    },
    applyMiddleware(...middllewares)
  );
  return { store };
};
