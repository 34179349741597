import {
  Button,
  CardActionArea,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import AddMore from "@material-ui/icons/PlaylistAdd";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import React, { Component, Fragment } from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import Moment from "react-moment";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import * as actions from "../actions";
import { getAllCustomerContracts, resetPassword, sendEmail } from "../actions";
import { getAdminKyc } from "../actions/Kyc";
import performRequest from "../configAxios";
import AbtcAvatar from "../custom/Avatar";
import ExpansionPanelIcon from "../custom/ExpansionPanelIcon";
import FormDialog from "../custom/FormDialog";
import HeaderPageItem from "../custom/HeaderPageItem";
import Number from "../custom/Number";
import PageContainer, { CardPageItem, PageItem } from "../custom/Page";
import { capitalCaseNormalizer, isPremiumContract } from "../util";
import { Roles, formatTextToHTML } from "../util/index";
import FormAttachment from "./components/FormAttachment";
import FormContractAgent from "./components/FormContractAgent";
import UserDetail from "./components/UserDetail";
import FormAttachmentMulti from "./components/FormAttachmentMulti";

class AdminUserData extends Component {
  state = {
    loading: true,
    customer: {},
    contracts: [],
    contractsActive: [],
    contractsArchive: [],
    customers: [],
    kyc: [],
    step: false,
    sendEmailOpen: false,
    contractAgentOpen: false,
    textEmail: "",
    edit: false,
    addDoc: false,
    addDocMulti: false,
    agents: [],
  };

  componentDidMount() {
    const { customer } = this.state;
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.loadData();
    }
  }

  loadData = () => {
    const { currentUser } = this.props;
    performRequest("GET", `/user/${this.props.match.params.id}`, null, true)
      .then((response) => {
        let customer = response.data.data;
        if (currentUser.role == Roles.Admin && customer.role == Roles.Agent && customer.contractAgent == null) {
          this.setState({ loading: false, customer: customer, contractAgentOpen: true });
        } else {
          this.setState({ loading: false, customer: customer });
        }
        performRequest("GET", `user`, null, true).then((response) => {
          const customers = response.data.data.filter((c) => c.consultant && c.consultant.id == this.props.match.params.id);
          this.setState({ customers, loading: false });
        });
      })
      .catch((e) => {
        this.props.history.push("/notfound");
      });

    getAllCustomerContracts(this.props.match.params.id).then((data) => {
      data.sort((c1, c2) => c1.id - c2.id);
      const contractsActive = data.filter((c) => c.status !== "ARCHIVE");
      const contractsArchive = data.filter((c) => c.status == "ARCHIVE");
      this.setState({
        contracts: data,
        contractsActive,
        contractsArchive,
        loading: false,
      });
    });
    getAdminKyc(this.props.match.params.id).then((data) => {
      this.setState({ kyc: data });
    });
  };

  closeAddDoc = () => {
    getAdminKyc(this.props.match.params.id).then((data) => {
      this.setState({ kyc: data, addDoc: false });
    });
  };

  closeAddDocMulti = () => {
    getAdminKyc(this.props.match.params.id).then((data) => {
      this.setState({ kyc: data, addDocMulti: false });
    });
  };

  resetUserPassword = () => {
    const {
      showSuccessMessage,
      showErrorMessage,
      match: {
        params: { id },
      },
    } = this.props;
    resetPassword(id)
      .then((data) => {
        showSuccessMessage("app.password.reset.sucessfully");
      })
      .catch((e) => {
        showErrorMessage(e.response ? e.response.data.message : e);
      });
  };

  onSendEmail = () => {
    const { showSuccessMessage, showErrorMessage } = this.props;
    const { customer } = this.state;

    let valFormated = formatTextToHTML(this.state.textEmail);
    let formProps = {
      message: valFormated,
    };
    sendEmail(customer.id, formProps)
      .then((responde) => {
        showSuccessMessage("app.email.sent");
        this.setState({
          sendEmailOpen: false,
          menuAnchor: undefined,
          textEmail: "",
        });
      })
      .catch((err) => {
        showErrorMessage(err.response ? err.response.data.message : err);
      });
  };

  render() {
    const { customer: customer, kyc, agents, customers, contractsArchive, contractsActive } = this.state;
    const { currentUser, history } = this.props;
    if (customer.role == undefined) {
      this.state.loading = true;
    } else {
      this.state.loading = false;
    }
    if (this.state.loading) return <CircularProgress style={{ marginTop: "25%" }} />;
    return (
      <PageContainer>
        <PageItem>
          <HeaderPageItem title="app.user.detail" showBackButton destination={history.location.state ? history.location.state.back : "/users"} onMore={(e) => this.setState({ menuAnchor: e.target })}>
            <Menu onClose={(e) => this.setState({ menuAnchor: undefined })} open={!!this.state.menuAnchor} anchorEl={this.state.menuAnchor}>
              {[Roles.Admin, Roles.Kyc, Roles.FinanceManager].includes(currentUser.role) && (
                <MenuItem component={Link} to={"/users/" + customer.id} onClick={(e) => this.setState({ menuAnchor: undefined })}>
                  <FormattedMessage id="app.user.edit" />
                </MenuItem>
              )}
              {[Roles.Admin, Roles.Kyc].includes(currentUser.role) && (
                <MenuItem
                  onClick={(e) => {
                    this.setState({ step: true, menuAnchor: undefined });
                  }}
                >
                  <FormattedMessage id="app.password.reset" />
                </MenuItem>
              )}
              <MenuItem onClick={(e) => this.setState({ sendEmailOpen: true })}>
                <FormattedMessage id="app.email.send" />
              </MenuItem>
              {[Roles.Admin, Roles.FinanceManager].includes(currentUser.role) && (
                <div>
                  <MenuItem component={Link} to={`/user/${customer.id}/history`} onClick={(e) => this.setState({ menuAnchor: undefined })}>
                    <FormattedMessage id="app.history" />
                  </MenuItem>
                  {customer.role == Roles.Agent && (
                    <MenuItem onClick={(e) => this.setState({ contractAgentOpen: true, menuAnchor: undefined })}>
                      <FormattedHTMLMessage id="app.contractAgent" />
                    </MenuItem>
                  )}
                </div>
              )}
            </Menu>
          </HeaderPageItem>
        </PageItem>
        <CardPageItem raised>
          <CardContent>
            <UserDetail currentUser={currentUser} user={customer.id}></UserDetail>
            <FormDialog title="app.password.reset" open={this.state.step} variant="secondary" noHeader onClose={(e) => this.setState({ step: false })}>
              <Typography style={{ marginBottom: 16 }}>
                <FormattedMessage id="app.password.reset.text" />
              </Typography>
              <Typography style={{ marginBottom: 16 }}>
                <FormattedMessage id="app.password.reset.text2" />
              </Typography>
              <Button
                fullWidth
                variant="outlined"
                onClick={(e) => {
                  this.resetUserPassword();
                  this.setState({ step: false });
                }}
                style={{ marginTop: 16 }}
              >
                <FormattedMessage id="app.password.reset" />
              </Button>
            </FormDialog>
            <FormDialog
              title={customer.contractAgent ? "app.contractAgent" : "app.contractAgent.create"}
              open={this.state.contractAgentOpen}
              variant="secondary"
              noHeader
              onClose={(e) => this.setState({ contractAgentOpen: false, menuAnchor: undefined })}
            >
              <FormContractAgent
                customer={this.state.customer}
                onActionComplete={(newContractAgent) =>
                  this.setState({
                    customer: { ...this.state.customer, contractAgent: newContractAgent },
                    contractAgentOpen: false,
                  })
                }
              />
            </FormDialog>
            <Fragment>
              <FormDialog title="app.addDocument" open={this.state.addDoc} variant="secondary" noHeader onClose={(e) => this.setState({ addDoc: false })}>
                <FormAttachment onActionComplete={this.closeAddDoc} user={customer} />
              </FormDialog>
              <FormDialog title="app.addDocument.multi" open={this.state.addDocMulti} variant="secondary" noHeader onClose={(e) => this.setState({ addDocMulti: false })}>
                <FormAttachmentMulti onActionComplete={this.closeAddDocMulti} user={customer} />
              </FormDialog>
              <SendEmailDialog
                title={<FormattedMessage id="app.email.send" />}
                open={this.state.sendEmailOpen}
                onClose={() => this.state.sendEmailOpen}
                action={this.onSendEmail}
                onAction={() =>
                  this.setState({
                    sendEmailOpen: false,
                    menuAnchor: undefined,
                  })
                }
              >
                <Grid container direction="row" justify="flex-start" alignItems="flex-start" item style={{ flexGrow: 1 }}>
                  <Grid item>
                    <Typography>
                      <FormattedMessage id="app.email.text.send" />
                    </Typography>
                  </Grid>

                  <Grid item md={12}>
                    <Grid item>
                      <TextField name="body" label="" multiline fullWidth onChange={(e) => (this.state.textEmail = e.target.value)} />
                    </Grid>
                  </Grid>
                </Grid>
              </SendEmailDialog>
            </Fragment>
          </CardContent>
        </CardPageItem>

        <CardPageItem raised>
          {customers && customers.length > 0 ? (
            <div style={{ width: "100%" }}>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpansionPanelIcon />}>
                  <Typography>
                    <FormattedMessage id="app.camel.customers" />
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ display: "flex", flexDirection: "column" }}>
                  {customers.map((c) => (
                    <ListItem
                      button
                      alignItems="flex-start"
                      key={c.id}
                      component={Link}
                      to={{
                        pathname: "/user/" + c.id,
                        state: { back: this.props.location.pathname },
                      }}
                    >
                      <ListItemAvatar>
                        <AbtcAvatar imageUrl={c.photoUrl} disableLink />
                      </ListItemAvatar>
                      <ListItemText
                        primaryTypographyProps={{ component: "div" }}
                        secondaryTypographyProps={{ component: "div" }}
                        primary={
                          <div
                            color="textSecondary"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>{capitalCaseNormalizer(c.name)}</span>
                            <span> {"Id: " + c.id}</span>
                          </div>
                        }
                        secondary={
                          <div>
                            <div
                              color="textSecondary"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <span>{c.email}</span>
                            </div>

                            <span style={{ color: "red" }}> {!c.apt ? <FormattedMessage id="app.inapt" /> : ""}</span>
                          </div>
                        }
                      />
                    </ListItem>
                  ))}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>
          ) : (
            <CardContent
              style={{
                marginBottom: 16,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography>
                <FormattedMessage id="app.user.clients.empty" />
              </Typography>
            </CardContent>
          )}
        </CardPageItem>

        <CardPageItem raised>
          {contractsActive && contractsActive.length > 0 ? (
            <div style={{ width: "100%" }}>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpansionPanelIcon />}>
                  <Typography>LCContracts</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ display: "flex", flexDirection: "column" }}>
                  {contractsActive.map((c) => (
                    <CardActionArea disabled={currentUser.role == "KYC"} key={c.id} component={Link} to={"/userContract/" + c.id}>
                      <CardContent
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div key={c.id} style={{ flexGrow: 1 }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              borderBottom: "1px solid gray",
                            }}
                          >
                            <Typography variant="body1" color={isPremiumContract(c) ? "primary" : "textPrimary"}>
                              {`C${c.id}`}
                            </Typography>
                            {currentUser.role != "KYC" && (
                              <Typography variant="body1" color={isPremiumContract(c) ? "primary" : "textPrimary"}>
                                <Number value={c.amount} currency="LCT" />
                              </Typography>
                            )}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="body2" color="textPrimary">
                              {c.type.description}
                            </Typography>
                            <>
                              {c.amount > 0 && c.status == "INACTIVE" ? (
                                <Typography variant="body2" color="textPrimary">
                                  <FormattedMessage id="app.aport.validating" />
                                </Typography>
                              ) : (
                                <Typography variant="body2" color="textPrimary">
                                  <FormattedMessage id={`app.enuns.${c.phase}`} />
                                </Typography>
                              )}
                            </>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="body2" color="textPrimary">
                              {c.segment.description}
                            </Typography>
                            <Typography variant="body2" color="textPrimary">
                              <Moment date={c.dueDate} format="DD/MM/YYYY" />
                            </Typography>
                          </div>
                          {c.splitAgent && (
                            <Typography variant="caption" color="textSecondary">
                              Split de <Number value={c.splitPercent} display="percentage" /> para {c.splitAgent.name} ({c.splitAgent.id})
                            </Typography>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: 16,
                          }}
                        >
                          <ChevronRightIcon color="action" />
                        </div>
                      </CardContent>
                    </CardActionArea>
                  ))}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>
          ) : (
            <CardContent
              style={{
                marginBottom: 16,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography>
                <FormattedMessage id="app.user.contracts.empty" />
              </Typography>
            </CardContent>
          )}
        </CardPageItem>

        <CardPageItem raised>
          {contractsArchive && contractsArchive.length > 0 && (
            <div style={{ width: "100%" }}>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpansionPanelIcon />}>
                  <Typography>
                    <FormattedMessage id="app.archivedContracts" />
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ display: "flex", flexDirection: "column" }}>
                  {contractsArchive.map((c) => (
                    <CardActionArea disabled={currentUser.role == "KYC"} key={c.id} component={Link} to={"/userContract/" + c.id}>
                      <CardContent
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div key={c.id} style={{ flexGrow: 1 }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography color={isPremiumContract(c) ? "primary" : "textPrimary"}>{`LCContract C${c.id}`}</Typography>{" "}
                            {isPremiumContract(c) && <Typography color="primary">Premium</Typography>}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography color="textSecondary">{c.type.description}</Typography>
                            {currentUser.role != "KYC" && (
                              <Typography color="textSecondary">
                                <Number value={c.amount} currency="LCT" />
                              </Typography>
                            )}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <>
                              {c.amount > 0 && c.status == "INACTIVE" ? (
                                <Typography color="textSecondary">
                                  <FormattedMessage id="app.aport.validating" />
                                </Typography>
                              ) : (
                                <Typography color="textSecondary">
                                  <FormattedMessage id={`app.enuns.${c.phase}`} />
                                </Typography>
                              )}
                            </>
                            <Typography color="textSecondary">
                              <Moment date={c.dueDate} format="DD/MM/YYYY" />
                            </Typography>
                          </div>
                          {c.splitAgent && (
                            <Typography variant="caption" color="textSecondary">
                              Split de <Number value={c.splitPercent} display="percentage" /> para {c.splitAgent.name} ({c.splitAgent.id})
                            </Typography>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: 16,
                          }}
                        >
                          <ChevronRightIcon color="action" />
                        </div>
                      </CardContent>
                    </CardActionArea>
                  ))}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>
          )}
        </CardPageItem>

        <CardPageItem raised>
          <div style={{ width: "100%" }}>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpansionPanelIcon />}>
                <Typography variant="body1" color="textPrimary">
                  <FormattedMessage id={`app.documents`} />
                  {(currentUser.role == Roles.Admin || currentUser.role == Roles.Kyc) && (
                    <>
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={(e) => {
                          this.setState({ addDoc: true });
                        }}
                      >
                        <Add />
                      </IconButton>
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={(e) => {
                          this.setState({ addDocMulti: true });
                        }}
                      >
                        <AddMore />
                      </IconButton>
                    </>
                  )}
                </Typography>
              </ExpansionPanelSummary>

              {kyc.map((c) => (
                <ExpansionPanelDetails key={c.id}>
                  <CardActionArea component={Link} to={"/kyc/" + c.id}>
                    <Grid container direction="row" justify="space-between" alignItems="center">
                      <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                        <Grid container direction="row" justify="space-between" alignItems="center">
                          <Typography variant="caption" color="primary">
                            <FormattedMessage id={`app.enuns.${c.documentType}`} />
                          </Typography>
                          <Typography variant="caption" color="textSecondary">
                            <FormattedMessage id={`app.enuns.${c.status}`} />
                          </Typography>
                        </Grid>

                        <Grid container direction="row" justify="space-between" alignItems="center">
                          <Typography variant="caption" color="textSecondary">
                            <FormattedMessage id="app.createDate" /> <Moment date={c.createdAt} format="DD/MM/YYYY" />
                          </Typography>
                          <Typography variant="caption" color="textSecondary">
                            <Moment date={c.statusDate} format="DD/MM/YYYY" />
                          </Typography>
                        </Grid>
                        {c.description && (
                          <Grid container direction="row" justify="space-between" alignItems="center">
                            <Typography variant="caption" color="textSecondary">
                              <FormattedMessage id="app.description" />
                              {": "}
                              {c.description}
                            </Typography>
                          </Grid>
                        )}

                        {c.reason && c.status != "APPROVED" && (
                          <Grid container direction="row" justify="space-between" alignItems="center">
                            <Typography variant="caption" color="textSecondary">
                              <FormattedMessage id="app.reason" />
                              {": "}
                              {c.reason}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: 16,
                          }}
                        >
                          <ChevronRightIcon color="action" />
                        </div>
                      </Grid>
                    </Grid>
                  </CardActionArea>
                </ExpansionPanelDetails>
              ))}
            </ExpansionPanel>
          </div>
        </CardPageItem>
      </PageContainer>
    );
  }
}

const SendEmailDialog = ({ open, onClose, title, children, action, onAction }) => (
  <Dialog fullWidth={true} maxWidth="md" open={open} onClose={onClose}>
    <DialogTitle> {title}</DialogTitle>
    <DialogContent>{children}</DialogContent>
    <DialogActions>
      <Button type="submit" onClick={action} color="primary">
        <FormattedMessage id="app.send" />
      </Button>
      <Button onClick={onAction} color="primary">
        <FormattedMessage id="app.close" />
      </Button>
    </DialogActions>
  </Dialog>
);

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default compose(
  connect(mapStateToProps, actions),
  reduxForm({
    form: "dashboardForm",
  })
)(AdminUserData);
