import { CircularProgress } from "@material-ui/core";
import queryString from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getContracts } from "../actions";
import ContractReportContent from "./components/ContractReportContent";

class ContractReportRedeemed extends Component {
  state = {
    loading: true,
    dataList: [],
    title: "",
  };

  componentDidMount() {
    const urlParams = queryString.parse(this.props.location.search);
    const params = {
      statusRedeemPaid: "PAYMENT_GENERATED",
      taxResidence: urlParams.taxResidence,
    };
    getContracts(params).then((dataList) => {
      this.setState({ dataList, loading: false });
    });
    this.setState({ title: `app.contracts.redeemed${params.taxResidence ? "." + params.taxResidence : ""}`, taxResidence: params.taxResidence });
  }

  render() {
    const { loading, dataList } = this.state;
    if (loading) return <CircularProgress style={{ marginTop: "25%" }} />;
    return <ContractReportContent title={this.state.title} dataList={dataList} desc={"redeemed"} taxResidence={this.state.taxResidence} />;
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default connect(mapStateToProps)(ContractReportRedeemed);
