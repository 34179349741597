import React, { Component, Fragment } from "react";
import { Dialog, DialogContent, Slide } from "@material-ui/core";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";

class DefaultModal extends Component {
  transition(props) {
    return <Slide direction="up" {...props} />;
  }

  render() {
    const { children, toogle, width, open } = this.props;

    let fullScreen = this.props.fullScreen;
    if (typeof fullScreen == "undefined") fullScreen = !isWidthUp("lg", width);
    return (
      <Fragment>
        <Dialog
          open={open == undefined ? true : open}
          onClose={toogle}
          fullWidth
          disableBackdropClick
          disableEscapeKeyDown
          fullScreen={fullScreen}
          TransitionComponent={this.transition}
        >
          <DialogContent>
            {children && React.cloneElement(children, { ...this.props })}
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

export default withWidth()(DefaultModal);
