import {
  USER_ME,
  ACTIVATE_USER,
  SET_CONFIG,
  USER_FETCH,
  ENABLE_2FA,
  USER_PREF,
  USER_KYC,
  USER_ACC,
  USER_ADDACC,
  USER_DELACC,
  USER_ADDEW,
  USER_EW,
  USER_DELEW,
  USER_GET_WALLET,
  USER_GET_WALLETS,
  GET_USER_ADDRESS,
  THEME_CHANGED,
  USER_AVATAR,
  GET_USER,
  CLEAR_ME
} from "../actions/types";

const INITIAL_STATE = {
  user: {
    activeUser: false,
    userKyc: false,
    userAcc: [],
    userExWallet: [],
    emailNotifications: false,
    transactionsNeed2faConfirmation: false,
    loginByEmailToken: false,

    me: {}
  },
  isFetching: false,
  twoStep: {},
  customerOtc: {},
  confirmEmail: {}
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ACTIVATE_USER:
      return { ...state, activeUser: action.payload };
    case USER_ME:
      return {
        ...state,
        user: { ...state.user, me: { ...state.user.me, ...action.payload } }
      };
    case CLEAR_ME:
      return {
        ...state,
        user: { ...state.user, me: {} }
      };
    case SET_CONFIG:
      return { ...state, user: { ...state.user, me: action.payload } };
    case USER_PREF:
      return { ...state, user: { ...state.user, ...action.payload } };
    case USER_ACC:
      return { ...state, user: { ...state.user, userAcc: action.payload } };
    case USER_ADDACC:
      return {
        ...state,
        user: {
          ...state.user,
          userAcc: state.user.userAcc.concat(action.payload)
        }
      };
    case USER_DELACC:
      return {
        ...state,
        user: {
          ...state.user,
          userAcc: state.user.userAcc.filter(acc => acc.id !== action.payload)
        }
      };
    case USER_EW:
      return {
        ...state,
        user: { ...state.user, userExWallet: action.payload }
      };
    case USER_ADDEW:
      return {
        ...state,
        user: {
          ...state.user,
          userExWallet: state.user.userExWallet.concat(action.payload)
        }
      };
    case USER_DELEW:
      return {
        ...state,
        user: {
          ...state.user,
          userExWallet: state.user.userExWallet.filter(externalWallet => externalWallet.id !== action.payload)
        }
      };
    case USER_FETCH:
      return { ...state, isFetching: !state.isFetching };
    case USER_KYC:
      return { ...state, user: { ...state.user, userKyc: action.payload } };
    case ENABLE_2FA:
      return { ...state, twoStep: action.payload };
    case USER_GET_WALLET:
      return {
        ...state,
        user: {
          ...state.user,
          me: { ...state.user.me, walletUser: action.payload }
        }
      };
    case USER_GET_WALLETS:
      action.payload.map(w => {
        let currency = action.payload.market.currencie.filter(c => c.id == w.currencyId)[0];
        w.freeBalance = w.balance - w.blockedBalance;
        w.currency = currency;
        return (w.currencyBanner = "static/banners/card" + currency.code.toLowerCase() + ".jpg");
      });
      return {
        ...state,
        user: {
          ...state.user,
          me: { ...state.user.me, walletsUser: action.payload }
        }
      };
    case GET_USER_ADDRESS:
      return {
        ...state,
        user: {
          ...state.user,
          me: { ...state.user.me, addressUser: action.payload }
        }
      };
    case THEME_CHANGED:
      return {
        ...state,
        user: {
          ...state.user,
          me: { ...state.user.me, themeId: action.payload }
        }
      };
    case USER_AVATAR:
      return {
        ...state,
        user: { ...state.user, me: { ...state.user.me, ...action.payload } }
      };
    case GET_USER:
      return { ...state, customerOtc: action.payload };
    default:
      return state;
  }
}
