import { CardContent, FormControl, IconButton, List, ListItem, ListItemText, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import ChevronRight from "@material-ui/icons/ChevronRight";
import IconSearch from "@material-ui/icons/Search";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import * as actions from "../actions";
import HeaderPageItem from "../custom/HeaderPageItem";
import PageContainer, { CardPageItem } from "../custom/Page";
import { Roles } from "../util";
import { country } from "./components/Terms";

class MenuReports extends Component {
  state = {
    taxResidence: this.props.currentUser.taxResidence,
    searchId: "",
  };

  ReportListItem = ({ captionId, path, taxResidence }) => {
    const { currentUser } = this.props;
    const taxParam = [Roles.Admin, Roles.FinanceManager].includes(currentUser.role) ? "?taxResidence=" + taxResidence : "";
    return (
      <ListItem button component={Link} to={path + taxParam}>
        <ListItemText primary={<FormattedMessage id={captionId} />} />
        <ChevronRight color="action" />
      </ListItem>
    );
  };

  ReportMenuList = ({ taxResidence }) => {
    return (
      <List>
        <this.ReportListItem captionId="app.contracts.justopened" path="/contractReportJustOpened" taxResidence={taxResidence} />
        <this.ReportListItem captionId="app.contracts.reportwaitingvalidation" path="/contractReportWaitingValidation" taxResidence={taxResidence} />
        <this.ReportListItem captionId="app.lccontracts.active" path="/contractReport" taxResidence={taxResidence} />
        <this.ReportListItem captionId="app.contracts.terminated" path="/contractReportTerminated" taxResidence={taxResidence} />
        <this.ReportListItem captionId="app.contracts.redeemed" path="/contractReportRedeemed" taxResidence={taxResidence} />
      </List>
    );
  };

  ManagerMenuList = () => {
    const { currentUser } = this.props;
    const taxResidence = "Brasil";
    return (
      <List>
        {/*<this.ReportListItem captionId="app.report.hierarchical" path="/reportHierarchical" taxResidence={taxResidence} />*/}
        {/*
          <this.ReportListItem captionId="Contracts commissions report" path="/contractsCommissions" taxResidence={taxResidence} />
          <this.ReportListItem captionId="Portfolio report" path="/agentPortfolio" taxResidence={taxResidence} />
        */}
        <this.ReportListItem captionId="Agents financial view" path="/agentsFinancial" taxResidence={taxResidence} />
        <this.ReportListItem captionId="app.report.managerAndAgents" path="/managerAndAgents" taxResidence={taxResidence} />
      </List>
    );
  };

  ReportMenu = ({ taxResidence }) => {
    return (
      <CardPageItem>
        <CardContent>
          <this.ReportMenuList taxResidence={taxResidence} />
        </CardContent>
      </CardPageItem>
    );
  };

  render() {
    const { currentUser } = this.props;
    const { taxResidence, searchId } = this.state;
    return (
      <PageContainer>
        <HeaderPageItem title="app.reports" showBackButton destination="/dashboard" />
        {[Roles.Admin, Roles.FinanceManager].includes(currentUser.role) && (
          <CardPageItem raised>
            <CardContent>
              <Typography variant="h6">
                <FormattedMessage id="app.user.report" />
              </Typography>
              <List>
                <this.ReportListItem captionId="app.user.report" path="/userReport" taxResidence="" />
              </List>
            </CardContent>
          </CardPageItem>
        )}

        <CardPageItem raised>
          <CardContent>
            <Typography variant="h6">
              <FormattedMessage id="app.search" />
            </Typography>
            <div style={{ display: "grid", gridGap: 4, gridTemplateColumns: "1fr 44px", marginTop: 16 }}>
              <TextField
                id="searchContract"
                label={<FormattedMessage id="app.search.contractId" />}
                variant="outlined"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ flexGrow: 1 }}
                onChange={(e) => this.setState({ searchId: e.target.value })}
              />
              <IconButton href={`/userContract/${searchId}`} target="_blank">
                <IconSearch fontSize="small" color="primary" />
              </IconButton>
            </div>
          </CardContent>
        </CardPageItem>

        <CardPageItem raised>
          <CardContent>
            <Typography variant="h6">
              <FormattedMessage id="app.report.lccontracts" />
            </Typography>
            {(currentUser.role == Roles.Admin || currentUser.role == Roles.FinanceManager) && (
              <>
                <Typography gutterBottom>
                  <FormattedMessage id="app.report.lccontracts.selectTaxResidence" />
                </Typography>
                <FormControl variant="outlined" fullWidth>
                  <Select value={taxResidence} onChange={(e) => this.setState({ taxResidence: e.target.value })}>
                    {country.map((ctry) => (
                      <MenuItem key={ctry.value} value={ctry.value}>
                        <FormattedMessage id={ctry.input} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
            <this.ReportMenuList taxResidence={taxResidence} />
          </CardContent>
        </CardPageItem>

        <CardPageItem raised>
          <CardContent>
            <Typography variant="h6">
              <FormattedMessage id="app.report.manager" />
            </Typography>
            <Typography variant="caption" color="error">
              Os relatórios desta seção estão em teste. Favor reportar eventuais inconsitências que forem detectadas.
            </Typography>
            <this.ManagerMenuList />
          </CardContent>
        </CardPageItem>
      </PageContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default compose(connect(mapStateToProps, actions))(MenuReports);

/*
<ListItem button onClick={this.contractReport(null,null,"PAYMENT_GENERATED","Brasil")}>
	<ListItemText primary={<FormattedMessage id="teste redeem" />} />
	<ChevronRight color="action" />
</ListItem>
*/
