import { AppBar, Button, Card, CardActionArea, CardContent, CircularProgress, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/Settings";
import moment from "moment";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import Moment from "react-moment";
import * as rssParser from "react-native-rss-parser";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import * as actions from "../actions";
import { getKyc } from "../actions";
import { getContracts } from "../actions/Contract";
import { getComissionPaymentsValue, getProfitPaymentsValue } from "../actions/Payment";
import AbtcAvatar from "../custom/Avatar";
import { badgesKyc } from "../custom/BadgesKyc";
import HeaderPageItem from "../custom/HeaderPageItem";
import Number from "../custom/Number";
import PageContainer, { CardPageItem, PageItem } from "../custom/Page";
import SvgPremiumAvatar from "../static/premium_avatar.svg";
import { getDataFromServer } from "../util";

class Dashboard extends Component {
  state = {
    loading: true,
    comissionValue: {},
    profitValue: {},
    contractsWaitingSign: [],
    contractsWaitingAport: [],
    contractsWaitingValidation: [],
    contractsWaitingFinalSign: [],
    contractsWaitingRedeem: [],
    contractsRedeemedWaitingPay: [],
    contractsRedeemedPaid: [],
    contractsActiveRecent: [],
    contractsExpiring: [],
    pj: this.props.currentUser.isPj,
    rss: undefined,
    smallHeader: false,
  };

  adjustHeaderOnScroll = () => {
    this.setState({ smallHeader: document.scrollingElement.scrollTop > 70 });
  };

  componentDidMount() {
    const { currentUser } = this.props;
    const currentMonth = moment();

    Promise.all([
      getKyc().then((data) => {
        this.setState({ userKyc: data });
      }),

      getComissionPaymentsValue(currentMonth.format("YYYY-MM-01"), currentMonth.endOf("month").format("YYYY-MM-DD")).then((data) => {
        this.setState({ comissionValue: data });
      }),

      getProfitPaymentsValue(currentMonth.format("YYYY-MM-01"), currentMonth.endOf("month").format("YYYY-MM-DD")).then((data) => {
        this.setState({ profitValue: data });
      }),

      getContracts({
        phase: "WAITING_SIGN",
        taxResidence: currentUser.taxResidence,
        consultantId: currentUser.id,
      }).then((data) => {
        this.setState({ contractsWaitingSign: data });
      }),

      getContracts({
        phase: "WAITING_APORT",
        taxResidence: currentUser.taxResidence,
        consultantId: currentUser.id,
      }).then((data) => {
        this.setState({ contractsWaitingAport: data.filter((c) => c.deposits.length === 0) });
        this.setState({ contractsWaitingValidation: data.filter((c) => c.deposits.length > 0) });
      }),

      getContracts({ phase: "VALIDATING", taxResidence: currentUser.taxResidence, consultantId: currentUser.id }).then((data) => {
        this.setState({ contractsWaitingFinalSign: data });
      }),

      getContracts({
        phase: "WAITING_WITHDRAW",
        taxResidence: currentUser.taxResidence,
        consultantId: currentUser.id,
      }).then((data) => {
        this.setState({ contractsWaitingRedeem: data });
      }),

      getContracts({
        statusRedeemPaid: "PAYMENT_GENERATED",
        taxResidence: currentUser.taxResidence,
        consultantId: currentUser.id,
      }).then((data) => {
        this.setState({ contractsRedeemedWaitingPay: data });
      }),

      getContracts({
        statusRedeemPaid: "PAID",
        taxResidence: currentUser.taxResidence,
        consultantId: currentUser.id,
      }).then((data) => {
        this.setState({
          contractsRedeemedPaid: data.filter((c) => moment().subtract(8, "days").isBefore(c.dueDate) && moment().subtract(8, "days").isBefore(c.archiveDate)),
        });
      }),

      getContracts({ status: "ACTIVE", taxResidence: currentUser.taxResidence, consultantId: currentUser.id }).then((data) => {
        data = data.filter((c) => c.user.id !== currentUser.id);
        this.setState({
          contractsActiveRecent: data.filter((c) => moment().subtract(8, "days").isBefore(c.initialDate)),
        });
        this.setState({ contractsExpiring: data.filter((c) => moment().add(15, "days").isAfter(c.dueDate)) });
      }),
    ]).then(() => this.setState({ loading: false }));

    getDataFromServer("GET", "/ext/blogFeed", null, true)
      .then((xml) => rssParser.parse(xml))
      .then((rss) => {
        const languageCheck = currentUser.locale === "es" ? "/es/" : currentUser.locale === "br" ? "/pt/" : "capital/post";
        rss = rss.items.filter((item) => item.links[0].url.includes(languageCheck)).slice(0, 5);
        this.setState({ rss });
      });

    document.addEventListener("scroll", this.adjustHeaderOnScroll);
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.adjustHeaderOnScroll);
  }

  pendent = (badges, currentUser) => {
    if (currentUser.telefone == null && currentUser.telefone2 == null) {
      badges.qtd = badges.qtd + 1;
      badges.cor = "error";
    }
    if (!currentUser.emailConfirmed) {
      badges.qtd = badges.qtd + 1;
      badges.cor = "error";
    }
    return badges;
  };

  someHasValue = (arrayOfArray) => arrayOfArray.some((a) => a.length);

  render() {
    const { currentUser } = this.props;
    const { loading, comissionValue, profitValue, userKyc, pj, rss, smallHeader } = this.state;
    const { contractsWaitingSign, contractsWaitingAport, contractsWaitingValidation, contractsWaitingFinalSign, contractsExpiring, contractsWaitingRedeem } = this.state;
    const { contractsActiveRecent, contractsRedeemedWaitingPay, contractsRedeemedPaid } = this.state;

    let badges = badgesKyc(pj, userKyc);
    badges = this.pendent(badges, currentUser);

    const blogLang = currentUser.locale === "es" ? "es/" : currentUser.locale === "br" ? "pt/" : "";

    if (loading) return <CircularProgress style={{ marginTop: "25%" }} />;

    return (
      <>
        <PageContainer removeSpacing>
          <AvatarHeaderPageItem currentUser={currentUser} badges={badges} small={smallHeader} />

          <PageItem style={{ marginBottom: 16 }}>
            <Typography variant="h6" style={{ textAlign: "center" }} color={currentUser.premium ? "primary" : undefined}>
              {currentUser.name}
            </Typography>
          </PageItem>

          <SummaryPageItem comissionValue={comissionValue} profitValue={profitValue} hideValues={currentUser.hideValues} />

          {this.someHasValue([contractsWaitingSign, contractsWaitingAport, contractsWaitingValidation, contractsWaitingFinalSign, contractsExpiring, contractsWaitingRedeem]) && (
            <PageItem style={{ padding: 16, marginTop: 16 }}>
              <Typography variant="h6" color="textPrimary" align="center">
                <FormattedMessage id="app.agent.dashboard.title.requiresAttention" />
              </Typography>
              <>
                <ContractCardList title="app.agent.dashboard.waitingSignContracts" list={contractsWaitingSign} dateField="createdAt" />
                <ContractCardList title="app.agent.dashboard.WaitingAportContracts" list={contractsWaitingAport} dateField="createdAt" />
                <ContractCardList title="app.agent.dashboard.WaitingValidationContracts" list={contractsWaitingValidation} dateField="createdAt" />
                <ContractCardList title="app.agent.dashboard.waitingFinalSignContracts" list={contractsWaitingFinalSign} dateField="createdAt" />
                <ContractCardList title="app.agent.dashboard.finishingContracts" list={contractsExpiring} dateField="dueDate" />
                <ContractCardList title="app.agent.dashboard.waitingRedeemContracts" list={contractsWaitingRedeem} dateField="dueDate" />
              </>
            </PageItem>
          )}
          {this.someHasValue([contractsActiveRecent, contractsRedeemedWaitingPay, contractsRedeemedPaid]) && (
            <PageItem style={{ padding: 16, marginTop: 16 }}>
              <Typography variant="h6" color="textPrimary" align="center">
                <FormattedMessage id="app.agent.dashboard.title.recentActivity" />
              </Typography>
              <>
                <ContractCardList title="app.agent.dashboard.activeRecentContracts" list={contractsActiveRecent} dateField="initialDate" />
                <ContractCardList title="app.agent.dashboard.redeemedWaitingPayContracts" list={contractsRedeemedWaitingPay} dateField="archiveDate" />
                <ContractCardList title="app.agent.dashboard.redeemedPaidContracts" list={contractsRedeemedPaid} dateField="archiveDate" />
              </>
            </PageItem>
          )}
          {rss && (
            <CardPageItem raised style={{ marginTop: 16 }}>
              <CardContent>
                <Typography gutterBottom variant="body1" style={{ textAlign: "center" }}>
                  <FormattedMessage id="app.dashboard.blog.title" />
                </Typography>
                <List dense>
                  {rss.map((item) => (
                    <ListItem button component="a" target="_blank" href={item.links[0].url} key={item.id}>
                      <ListItemText primary={item.title} secondary={<Moment fromNow date={item.published} />} />
                      <ListItemSecondaryAction>
                        <ChevronRight />
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </CardPageItem>
          )}

          <PageItem style={{ textAlign: "center", marginTop: 32, marginBottom: 16 }}>
            <Typography variant="body2" color="textSecondary">
              <FormattedMessage id="app.dashboard.text1" />
            </Typography>
            <Button variant="outlined" color="primary" target="_blank" href={`https://www.lux.capital/${blogLang}blog`}>
              <FormattedMessage id="app.access.blog" />
            </Button>
          </PageItem>
        </PageContainer>
      </>
    );
  }
}

const AvatarHeaderPageItem = ({ currentUser, badges, small }) => {
  const appBarPadding = small ? 14 : 30;
  const avatarSize = small ? 70 : 160;
  const avatarBorder = small ? "2px #1A1A1A solid" : "8px rgba(254, 193, 44, 1) solid";
  const nameVariant = small ? "body1" : "h6";
  const nameColor = small ? "secondary" : "textPrimary";
  const nameMarginTop = small ? 0 : 8;
  return (
    <HeaderPageItem style={{ height: 215 }}>
      <AppBar
        position="relative"
        style={{
          height: 110,
          paddingTop: 30,
          display: "flex",
          alignItems: "center",
        }}
        color={currentUser.premium ? "secondary" : "primary"}
      >
        <IconButton style={{ position: "absolute", left: 0, top: 0 }} component={Link} to="/logout" color={currentUser.premium ? "primary" : "secondary"}>
          <ExitToAppIcon style={{ transform: "rotate(180deg)" }} />
        </IconButton>
        <IconButton style={{ position: "absolute", right: 0, top: 0 }} component={Link} to="/more" color={currentUser.premium ? "primary" : "secondary"}>
          <Badge badgeContent={badges.qtd} color={badges.cor}>
            <SettingsIcon />
          </Badge>
        </IconButton>
        <AbtcAvatar imageUrl={currentUser.photoUrl} disableLink size={160} avatarStyle={currentUser.premium ? {} : { border: "8px rgba(254, 193, 44, 1) solid" }} style={{}} />
        {currentUser.premium && <img src={SvgPremiumAvatar} style={{ position: "relative", width: 176, height: 176, top: -169 }} />}
      </AppBar>
    </HeaderPageItem>
  );
};

const SummaryPageItem = ({ comissionValue, profitValue, hideValues }) => (
  <>
    {(!!comissionValue.val || !!profitValue.val) && (
      <CardPageItem style={{ textAlign: "center" }} raised>
        <CardActionArea component={Link} to={"/history/"}>
          <CardContent>
            {!!comissionValue.val && (
              <>
                <Typography variant="caption" color="textSecondary">
                  <FormattedMessage id="app.agent.dashboard.text1" />
                </Typography>
                <Typography variant="h3" color="primary">
                  <Number value={comissionValue.val} currency="LCT" hideValue={hideValues} />
                </Typography>
              </>
            )}

            {!!profitValue.val && (
              <>
                <div style={{ borderBottom: "#666 1px solid", margin: 16 }} />
                <Typography variant="caption" color="textSecondary">
                  <FormattedMessage id="app.agent.dashboard.text4" />
                </Typography>
                <Typography variant="h3" color="primary">
                  <Number value={profitValue.val} currency="LCT" hideValue={hideValues} />
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  <small>
                    (<FormattedMessage id="app.agent.dashboard.text5" />)
                  </small>
                </Typography>
              </>
            )}
          </CardContent>
        </CardActionArea>
      </CardPageItem>
    )}
  </>
);

const ContractCardList = ({ title, list, dateField }) => (
  <>
    {list &&
      list.length > 0 &&
      list.map((c) => (
        <Card key={c.id} elevation={1} style={{ marginTop: 8 }}>
          <CardActionArea component={Link} to={"/userContract/" + c.id}>
            <CardContent>
              <Typography variant="body2">{c.user.name}</Typography>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="caption" color="textSecondary">
                  C{c.id}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  <FormattedMessage id={"app.agent.dashboard.contract." + dateField} /> <Moment date={c[dateField]} format="DD/MM/YYYY" />
                </Typography>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div></div>
                <Typography variant="body2" color="primary">
                  <FormattedMessage id={title} />
                </Typography>
              </div>
            </CardContent>
          </CardActionArea>
        </Card>
      ))}
  </>
);

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default compose(connect(mapStateToProps, actions))(Dashboard);
