import React, { Component } from "react";
import { Button, FormControlLabel, Checkbox, CardContent } from "@material-ui/core";
import { reduxForm, Field } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import CustomInputForm from "../custom/CustomInput";
import performRequest from "../configAxios";
import * as actions from "../actions";
import { createTextMask } from "redux-form-input-masks";
import PageContainer, { PageItem } from "../custom/Page";
import HeaderPageItem from "../custom/HeaderPageItem";
import { getAddressInfo } from "../actions";
import { FormattedMessage } from "react-intl";

function FormCheckbox({ input, label, ...props }) {
  return <Checkbox label={label} checked={input.value ? true : false} onChange={input.onChange} {...props} />;
}

class AgentFormCustomer extends Component {
  onSubmit = (formProps) => {
    const { showErrorMessage, showSuccessMessage } = this.props;
    performRequest("POST", "/user", formProps, true)
      .then((response) => {
        showSuccessMessage("app.create");
        this.props.history.push("/customers");
      })
      .catch((e) => {
        if (e.response) {
          showErrorMessage(e.response.data.message);
        } else {
          showErrorMessage(e);
        }
      });
  };

  verifyCep = (cep) => {
    if (cep.length == 8) {
      getAddressInfo(cep.replace(/[^A-Za-z0-9]/gi, ""))
        .then((data) => {
          if (!data.erro) {
            this.props.change("address", data.logradouro + " - " + data.bairro);
            this.props.change("city", data.localidade);
            this.props.change("state", data.uf);
            this.props.change("country", "Brasil");
          }
        })
        .catch((e) => {});
    }
  };

  render() {
    const { handleSubmit } = this.props;
    const createPhoneMask = createTextMask({
      pattern: "(99) 9 9999-9999",
    });
    return (
      <PageContainer>
        <PageItem>
          <HeaderPageItem title="app.newCustomer" />
        </PageItem>
        <PageItem>
          <CardContent>
            <form onSubmit={handleSubmit(this.onSubmit)}>
              <Field
                name="email"
                label="app.email"
                type="email"
                InputLabelProps={{
                  shrink: true,
                }}
                component={CustomInputForm}
              />
              <Field
                name="name"
                label="app.name"
                InputLabelProps={{
                  shrink: true,
                }}
                component={CustomInputForm}
              />
              <Field
                name="documentId"
                label="Cpf/Cnpj"
                InputLabelProps={{
                  shrink: true,
                }}
                component={CustomInputForm}
              />

              <Field
                name="zipCode"
                label="app.zipCode"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 17,
                }}
                component={CustomInputForm}
                onChange={(e) => this.verifyCep(e.target.value)}
              />
              <Field
                name="address"
                label="app.address"
                InputLabelProps={{
                  shrink: true,
                }}
                component={CustomInputForm}
              />
              <Field
                name="city"
                label="app.city"
                InputLabelProps={{
                  shrink: true,
                }}
                component={CustomInputForm}
              />
              <Field
                name="state"
                label="app.state"
                InputLabelProps={{
                  shrink: true,
                }}
                component={CustomInputForm}
              />
              <Field
                name="country"
                label="app.country"
                InputLabelProps={{
                  shrink: true,
                }}
                component={CustomInputForm}
              />
              <div>
                <Field
                  name="telefone"
                  label="app.phone"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  component={CustomInputForm}
                  {...createPhoneMask}
                />
                <FormControlLabel label={<FormattedMessage id="app.whatsapp" />} control={<Field component={FormCheckbox} name="isWhats" color="primary" />} />
              </div>
              <div>
                <Field
                  name="telefone2"
                  label="app.phone2"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  component={CustomInputForm}
                  {...createPhoneMask}
                />

                <FormControlLabel label={<FormattedMessage id="app.whatsapp" />} control={<Field component={FormCheckbox} name="isWhats2" color="primary" />} />
              </div>
              <Button
                id="bSubmitOrderForm"
                style={{ marginTop: 10 }}
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={this.props.pristine || this.props.submitting || this.props.invalid}
              >
                <FormattedMessage id="app.button.save" />
              </Button>
            </form>
          </CardContent>
        </PageItem>
      </PageContainer>
    );
  }
}

const validate = (values, props) => {
  const errors = {};
  if (!values.name) {
    errors.name = "app.form.name.error";
  }
  if (!values.email) {
    errors.email = "app.form.email.error";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+$/i.test(values.email)) {
    errors.email = "app.form.email.invalid";
  }
  if (!values.documentId) {
    errors.documentId = "app.form.cpf.error";
  }

  if (!values.zipCode) {
    errors.zipCode = "app.form.zipcode.error";
  }
  if (!values.address) {
    errors.address = "app.form.address.error";
  }
  if (!values.city) {
    errors.city = "app.form.city.error";
  }
  if (!values.state) {
    errors.state = "app.form.state.error";
  }
  if (!values.country) {
    errors.country = "app.form.country.error";
  }
  if (!values.telefone) {
    errors.telefone = "app.form.phone.error";
  }
  return errors;
};

export default compose(
  reduxForm({
    form: "customerForm",
    validate,
  }),
  connect(null, actions)
)(AgentFormCustomer);
