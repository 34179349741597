import moment from "moment-business-days";
import * as momentTimezone from "moment-timezone";

export const TimeZones = [
  { value: "Australia/ACT", input: "ACT +10:00" },
  { value: "Africa/Abidjan", input: "Abidjan +00:00" },
  { value: "Africa/Accra", input: "Accra +00:00" },
  { value: "Brazil/Acre", input: "Acre -05:00" },
  { value: "America/Adak", input: "Adak -09:00" },
  { value: "Africa/Addis_Ababa", input: "Addis Ababa +03:00" },
  { value: "Australia/Adelaide", input: "Adelaide +09:30" },
  { value: "Asia/Aden", input: "Aden +03:00" },
  { value: "US/Alaska", input: "Alaska -08:00" },
  { value: "US/Aleutian", input: "Aleutian -09:00" },
  { value: "Africa/Algiers", input: "Algiers +01:00" },
  { value: "Asia/Almaty", input: "Almaty +06:00" },
  { value: "Asia/Amman", input: "Amman +03:00" },
  { value: "Europe/Amsterdam", input: "Amsterdam +02:00" },
  { value: "Asia/Anadyr", input: "Anadyr +12:00" },
  { value: "America/Anchorage", input: "Anchorage -08:00" },
  { value: "Europe/Andorra", input: "Andorra +02:00" },
  { value: "America/Anguilla", input: "Anguilla -04:00" },
  { value: "Indian/Antananarivo", input: "Antananarivo +03:00" },
  { value: "America/Antigua", input: "Antigua -04:00" },
  { value: "Pacific/Apia", input: "Apia +13:00" },
  { value: "Asia/Aqtau", input: "Aqtau +05:00" },
  { value: "Asia/Aqtobe", input: "Aqtobe +05:00" },
  { value: "America/Araguaina", input: "Araguaina -03:00" },
  { value: "US/Arizona", input: "Arizona -07:00" },
  { value: "America/Aruba", input: "Aruba -04:00" },
  { value: "Asia/Ashgabat", input: "Ashgabat +05:00" },
  { value: "Asia/Ashkhabad", input: "Ashkhabad +05:00" },
  { value: "Africa/Asmara", input: "Asmara +03:00" },
  { value: "Africa/Asmera", input: "Asmera +03:00" },
  { value: "Europe/Astrakhan", input: "Astrakhan +04:00" },
  { value: "America/Asuncion", input: "Asuncion -04:00" },
  { value: "Europe/Athens", input: "Athens +03:00" },
  { value: "America/Atikokan", input: "Atikokan -05:00" },
  { value: "America/Atka", input: "Atka -09:00" },
  { value: "Canada/Atlantic", input: "Atlantic -03:00" },
  { value: "Asia/Atyrau", input: "Atyrau +05:00" },
  { value: "Pacific/Auckland", input: "Auckland +12:00" },
  { value: "Atlantic/Azores", input: "Azores +00:00" },
  { value: "Asia/Baghdad", input: "Baghdad +03:00" },
  { value: "America/Bahia", input: "Bahia -03:00" },
  { value: "America/Bahia_Banderas", input: "Bahia Banderas -05:00" },
  { value: "Asia/Bahrain", input: "Bahrain +03:00" },
  { value: "Mexico/BajaNorte", input: "BajaNorte -07:00" },
  { value: "Mexico/BajaSur", input: "BajaSur -06:00" },
  { value: "Asia/Baku", input: "Baku +04:00" },
  { value: "Africa/Bamako", input: "Bamako +00:00" },
  { value: "Asia/Bangkok", input: "Bangkok +07:00" },
  { value: "Africa/Bangui", input: "Bangui +01:00" },
  { value: "Africa/Banjul", input: "Banjul +00:00" },
  { value: "America/Barbados", input: "Barbados -04:00" },
  { value: "Asia/Barnaul", input: "Barnaul +07:00" },
  { value: "Asia/Beirut", input: "Beirut +03:00" },
  { value: "America/Belem", input: "Belem -03:00" },
  { value: "Europe/Belfast", input: "Belfast +01:00" },
  { value: "Europe/Belgrade", input: "Belgrade +02:00" },
  { value: "America/Belize", input: "Belize -06:00" },
  { value: "Europe/Berlin", input: "Berlin +02:00" },
  { value: "Atlantic/Bermuda", input: "Bermuda -03:00" },
  { value: "America/North_Dakota/Beulah", input: "Beulah -05:00" },
  { value: "Asia/Bishkek", input: "Bishkek +06:00" },
  { value: "Africa/Bissau", input: "Bissau +00:00" },
  { value: "America/Blanc-Sablon", input: "Blanc-Sablon -04:00" },
  { value: "Africa/Blantyre", input: "Blantyre +02:00" },
  { value: "America/Boa_Vista", input: "Boa Vista -04:00" },
  { value: "America/Bogota", input: "Bogota -05:00" },
  { value: "America/Boise", input: "Boise -06:00" },
  { value: "Pacific/Bougainville", input: "Bougainville +11:00" },
  { value: "Europe/Bratislava", input: "Bratislava +02:00" },
  { value: "Africa/Brazzaville", input: "Brazzaville +01:00" },
  { value: "Australia/Brisbane", input: "Brisbane +10:00" },
  { value: "Australia/Broken_Hill", input: "Broken Hill +09:30" },
  { value: "Asia/Brunei", input: "Brunei +08:00" },
  { value: "Europe/Brussels", input: "Brussels +02:00" },
  { value: "Europe/Bucharest", input: "Bucharest +03:00" },
  { value: "Europe/Budapest", input: "Budapest +02:00" },
  { value: "America/Argentina/Buenos_Aires", input: "Buenos Aires -03:00" },
  { value: "Africa/Bujumbura", input: "Bujumbura +02:00" },
  { value: "Europe/Busingen", input: "Busingen +02:00" },
  { value: "CET", input: "CET +02:00" },
  { value: "CST6CDT", input: "CST6CDT -05:00" },
  { value: "Africa/Cairo", input: "Cairo +02:00" },
  { value: "Asia/Calcutta", input: "Calcutta +05:30" },
  { value: "America/Cambridge_Bay", input: "Cambridge Bay -06:00" },
  { value: "America/Campo_Grande", input: "Campo Grande -04:00" },
  { value: "Atlantic/Canary", input: "Canary +01:00" },
  { value: "Australia/Canberra", input: "Canberra +10:00" },
  { value: "America/Cancun", input: "Cancun -05:00" },
  { value: "Atlantic/Cape_Verde", input: "Cape Verde -01:00" },
  { value: "America/Caracas", input: "Caracas -04:00" },
  { value: "Africa/Casablanca", input: "Casablanca +01:00" },
  { value: "Antarctica/Casey", input: "Casey +11:00" },
  { value: "America/Argentina/Catamarca", input: "Catamarca -03:00" },
  { value: "America/Catamarca", input: "Catamarca -03:00" },
  { value: "America/Cayenne", input: "Cayenne -03:00" },
  { value: "America/Cayman", input: "Cayman -05:00" },
  { value: "America/North_Dakota/Center", input: "Center -05:00" },
  { value: "Canada/Central", input: "Central -05:00" },
  { value: "Africa/Ceuta", input: "Ceuta +02:00" },
  { value: "Indian/Chagos", input: "Chagos +06:00" },
  { value: "Pacific/Chatham", input: "Chatham +12:45" },
  { value: "America/Chicago", input: "Chicago -05:00" },
  { value: "America/Chihuahua", input: "Chihuahua -06:00" },
  { value: "Europe/Chisinau", input: "Chisinau +03:00" },
  { value: "Asia/Chita", input: "Chita +09:00" },
  { value: "Asia/Choibalsan", input: "Choibalsan +08:00" },
  { value: "Asia/Chongqing", input: "Chongqing +08:00" },
  { value: "Indian/Christmas", input: "Christmas +07:00" },
  { value: "Asia/Chungking", input: "Chungking +08:00" },
  { value: "Pacific/Chuuk", input: "Chuuk +10:00" },
  { value: "Indian/Cocos", input: "Cocos +06:30" },
  { value: "Asia/Colombo", input: "Colombo +05:30" },
  { value: "America/Argentina/ComodRivadavia", input: "ComodRivadavia -03:00" },
  { value: "Indian/Comoro", input: "Comoro +03:00" },
  { value: "Africa/Conakry", input: "Conakry +00:00" },
  { value: "Chile/Continental", input: "Continental -04:00" },
  { value: "Europe/Copenhagen", input: "Copenhagen +02:00" },
  { value: "America/Coral_Harbour", input: "Coral Harbour -05:00" },
  { value: "America/Argentina/Cordoba", input: "Cordoba -03:00" },
  { value: "America/Cordoba", input: "Cordoba -03:00" },
  { value: "America/Costa_Rica", input: "Costa Rica -06:00" },
  { value: "America/Creston", input: "Creston -07:00" },
  { value: "Cuba", input: "Cuba -04:00" },
  { value: "America/Cuiaba", input: "Cuiaba -04:00" },
  { value: "America/Curacao", input: "Curacao -04:00" },
  { value: "Australia/Currie", input: "Currie +10:00" },
  { value: "Asia/Dacca", input: "Dacca +06:00" },
  { value: "Africa/Dakar", input: "Dakar +00:00" },
  { value: "Asia/Damascus", input: "Damascus +03:00" },
  { value: "America/Danmarkshavn", input: "Danmarkshavn +00:00" },
  { value: "Africa/Dar_es_Salaam", input: "Dar es_Salaam +03:00" },
  { value: "Australia/Darwin", input: "Darwin +09:30" },
  { value: "Antarctica/Davis", input: "Davis +07:00" },
  { value: "America/Dawson_Creek", input: "Dawson Creek -07:00" },
  { value: "Brazil/DeNoronha", input: "DeNoronha -02:00" },
  { value: "America/Denver", input: "Denver -06:00" },
  { value: "America/Detroit", input: "Detroit -04:00" },
  { value: "Asia/Dhaka", input: "Dhaka +06:00" },
  { value: "Asia/Dili", input: "Dili +09:00" },
  { value: "Africa/Djibouti", input: "Djibouti +03:00" },
  { value: "America/Dominica", input: "Dominica -04:00" },
  { value: "Africa/Douala", input: "Douala +01:00" },
  { value: "Asia/Dubai", input: "Dubai +04:00" },
  { value: "Europe/Dublin", input: "Dublin +01:00" },
  { value: "Antarctica/DumontDUrville", input: "DumontDUrville +10:00" },
  { value: "Asia/Dushanbe", input: "Dushanbe +05:00" },
  { value: "EET", input: "EET +03:00" },
  { value: "EST", input: "EST -05:00" },
  { value: "EST5EDT", input: "EST5EDT -04:00" },
  { value: "Brazil/East", input: "East -03:00" },
  { value: "US/East-Indiana", input: "East-Indiana -04:00" },
  { value: "Pacific/Easter", input: "Easter -06:00" },
  { value: "Chile/EasterIsland", input: "EasterIsland -06:00" },
  { value: "Canada/Eastern", input: "Eastern -04:00" },
  { value: "America/Edmonton", input: "Edmonton -06:00" },
  { value: "Pacific/Efate", input: "Efate +11:00" },
  { value: "Egypt", input: "Egypt +02:00" },
  { value: "Eire", input: "Eire +01:00" },
  { value: "America/Eirunepe", input: "Eirunepe -05:00" },
  { value: "Africa/El_Aaiun", input: "El Aaiun +01:00" },
  { value: "America/El_Salvador", input: "El Salvador -06:00" },
  { value: "Pacific/Enderbury", input: "Enderbury +13:00" },
  { value: "America/Ensenada", input: "Ensenada -07:00" },
  { value: "Australia/Eucla", input: "Eucla +08:45" },
  { value: "Atlantic/Faeroe", input: "Faeroe +01:00" },
  { value: "Pacific/Fakaofo", input: "Fakaofo +13:00" },
  { value: "Asia/Famagusta", input: "Famagusta +03:00" },
  { value: "Atlantic/Faroe", input: "Faroe +01:00" },
  { value: "Pacific/Fiji", input: "Fiji +12:00" },
  { value: "America/Fort_Nelson", input: "Fort Nelson -07:00" },
  { value: "America/Fort_Wayne", input: "Fort Wayne -04:00" },
  { value: "America/Fortaleza", input: "Fortaleza -03:00" },
  { value: "Africa/Freetown", input: "Freetown +00:00" },
  { value: "Pacific/Funafuti", input: "Funafuti +12:00" },
  { value: "GB-Eire", input: "GB-Eire +01:00" },
  { value: "Africa/Gaborone", input: "Gaborone +02:00" },
  { value: "Pacific/Galapagos", input: "Galapagos -06:00" },
  { value: "Pacific/Gambier", input: "Gambier -09:00" },
  { value: "Asia/Gaza", input: "Gaza +03:00" },
  { value: "Mexico/General", input: "General -05:00" },
  { value: "Europe/Gibraltar", input: "Gibraltar +02:00" },
  { value: "America/Glace_Bay", input: "Glace Bay -03:00" },
  { value: "America/Godthab", input: "Godthab -02:00" },
  { value: "America/Goose_Bay", input: "Goose Bay -03:00" },
  { value: "America/Grand_Turk", input: "Grand Turk -04:00" },
  { value: "Greenwich", input: "Greenwich +00:00" },
  { value: "America/Grenada", input: "Grenada -04:00" },
  { value: "Pacific/Guadalcanal", input: "Guadalcanal +11:00" },
  { value: "America/Guadeloupe", input: "Guadeloupe -04:00" },
  { value: "Pacific/Guam", input: "Guam +10:00" },
  { value: "America/Guatemala", input: "Guatemala -06:00" },
  { value: "America/Guayaquil", input: "Guayaquil -05:00" },
  { value: "Europe/Guernsey", input: "Guernsey +01:00" },
  { value: "America/Guyana", input: "Guyana -04:00" },
  { value: "HST", input: "HST -10:00" },
  { value: "America/Halifax", input: "Halifax -03:00" },
  { value: "Africa/Harare", input: "Harare +02:00" },
  { value: "Asia/Harbin", input: "Harbin +08:00" },
  { value: "America/Havana", input: "Havana -04:00" },
  { value: "US/Hawaii", input: "Hawaii -10:00" },
  { value: "Asia/Hebron", input: "Hebron +03:00" },
  { value: "Europe/Helsinki", input: "Helsinki +03:00" },
  { value: "America/Hermosillo", input: "Hermosillo -07:00" },
  { value: "Asia/Ho_Chi_Minh", input: "Ho Chi_Minh +07:00" },
  { value: "Australia/Hobart", input: "Hobart +10:00" },
  { value: "Asia/Hong_Kong", input: "Hong Kong +08:00" },
  { value: "Hongkong", input: "Hongkong +08:00" },
  { value: "Pacific/Honolulu", input: "Honolulu -10:00" },
  { value: "Asia/Hovd", input: "Hovd +07:00" },
  { value: "Iceland", input: "Iceland +00:00" },
  { value: "US/Indiana-Starke", input: "Indiana-Starke -05:00" },
  { value: "America/Indianapolis", input: "Indianapolis -04:00" },
  { value: "America/Inuvik", input: "Inuvik -06:00" },
  { value: "America/Iqaluit", input: "Iqaluit -04:00" },
  { value: "Iran", input: "Iran +04:30" },
  { value: "Asia/Irkutsk", input: "Irkutsk +08:00" },
  { value: "Europe/Isle_of_Man", input: "Isle of_Man +01:00" },
  { value: "Israel", input: "Israel +03:00" },
  { value: "Asia/Istanbul", input: "Istanbul +03:00" },
  { value: "Asia/Jakarta", input: "Jakarta +07:00" },
  { value: "Jamaica", input: "Jamaica -05:00" },
  { value: "Atlantic/Jan_Mayen", input: "Jan Mayen +02:00" },
  { value: "Japan", input: "Japan +09:00" },
  { value: "Asia/Jayapura", input: "Jayapura +09:00" },
  { value: "Europe/Jersey", input: "Jersey +01:00" },
  { value: "Asia/Jerusalem", input: "Jerusalem +03:00" },
  { value: "Africa/Johannesburg", input: "Johannesburg +02:00" },
  { value: "Pacific/Johnston", input: "Johnston -10:00" },
  { value: "Africa/Juba", input: "Juba +02:00" },
  { value: "America/Jujuy", input: "Jujuy -03:00" },
  { value: "America/Juneau", input: "Juneau -08:00" },
  { value: "Asia/Kabul", input: "Kabul +04:30" },
  { value: "Europe/Kaliningrad", input: "Kaliningrad +02:00" },
  { value: "Asia/Kamchatka", input: "Kamchatka +12:00" },
  { value: "Africa/Kampala", input: "Kampala +03:00" },
  { value: "Asia/Karachi", input: "Karachi +05:00" },
  { value: "Asia/Kashgar", input: "Kashgar +06:00" },
  { value: "Asia/Kathmandu", input: "Kathmandu +05:45" },
  { value: "Asia/Katmandu", input: "Katmandu +05:45" },
  { value: "Indian/Kerguelen", input: "Kerguelen +05:00" },
  { value: "Asia/Khandyga", input: "Khandyga +09:00" },
  { value: "Africa/Khartoum", input: "Khartoum +02:00" },
  { value: "Europe/Kiev", input: "Kiev +03:00" },
  { value: "Africa/Kigali", input: "Kigali +02:00" },
  { value: "Africa/Kinshasa", input: "Kinshasa +01:00" },
  { value: "Pacific/Kiritimati", input: "Kiritimati +14:00" },
  { value: "Europe/Kirov", input: "Kirov +03:00" },
  { value: "America/Indiana/Knox", input: "Knox -05:00" },
  { value: "America/Knox_IN", input: "Knox IN -05:00" },
  { value: "Asia/Kolkata", input: "Kolkata +05:30" },
  { value: "Pacific/Kosrae", input: "Kosrae +11:00" },
  { value: "America/Kralendijk", input: "Kralendijk -04:00" },
  { value: "Asia/Krasnoyarsk", input: "Krasnoyarsk +07:00" },
  { value: "Asia/Kuala_Lumpur", input: "Kuala Lumpur +08:00" },
  { value: "Asia/Kuching", input: "Kuching +08:00" },
  { value: "Asia/Kuwait", input: "Kuwait +03:00" },
  { value: "Pacific/Kwajalein", input: "Kwajalein +12:00" },
  { value: "Australia/LHI", input: "LHI +10:30" },
  { value: "America/La_Paz", input: "La Paz -04:00" },
  { value: "America/Argentina/La_Rioja", input: "La Rioja -03:00" },
  { value: "Africa/Lagos", input: "Lagos +01:00" },
  { value: "Africa/Libreville", input: "Libreville +01:00" },
  { value: "Libya", input: "Libya +02:00" },
  { value: "America/Lima", input: "Lima -05:00" },
  { value: "Australia/Lindeman", input: "Lindeman +10:00" },
  { value: "Europe/Lisbon", input: "Lisbon +01:00" },
  { value: "Europe/Ljubljana", input: "Ljubljana +02:00" },
  { value: "Africa/Lome", input: "Lome +00:00" },
  { value: "Europe/London", input: "London +01:00" },
  { value: "Arctic/Longyearbyen", input: "Longyearbyen +02:00" },
  { value: "Australia/Lord_Howe", input: "Lord Howe +10:30" },
  { value: "America/Los_Angeles", input: "Los Angeles -07:00" },
  { value: "America/Louisville", input: "Louisville -04:00" },
  { value: "America/Lower_Princes", input: "Lower Princes -04:00" },
  { value: "Africa/Luanda", input: "Luanda +01:00" },
  { value: "Africa/Lubumbashi", input: "Lubumbashi +02:00" },
  { value: "Africa/Lusaka", input: "Lusaka +02:00" },
  { value: "Europe/Luxembourg", input: "Luxembourg +02:00" },
  { value: "MET", input: "MET +02:00" },
  { value: "MST", input: "MST -07:00" },
  { value: "MST7MDT", input: "MST7MDT -06:00" },
  { value: "Asia/Macao", input: "Macao +08:00" },
  { value: "Asia/Macau", input: "Macau +08:00" },
  { value: "America/Maceio", input: "Maceio -03:00" },
  { value: "Antarctica/Macquarie", input: "Macquarie +10:00" },
  { value: "Atlantic/Madeira", input: "Madeira +01:00" },
  { value: "Europe/Madrid", input: "Madrid +02:00" },
  { value: "Asia/Magadan", input: "Magadan +11:00" },
  { value: "Indian/Mahe", input: "Mahe +04:00" },
  { value: "Pacific/Majuro", input: "Majuro +12:00" },
  { value: "Asia/Makassar", input: "Makassar +08:00" },
  { value: "Africa/Malabo", input: "Malabo +01:00" },
  { value: "Indian/Maldives", input: "Maldives +05:00" },
  { value: "Europe/Malta", input: "Malta +02:00" },
  { value: "America/Managua", input: "Managua -06:00" },
  { value: "America/Manaus", input: "Manaus -04:00" },
  { value: "Asia/Manila", input: "Manila +08:00" },
  { value: "Africa/Maputo", input: "Maputo +02:00" },
  { value: "America/Indiana/Marengo", input: "Marengo -04:00" },
  { value: "Europe/Mariehamn", input: "Mariehamn +03:00" },
  { value: "America/Marigot", input: "Marigot -04:00" },
  { value: "Pacific/Marquesas", input: "Marquesas -09:30" },
  { value: "America/Martinique", input: "Martinique -04:00" },
  { value: "Africa/Maseru", input: "Maseru +02:00" },
  { value: "America/Matamoros", input: "Matamoros -05:00" },
  { value: "Indian/Mauritius", input: "Mauritius +04:00" },
  { value: "Antarctica/Mawson", input: "Mawson +05:00" },
  { value: "Indian/Mayotte", input: "Mayotte +03:00" },
  { value: "America/Mazatlan", input: "Mazatlan -06:00" },
  { value: "Africa/Mbabane", input: "Mbabane +02:00" },
  { value: "Antarctica/McMurdo", input: "McMurdo +12:00" },
  { value: "Australia/Melbourne", input: "Melbourne +10:00" },
  { value: "America/Argentina/Mendoza", input: "Mendoza -03:00" },
  { value: "America/Mendoza", input: "Mendoza -03:00" },
  { value: "America/Menominee", input: "Menominee -05:00" },
  { value: "America/Merida", input: "Merida -05:00" },
  { value: "America/Metlakatla", input: "Metlakatla -08:00" },
  { value: "America/Mexico_City", input: "Mexico City -05:00" },
  { value: "US/Michigan", input: "Michigan -04:00" },
  { value: "Pacific/Midway", input: "Midway -11:00" },
  { value: "Europe/Minsk", input: "Minsk +03:00" },
  { value: "America/Miquelon", input: "Miquelon -02:00" },
  { value: "Africa/Mogadishu", input: "Mogadishu +03:00" },
  { value: "Europe/Monaco", input: "Monaco +02:00" },
  { value: "America/Moncton", input: "Moncton -03:00" },
  { value: "Africa/Monrovia", input: "Monrovia +00:00" },
  { value: "America/Monterrey", input: "Monterrey -05:00" },
  { value: "America/Montevideo", input: "Montevideo -03:00" },
  { value: "America/Kentucky/Monticello", input: "Monticello -04:00" },
  { value: "America/Montreal", input: "Montreal -04:00" },
  { value: "America/Montserrat", input: "Montserrat -04:00" },
  { value: "Europe/Moscow", input: "Moscow +03:00" },
  { value: "Canada/Mountain", input: "Mountain -06:00" },
  { value: "Asia/Muscat", input: "Muscat +04:00" },
  { value: "Australia/NSW", input: "NSW +10:00" },
  { value: "NZ", input: "NZ +12:00" },
  { value: "NZ-CHAT", input: "NZ-CHAT +12:45" },
  { value: "Africa/Nairobi", input: "Nairobi +03:00" },
  { value: "America/Nassau", input: "Nassau -04:00" },
  { value: "Pacific/Nauru", input: "Nauru +12:00" },
  { value: "Navajo", input: "Navajo -06:00" },
  { value: "Africa/Ndjamena", input: "Ndjamena +01:00" },
  { value: "America/North_Dakota/New_Salem", input: "New Salem -05:00" },
  { value: "America/New_York", input: "New York -04:00" },
  { value: "Canada/Newfoundland", input: "Newfoundland -02:30" },
  { value: "Africa/Niamey", input: "Niamey +01:00" },
  { value: "Asia/Nicosia", input: "Nicosia +03:00" },
  { value: "America/Nipigon", input: "Nipigon -04:00" },
  { value: "Pacific/Niue", input: "Niue -11:00" },
  { value: "America/Nome", input: "Nome -08:00" },
  { value: "Pacific/Norfolk", input: "Norfolk +11:00" },
  { value: "America/Noronha", input: "Noronha -02:00" },
  { value: "Australia/North", input: "North +09:30" },
  { value: "Africa/Nouakchott", input: "Nouakchott +00:00" },
  { value: "Pacific/Noumea", input: "Noumea +11:00" },
  { value: "Asia/Novokuznetsk", input: "Novokuznetsk +07:00" },
  { value: "Asia/Novosibirsk", input: "Novosibirsk +07:00" },
  { value: "America/Ojinaga", input: "Ojinaga -06:00" },
  { value: "Asia/Omsk", input: "Omsk +06:00" },
  { value: "Asia/Oral", input: "Oral +05:00" },
  { value: "Europe/Oslo", input: "Oslo +02:00" },
  { value: "Africa/Ouagadougou", input: "Ouagadougou +00:00" },
  { value: "PRC", input: "PRC +08:00" },
  { value: "PST8PDT", input: "PST8PDT -07:00" },
  { value: "US/Pacific", input: "Pacific -07:00" },
  { value: "US/Pacific-New", input: "Pacific-New -03:00" },
  { value: "Pacific/Pago_Pago", input: "Pago Pago -11:00" },
  { value: "Pacific/Palau", input: "Palau +09:00" },
  { value: "Antarctica/Palmer", input: "Palmer -03:00" },
  { value: "America/Panama", input: "Panama -05:00" },
  { value: "America/Pangnirtung", input: "Pangnirtung -04:00" },
  { value: "America/Paramaribo", input: "Paramaribo -03:00" },
  { value: "Europe/Paris", input: "Paris +02:00" },
  { value: "Australia/Perth", input: "Perth +08:00" },
  { value: "America/Indiana/Petersburg", input: "Petersburg -04:00" },
  { value: "Asia/Phnom_Penh", input: "Phnom Penh +07:00" },
  { value: "America/Phoenix", input: "Phoenix -07:00" },
  { value: "Pacific/Pitcairn", input: "Pitcairn -08:00" },
  { value: "Europe/Podgorica", input: "Podgorica +02:00" },
  { value: "Pacific/Pohnpei", input: "Pohnpei +11:00" },
  { value: "Poland", input: "Poland +02:00" },
  { value: "Pacific/Ponape", input: "Ponape +11:00" },
  { value: "Asia/Pontianak", input: "Pontianak +07:00" },
  { value: "America/Port-au-Prince", input: "Port-au-Prince -04:00" },
  { value: "Pacific/Port_Moresby", input: "Port Moresby +10:00" },
  { value: "America/Port_of_Spain", input: "Port of_Spain -04:00" },
  { value: "Africa/Porto-Novo", input: "Porto-Novo +01:00" },
  { value: "America/Porto_Acre", input: "Porto Acre -05:00" },
  { value: "America/Porto_Velho", input: "Porto Velho -04:00" },
  { value: "Portugal", input: "Portugal +01:00" },
  { value: "Europe/Prague", input: "Prague +02:00" },
  { value: "America/Puerto_Rico", input: "Puerto Rico -04:00" },
  { value: "America/Punta_Arenas", input: "Punta Arenas -03:00" },
  { value: "Asia/Pyongyang", input: "Pyongyang +09:00" },
  { value: "Asia/Qatar", input: "Qatar +03:00" },
  { value: "Australia/Queensland", input: "Queensland +10:00" },
  { value: "Asia/Qyzylorda", input: "Qyzylorda +05:00" },
  { value: "ROC", input: "ROC +08:00" },
  { value: "ROK", input: "ROK +09:00" },
  { value: "America/Rainy_River", input: "Rainy River -05:00" },
  { value: "Asia/Rangoon", input: "Rangoon +06:30" },
  { value: "America/Rankin_Inlet", input: "Rankin Inlet -05:00" },
  { value: "Pacific/Rarotonga", input: "Rarotonga -10:00" },
  { value: "America/Recife", input: "Recife -03:00" },
  { value: "America/Regina", input: "Regina -06:00" },
  { value: "America/Resolute", input: "Resolute -05:00" },
  { value: "Indian/Reunion", input: "Reunion +04:00" },
  { value: "Atlantic/Reykjavik", input: "Reykjavik +00:00" },
  { value: "Europe/Riga", input: "Riga +03:00" },
  { value: "America/Rio_Branco", input: "Rio Branco -05:00" },
  { value: "America/Argentina/Rio_Gallegos", input: "Rio Gallegos -03:00" },
  { value: "Asia/Riyadh", input: "Riyadh +03:00" },
  { value: "Europe/Rome", input: "Rome +02:00" },
  { value: "America/Rosario", input: "Rosario -03:00" },
  { value: "Antarctica/Rothera", input: "Rothera -03:00" },
  { value: "Asia/Saigon", input: "Saigon +07:00" },
  { value: "Pacific/Saipan", input: "Saipan +10:00" },
  { value: "Asia/Sakhalin", input: "Sakhalin +11:00" },
  { value: "America/Argentina/Salta", input: "Salta -03:00" },
  { value: "Europe/Samara", input: "Samara +04:00" },
  { value: "Asia/Samarkand", input: "Samarkand +05:00" },
  { value: "Pacific/Samoa", input: "Samoa -11:00" },
  { value: "US/Samoa", input: "Samoa -11:00" },
  { value: "America/Argentina/San_Juan", input: "San Juan -03:00" },
  { value: "America/Argentina/San_Luis", input: "San Luis -03:00" },
  { value: "Europe/San_Marino", input: "San Marino +02:00" },
  { value: "America/Santa_Isabel", input: "Santa Isabel -07:00" },
  { value: "America/Santarem", input: "Santarem -03:00" },
  { value: "America/Santiago", input: "Santiago -04:00" },
  { value: "America/Santo_Domingo", input: "Santo Domingo -04:00" },
  { value: "America/Sao_Paulo", input: "Sao Paulo -03:00" },
  { value: "Africa/Sao_Tome", input: "Sao Tome +00:00" },
  { value: "Europe/Sarajevo", input: "Sarajevo +02:00" },
  { value: "Europe/Saratov", input: "Saratov +04:00" },
  { value: "Canada/Saskatchewan", input: "Saskatchewan -06:00" },
  { value: "America/Scoresbysund", input: "Scoresbysund +00:00" },
  { value: "Asia/Seoul", input: "Seoul +09:00" },
  { value: "Asia/Shanghai", input: "Shanghai +08:00" },
  { value: "America/Shiprock", input: "Shiprock -06:00" },
  { value: "Europe/Simferopol", input: "Simferopol +03:00" },
  { value: "Asia/Singapore", input: "Singapore +08:00" },
  { value: "Singapore", input: "Singapore +08:00" },
  { value: "America/Sitka", input: "Sitka -08:00" },
  { value: "Europe/Skopje", input: "Skopje +02:00" },
  { value: "Europe/Sofia", input: "Sofia +03:00" },
  { value: "Australia/South", input: "South +09:30" },
  { value: "Atlantic/South_Georgia", input: "South Georgia -02:00" },
  { value: "Antarctica/South_Pole", input: "South Pole +12:00" },
  { value: "Asia/Srednekolymsk", input: "Srednekolymsk +11:00" },
  { value: "America/St_Barthelemy", input: "St Barthelemy -04:00" },
  { value: "Atlantic/St_Helena", input: "St Helena +00:00" },
  { value: "America/St_Johns", input: "St Johns -02:30" },
  { value: "America/St_Kitts", input: "St Kitts -04:00" },
  { value: "America/St_Lucia", input: "St Lucia -04:00" },
  { value: "America/St_Thomas", input: "St Thomas -04:00" },
  { value: "America/St_Vincent", input: "St Vincent -04:00" },
  { value: "Atlantic/Stanley", input: "Stanley -03:00" },
  { value: "Europe/Stockholm", input: "Stockholm +02:00" },
  { value: "America/Swift_Current", input: "Swift Current -06:00" },
  { value: "Australia/Sydney", input: "Sydney +10:00" },
  { value: "Antarctica/Syowa", input: "Syowa +03:00" },
  { value: "Pacific/Tahiti", input: "Tahiti -10:00" },
  { value: "Asia/Taipei", input: "Taipei +08:00" },
  { value: "Europe/Tallinn", input: "Tallinn +03:00" },
  { value: "Pacific/Tarawa", input: "Tarawa +12:00" },
  { value: "Asia/Tashkent", input: "Tashkent +05:00" },
  { value: "Australia/Tasmania", input: "Tasmania +10:00" },
  { value: "Asia/Tbilisi", input: "Tbilisi +04:00" },
  { value: "America/Tegucigalpa", input: "Tegucigalpa -06:00" },
  { value: "Asia/Tehran", input: "Tehran +04:30" },
  { value: "Asia/Tel_Aviv", input: "Tel Aviv +03:00" },
  { value: "America/Indiana/Tell_City", input: "Tell City -05:00" },
  { value: "Asia/Thimbu", input: "Thimbu +06:00" },
  { value: "Asia/Thimphu", input: "Thimphu +06:00" },
  { value: "America/Thule", input: "Thule -03:00" },
  { value: "America/Thunder_Bay", input: "Thunder Bay -04:00" },
  { value: "America/Tijuana", input: "Tijuana -07:00" },
  { value: "Africa/Timbuktu", input: "Timbuktu +00:00" },
  { value: "Europe/Tirane", input: "Tirane +02:00" },
  { value: "Europe/Tiraspol", input: "Tiraspol +03:00" },
  { value: "Asia/Tokyo", input: "Tokyo +09:00" },
  { value: "Asia/Tomsk", input: "Tomsk +07:00" },
  { value: "Pacific/Tongatapu", input: "Tongatapu +13:00" },
  { value: "America/Toronto", input: "Toronto -04:00" },
  { value: "America/Tortola", input: "Tortola -04:00" },
  { value: "Africa/Tripoli", input: "Tripoli +02:00" },
  { value: "Antarctica/Troll", input: "Troll +02:00" },
  { value: "Pacific/Truk", input: "Truk +10:00" },
  { value: "America/Argentina/Tucuman", input: "Tucuman -03:00" },
  { value: "Africa/Tunis", input: "Tunis +01:00" },
  { value: "Turkey", input: "Turkey +03:00" },
  { value: "Etc/UCT", input: "UCT +00:00" },
  { value: "Asia/Ujung_Pandang", input: "Ujung Pandang +08:00" },
  { value: "Asia/Ulaanbaatar", input: "Ulaanbaatar +08:00" },
  { value: "Asia/Ulan_Bator", input: "Ulan Bator +08:00" },
  { value: "Europe/Ulyanovsk", input: "Ulyanovsk +04:00" },
  { value: "Universal", input: "Universal +00:00" },
  { value: "Asia/Urumqi", input: "Urumqi +06:00" },
  { value: "America/Argentina/Ushuaia", input: "Ushuaia -03:00" },
  { value: "Asia/Ust-Nera", input: "Ust-Nera +10:00" },
  { value: "Europe/Uzhgorod", input: "Uzhgorod +03:00" },
  { value: "Europe/Vaduz", input: "Vaduz +02:00" },
  { value: "America/Vancouver", input: "Vancouver -07:00" },
  { value: "Europe/Vatican", input: "Vatican +02:00" },
  { value: "America/Indiana/Vevay", input: "Vevay -04:00" },
  { value: "Australia/Victoria", input: "Victoria +10:00" },
  { value: "Europe/Vienna", input: "Vienna +02:00" },
  { value: "Asia/Vientiane", input: "Vientiane +07:00" },
  { value: "Europe/Vilnius", input: "Vilnius +03:00" },
  { value: "America/Indiana/Vincennes", input: "Vincennes -04:00" },
  { value: "America/Virgin", input: "Virgin -04:00" },
  { value: "Asia/Vladivostok", input: "Vladivostok +10:00" },
  { value: "Europe/Volgograd", input: "Volgograd +03:00" },
  { value: "Antarctica/Vostok", input: "Vostok +06:00" },
  { value: "W-SU", input: "W-SU +03:00" },
  { value: "WET", input: "WET +01:00" },
  { value: "Pacific/Wake", input: "Wake +12:00" },
  { value: "Pacific/Wallis", input: "Wallis +12:00" },
  { value: "Europe/Warsaw", input: "Warsaw +02:00" },
  { value: "Australia/West", input: "West +08:00" },
  { value: "Brazil/West", input: "West -04:00" },
  { value: "America/Whitehorse", input: "Whitehorse -07:00" },
  { value: "America/Indiana/Winamac", input: "Winamac -04:00" },
  { value: "Africa/Windhoek", input: "Windhoek +02:00" },
  { value: "America/Winnipeg", input: "Winnipeg -05:00" },
  { value: "America/Yakutat", input: "Yakutat -08:00" },
  { value: "Asia/Yakutsk", input: "Yakutsk +09:00" },
  { value: "Australia/Yancowinna", input: "Yancowinna +09:30" },
  { value: "Asia/Yangon", input: "Yangon +06:30" },
  { value: "Pacific/Yap", input: "Yap +10:00" },
  { value: "Asia/Yekaterinburg", input: "Yekaterinburg +05:00" },
  { value: "America/Yellowknife", input: "Yellowknife -06:00" },
  { value: "Asia/Yerevan", input: "Yerevan +04:00" },
  { value: "Canada/Yukon", input: "Yukon -07:00" },
  { value: "Europe/Zagreb", input: "Zagreb +02:00" },
  { value: "Europe/Zaporozhye", input: "Zaporozhye +03:00" },
  { value: "Zulu", input: "Zulu +00:00" },
  { value: "Europe/Zurich", input: "Zurich +02:00" },
];

/*
  Essa função é usada para pegar a lista de fusos horarios e formatar para a lista TimeZones
*/
export const onTimezones = () => {
  // o array a ser ordenado
  var list = TimeZones;

  // array temporário que armazena os objetos com o índice e o valor para ordenação
  var mapped = list.map(function (data, i) {
    // Faço o split para pegar só o nome da cidade e não o pais e o continente
    // que só alguns possuem
    // index = ordena a lista
    // value = recebe a zona
    // input o nome da cidade dessa zona
    let fuso = data.value.split("/");
    return { index: i, value: data.value, input: fuso[fuso.length - 1] };
  });

  // Ordenando o array pelo nome da cidade que esta no input
  mapped.sort(function (a, b) {
    return +(a.input > b.input) || +(a.input === b.input) - 1;
  });

  // container para o resultado ordenado
  var result = mapped.map(function (el) {
    var fuso = momentTimezone().tz(el.value).format("Z");
    // Gera no console a lista no formato que preciso
    // Removido console log abaixo para não ficar aparecendo na busca.
    // QUal o objetivo dessa rotina?
    //console_.log("{ value:'" + el.value + "', input: '" + el.input.replace("_", " ") + " " + fuso + "' },");
  });
};
