import { Button, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, TextField, Typography } from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import ViewIcon from "@material-ui/icons/Visibility";
import QRCode from "qrcode.react";
import React, { Component, Fragment } from "react";
import { FormattedMessage } from "react-intl";
import Moment from "react-moment";
import { connect } from "react-redux";
import { compose } from "redux";
import * as actions from "../../actions";
import { approveWithdrawal, getWithdrawById, rejectWithdrawal, getLastWithdrawalsByUser } from "../../actions";
import FormDialog from "../../custom/FormDialog";
import InfoItem from "../../custom/InfoItem";
import Number from "../../custom/Number";
import QuotationUpdater from "../../custom/QuotationUpdater";
import { addDays, HashResult, HashType, networkNameByPartnerWithdrawType, Roles, validateCriptoAddress } from "../../util";
import FormUpdateAddress from "./FormUpdateAddress";
import FormUpdateFee from "./FormUpdateFee";
import { GenericDialog } from "./GenericDialog";

class AdminWIthdrawalPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formProps: {
        transactionId: "",
        withdrawDate: null,
        amount: null,
        fee: null,
        btc: "",
      },
      LCTBTCSell: 0,
      withdrawal: null,
      navWithdrawall: false,
      transactionId: "",
      transaction: null,
      rejectionReason: "",
      openTransaction: false,
      openRejectReason: false,
      loading: true,
      edit: false,
      flagApprove: false,
      flagRejected: false,
      btnActive: false,
      btnReject: true,
      editWallet: false,
      newAddress: "",
      flagWallet: false,
      btAddress: false,
      wId: this.props.selectWithdrawal.id,
      previousAddressesPopup: false,
      previousAddresses: [],
    };
  }

  async componentDidMount() {
    const { wId } = this.state;
    let w = await getWithdrawById(wId);
    let pa = await getLastWithdrawalsByUser(w.account.user.id);
    let addresses = new Set();
    pa = pa.filter((w) => {
      let ok = !addresses.has(w.address);
      addresses.add(w.address);
      return ok;
    });
    this.setState({
      withdrawal: w,
      previousAddresses: pa.map((w) => ({
        id: w.id,
        authorizeDate: w.authorizeDate,
        addressNetwork: w.addressNetwork,
        address: w.address,
        amount: w.amount,
        status: w.status,
      })),
      loading: false,
      navWithdrawall: w.navWithdrawall,
    });
  }

  onSubmit = () => {
    this.setState({ flagApprove: true });
  };

  confirmWithdrawl = () => {
    let formProps = this.state.formProps;
    var date = new Date();
    formProps = {
      transactionId: this.state.transactionId ? this.state.transactionId : "0",
      withdrawDate: this.state.transaction ? this.state.transaction.received : date,
      amount: this.state.withdrawal.amount - this.state.withdrawal.fee,
      btc: this.state.transaction ? this.state.transaction.transactionValue : 0,
      address: this.state.withdrawal.address,
    };
    this.setState({ flagApprove: true });
    const { wId } = this.state;
    const { showSuccessMessage, showErrorMessage } = this.props;
    approveWithdrawal(wId, formProps)
      .then((data) => {
        showSuccessMessage("app.withdrawal.approved");
        this.props.onActionComplete();
      })
      .catch((e) => {
        showErrorMessage(e.response ? e.response.data.message : e);
      });
  };

  rejectWithdrawal = () => {
    const { wId } = this.state;
    const { showSuccessMessage, showErrorMessage, history } = this.props;
    let formProps = {
      rejectionReason: this.state.rejectionReason,
    };
    rejectWithdrawal(wId, formProps)
      .then((data) => {
        showSuccessMessage("app.withdrawal.reject");
        this.props.onActionComplete();
      })
      .catch((e) => {
        showErrorMessage(e.response ? e.response.data.message : e);
      });
  };

  setTransaction = (transaction) => {
    const { showErrorMessage, showSuccessMessage } = this.props;
    transaction.messageError?.length > 0 ? showErrorMessage(transaction.messageError[0]) : showSuccessMessage("Hash verificada");
    this.setState({
      openTransaction: true,
      transactionId: transaction.id,
      transaction,
    });
  };

  submitTransactionId = (idTransaction) => {
    this.setState({ loading: true });
    validateCriptoAddress(idTransaction)
      .then((data) => {
        this.setState({ loading: false });
        if (data.result === HashResult.NOT_FOUND) {
          this.setTransaction({ ...data, id: idTransaction, messageError: ["Hash não encontrada."] });
        } else if (data.type !== HashType.TRANSACTION) {
          this.setTransaction({ ...data, id: idTransaction, messageError: ["Hash não é uma transação."] });
        } else {
          this.setTransaction({ ...data, id: data.hash, received: data.date });
        }
      })
      .catch((e) => {
        this.setState({ loading: false });
        this.setTransaction({ id: idTransaction, messageError: ["Erro ao procurar hash: " + e.message] });
      });
  };

  validaTransactionId = (value) => {
    this.setState({ transactionId: value });
    this.state.btnActive = value.length >= 10;
  };

  validaRejectReason = (value) => {
    this.setState({ rejectionReason: value });
    if (value.length < 5) {
      this.state.btnReject = true;
    } else {
      this.state.btnReject = false;
    }
  };

  onRejecteReason = () => {
    this.setState({ flagRejected: true });
  };

  render() {
    const { currentUser, onActionComplete } = this.props;
    const { withdrawal, openTransaction, transaction, loading, LCTBTCSell, previousAddresses } = this.state;
    if (loading) return <CircularProgress style={{ marginTop: "25%" }} />;
    let withdrawalTaxResidence = withdrawal.account.user.taxResidence;
    return (
      <CardContent>
        <div>
          {/* Status */}
          <Typography variant="h5" color="primary">
            <FormattedMessage id={`app.enuns.${withdrawal.status}`} />
          </Typography>
          <Typography>
            <FormattedMessage id="app.adminWithdrawal.text1" />
          </Typography>
          <Divider />
          <br />
          {/* Cliente */}
          <InfoItem caption={<FormattedMessage id="app.contract.canceledBy.customer" />} text={withdrawal.account.user.name} />
          {/* Data Autorização e Data de pedido */}
          <InfoItem
            caption={<FormattedMessage id="app.confirmed" />}
            text={<Moment date={withdrawal.authorizeDate ? withdrawal.authorizeDate : withdrawal.createdAt} format="DD/MM/YYYY" />}
            extraCaption={<FormattedMessage id="app.data.order" />}
            extraText={<Moment date={withdrawal.createdAt} format="DD/MM/YYYY" />}
          />
          {/* Data limite */}
          <InfoItem
            caption={<FormattedMessage id="app.date.limit" />}
            text={<Moment date={addDays(withdrawal.authorizeDate ? withdrawal.authorizeDate : withdrawal.createdAt, 4)} format="DD/MM/YYYY" />}
          />
          {/* Valor solicitado e Taxas */}
          <InfoItem
            caption={<FormattedMessage id="app.amount.requested" />}
            text={<Number value={withdrawal.amount} currency="LCT" taxResidence={withdrawalTaxResidence} />}
            extraCaption={<FormattedMessage id="app.fee" />}
            extraText={
              <>
                <IconButton
                  onClick={(e) => {
                    this.setState({ edit: true });
                  }}
                >
                  <Edit fontSize="small" />
                </IconButton>
                <Number value={withdrawal.fee} currency="LCT" taxResidence={withdrawalTaxResidence} />
              </>
            }
          />
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container direction="row">
              {/* Valor a pagar*/}
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography style={{ marginBottom: -10 }} variant="h6" gutterBottom color="primary">
                  <FormattedMessage id="app.amount.payable" />
                </Typography>
                <Typography variant="h6" gutterBottom color="primary">
                  <Number value={withdrawal.amount - withdrawal.fee} currency="LCT" taxResidence={withdrawalTaxResidence} />
                </Typography>
              </Grid>
              {withdrawal.partnerWithdraw == 0 ? (
                withdrawal.partnerwithdrawtype == 0 ? (
                  <>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Grid container direction="row" justify="flex-end">
                        <Grid item>
                          <InfoItem
                            caption={
                              <>
                                <FormattedMessage id="app.estimated.BTC" /> *
                              </>
                            }
                            text={<Number value={withdrawal.valueWithFee / LCTBTCSell} currency="BTC" />}
                          />
                        </Grid>
                        <Grid item>
                          <QuotationUpdater
                            onChange={({ LCTBTCSell }) => {
                              this.setState({ LCTBTCSell });
                            }}
                            size={16}
                            style={{ marginTop: 24 }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : withdrawal.partnerwithdrawtype == 1 ? (
                  <>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Grid container direction="row" justify="flex-end">
                        <Grid item>
                          <InfoItem caption={<FormattedMessage id="app.type.usdtTRX.button" />} text={<FormattedMessage id="app.no.price.1" />} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : withdrawal.partnerwithdrawtype == 2 ? (
                  <>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Grid container direction="row" justify="flex-end">
                        <Grid item>
                          <InfoItem caption={<FormattedMessage id="app.type.usdtETH.button" />} text={<FormattedMessage id="app.no.price.1" />} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  ""
                )
              ) : withdrawal.partnerWithdraw == 1 ? (
                <>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Grid container direction="row" justify="flex-end">
                      <Grid item>
                        <InfoItem
                          caption={
                            <>
                              <FormattedMessage id="app.estimated.BTC" /> *
                            </>
                          }
                          text={<Number value={withdrawal.valueWithFee / LCTBTCSell} currency="BTC" />}
                        />
                      </Grid>
                      <Grid item>
                        <QuotationUpdater
                          onChange={({ LCTBTCSell }) => {
                            this.setState({ LCTBTCSell });
                          }}
                          size={16}
                          style={{ marginTop: 24 }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (
                withdrawal.partnerWithdraw == 2 && (
                  <>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Grid container direction="row" justify="flex-end">
                        <Grid item>
                          <FormattedMessage id="app.no.price" />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )
              )}
            </Grid>
          </Grid>
          {withdrawal.partnerWithdraw === 4 ? (
            <Typography gutterBottom>
              <FormattedMessage id="app.withdraw.bankingchannel.optionlabel" />
            </Typography>
          ) : (
            <InfoItem
              caption={<FormattedMessage id="app.wallet.destination" />}
              text={
                <>
                  {withdrawal.address}
                  {currentUser.role == Roles.Admin && (
                    <IconButton
                      onClick={(e) => {
                        this.setState({ editWallet: true });
                      }}
                    >
                      <Edit fontSize="small" />
                    </IconButton>
                  )}
                  <IconButton
                    onClick={(e) => {
                      this.setState({ previousAddressesPopup: true });
                    }}
                  >
                    <ViewIcon fontSize="small" />
                  </IconButton>
                </>
              }
            />
          )}

          {withdrawal.partnerWithdraw === 1 ? (
            <>
              <Typography gutterBottom color="primary">
                <b>{<FormattedMessage id="app.warning" />}</b>
                {": "}
                {<FormattedMessage id="app.withdraw.partner" />}

                {" - "}
                {withdrawal.partnerwithdrawtype == 0 ? <FormattedMessage id="app.automatic.selling" /> : <FormattedMessage id="app.balance.btc" />}
              </Typography>
            </>
          ) : withdrawal.partnerWithdraw === 0 ? (
            <>
              <Typography gutterBottom color="primary">
                {[0, 1, 2, 4].includes(withdrawal.partnerwithdrawtype) ? networkNameByPartnerWithdrawType[withdrawal.partnerwithdrawtype] : ""}
              </Typography>
            </>
          ) : withdrawal.partnerWithdraw === 2 ? (
            <Typography gutterBottom color="primary">
              <b>{<FormattedMessage id="app.warning" />}</b>
              {": "}
              {<FormattedMessage id="app.withdraw.reaport" />}
            </Typography>
          ) : withdrawal.partnerWithdraw === 3 ? (
            <>
              <InfoItem caption={<FormattedMessage id="app.wallet.partnerComment" />} text={withdrawal.partnerComment ?? "N/A"} />
              <Typography gutterBottom color="primary">
                <b>
                  <FormattedMessage id="app.warning" />
                </b>
                :
              </Typography>
              <Typography gutterBottom>Se não houver transação, utilize: ALTAVISTA-{withdrawal.id}.</Typography>
            </>
          ) : withdrawal.partnerWithdraw === 4 ? (
            <>
              <Typography variant="caption" color="textSecondary">
                <FormattedMessage id="app.withdraw.bankingchannel.commentlabel" />
              </Typography>
              <pre style={{ margin: 0, marginBottom: 16, paddingLeft: 16 }}>{withdrawal.partnerComment ?? "N/A"}</pre>
            </>
          ) : null}
          <Divider style={{ marginBottom: 3 }} />
          {withdrawal.partnerWithdraw === 2 ? (
            <Button fullWidth variant="contained" color="primary" style={{ marginTop: 16 }} onClick={(e) => this.setState({ flagApprove: true })}>
              <FormattedMessage id="app.withdraw.reaport" />
            </Button>
          ) : withdrawal.partnerWithdraw === 4 ? (
            <>
              <TextField
                fullWidth
                id="transactionId"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                label="Comments"
                onChange={(e) => this.validaTransactionId(e.target.value)}
              />
              <Button fullWidth variant="contained" color="primary" style={{ marginTop: 16 }} onClick={(e) => this.setState({ flagApprove: true })}>
                <FormattedMessage id="app.withdraw.bankingchannel.confirmlabel" />
              </Button>
            </>
          ) : (
            <>
              {withdrawal.partnerWithdraw !== 3 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: 32,
                    marginBottom: 32,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      padding: 10,
                      width: "fit-content",
                    }}
                  >
                    <QRCode value={withdrawal.address} size={250} />
                  </div>
                  <br />
                  <div>{withdrawal.address}</div>
                </div>
              )}
              <Divider style={{ marginBottom: 3 }} />
              <Typography gutterBottom color="textSecondary">
                <b>{<FormattedMessage id="app.important" />}</b> {<FormattedMessage id="app.adminWithdrawal.text2" />}
              </Typography>
              <TextField
                fullWidth
                id="transactionId"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                label={<FormattedMessage id="app.contract.waitingDeposit.button2" />}
                onChange={(e) => this.validaTransactionId(e.target.value)}
              />
              {/* Consulta a transação  */}
              <Button fullWidth variant="contained" color="primary" style={{ marginTop: 16 }} disabled={!this.state.btnActive} onClick={(e) => this.submitTransactionId(this.state.transactionId)}>
                <FormattedMessage id="app.transaction.blockchain.select" />
              </Button>
            </>
          )}
          <Button fullWidth variant="contained" color="secondary" style={{ marginTop: 16 }} onClick={(e) => this.setState({ openRejectReason: true })}>
            <FormattedMessage id="app.reject" />
          </Button>
        </div>
        {/* Diálogo após verificar a transação */}
        <TransactionDialog
          open={openTransaction}
          transaction={transaction}
          withdrawal={withdrawal}
          currentUser={currentUser}
          onClose={(e) => this.setState({ openTransaction: false })}
          onConfirm={this.onSubmit}
          taxResidence={withdrawalTaxResidence}
        />
        {/* Confirma o saque? */}
        <GenericDialog
          title={<FormattedMessage id="app.withdrawConfirmOrNo" />}
          onCancel={() => this.setState({ flagApprove: false })}
          open={this.state.flagApprove}
          onOk={(e) => this.confirmWithdrawl()}
        ></GenericDialog>
        {/* Rejeita o saque? */}
        <GenericDialog
          title={<FormattedMessage id="app.withdrawRejectOrNo" />}
          onCancel={() => this.setState({ flagRejected: false })}
          open={this.state.flagRejected}
          onOk={() => {
            this.rejectWithdrawal();
          }}
        ></GenericDialog>
        {/* Alterar Carteira */}
        <GenericDialog
          title={<FormattedMessage id="app.wallet.destination.change.sure" />}
          onCancel={() => this.setState({ flagWallet: false, btAddress: false })}
          open={this.state.flagWallet}
          onOk={() => {
            this.setState({
              withdrawal: { ...this.state.withdrawal, address: this.state.newAddress },
              flagWallet: false,
              btAddress: false,
            });
          }}
        ></GenericDialog>
        <FormDialog title="app.wallet.destination.change" open={this.state.editWallet} variant="secondary" noHeader onClose={(e) => this.setState({ editWallet: false })}>
          <FormUpdateAddress withdrawal={withdrawal} onActionComplete={(withdrawal) => this.setState({ withdrawal, editWallet: false })} />
        </FormDialog>
        <FormDialog title="app.rate.update" open={this.state.edit} variant="secondary" noHeader onClose={(e) => this.setState({ edit: false })}>
          <FormUpdateFee withdrawal={withdrawal} onActionComplete={(withdrawal) => this.setState({ withdrawal, edit: false })} />
        </FormDialog>
        {/* Motivo da rejeição */}
        <Dialog fullWidth={true} maxWidth="md" style={{ color: "red" }} open={this.state.openRejectReason} onClose={(e) => this.setState({ openRejectReason: false })}>
          <DialogTitle>
            <FormattedMessage id="app.reject.reason.enter" />
          </DialogTitle>
          <Grid item xs={12}>
            <DialogContent>
              <TextField
                fullWidth
                id="transactionId"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                label={<FormattedMessage id="app.reason.error" />}
                onChange={(e) => this.validaRejectReason(e.target.value)}
              />
            </DialogContent>
          </Grid>

          <DialogActions>
            <Button disabled={this.state.btnReject} onClick={(e) => this.onRejecteReason(e)} color="primary">
              <FormattedMessage id="app.reject" />
            </Button>

            <Button onClick={(e) => this.setState({ openRejectReason: false })} color="primary">
              <FormattedMessage id="app.close" />
            </Button>
          </DialogActions>
        </Dialog>

        <PreviousAddressesDialog
          currentAddress={withdrawal.address}
          previousAddresses={previousAddresses}
          open={this.state.previousAddressesPopup}
          onClose={(e) => this.setState({ previousAddressesPopup: false })}
        />
      </CardContent>
    );
  }
}

const TransactionDialog = ({ open, transaction, withdrawal, currentUser, onClose, onConfirm, taxResidence }) => {
  if (!open) return null;
  const hasError = transaction.messageError?.length > 0;
  return (
    <TransactionDialogHelper
      title={hasError ? <FormattedMessage id="app.transaction.problem" /> : <FormattedMessage id="app.approveWithdrawal" />}
      onClose={onClose}
      open={open}
      action={onConfirm}
      onAction={onClose}
      hasError={hasError}
    >
      {hasError && (
        <InfoItem
          color="primary"
          caption={<FormattedMessage id="app.problems.detected" />}
          text={transaction.messageError.map((e) => (
            <Fragment key={e}>
              <FormattedMessage id={e} />
              <br />
            </Fragment>
          ))}
        />
      )}
      <InfoItem caption={<FormattedMessage id="app.withdrawal.user" />} text={withdrawal.account.user.name} />
      <InfoItem caption={<FormattedMessage id="app.withdrawal.value" />} text={<Number value={withdrawal.amount - withdrawal.fee} currency="LCT" taxResidence={taxResidence} />} />
      <InfoItem caption={<FormattedMessage id="app.wallet.address" />} text={withdrawal.address} />
      <InfoItem caption={<FormattedMessage id="app.transactionId" />} text={transaction.id} />
      <InfoItem caption={<FormattedMessage id="app.date" />} text={transaction.date} extraCaption={<FormattedMessage id="app.confirmations" />} extraText={transaction.confirmations} />
      <InfoItem caption={<FormattedMessage id="app.network" />} text={transaction.network} extraCaption={<FormattedMessage id="app.token" />} extraText={transaction.token} />
    </TransactionDialogHelper>
  );
};

const TransactionDialogHelper = ({ open, onClose, title, children, action, onAction, hasError }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>{children}</DialogContent>
    <DialogActions>
      <Button onClick={action} color="textPrimary" style={hasError ? { color: "red", fontWeight: "bold" } : {}}>
        <FormattedMessage id="app.toApprove" />
      </Button>
      <Button onClick={onAction} color="primary">
        <FormattedMessage id="app.close" />
      </Button>
    </DialogActions>
  </Dialog>
);

function PreviousAddressesDialog({ currentAddress, previousAddresses, open, onClose, userId, ...props }) {
  return (
    <Dialog fullWidth={true} maxWidth="md" open={open} onClose={onClose}>
      <DialogContent>
        <Typography variant="caption" color="textSecondary">
          <FormattedMessage id="app.withdrawalAddressCheck.current" />
        </Typography>
        <Typography gutterBottom>{currentAddress}</Typography>
        <Typography variant="caption" color="textSecondary">
          <FormattedMessage id="app.withdrawalAddressCheck.previous" />
        </Typography>
        {previousAddresses.map((a) => (
          <div key={a.id} style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography>{a.address}</Typography>
            <Typography variant="caption" color="textSecondary">
              <span style={{ marginRight: 8 }}>{a.addressNetwork}</span> <Moment date={a.authorizeDate} format="DD/MM/YYYY" />
            </Typography>
          </div>
        ))}
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button onClick={(e) => onClose()} variant="contained" color="primary">
          <FormattedMessage id="app.close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default compose(connect(mapStateToProps, actions))(AdminWIthdrawalPopUp);
