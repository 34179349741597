import React, { Component } from "react";
import { numberFormatter } from "../../util";

class LCContractEN extends Component {
  render() {
    let { duration, amount, type, max, addendum, final } = this.props;
    max = max.toFixed(2).replace(".", ","); // Formatar para 2 casas decimais
    return (
      <>
        <div className="doc" style={{ textAlign: "justify" }}>
          <p>
            <span>Addendum to the Platform's Terms of Use</span>
          </p>
          <p>
            <span>
              The acceptance of this present addendum (LCCONTRACT) implies the express agreement of the USER ratifying the clauses and conditions foreseen in the document entitled TERMS AND CONDITIONS
              OF USE of the platform, as well as the following terms contained below:
            </span>
          </p>
          <ol>
            <li>
              <p className="bold">OBJECT</p>
              <ol>
                <li>
                  <span>The PURPOSE of the present LCCONTRACT is to establish the specific rules concerning the custody of digital assets as follows.</span>
                </li>
                <li>
                  <span>The custody MODALITY is of the {type} type, pursuant to the rules set forth in the TERMS AND CONDITIONS OF USE.</span>
                </li>
                <li>
                  <span>The DURATION of the present LCCONTRACT is {duration} months, as of the date of acceptance of this addendum.</span>
                </li>
                <li>
                  <span>The CUSTODIED VOLUME is {numberFormatter(amount)}, paid up after the acceptance of the present LCCONTRACT.</span>
                </li>
                <li>
                  <span>The expected result is variable.</span>
                  <ol>
                    <li>
                      <span>This result is partially calculated each month on the CUSTODIED VOLUME, which does not include results from the month itself or from previous months.</span>
                    </li>
                    <li>
                      <span>
                        The results measured monthly by the operations of the present LCCONTRACT are not covered by its terms, being allocated directly in the account statement of the USER, on the
                        platform, as provided in the TERMS AND CONDITIONS OF USE.
                      </span>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <p className="bold">THE OPERATION OF LCCONTRACT</p>
              <ol>
                <li>
                  <span>The general operating rules of LCCONTRACT are defined in the TERMS AND CONDITIONS OF USE.</span>
                </li>
                <li>
                  <span>By accepting the present, the USER ratifies all the clauses and conditions described in the TERMS AND CONDITIONS OF USE.</span>
                </li>
                <li>
                  <span>The USER hereby authorizes the use of the digital assets held in custody in selected transactions at the discretion of Lux Capital, LLC.</span>
                </li>
                <li>
                  <span>
                    This LCCONTRACT will be activated one business day (D+1) after the confirmation of the contribution by the user, according to the conditions in the TERMS AND CONDITIONS OF USE.
                  </span>
                </li>
                <li>
                  <span>The CUSTODIED VOLUME will remain under custody for the duration of this LCCONTRACT and cannot be redeemed before that time.</span>
                  <ol>
                    <li>
                      <span>
                        In the exceptional case of the need for anticipated redemption of the CUSTODIED VOLUME, the USER must proceed in accordance with that established in the TERMS AND CONDITIONS OF
                        USE.
                      </span>
                    </li>
                  </ol>
                </li>
                <li>
                  <span>
                    The increase in the volume of digital assets originated by the operations of the present LCCONTRACT may be visualized in the USER's account statement and will be available for use
                    in the appropriate periods, according to the MODALITY contracted herein.
                  </span>
                </li>
                <li>
                  <span>
                    When the validity of this LCCONTRACT expires, the total volume in custody will cease to be used in operations, with the respective volume remaining available for RESCUE by the
                    USER.
                  </span>
                  <ol>
                    <li>
                      <span>
                        After completion of the RESCUE of the totality of the digital assets held in custody by Lux Capital, LLC, they will be allocated in the account statement of the USER within
                        four working days (D+4).
                      </span>
                    </li>
                    <li>
                      <span>It is up to the USER to be aware of the expiration date to proceed to the redemption of its volume of digital assets.</span>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <p className="bold">The USER agrees with the provisions contained in this instrument, as well as ratifies the provisions contained in the TERMS AND CONDITIONS OF USE.</p>
            </li>
            {addendum && (
              <li>
                <p className="bold">ADDENDUM</p>
                <br />
                <div style={{ marginLeft: 30 }}>
                  <p style={{ whiteSpace: "pre-line" }}>{addendum}</p>
                </div>
              </li>
            )}
          </ol>
        </div>
      </>
    );
  }
}

export default LCContractEN;
