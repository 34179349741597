import React, { Component } from "react";
import { Button, CardContent, CardActions } from "@material-ui/core";
import { reduxForm, Field } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import CustomInputForm from "../custom/CustomInput";
import performRequest from "../configAxios";
import * as actions from "../actions";
import HeaderPageItem from "../custom/HeaderPageItem";
import PageContainer, { PageItem } from "../custom/Page";
import { FormattedMessage } from "react-intl";

class ChangePasswordForm extends Component {
  onSubmit = (formProps) => {
    const { showErrorMessage, showSuccessMessage, history } = this.props;
    performRequest("PUT", `/user/changePassword`, formProps, true)
      .then((response) => {
        showSuccessMessage("app.password.change");
        history.push("/more");
      })
      .catch((e) => {
        showErrorMessage(e.response ? e.response.data.message : e);
      });
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <PageContainer>
        <PageItem>
          <HeaderPageItem title={<FormattedMessage id="app.changePassword" />} showBackButton destination="/more" />
        </PageItem>
        <PageItem>
          <CardContent>
            <form onSubmit={handleSubmit(this.onSubmit)}>
              <Field
                name="password"
                label="app.password"
                type="password"
                inputProps={{ maxLength: 20 }}
                InputLabelProps={{
                  shrink: true,
                }}
                component={CustomInputForm}
              />
              <Field
                name="newPassword"
                label="app.newPassword"
                inputProps={{ maxLength: 20 }}
                type="password"
                InputLabelProps={{
                  shrink: true,
                }}
                component={CustomInputForm}
              />
              <Field
                name="confirmPassword"
                label="app.confirmPassword"
                inputProps={{ maxLength: 20 }}
                type="password"
                InputLabelProps={{
                  shrink: true,
                }}
                component={CustomInputForm}
              />

              <CardActions>
                <Button
                  fullWidth
                  id="bSubmitOrderForm"
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={this.props.pristine || this.props.submitting || this.props.invalid}
                >
                  <FormattedMessage id="app.button.save" />
                </Button>
              </CardActions>
            </form>
          </CardContent>
        </PageItem>
      </PageContainer>
    );
  }
}

const validate = (values, props) => {
  const errors = {};

  if (!values.password) {
    errors.password = "app.password.error";
  }

  if (!values.newPassword) {
    errors.newPassword = "app.newPassword.error";
  } else if (values.newPassword.length < 6) {
    errors.newPassword = "app.password.min";
  } else {
    if (!values.confirmPassword) {
      errors.confirmPassword = "app.confirmPassword.error";
    } else if (values.newPassword !== values.confirmPassword) {
      errors.confirmPassword = "app.confirmPassword.error.equals";
    }
  }

  return errors;
};

function mapStateToProps(state) {
  return {
    user: state.user.user.me,
    initialValues: state.user.user.me,
  };
}

export default compose(
  reduxForm({
    form: "changePasswordForm",

    validate,
  }),
  connect(mapStateToProps, actions)
)(ChangePasswordForm);
