import React, { Component } from "react";
import { Card, CardContent } from "@material-ui/core";
import { compose } from "redux";
import { connect } from "react-redux";
import * as actions from "../actions";
import { reduxForm } from "redux-form";
import PageContainer, { PageItem } from "../custom/Page";
import InfoList, { InfoListItem } from "../custom/InfoList";

import { phoneFormatter, cnpjNormalizer } from "../util/index";
import HeaderPageItem from "../custom/HeaderPageItem";
import { cpfNormalizer } from "../util";

class CustomerData extends Component {
  render() {
    const { currentUser } = this.props;
    return (
      <PageContainer>
        <PageItem>
          <HeaderPageItem title="app.data.personal" showBackButton />
        </PageItem>
        <PageItem>
          <Card>
            <CardContent>
              <InfoList>
                <InfoListItem label="app.name" info={currentUser.name} />
                <InfoListItem label="app.adress" info={currentUser.address} />
                <InfoListItem
                  label={!currentUser.isPj ? "CPF" : "CNPJ"}
                  info={!currentUser.isPj ? cpfNormalizer(currentUser.documentId) : cnpjNormalizer(currentUser.documentId)}
                />
                <InfoListItem label="app.email" info={currentUser.email} />
                <InfoListItem label="app.phone" info={phoneFormatter(currentUser.telefone)} />
              </InfoList>
              {currentUser.telefone2 && (
                <InfoList>
                  <InfoListItem label="app.phone" info={phoneFormatter(currentUser.telefone2)} />
                </InfoList>
              )}
            </CardContent>
          </Card>
        </PageItem>
      </PageContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me
  };
}

export default compose(
  connect(
    mapStateToProps,
    actions
  ),
  reduxForm({
    form: "dashboardForm",
    enableReinitialize: true
  })
)(CustomerData);
