import { Button, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Link, Menu, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { withTheme } from "@material-ui/styles";
import React, { Fragment, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import HeaderPageItem from "../custom/HeaderPageItem";
import InfoItem from "../custom/InfoItem";
import Number from "../custom/Number";
import PageContainer, { CardPageItem, PageItem } from "../custom/Page";
import messages_br from "../translations/br.json";
import messages_en from "../translations/en.json";
import messages_es from "../translations/es.json";
//import { getConfig2, getAtiveContracts, getStatusRedeemContracts, getRedeemContracts } from "../actions";
import { getContracts } from "../actions";
import { Roles, hasActualRole } from "../util";
import TableList from "./components/TableList";

const messages = {
  en: messages_en,
  br: messages_br,
  es: messages_es,
};

function ReportContractsCommissions({ currentUser, theme, taxResidence }) {
  const FILTER_DIALOG = 1;
  const SELECT_COLUMN_DIALOG = 2;

  const initialFilterSet = new FilterSet([new AgentFilter([])]);

  const [moreAnchor, setMoreAnchor] = useState(null);
  const [filterDialogId, setFilterDialogId] = useState(null);
  const [filterSet, setFilterSet] = useState(initialFilterSet);
  const [contracts, setContracts] = useState(null);
  const [filteredContracts, setFilteredContracts] = useState(null);
  const [totals, setTotals] = useState({});

  const options = {
    exportButton: true,
    search: false,
    filtering: false,
    paging: false,
    exportDelimiter: "\t",
    padding: "dense",
    headerStyle: {
      fontSize: 12,
      color: "#FFBE2D",
      textAlignLast: "start",
      whiteSpace: "nowrap",
    },
    rowStyle: (rowData, index) => {
      if (index % 2) {
        return { whiteSpace: "nowrap", backgroundColor: theme.palette.secondary.light };
      } else {
        return { whiteSpace: "nowrap", backgroundColor: theme.palette.secondary.dark };
      }
    },
  };

  const UNIT_FONT_SIZE = "60%";
  const DARK_FONT_COLOR = "#999";
  const userRoute = hasActualRole([Roles.Admin, Roles.Auditor, Roles.Kyc, Roles.FinanceManager], currentUser) ? "/user/" : "/customer/";

  const columns = [
    newColumn("app.id", "id", "numeric", "left", (rowData) => (
      <Link href={"/userContract/" + rowData.id} target="_blank">
        {"C " + rowData.id}
      </Link>
    )),
    newColumn("app.customer", "userName", "string", "left", (rowData) => (
      <>
        <Link href={userRoute + rowData.userId} target="_blank">
          {rowData.userName}{" "}
        </Link>
        <small style={{ color: DARK_FONT_COLOR }}>({rowData.userId})</small>
      </>
    )),
    newColumn("app.agent", "agentName", "string", "left", (rowData) => (
      <>
        <Link href={userRoute + rowData.agentId} target="_blank">
          {rowData.agentName}{" "}
        </Link>
        <small style={{ color: DARK_FONT_COLOR }}>({rowData.agentId})</small>
      </>
    )),
    newColumn("app.custodian.lct", "amount", "numeric", "right", (rowData) => (
      <>
        <Number value={rowData.amount} currency="LCT" display="none" />
      </>
    )),

    newColumn("manager id", "managerId", "numeric", "center", (rowData) => <small style={{ color: DARK_FONT_COLOR }}>{rowData.managerId}</small>),
    newColumn("manager %", "managerPercent", "numeric", "right", (rowData) => <Number value={rowData.managerPercent} display="percentage" />),
    newColumn("manager value", "managerValue", "numeric", "right", (rowData) => <Number value={rowData.managerValue} currency="LCT" display="none" />),

    newColumn("type %", "typePercent", "numeric", "right", (rowData) => <Number value={rowData.typePercent} display="percentage" />),
    newColumn("type value", "typeValue", "numeric", "right", (rowData) => <Number value={rowData.typeValue} currency="LCT" display="none" />),

    newColumn("agent id", "agentId", "numeric", "center", (rowData) => <small style={{ color: DARK_FONT_COLOR }}>{rowData.agentId}</small>),
    newColumn("agent %", "agentPercent", "numeric", "right", (rowData) => <Number value={rowData.agentPercent} display="percentage" />),
    newColumn("agent value", "agentValue", "numeric", "right", (rowData) => <Number value={rowData.agentValue} currency="LCT" display="none" />),

    newColumn("split id", "splitId", "numeric", "center", (rowData) => <small style={{ color: DARK_FONT_COLOR }}>{rowData.splitId}</small>),
    newColumn("split %", "splitPercent", "numeric", "right", (rowData) => rowData.splitPercent && <Number value={rowData.splitPercent} display="percentage" />),
    newColumn("split value", "splitValue", "numeric", "right", (rowData) => rowData.splitValue && <Number value={rowData.splitValue} currency="LCT" display="none" />),

    newColumn("recommender id", "recommenderId", "numeric", "center", (rowData) => <small style={{ color: DARK_FONT_COLOR }}>{rowData.recommenderId}</small>),
    newColumn("recommender %", "recommenderPercent", "numeric", "right", (rowData) => rowData.recommenderPercent && <Number value={rowData.recommenderPercent} display="percentage" />),
    newColumn("recommender value", "recommenderValue", "numeric", "right", (rowData) => rowData.recommenderValue && <Number value={rowData.recommenderValue} currency="LCT" display="none" />),
  ];

  useEffect(() => {
    loadAndConvertContracts();
  }, []);

  useEffect(() => {
    filterAndCountContracts();
  }, [filterSet]);

  async function loadAndConvertContracts() {
    const params = {
      phase: ["EXECUTING"],
      taxResidence,
    };
    let rawContracts = await getContracts(params);
    setContracts(rawContracts.map(mapContract));

    let ids = new Set();
    filterSet.filters[0].options = [{ label: messages[currentUser.locale]["app.all"], value: 0 }].concat(
      rawContracts
        .filter((c) => !ids.has(c.user.consultant.id) && ids.add(c.user.consultant.id))
        .map((c) => ({ label: `${c.user.consultant.name} (${c.user.consultant.id})`, value: c.user.consultant.id }))
    );
    setFilterSet({ ...filterSet });
  }

  function filterAndCountContracts() {
    if (contracts !== null) {
      let filtered = filterSet.apply(contracts);
      setFilteredContracts(filtered);
      let totals = filtered.reduce(
        (t, c) => ({
          count: t.count + 1,
          amount: t.amount + c.amount,
          manager: t.manager + c.managerValue,
          agent: t.agent + c.agentValue,
        }),
        {
          count: 0,
          amount: 0,
          manager: 0,
          agent: 0,
        }
      );
      setTotals(totals);
    }
  }

  function mapContract(c) {
    let agentLimit = 1;
    let agreementAgent = c.user.consultant?.contractAgent;
    if (agreementAgent && agreementAgent.status === "ACTIVE" && agreementAgent.type === "AGENT") {
      agentLimit = 1 - agreementAgent.splitForManager;
    }

    let managerPercent = 0;
    let agreementManager = c.user.consultant?.manager?.contractAgent;
    if (agreementManager && agreementManager.status === "ACTIVE" && agreementManager.type === "MANAGER") {
      managerPercent = agreementManager.managerComission;
    }

    let userPercent = (c.maxMonthlyYield * c.segment.baseYield) / (100 * 100);
    let typePercent = (c.type.agentCommission * c.segment.commissionModifier) / 100;
    let agentPercent = agentLimit * typePercent;
    let splitPercent = c.splitAgent === null ? null : c.splitPercent * agentPercent;
    let recommenderPercent = null;
    if (splitPercent !== null) {
      agentPercent -= splitPercent;
    }
    if (recommenderPercent !== null) {
      agentPercent -= recommenderPercent;
    }

    return {
      id: c.id,
      phase: c.phase,
      initialDate: c.initialDate,
      dueDate: c.dueDate,
      typeDescription: c.type.description,
      amount: c.amount,

      userId: c.user.id,
      userName: c.user.name,
      userPercent: userPercent,
      userValue: userPercent * c.amount,

      typePercent,
      typeValue: typePercent * c.amount,
      agentLimit,

      agentId: c.user.consultant.id,
      agentName: c.user.consultant.name,
      agentPercent,
      agentValue: agentPercent * c.amount,

      managerId: c.user.consultant.manager.id,
      managerName: c.user.consultant.manager.name,
      managerPercent,
      managerValue: managerPercent * c.amount,

      splitId: c.splitAgent?.id,
      splitName: "(" + c.splitAgent?.name + ")",
      splitPercent,
      splitValue: splitPercent === null ? null : splitPercent * c.amount,

      recommenderId: null,
      recommenderName: null,
      recommenderPercent,
      recommenderValue: recommenderPercent === null ? null : recommenderPercent * c.amount,
    };
  }

  function openDialog(dialogId) {
    setMoreAnchor(null);
    setFilterDialogId(dialogId);
  }

  return (
    <>
      <PageContainer full>
        <HeaderPageItem title="Contracts commissions report" showBackButton destination="/reports">
          <Menu keepMounted anchorEl={moreAnchor} open={Boolean(moreAnchor)} onClose={() => setMoreAnchor(null)} component="li">
            <MenuItem button onClick={() => openDialog(FILTER_DIALOG)}>
              <FormattedMessage id="app.report.filter" />
            </MenuItem>
            {/*
            <MenuItem button onClick={() => openDialog(SELECT_COLUMN_DIALOG)}>
              <FormattedMessage id="app.column.filter" />
            </MenuItem>
            */}
          </Menu>
        </HeaderPageItem>

        <CardPageItem raised double>
          <CardContent>
            <div style={{ display: "grid", gridAutoFlow: "column", justifyContent: "start", alignItems: "start", columnGap: 16 }}>
              <InfoItem caption={<FormattedMessage id={`app.agent`} />} text={<>{filterSet.filters[0].options.find((o) => o.value === filterSet.filters[0].value)?.label}</>} />
              <InfoItem
                center
                caption={<FormattedMessage id={`app.contracts`} />}
                text={
                  <>
                    <Number value={totals.count} />
                  </>
                }
              />
            </div>
            <div style={{ display: "grid", gridAutoFlow: "column", justifyContent: "start", alignItems: "start", columnGap: 16 }}>
              <InfoItem
                caption={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FormattedMessage id="app.custodian.total" currency="LCT" />
                  </div>
                }
                text={<Number value={totals.amount} currency="LCT" />}
              />

              <InfoItem
                caption={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FormattedMessage id="app.comissionManager.lct" />
                  </div>
                }
                text={<Number value={totals.manager} currency="LCT" />}
              />
              <InfoItem
                caption={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FormattedMessage id="app.agent" />
                  </div>
                }
                text={<Number value={totals.agent} currency="LCT" />}
              />
            </div>
          </CardContent>
        </CardPageItem>

        <CardPageItem raised double>
          <CardContent>
            <Typography variant="caption" color="textSecondary" style={{ marginBottom: 8 }} component="div">
              Filtro
            </Typography>
            <TextField
              select
              variant="outlined"
              fullWidth
              label="Agent"
              value={filterSet.filters[0].value}
              onChange={(e) => {
                filterSet.filters[0].value = e.target.value;
                setFilterSet({ ...filterSet });
              }}
            >
              {filterSet.filters[0].options.map((o) => (
                <MenuItem key={o.label} value={o.value}>
                  {o.label}
                </MenuItem>
              ))}
            </TextField>
          </CardContent>
        </CardPageItem>

        <PageItem>
          {contracts === null || filteredContracts === null || moreAnchor !== null || filterDialogId !== null ? (
            <div style={{ textAlign: "center" }}>
              <CircularProgress size={24} />
            </div>
          ) : (
            <TableList title="" columns={columns} data={filteredContracts} options={options} user={currentUser} />
          )}
        </PageItem>
      </PageContainer>

      {filterDialogId === FILTER_DIALOG && (
        <FilterDialog
          title={<FormattedMessage id="app.filter" />}
          open={true}
          onClose={() => setFilterDialogId(null)}
          onAction={(values) => {
            setFilterDialogId(null);
            filterSet.filters.forEach((filter, index) => (filter.value = values[index]));
            setFilterSet({ ...filterSet });
          }}
          filterSet={filterSet}
        />
      )}
    </>
  );
}

class AgentFilter {
  value = 0;
  caption = "app.agent";
  options = [];
  constructor(options) {
    this.options = options;
  }
  apply = (contracts) => contracts.filter((c) => this.value === 0 || c.agentId === this.value);
}

class FilterSet {
  filters = [];
  constructor(filters) {
    this.filters = filters;
  }
  apply = (contracts) => this.filters.reduce((contracts, filter) => filter.apply(contracts), contracts);
}

function FilterDialog({ open, onClose, title, onAction, filterSet }) {
  const [values, setValues] = useState(filterSet.filters.map((f) => f.value));

  function submitClick() {
    onAction(values);
  }

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        {filterSet.filters.map((filter, index) => (
          <Fragment key={filter.caption}>
            <TextField
              variant="outlined"
              select
              label={<FormattedMessage id={filter.caption} />}
              value={values[index]}
              onChange={(e) => {
                values[index] = e.target.value;
                setValues([...values]);
              }}
              style={{ width: "100%", marginTop: 16 }}
            >
              {filter.options.map((o) => (
                <MenuItem key={o.label} value={o.value}>
                  {o.label}
                </MenuItem>
              ))}
            </TextField>
            <Divider />
          </Fragment>
        ))}
      </DialogContent>

      <DialogActions>
        <Button type="submit" onClick={submitClick} color="primary">
          <FormattedMessage id="app.filter" />
        </Button>
        <Button onClick={onClose} color="default">
          <FormattedMessage id="app.cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function newColumn(title, field, type, align, render) {
  return {
    title,
    field,
    type,
    cellStyle: {
      textAlign: align,
      fontSize: 12,
      position: "sticky",
    },
    headerStyle: {
      textAlignLast: align,
      fontSize: 12,
    },
    render,
  };
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default connect(mapStateToProps)(withTheme(ReportContractsCommissions));
