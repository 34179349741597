import {
  Button,
  CardContent,
  CircularProgress,
  DialogContent,
  DialogContentText,
  IconButton,
  InputAdornment,
  InputBase,
  ListItem,
  ListItemAvatar,
  ListItemText,
  OutlinedInput,
  TextField,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";
import CancelIcon from "@material-ui/icons/Cancel";
import DoneIcon from "@material-ui/icons/Done";
import EditIcon from "@material-ui/icons/Edit";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import SearchIcon from "@material-ui/icons/Search";
import ViewIcon from "@material-ui/icons/Visibility";
import moment from "moment";
import React, { Component } from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import {
  changeAgentAdmin,
  changeRecommenderAdmin,
  clearAgent,
  clearRecommender,
  dispatchErrorMessage,
  dispatchSuccessMessage,
  getUsersBelowMe,
  loadBeneficiary,
  toogleAgentRole,
} from "../../actions";
import performRequest from "../../configAxios";
import AbtcAvatar from "../../custom/Avatar";
import FormDialog from "../../custom/FormDialog";
import NumberFormat from "../../custom/Number";
import wpp from "../../static/whatsapp.png";
import {
  Roles,
  capitalCaseNormalizer,
  documentFormatter,
  isAgent,
  isAgentManager,
  isAgentManagerOf,
  isUserBelowManager,
  phoneFormatter,
  userDocumentFormatter,
} from "../../util";
import FormContractAgent from "../components/FormContractAgent";
import InfoItem from "../../custom/InfoItem";

class UserDetail extends Component {
  state = {
    loading: true,
    user: {},
    currentUser: {},
    showChangeAgentDialog: false,
    showChangeRecommenderDialog: false,
    showComissionDialog: false,
    showClearAgentConfirmation: false,
    showClearRecommenderConfirmation: false,
    openToogleAgentDialog: false,
    showBeneficiaryDialog: false,
    agents: [],
    recommenders: [],
    recommenderId: null,
    recommenderName: "",
    recommenderPercent: null,
  };

  componentDidMount() {
    this.loadData();
    this.setState({
      user: this.props.user,
      currentUser: this.props.currentUser,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.user !== prevProps.user) {
      this.loadData();
    }
  }

  searchAgentsTimeoutId = null;
  searchAgents = (name) => {
    if (name == null) {
      this.setState({ agents: [] });
    } else if (name.trim().length > 0) {
      this.searchAgentsGet(name);
    } else {
      this.searchAgentsGet("");
    }
  };

  searchAgentsGet = (name) => {
    clearTimeout(this.searchAgentsTimeoutId);
    this.searchAgentsTimeoutId = setTimeout(() => {
      getUsersBelowMe({ role: Roles.Agent, name, includeMe: true })
        .then((agents) => {
          this.setState({ agents });
        })
        .catch();
    }, 1000);
  };

  searchRecommendersTimeoutId = null;
  searchRecommenders = (name) => {
    if (name == null) {
      this.setState({ recommenders: [] });
    } else if (name.trim().length > 0) {
      this.searchRecommendersGet(name);
    } else {
      this.searchRecommendersGet("");
    }
  };

  searchRecommendersGet = async (name) => {
    clearTimeout(this.searchRecommendersTimeoutId);
    this.searchRecommendersTimeoutId = setTimeout(() => {
      getUsersBelowMe({ name, includeMe: false })
        .then((recommenders) => {
          this.setState({ recommenders });
        })
        .catch();
    }, 1000);
  };

  changeAgent = (id, ag) => {
    const agId = {
      consultantId: ag,
    };
    changeAgentAdmin(id, agId).then((data) => {
      this.setState({ user: data });
    });
  };

  changeRecommender = (id, recId, recPer) => {
    const data = {
      recommenderId: recId,
      recommenderPercent: recPer,
    };
    changeRecommenderAdmin(id, data).then((user) => {
      this.setState({ user });
    });
  };

  clearAgent = () => {
    const { user } = this.state;
    clearAgent(user.id).then((data) => {
      this.loadData();
      this.setState({ user: data, showClearAgentConfirmation: false });
    });
  };

  clearRecommender = () => {
    const { user } = this.state;
    clearRecommender(user.id).then((data) => {
      this.loadData();
      this.setState({ user: data, showClearRecommenderConfirmation: false });
    });
  };

  toogleAgent = () => {
    const { user } = this.state;
    const { dispatch } = this.props;
    toogleAgentRole(user.id)
      .then((data) => {
        this.loadData();
        this.setState({ user: data });
        dispatchSuccessMessage("app.agentRoleChanged", dispatch);
      })
      .catch((e) => {
        dispatchErrorMessage(e.response.data.message, dispatch);
      });
  };

  loadData = () => {
    const { currentUser, user } = this.props;
    //const { user } = this.state;
    performRequest("GET", `/user/${user}`, null, true)
      .then((response) => {
        let user = response.data.data;
        this.setState({
          loading: false,
          user: user,
          recommenderId: user.recommender?.id,
          recommenderName: user.recommender?.name,
          recommenderPercent: user.recommenderPercent ?? 0.3,
        });
        loadBeneficiary(user.id).then((beneficiary) => this.setState({ user: { ...user, beneficiary } }));
      })
      .catch((e) => {
        this.props.history.push("/notfound");
      });
  };

  userDocumentType = (document, personType) => {
    if (document == "NIT" && personType == "NATURAL_PERSON") {
      return <FormattedMessage id={`app.cedula`} />;
    } else {
      return <FormattedMessage id={`app.enuns.${document}`} />;
    }
  };

  render() {
    const { agents, recommenders, loading, user, currentUser, showComissionDialog, openToogleAgentDialog, showBeneficiaryDialog } = this.state;
    const role = {
      agent: currentUser.role == Roles.Agent,
      admin: currentUser.role == Roles.Admin,
      client: currentUser.role == Roles.Customer,
    };

    if (loading) return <CircularProgress style={{ marginTop: "25%" }} />;

    const canEdit = [Roles.Admin, Roles.FinanceManager].includes(currentUser.role) || isUserBelowManager(user, currentUser);
    return (
      <CardContent>
        {!this.props.hideAvatar && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: 16,
            }}
          >
            <AbtcAvatar imageUrl={user.photoUrl} disableLink size={120} />
          </div>
        )}

        {/* nome */}
        <Typography color="textSecondary" variant="caption">
          {user.id}
          {" - "}
          <FormattedHTMLMessage id={`app.enuns.${user.role}`} />
          {" - "}
          <span style={{ color: user.apt ? "inherit" : "red" }}>
            <FormattedMessage id={user.apt ? "app.apt" : "app.inapt"} />
          </span>
          {user.premium && (
            <>
              {" "}
              - <FormattedMessage id="app.premium" />
            </>
          )}
        </Typography>
        <Typography color={user.premium ? "primary" : undefined} paragraph>
          {user.name}
        </Typography>

        {/* telefones */}
        <Typography color="textSecondary" variant="caption">
          <FormattedMessage id="app.phone" />
        </Typography>
        <Typography paragraph component="div">
          {!user.telefone && !user.telefone2 ? (
            <FormattedMessage id="app.empty" />
          ) : (
            <>
              {user.telefone && (
                <div>
                  {phoneFormatter(user.telefone)}{" "}
                  {user.isWhats && (
                    <a href={"https://wa.me/55" + user.telefone} target="_blank" rel="noopener noreferrer">
                      <img src={wpp} width="14" height="14" alt=" " />
                    </a>
                  )}
                </div>
              )}
              {user.telefone2 && (
                <div>
                  {phoneFormatter(user.telefone2)}{" "}
                  {user.isWhats2 && (
                    <a href={"https://wa.me/55" + user.telefone2} target="_blank" rel="noopener noreferrer">
                      <img src={wpp} width="14" height="14" alt=" " />
                    </a>
                  )}
                </div>
              )}
            </>
          )}
        </Typography>
        {/* email */}
        {!user.emailConfirmed ? (
          <>
            <Typography color="textSecondary" variant="caption">
              <FormattedMessage id="app.email" />{" "}
              <span style={{ color: "red" }}>
                <FormattedMessage id="app.not.confirmed" />
              </span>
            </Typography>
            <Typography paragraph>{user.email}</Typography>
          </>
        ) : (
          <>
            <Typography color="textSecondary" variant="caption">
              <FormattedMessage id="app.email" />
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: -4,
              }}
            >
              <Typography paragraph>
                {user.email}
                <IconButton size="small" disabled style={{ color: "green" }}>
                  <DoneIcon />
                </IconButton>
              </Typography>
            </div>
          </>
        )}

        {/* domicilio fiscal */}
        <Typography color="textSecondary" variant="caption">
          <FormattedMessage id="app.taxResidence" />
        </Typography>
        <Typography paragraph>{user.taxResidence}</Typography>

        {/* documentos */}
        <Typography color="textSecondary" variant="caption">
          {this.userDocumentType(user.documentType, user.type)}
          {/* <FormattedMessage id={`app.enuns.${user.documentType}`} /> */}
        </Typography>
        <Typography paragraph>{userDocumentFormatter(user, <FormattedMessage id="app.empty" />)}</Typography>
        {user.isPj && (
          <>
            <Typography color="textSecondary" variant="caption">
              <FormattedMessage id="app.legalRepresentativeName" />
            </Typography>
            <Typography paragraph>{user.legalRepresentativeName ? user.legalRepresentativeName : <FormattedMessage id="app.empty" />}</Typography>

            <Typography color="textSecondary" variant="caption">
              <FormattedMessage id="app.legalRepresentativeDocumentId" />
            </Typography>
            <Typography paragraph>{documentFormatter(user.legalRepresentativeId, "CPF", <FormattedMessage id="app.empty" />)}</Typography>
          </>
        )}
        {!user.isPj && (
          <>
            {/* data de nascimento */}
            <Typography color="textSecondary" variant="caption">
              <FormattedMessage id="app.dateOfBirth" />
            </Typography>
            <Typography paragraph>{user.dateOfBirth ? moment(user.dateOfBirth).format("DD/MM/YYYY") : <FormattedMessage id="app.empty" />}</Typography>
          </>
        )}

        {/* endereço */}
        <Typography color="textSecondary" variant="caption">
          <FormattedMessage id="app.address" />
        </Typography>
        <Typography paragraph>
          {user.address ? user.address : "N/D"}, {user.city ? user.city : "N/D"} - {user.state ? user.state : "N/D"} - {user.country ? user.country : "N/D"}
        </Typography>

        {/* data de cadastro */}
        <Typography color="textSecondary" variant="caption">
          <FormattedMessage id="app.dateRegister" />
        </Typography>
        <Typography paragraph>{moment(user.createdAt).format("DD/MM/YYYY")}</Typography>

        {/* idioma */}
        <Typography color="textSecondary" variant="caption">
          <FormattedMessage id="app.language" />
        </Typography>
        <Typography paragraph>
          <FormattedMessage id={`app.language.${user.locale}`} />
        </Typography>

        {/* perfil */}
        <Typography color="textSecondary" variant="caption">
          <FormattedMessage id="app.role" />
        </Typography>
        <Typography paragraph>
          <FormattedHTMLMessage id={`app.enuns.${user.role}`} />
          {([Roles.Admin, Roles.FinanceManager].includes(currentUser.role) || isAgentManagerOf(currentUser, user)) && (
            <Tooltip title={<FormattedHTMLMessage id={isAgent(user) ? "app.unassignAgentRoleCaption" : "app.assignAgentRoleCaption"} />}>
              <IconButton
                size="small"
                disabled={!user.apt}
                onClick={(e) => {
                  this.setState({ openToogleAgentDialog: true });
                }}
              >
                {isAgent(user) ? <CancelIcon fontSize="small" /> : <GroupAddIcon fontSize="small" />}
              </IconButton>
            </Tooltip>
          )}
        </Typography>

        {/* status do service agreement */}
        {user.role == Roles.Agent &&
          (user.contractAgent ? (
            <>
              {user.contractAgent.signatureDate ? (
                <>
                  <Typography gutterBottom color="textSecondary" variant="caption">
                    <FormattedHTMLMessage id="app.contractAgent" /> (
                    <FormattedHTMLMessage id={`app.contractAgent.${user.contractAgent.type}`} />)
                  </Typography>
                  <Typography>
                    <FormattedMessage id={`app.enuns.${user.contractAgent.status}`} /> <FormattedMessage id="app.contractAgent.signed" />
                    {moment(user.contractAgent.signatureDate).format("DD/MM/YYYY")}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography gutterBottom color="textSecondary" variant="caption">
                    <FormattedHTMLMessage id="app.contractAgent" />
                  </Typography>
                  <Typography>
                    <FormattedMessage id={`app.enuns.${user.contractAgent.status}`} />
                  </Typography>
                </>
              )}
              {canEdit && isAgent(user) && (
                <Typography variant="caption">
                  Comisison limit: <NumberFormat value={1 - user.contractAgent.splitForManager} display="percentage" />
                  <Tooltip title={<FormattedMessage id="app.edit" />}>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        this.setState({ showComissionDialog: true });
                      }}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Typography>
              )}
              <Typography paragraph>{/*espaçador*/}</Typography>
            </>
          ) : (
            <>
              <Typography gutterBottom color="textSecondary" variant="caption">
                <FormattedHTMLMessage id="app.contractAgent" />
              </Typography>
              <Typography paragraph color="error">
                <b>
                  <FormattedMessage id="app.none" />
                </b>
              </Typography>
            </>
          ))}

        {/* agente */}
        {user.consultant ? (
          <>
            <Typography color="textSecondary" variant="caption">
              {user.consultant.id} {" - "}
              <FormattedMessage id="app.agent.assigned" />
            </Typography>
            <div color="textSecondary" style={{ display: "flex" }}>
              <Typography
                paragraph
                color="textPrimary"
                style={{ alignSelf: "center", textDecoration: "none" }}
                component={
                  currentUser.role == Roles.Admin ||
                  currentUser.role == Roles.BusinessManager ||
                  currentUser.role == Roles.Auditor ||
                  currentUser.role == Roles.AgentsManager
                    ? Link
                    : null
                }
                to={`/user/${user.consultant.id}`}
              >
                {capitalCaseNormalizer(user.consultant.name)}
              </Typography>
              <div>
                {canEdit && (
                  <>
                    <Tooltip title={<FormattedMessage id="app.changeAgentOrAgentManager" />}>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          this.setState({ showChangeAgentDialog: true });
                        }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id="app.agentOrAgentManager.clear" />}>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          this.setState({ showClearAgentConfirmation: true });
                        }}
                      >
                        <CancelIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            {currentUser.role === Roles.Admin && user.role !== Roles.Admin && (
              <Button
                disabled={currentUser.role == Roles.Kyc}
                style={{ marginTop: 16 }}
                fullWidth
                variant="outlined"
                onClick={(e) => {
                  this.setState({ showChangeAgentDialog: true });
                }}
              >
                <FormattedMessage id="app.addAgent" />
              </Button>
            )}
          </>
        )}

        {/* recomendante */}
        <Typography color="textSecondary" variant="caption">
          {user.recommender?.id} {" - "}
          <FormattedMessage id="app.recommender.assigned" />
        </Typography>
        {user.recommender ? (
          <>
            <div color="textSecondary" style={{ display: "flex" }}>
              <Typography
                paragraph
                color="textPrimary"
                style={{ alignSelf: "center", textDecoration: "none" }}
                component={
                  currentUser.role == Roles.Admin ||
                  currentUser.role == Roles.BusinessManager ||
                  currentUser.role == Roles.Auditor ||
                  currentUser.role == Roles.AgentsManager
                    ? Link
                    : null
                }
                to={`/user/${user.recommender.id}`}
              >
                {capitalCaseNormalizer(user.recommender.name)}{" "}
                {canEdit && (
                  <small>
                    (<NumberFormat value={user.recommenderPercent} display="percentage" />)
                  </small>
                )}
              </Typography>
              <div>
                {canEdit && (
                  <>
                    <Tooltip title={<FormattedMessage id="app.changeRecommender" />}>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          this.setState({ showChangeRecommenderDialog: true });
                        }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id="app.recommender.clear" />}>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          this.setState({ showClearRecommenderConfirmation: true });
                        }}
                      >
                        <CancelIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div color="textSecondary" style={{ display: "flex" }}>
              <Typography paragraph color="textPrimary" style={{ alignSelf: "center", textDecoration: "none" }}>
                <FormattedMessage id="app.none" />
              </Typography>
              <div>
                {canEdit && (
                  <>
                    <Tooltip title={<FormattedMessage id="app.changeRecommender" />}>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          this.setState({ showChangeRecommenderDialog: true });
                        }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </div>
            </div>
          </>
        )}

        {/* beneficiário */}
        <Typography color="textSecondary" variant="caption">
          <FormattedMessage id="app.beneficiary" />
        </Typography>
        {user.beneficiary ? (
          <>
            <div color="textSecondary" style={{ display: "flex" }}>
              <Typography paragraph color="textPrimary" style={{ alignSelf: "center", textDecoration: "none" }}>
                {capitalCaseNormalizer(user.beneficiary.name)} {canEdit && <small>({user.beneficiary.relationship})</small>}
              </Typography>
              <div>
                <IconButton
                  size="small"
                  onClick={(e) => {
                    this.setState({ showBeneficiaryDialog: true });
                  }}
                >
                  <ViewIcon fontSize="small" />
                </IconButton>
              </div>
            </div>
          </>
        ) : (
          <>
            <div color="textSecondary" style={{ display: "flex" }}>
              <Typography paragraph color="textPrimary" style={{ alignSelf: "center", textDecoration: "none" }}>
                <FormattedMessage id="app.none" />
              </Typography>
            </div>
          </>
        )}

        {/* observações */}
        {[Roles.Admin, Roles.Kyc, Roles.FinanceManager].includes(currentUser.role) && (
          <>
            <Typography color="textSecondary" variant="caption">
              <FormattedMessage id="app.obs" />
            </Typography>
            <Typography paragraph style={{ whiteSpace: "pre-line" }}>
              {user.observations ?? "N/A"}
            </Typography>
          </>
        )}

        <FormDialog title="app.edit" open={showComissionDialog} variant="secondary" noHeader onClose={(e) => this.setState({ showComissionDialog: false })}>
          <FormContractAgent
            customer={user}
            onActionComplete={(contractAgent) => this.setState({ user: { ...user, contractAgent }, showComissionDialog: false })}
          />
        </FormDialog>

        <FormDialog
          title="app.changeAgent"
          open={this.state.showChangeAgentDialog}
          variant="secondary"
          noHeader
          onClose={(e) => this.setState({ showChangeAgentDialog: false })}
        >
          <Typography style={{ marginBottom: 16 }}>
            <FormattedMessage id="app.changeAgent.text" />
          </Typography>
          <IconButton aria-label="Search">
            <SearchIcon />
          </IconButton>
          <FormattedMessage id="app.searchAgent">
            {(placeholder) => <InputBase placeholder={placeholder} onChange={(e) => this.searchAgents(e.target.value)} />}
          </FormattedMessage>

          {agents.map((c) => {
            if (user.id != c.id) {
              return (
                <ListItem
                  button
                  alignItems="flex-start"
                  key={c.id}
                  onClick={(e) => {
                    this.changeAgent(user.id, c.id);
                    this.setState({ showChangeAgentDialog: false });
                  }}
                >
                  <ListItemAvatar>
                    <AbtcAvatar imageUrl={c.photoUrl} disableLink />
                  </ListItemAvatar>
                  <ListItemText
                    primaryTypographyProps={{ component: "div" }}
                    secondaryTypographyProps={{ component: "div" }}
                    primary={
                      <div
                        color="textSecondary"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>{capitalCaseNormalizer(c.name)}</span>
                        <span> {"Id: " + c.id}</span>
                      </div>
                    }
                    secondary={
                      <div>
                        <div
                          color="textSecondary"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>{c.email}</span>
                        </div>
                      </div>
                    }
                  />
                </ListItem>
              );
            }
          })}
        </FormDialog>

        <FormDialog
          title="app.changeRecommender"
          open={this.state.showChangeRecommenderDialog}
          variant="secondary"
          noHeader
          onClose={(e) => {
            this.setState({ showChangeRecommenderDialog: false });
            this.loadData();
          }}
        >
          <Typography style={{ marginBottom: 16 }}>
            <FormattedMessage id="app.changeRecommender.text" />
          </Typography>
          <div style={{ display: "grid", gridTemplateColumns: "1fr 3fr 1fr 1fr", gap: 8 }}>
            <TextField
              type="number"
              variant="outlined"
              value={(this.state.recommenderPercent * 1000) / 10}
              onChange={(e) => this.setState({ recommenderPercent: Number(e.target.value) / 100 })}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              inputProps={{ min: 1, max: 100 }}
              labelWidth={0}
              label="Percentual"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              type="search"
              variant="outlined"
              value={this.state.recommenderName}
              onChange={(e) => {
                this.setState({ recommenderName: e.target.value, recommenderId: "" });
                this.searchRecommenders(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              label={<FormattedMessage id="app.searchRecommender" />}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              variant="outlined"
              value={this.state.recommenderId}
              InputProps={{
                readOnly: true,
              }}
              label="Id"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Button
              variant="outlined"
              color="primary"
              onClick={(e) => {
                this.changeRecommender(user.id, this.state.recommenderId, this.state.recommenderPercent);
                this.setState({ showChangeRecommenderDialog: false });
              }}
            >
              <FormattedMessage id="app.ok" />
            </Button>
          </div>

          {recommenders.map((c) => {
            if (user.id != c.id) {
              return (
                <ListItem
                  button
                  alignItems="flex-start"
                  key={c.id}
                  onClick={(e) => {
                    this.setState({ recommenderId: c.id, recommenderName: c.name });
                    this.searchRecommenders(null);
                  }}
                >
                  <ListItemAvatar>
                    <AbtcAvatar imageUrl={c.photoUrl} disableLink />
                  </ListItemAvatar>
                  <ListItemText
                    primaryTypographyProps={{ component: "div" }}
                    secondaryTypographyProps={{ component: "div" }}
                    primary={
                      <div
                        color="textSecondary"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>{capitalCaseNormalizer(c.name)}</span>
                        <span> {"Id: " + c.id}</span>
                      </div>
                    }
                    secondary={
                      <div>
                        <div
                          color="textSecondary"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>{c.email}</span>
                        </div>
                      </div>
                    }
                  />
                </ListItem>
              );
            }
          })}
        </FormDialog>

        <Dialog open={this.state.showClearAgentConfirmation}>
          <DialogTitle>
            <FormattedHTMLMessage id="app.agentOrAgentManager.clear" />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <FormattedHTMLMessage id="app.agentOrAgentManager.clear.text" />
            </DialogContentText>
            <DialogActions>
              <Button
                style={{ marginTop: 16 }}
                variant="outlined"
                onClick={(e) => {
                  this.clearAgent();
                }}
              >
                <FormattedMessage id="app.ok" />
              </Button>
              <Button
                style={{ marginTop: 16 }}
                variant="outlined"
                onClick={(e) => {
                  this.setState({ showClearAgentConfirmation: false });
                }}
              >
                <FormattedMessage id="app.cancel" />
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>

        {user.beneficiary && (
          <FormDialog
            title="app.beneficiary"
            open={showBeneficiaryDialog}
            variant="secondary"
            noHeader
            onClose={(e) => this.setState({ showBeneficiaryDialog: false })}
          >
            <InfoItem caption={<FormattedHTMLMessage id="app.relationship" />} text={user.beneficiary.relationship} />
            <InfoItem caption={<FormattedHTMLMessage id="app.name" />} text={user.beneficiary.name} />
            <InfoItem caption={<FormattedHTMLMessage id="app.email" />} text={user.beneficiary.email} />
            <InfoItem caption={<FormattedHTMLMessage id="app.phone" />} text={user.beneficiary.phone} />
            <InfoItem caption={<FormattedHTMLMessage id="app.address" />} text={user.beneficiary.address} />
            <InfoItem caption={<FormattedHTMLMessage id="app.documentType" />} text={user.beneficiary.documentType} />
            <InfoItem caption={<FormattedHTMLMessage id="app.documentId" />} text={user.beneficiary.documentId} />
            <InfoItem caption={<FormattedHTMLMessage id="app.dateOfBirth" />} text={user.beneficiary.dateOfBirth} />
            <InfoItem caption={<FormattedHTMLMessage id="app.obs" />} text={user.beneficiary.observations ?? "(N/A)"} />
          </FormDialog>
        )}

        <Dialog open={this.state.showClearRecommenderConfirmation}>
          <DialogTitle>
            <FormattedHTMLMessage id="app.recommender.clear" />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <FormattedHTMLMessage id="app.recommender.clear.text" />
            </DialogContentText>
            <DialogActions>
              <Button
                style={{ marginTop: 16 }}
                variant="outlined"
                onClick={(e) => {
                  this.clearRecommender();
                }}
              >
                <FormattedMessage id="app.ok" />
              </Button>
              <Button
                style={{ marginTop: 16 }}
                variant="outlined"
                onClick={(e) => {
                  this.setState({ showClearRecommenderConfirmation: false });
                }}
              >
                <FormattedMessage id="app.cancel" />
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>

        <ToogleAgentDialog
          open={openToogleAgentDialog}
          user={user}
          onConfirm={() => {
            this.toogleAgent();
            this.setState({ openToogleAgentDialog: false });
          }}
          onCancel={() => this.setState({ openToogleAgentDialog: false })}
        />
      </CardContent>
    );
  }
}

function ToogleAgentDialog({ open, onCancel, onConfirm, user }) {
  return (
    <Dialog open={open}>
      <DialogTitle>
        <FormattedHTMLMessage id={user.role === Roles.Agent ? "app.unassignAgentRoleCaption" : "app.assignAgentRoleCaption"} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormattedHTMLMessage id={user.role === Roles.Agent ? "app.unassignAgentRoleConfirmation" : "app.assignAgentRoleConfirmation"} />
        </DialogContentText>
        <DialogActions>
          <Button style={{ marginTop: 16 }} variant="outlined" onClick={(e) => onConfirm()}>
            <FormattedMessage id="app.ok" />
          </Button>
          <Button style={{ marginTop: 16 }} variant="outlined" onClick={(e) => onCancel()}>
            <FormattedMessage id="app.cancel" />
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default compose(connect((state) => ({})))(UserDetail);
