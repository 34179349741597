import {
  Button,
  CardContent,
  CircularProgress,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Delete from "@material-ui/icons/Delete";
import Email from "@material-ui/icons/Email";
import moment from "moment";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import Moment from "react-moment";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import * as actions from "../actions";
import {
  cancelWithdrawal,
  getAccount,
  getMyPendingWithdrawals,
  getStatement,
  getWithdraw,
  resendEmail,
} from "../actions";
import { getIncomePayments } from "../actions/Payment";
import ExpansionPanelIcon from "../custom/ExpansionPanelIcon";
import FixedPageItem from "../custom/FixedPageItem";
import FormDialog from "../custom/FormDialog";
import HeaderPageItem from "../custom/HeaderPageItem";
import InfoItem from "../custom/InfoItem";
import Number, {
  getLCTDisplayMode,
  LCTDisplayModes,
  setLCTConvertedCurrency,
  setLCTDisplayMode,
  setLCTRate,
} from "../custom/Number";
import PageContainer, { CardPageItem } from "../custom/Page";

class AdminStatementDataUser extends Component {
  state = {
    saque: {},
    step: false,
    step1: false,
    loading: true,
    extract: [],
    startDate: null,
    account: {},
    withdraw: {},
    payments: [],
    pendingAuthWithdrawals: [],
    openContractsPayments: [],
    closedContractsPayments: [],
  };
  currentMonth = moment();

  loadMonth() {
    let inicio = this.currentMonth.startOf("month").format("YYYY-MM-DD");
    let fim = this.currentMonth.endOf("month").format("YYYY-MM-DD");
    getStatement(inicio, fim).then((data) => {
      this.setState({
        extract: data,
        startDate: this.props.currentUser.createdAt,
      });
    });
  }

  loadAccount() {
    getAccount().then((data) => {
      this.setState({ account: data });
    });
    getWithdraw().then((data) => {
      this.setState({ pendingAuthWithdrawals: data });
    });
    getMyPendingWithdrawals().then((data) => {
      this.setState({ withdraw: data });
    });
    getIncomePayments().then((data) => {
      let incomingPaymentsTotal = 0;
      let futurePaymentsTotal = 0;
      let incomingPayments = [];
      let futurePayments = [];
      for (const p of data) {
        if (p.type == "PROFIT") {
          incomingPaymentsTotal += p.value;
          incomingPayments.push(p);
        } else {
          futurePaymentsTotal += p.value;
          futurePayments.push(p);
        }
      }
      this.setState({
        incomingPayments,
        futurePayments,
        incomingPaymentsTotal,
        futurePaymentsTotal,
        loading: false,
      });
    });
  }

  componentDidMount() {
    this.loadMonth();
    this.loadAccount();
    setLCTDisplayMode(LCTDisplayModes.Original);
  }

  cancel = (id) => {
    const { showSuccessMessage, showErrorMessage } = this.props;
    cancelWithdrawal(id)
      .then((data) => {
        showSuccessMessage("app.withdrawal.cancel");
        this.loadAccount();
      })
      .catch((e) => {
        showErrorMessage(e.response ? e.response.data.message : e);
      });
  };
  email = (id) => {
    const { showSuccessMessage, showErrorMessage } = this.props;
    resendEmail(id)
      .then((data) => {
        showSuccessMessage("app.email.resentSuccesfully");
        this.loadAccount();
      })
      .catch((e) => {
        showErrorMessage(e.response ? e.response.data.message : e);
      });
  };

  nextMonth() {
    this.currentMonth = moment(this.currentMonth.startOf("month").add(1, "M").format("YYYY-MM-DD"));
    let inicio = this.currentMonth.startOf("month").format("YYYY-MM-DD");
    let fim = this.currentMonth.endOf("month").format("YYYY-MM-DD");
    getStatement(inicio, fim).then((data) => {
      this.setState({ extract: data });
    });
  }

  previousMonth() {
    this.currentMonth = moment(this.currentMonth.startOf("month").subtract(1, "M").format("YYYY-MM-DD"));
    let inicio = this.currentMonth.startOf("month").format("YYYY-MM-DD");
    let fim = this.currentMonth.endOf("month").format("YYYY-MM-DD");
    getStatement(inicio, fim).then((data) => {
      this.setState({ extract: data });
    });
  }

  hasNext() {
    return this.currentMonth.isBefore(moment().startOf("month"));
  }

  hasPrevious() {
    return this.currentMonth.isAfter(moment(this.state.startDate).endOf("month"));
  }

  render() {
    const {
      extract,
      account,
      withdraw,
      saque,
      payments,
      pendingAuthWithdrawals,
      loading,
      incomingPayments,
      futurePayments,
      incomingPaymentsTotal,
      futurePaymentsTotal,
    } = this.state;
    if (loading) return <CircularProgress style={{ marginTop: "25%" }} />;
    return (
      <PageContainer>
        <HeaderPageItem title="app.account" onMore={(e) => this.setState({ menuAnchor: e.target })} />
        <Menu
          onClose={(e) => this.setState({ menuAnchor: undefined })}
          open={!!this.state.menuAnchor}
          anchorEl={this.state.menuAnchor}
        >
          <MenuItem component={Link} to={"/withdraw"} onClick={(e) => this.setState({ menuAnchor: undefined })}>
            <FormattedMessage id="app.newWithdraw" />
          </MenuItem>
        </Menu>

        <FixedPageItem position="top" height={45}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <IconButton onClick={(e) => this.previousMonth()} disabled={!this.hasPrevious()}>
              <ChevronLeftIcon />
            </IconButton>

            <Typography variant="h6">
              <Moment date={this.currentMonth} format="MMMM \d\e YYYY" />
            </Typography>

            <IconButton onClick={(e) => this.nextMonth()} disabled={!this.hasNext()}>
              <ChevronRightIcon />
            </IconButton>
          </div>
        </FixedPageItem>
        <FormDialog
          title="app.confirm"
          open={this.state.step}
          variant="secondary"
          noHeader
          onClose={(e) => this.setState({ step: false })}
        >
          <Typography style={{ marginBottom: 16 }}>
            <FormattedMessage id="app.cancelWithdrawalQuestion" />
          </Typography>
          <Typography style={{ marginBottom: 16 }}>
            <FormattedMessage id="app.data.checkout" />
          </Typography>
          <InfoItem
            caption="app.withdraw.amount"
            text={<Number value={saque.amount} currency="LCT" />}
            color="primary"
          />
          <InfoItem caption="app.wallet.destination" text={saque.address} />

          <Button
            fullWidth
            variant="contained"
            color="primary"
            style={{ marginTop: 16, marginBottom: 16 }}
            onClick={(e) => {
              this.cancel(saque.id);
              this.setState({ step: false });
            }}
          >
            <FormattedMessage id="app.ok" />
          </Button>
        </FormDialog>
        <FormDialog
          title="app.resendEmail.title"
          open={this.state.step1}
          variant="secondary"
          noHeader
          onClose={(e) => this.setState({ step1: false })}
        >
          <Typography style={{ marginBottom: 16 }}>
            <FormattedMessage id="app.resendEmail.text" />
          </Typography>

          <Button
            fullWidth
            variant="contained"
            color="primary"
            style={{ marginTop: 16, marginBottom: 16 }}
            onClick={(e) => {
              this.email(saque.id);
              this.setState({ step1: false });
            }}
          >
            <FormattedMessage id="app.ok" />
          </Button>
        </FormDialog>

        {pendingAuthWithdrawals && pendingAuthWithdrawals.length > 0 && (
          <div style={{ width: "100%" }}>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpansionPanelIcon />}>
                <Typography>
                  <FormattedMessage id="app.withdrawals.auth" />
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography variant="caption" color="textSecondary" style={{ width: "100%" }}>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td align="left">
                          <FormattedMessage id="app.date" />
                        </td>
                        <td align="center">
                          <FormattedMessage id="app.camel.amount" />
                        </td>
                        <td align="right">
                          <FormattedMessage id="app.cancel" />
                        </td>
                        <td align="right">
                          <FormattedMessage id="app.resend" />{" "}
                        </td>
                      </tr>
                      {pendingAuthWithdrawals.map((withdraw) => (
                        <tr key={withdraw.id} style={{ color: "textSecondary" }}>
                          <td align="left" style={{ color: "primary" }}>
                            <Moment date={withdraw.createdAt} format="DD/MM/YYYY" />
                          </td>

                          <td align="center">
                            <Number value={withdraw.amount} currency="LCT" />
                          </td>
                          <td align="right">
                            <IconButton
                              onClick={(e) => {
                                this.setState({ step: true, saque: withdraw });
                              }}
                            >
                              <Delete fontSize="small" />
                            </IconButton>
                          </td>
                          <td align="right">
                            <IconButton
                              onClick={(e) => {
                                this.setState({ step1: true, saque: withdraw });
                              }}
                            >
                              <Email fontSize="small" />
                            </IconButton>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        )}
        {withdraw && withdraw.length > 0 && (
          <div style={{ width: "100%" }}>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpansionPanelIcon />}>
                <Typography>
                  <FormattedMessage id="app.pendingWithdrawals" />
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography variant="caption" color="textSecondary" style={{ width: "100%" }}>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td align="left">
                          <FormattedMessage id="app.date" />
                        </td>
                        <td align="center">
                          <FormattedMessage id="app.camel.amount" />
                        </td>
                        <td align="right">
                          <FormattedMessage id="app.fee" />
                        </td>
                        <td align="right">
                          <FormattedMessage id="app.receiving" />
                        </td>
                      </tr>
                      {withdraw.map((withdraw) => (
                        <tr key={withdraw.id} style={{ color: "textSecondary" }}>
                          <td align="left" style={{ color: "primary" }}>
                            <Moment date={withdraw.createdAt} format="DD/MM/YYYY" />
                          </td>

                          <td align="center">
                            <Number value={withdraw.amount} currency="LCT" />
                          </td>
                          <td align="center">
                            <Number value={withdraw.fee} currency="LCT" />
                          </td>
                          <td align="right">
                            <Number value={withdraw.valueWithFee} currency="LCT" />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        )}
        {incomingPayments && incomingPayments.length > 0 && (
          <div style={{ width: "100%" }}>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpansionPanelIcon />}>
                <Typography>
                  <FormattedMessage id="app.increaseToRelease" /> (
                  <Number value={incomingPaymentsTotal} currency="LCT" />)
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography variant="caption" color="textSecondary" style={{ width: "100%" }}>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td align="left">
                          <FormattedMessage id="app.lccontract" />
                        </td>
                        <td align="center">
                          <FormattedMessage id="app.date" />
                        </td>
                        <td align="right">
                          <FormattedMessage id="app.camel.amount" />
                        </td>
                      </tr>
                      {incomingPayments.map((payment) => (
                        <tr key={payment.id} style={{ color: "textSecondary" }}>
                          <td align="left" style={{ color: "primary" }}>
                            C{payment.contract.id}
                          </td>
                          <td align="center" style={{ color: "primary" }}>
                            <Moment date={payment.paymentDate} format="DD/MM/YYYY" />
                          </td>
                          <td align="right">
                            <Number value={payment.value} currency="LCT" />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        )}
        {futurePayments && futurePayments.length > 0 && (
          <div style={{ width: "100%" }}>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpansionPanelIcon />}>
                <Typography>
                  <FormattedMessage id="app.futureReleases" /> (
                  <Number value={futurePaymentsTotal} currency="LCT" />)
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography variant="caption" color="textSecondary" style={{ width: "100%" }}>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td align="left">
                          <FormattedMessage id="app.lccontract" />
                        </td>
                        <td align="center">
                          <FormattedMessage id="app.date" />
                        </td>
                        <td align="right">
                          <FormattedMessage id="app.camel.amount" />
                        </td>
                      </tr>
                      {futurePayments.map((payment) => (
                        <tr key={payment.id} style={{ color: "textSecondary" }}>
                          <td align="left" style={{ color: "primary" }}>
                            C{payment.contract.id}
                          </td>
                          <td align="center" style={{ color: "primary" }}>
                            <Moment date={payment.paymentDate} format="DD/MM/YYYY" />
                          </td>
                          <td align="right">
                            <Number value={payment.value} currency="LCT" />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        )}
        {payments && payments.length > 0 && (
          <div style={{ width: "100%" }}>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpansionPanelIcon />}>
                <Typography>
                  <FormattedMessage id="app.payments.upcomings" />
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography variant="caption" color="textSecondary" style={{ width: "100%" }}>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td align="left">
                          <FormattedMessage id="app.date" />
                        </td>
                        <td align="right">
                          <FormattedMessage id="app.camel.amount" />
                        </td>
                      </tr>
                      {payments.map((payment) => (
                        <tr key={payment.id} style={{ color: "textSecondary" }}>
                          <td align="left" style={{ color: "primary" }}>
                            <Moment date={payment.paymentDate} format="DD/MM/YYYY" />
                          </td>
                          <td align="right">
                            <Number value={payment.value} currency="LCT" />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        )}
        {extract && extract.length > 0 ? (
          <div style={{ width: "100%", marginTop: 2 }}>
            <CardPageItem>
              <CardContent>
                <Typography variant="caption" color="textSecondary" style={{ width: "100%" }}>
                  <FormattedMessage id="app.camel.history" />
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td align="left">
                          <FormattedMessage id="app.date" />
                        </td>
                        <td align="center">
                          <FormattedMessage id="app.description" />
                        </td>
                        <td align="right">
                          <FormattedMessage id="app.camel.amount" />
                        </td>
                        <td align="right">
                          <FormattedMessage id="app.balance" />
                        </td>
                      </tr>
                      {extract.map((extract) => (
                        <tr key={extract.id} style={{ color: "textSecondary" }}>
                          <td align="left" style={{ color: "primary" }}>
                            <Moment date={extract.statementDate} format="DD" />
                          </td>
                          <td align="center" style={{ color: "primary" }}>
                            {extract.description}
                          </td>
                          <td align="right">
                            <Number
                              style={{
                                color: extract.type === "CREDIT" ? "green" : "red",
                              }}
                              value={extract.value}
                              currency="LCT"
                              display="none"
                            />
                          </td>
                          <td align="right">
                            <Number value={extract.balance} currency="LCT" display="none" />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Typography>
              </CardContent>
            </CardPageItem>
          </div>
        ) : (
          <CardPageItem style={{ textAlign: "center" }}>
            <Typography>
              <FormattedMessage id="app.noStatement" />
            </Typography>
          </CardPageItem>
        )}

        <FixedPageItem isCard position="bottom" height={90}>
          <CardContent>
            <InfoItem
              variant="h5"
              caption="app.balanceBlocked"
              extraCaption="app.camel.balanceAvailiable"
              text={<Number value={account.balanceBlocked ? account.balanceBlocked : 0} currency="LCT" />}
              extraText={<Number value={account.balance ? account.balance : 0} currency="LCT" />}
            />
          </CardContent>
        </FixedPageItem>
      </PageContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default compose(connect(mapStateToProps, actions))(AdminStatementDataUser);
