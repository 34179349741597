import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import Edit from "@material-ui/icons/Edit";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import Moment from "react-moment";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";
import { dispatchErrorMessage, dispatchSuccessMessage } from "../actions/Alert";
import { getContracts } from "../actions/Contract";
import { getUserBelowMe, getUsersBelowMe, toogleAgentRole } from "../actions/User";
import AbtcAvatar from "../custom/Avatar";
import FixedPageItem from "../custom/FixedPageItem";
import HeaderPageItem from "../custom/HeaderPageItem";
import Number from "../custom/Number";
import PageContainer, { CardPageItem, PageItem } from "../custom/Page";
import { capitalCaseNormalizer, diffDatedays, Roles, isAgentManagerOf, hasActualRole, isAgent, isPremiumContract } from "../util";
import UserDetail from "./components/UserDetail";

function AgentDataCustomer({ currentUser, match, history, dispatch }) {
  const [loading, setLoading] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [user, setUser] = useState({});
  const [customers, setCustomers] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [previousUserId, setPreviousUserId] = useState([]);
  const [menuAnchor, setMenuAnchor] = useState(undefined);

  const contractAgentStatus = currentUser.contractAgent ? currentUser.contractAgent.status : "NONE";

  useEffect(() => {
    let closure_user = {};
    getUserBelowMe(match.params.id)
      .then((userData) => {
        closure_user = userData;
        setUser(userData);
        return getContracts({
          userId: closure_user.id,
          consultantId: closure_user.consultant.id,
          taxResidence: currentUser.taxResidence,
        });
      })
      .then((contractsData) => {
        setContracts(contractsData);
        if (closure_user.role !== Roles.Agent) setLoading(false);
        return getUsersBelowMe({ consultantId: match.params.id });
      })
      .then((customersData) => {
        setCustomers(customersData.sort((u1, u2) => compApt(u1, u2) ?? compName(u1, u2)));
        setLoading(false);
      });
  }, [match.params.id, user.role]);

  const handleCustomerClick = (userId) => {
    previousUserId.push(match.params.id);
    setPreviousUserId(previousUserId);
    history.push("/customer/" + userId);
  };

  const goBack = () => {
    if (!previousUserId || previousUserId.length === 0) history.push("/customers");
    else {
      const userId = previousUserId.pop();
      setPreviousUserId(previousUserId);
      history.push("/customer/" + userId);
    }
  };

  if (loading) return <CircularProgress style={{ marginTop: "25%" }} />;

  return (
    <PageContainer>
      <PageItem>
        <HeaderPageItem title="app.customerInfo" showBackButton onBack={goBack} />
      </PageItem>

      <FixedPageItem position="top" variant="sticky">
        <Card>
          <CardHeader
            avatar={<AbtcAvatar imageUrl={user.photoUrl} disableLink />}
            title={`${capitalCaseNormalizer(user.name)} (${user.id})`}
            subheader={
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ flexGrow: 1 }}>
                  {user.email}{" "}
                  {!user.apt ? (
                    <span style={{ color: "red" }}>
                      <FormattedMessage id="app.inapt" />
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  {user.premium && (
                    <>
                      <Typography variant="caption" color="primary">
                        <FormattedMessage id="app.premium" />
                      </Typography>
                      <br />
                    </>
                  )}
                  <FormattedMessage id={"app.enuns." + user.role} />
                </div>
              </div>
            }
          />
        </Card>
        <Tabs variant="fullWidth" indicatorColor="primary" textColor="primary" value={tabIndex} onChange={(event, value) => setTabIndex(value)}>
          {user.role === Roles.Agent && <Tab label={<FormattedMessage id="app.camel.customers" />} />}
          <Tab label={<FormattedMessage id="app.lccontracts" />} />
          <Tab label={<FormattedMessage id="app.user" />} />
        </Tabs>
      </FixedPageItem>

      <TabContentsGroup value={tabIndex}>
        <TabContents hidden={user.role !== Roles.Agent}>
          {user.role === Roles.Customer && <></>}
          {user.role === Roles.Agent && (
            <PageItem>
              {customers.length === 0 ? (
                <Typography align="center">
                  <FormattedMessage id="app.customer.clients.empty" />
                </Typography>
              ) : (
                <UserCardList users={customers} onClick={handleCustomerClick} />
              )}
            </PageItem>
          )}
        </TabContents>

        <TabContents>
          {user.consultant.id === currentUser.id && user.role === Roles.Customer && (
            <CardPageItem>
              <CardContent>
                {contractAgentStatus === "ACTIVE" ? (
                  <div style={{ textAlign: "center" }}>
                    {!user.apt && (
                      <>
                        <Typography color="primary" variant="body1">
                          <FormattedHTMLMessage id="app.warning" />
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          <FormattedHTMLMessage id="app.customer.still.inapt" />
                        </Typography>
                      </>
                    )}
                    <Button disabled={!user.apt} variant="contained" color="primary" component={Link} to={"/newContract/" + user.id}>
                      <FormattedMessage id="app.newContract" />
                    </Button>
                  </div>
                ) : contractAgentStatus === "PENDING" ? (
                  <div style={{ textAlign: "center" }}>
                    <Typography color="primary" variant="body1">
                      <FormattedHTMLMessage id="app.warning" />
                    </Typography>
                    <Typography variant="body2">
                      <FormattedHTMLMessage id="app.contractAgent.detail.text1" />
                    </Typography>
                    <Button variant="contained" color="primary" component={Link} to={"/contractAgent/" + currentUser.id} style={{ marginTop: 16 }}>
                      <FormattedHTMLMessage id="app.contractAgent.approve" />
                    </Button>
                  </div>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <Typography color="primary" variant="body1">
                      <FormattedHTMLMessage id="app.warning" />
                    </Typography>
                    <Typography variant="body2">
                      <FormattedHTMLMessage id="app.contractAgent.detail.text1" />
                      <br />
                      <FormattedHTMLMessage id="app.administrator.contact.please" />
                    </Typography>
                  </div>
                )}
              </CardContent>
            </CardPageItem>
          )}
          <PageItem>
            {contracts.length === 0 ? (
              <Typography align="center">
                <FormattedMessage id="app.customer.contracts.empty" />
              </Typography>
            ) : (
              <ContractCardList list={contracts} dateField="createdAt" />
            )}
          </PageItem>
        </TabContents>

        <TabContents>
          <PageItem>
            <UserDetail currentUser={currentUser} user={user.id} hideAvatar></UserDetail>
          </PageItem>
        </TabContents>
      </TabContentsGroup>
    </PageContainer>
  );
}

function TabContentsGroup({ children, value }) {
  children = children.filter((c) => c && c.props && !c.props.hidden);
  if (typeof value !== "number" || value < 0 || value >= children.length) return null;
  return children[value];
}

function TabContents({ children }) {
  return children;
}

const compApt = (u1, u2) => (u1.apt === u2.apt ? null : u1.apt ? -1 : 1);
const compName = (u1, u2) => {
  const n1 = u1.name.toUpperCase();
  const n2 = u2.name.toUpperCase();
  return n1 === n2 ? null : n1 < n2 ? -1 : 1;
};

function UserCardList({ users, onClick }) {
  let currentApt = null;
  return (
    <List>
      {users.map((u) => {
        let subHeader = null;
        const apt = u.apt ? "apt" : "inapt";
        if (currentApt !== apt) {
          currentApt = apt;
          subHeader = (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "black",
                height: 32,
              }}
            >
              <Typography variant="body2" color="textSecondary">
                <FormattedMessage id={"app." + currentApt} />
              </Typography>
            </div>
          );
        }
        return (
          <Fragment key={u.id}>
            {subHeader}
            <ListItem key={u.id} button onClick={(e) => onClick(u.id)} component={Link} to={"/customer/" + u.id}>
              <ListItemAvatar>
                <AbtcAvatar imageUrl={u.photoUrl} disableLink />
              </ListItemAvatar>
              <ListItemText
                primary={`${capitalCaseNormalizer(u.name)} (${u.id})`}
                secondary={
                  <>
                    {u.email}{" "}
                    {!u.apt ? (
                      <span style={{ color: "red" }}>
                        <FormattedMessage id="app.inapt" />
                      </span>
                    ) : (
                      ""
                    )}
                  </>
                }
              />
              <ListItemSecondaryAction>
                <ChevronRight />
              </ListItemSecondaryAction>
            </ListItem>
          </Fragment>
        );
      })}
    </List>
  );
}

function contractProgress(contract) {
  const dateOpen = moment(contract.initialDate ? contract.initialDate : contract.createdAt);
  const dateClose = moment(contract.dueDate);
  let progress = diffDatedays(dateOpen);
  let days = moment.duration(dateClose.diff(dateOpen));
  let total = Math.round(days.asDays());
  return (progress * 100) / total;
}

const compStatusHelper = new Map();
compStatusHelper.set("INACTIVE", 0);
compStatusHelper.set("ACTIVE", 1);
compStatusHelper.set("ARCHIVE", 2);

const compPhaseHelper = new Map();
compPhaseHelper.set("WAITING_SIGN", 0);
compPhaseHelper.set("WAITING_APORT", 1);
compPhaseHelper.set("WAITING_VALIDATION", 2);
compPhaseHelper.set("VALIDATING", 3);

const compStatus = (c1, c2) => (c1.status === c2.status ? null : compStatusHelper.get(c1.status) - compStatusHelper.get(c2.status));
const compPhase = (c1, c2) => (c1.phase === c2.phase ? null : compPhaseHelper.get(c1.phase) - compPhaseHelper.get(c2.phase));
const compId = (c1, c2) => c1.id - c2.id;
const adjustPhase = (c) => (c.phase !== "WAITING_APORT" ? c.phase : c.deposits.length > 0 ? "WAITING_VALIDATION" : c.phase);

function ContractCardList({ list }) {
  let currentPhase = "";
  return (
    <List>
      {list &&
        list.length > 0 &&
        list
          .map((c) => ({ ...c, phase: adjustPhase(c) }))
          .sort((c1, c2) => compStatus(c1, c2) ?? compPhase(c1, c2) ?? compId(c1, c2))
          .map((c) => {
            const amountIsValid = c.amount && c.amount !== 1;
            let subHeader = null;
            const phase = c.phase;
            if (currentPhase !== phase) {
              currentPhase = phase;
              subHeader = (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "black",
                    height: 32,
                  }}
                >
                  <Typography variant="body2" color="textSecondary">
                    <FormattedMessage id={"app.enuns." + phase} />
                  </Typography>
                </div>
              );
            }
            return (
              <Fragment key={c.id}>
                {subHeader}
                <ListItem key={c.id} button component={Link} to={"/userContract/" + c.id} divider>
                  <ListItemText
                    disableTypography
                    primary={
                      <>
                        <div style={{ display: "flex", justifyContent: "start", alignItems: "baseline" }}>
                          <Typography color="primary" style={{ width: 55 }}>
                            C{c.id}
                          </Typography>
                          <Typography variant="caption" color="textSecondary">
                            {c.type.durationInMonth}M <FormattedMessage id={"app.enuns." + c.type.type} />
                          </Typography>
                          {isPremiumContract(c) && (
                            <Typography variant="caption" color="primary" style={{ marginLeft: 4 }}>
                              <FormattedMessage id={"app.premium"} />
                            </Typography>
                          )}

                          <div style={{ flexGrow: 1 }}></div>
                          <Typography variant="body1" color={amountIsValid ? "primary" : "textSecondary"}>
                            {amountIsValid ? <Number value={c.amount} currency="LCT" /> : <Number value={c.previsionAmount} currency="LCT" />}
                          </Typography>
                        </div>
                        {c.status === "ACTIVE" && (
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ width: 100 }}>
                              <Typography variant="caption" color="textSecondary">
                                <FormattedMessage id={"app.enuns." + c.phase} /> {Math.round(contractProgress(c), 2)}%
                              </Typography>
                              <LinearProgress variant="determinate" style={{ marginTop: 8, height: 10 }} value={contractProgress(c)} />
                            </div>
                            <div style={{ textAlign: "right" }}>
                              <Typography variant="caption" color="textSecondary">
                                <Moment date={c.initialDate} format="DD/MM/YYYY" />
                                <br />

                                <Moment date={c.dueDate} format="DD/MM/YYYY" />
                              </Typography>
                            </div>
                          </div>
                        )}
                        {c.status === "ARCHIVE" && (
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>
                              <Typography variant="caption" color="textSecondary">
                                <Number value={c.amount} currency="LCT" />
                                <br />
                                <Moment date={c.updatedAt} format="DD/MM/YYYY" />
                              </Typography>
                            </div>
                            <div>
                              <Typography variant="caption" color="textSecondary">
                                <FormattedMessage id={"app.enuns." + c.phase} />
                                <br />
                                <Moment date={c.updatedAt} format="DD/MM/YYYY" />
                              </Typography>
                            </div>
                          </div>
                        )}
                      </>
                    }
                  />
                  <ListItemSecondaryAction>
                    <ChevronRight />
                  </ListItemSecondaryAction>
                </ListItem>
              </Fragment>
            );
          })}
    </List>
  );
}

function ContractCardList2({ title, list, dateField }) {
  return (
    <>
      {list &&
        list.length > 0 &&
        list.map((c) => (
          <Card key={c.id} elevation={1} style={{ marginTop: 8 }}>
            <CardActionArea component={Link} to={"/userContract/" + c.id}>
              <CardContent>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="body1" color="primary">
                    <Number value={c.amount} currency="LCT" />
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    {c.phase}
                  </Typography>
                </div>

                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="caption" color="textSecondary">
                    C{c.id}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    <FormattedMessage id={"app.agent.dashboard.contract.initialDate"} /> <Moment date={c.initialDate} format="DD/MM/YYYY" />
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    <FormattedMessage id={"app.agent.dashboard.contract.dueDate"} /> <Moment date={c.dueDate} format="DD/MM/YYYY" />
                  </Typography>
                </div>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
    </>
  );
}

export default compose(
  connect((state) => ({ currentUser: state.user.user.me })),
  withRouter
)(AgentDataCustomer);
