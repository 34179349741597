import { Button, CardContent } from "@material-ui/core";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import * as actions from "../../actions";
import { updatePayment } from "../../actions/Payment";
import CustomInputForm, { CustomDatePicker } from "../../custom/CustomInput";

// VERIFICADO LCTInputMask - precisou chamar o getConfig.

class FormPayment extends Component {
  onSubmit = (formProps) => {
    const { showErrorMessage, onActionComplete } = this.props;
    formProps = { ...formProps, accountId: formProps.account };
    updatePayment(formProps.id, formProps)
      .then((response) => {
        onActionComplete();
      })
      .catch((e) => {
        showErrorMessage(e.response ? e.response.data.message : e);
      });
  };

  componentDidMount() {
    this.props.initialize(this.props.type);
  }

  render() {
    const { handleSubmit, config } = this.props;
    const lctMask = config.LCTInputMask();
    return (
      <CardContent>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <Field
            name="paymentDate"
            label="app.payday"
            InputLabelProps={{
              shrink: true,
            }}
            component={CustomDatePicker}
            disablePast={true}
          />
          <Field
            name="fine"
            label="app.fine"
            InputLabelProps={{
              shrink: true,
            }}
            component={CustomInputForm}
            {...lctMask}
          />
          <Field
            name="value"
            label="app.camel.amount"
            InputLabelProps={{
              shrink: true,
            }}
            component={CustomInputForm}
            {...lctMask}
          />

          <Button
            fullWidth
            id="bSubmitTypeForm"
            variant="contained"
            color="primary"
            type="submit"
            disabled={this.props.pristine || this.props.submitting || this.props.invalid}
          >
            <FormattedMessage id="app.button.save" />
          </Button>
        </form>
      </CardContent>
    );
  }
}

const validate = (values, props) => {
  const errors = {};
  if (!values.value) {
    errors.value = "app.value.report";
  }
  if (!values.paymentDate) {
    errors.paymentDate = "app.payday.mustBe.informed";
  }
  return errors;
};

function mapStateToProps(state) {
  return {
    config: state.config,
  };
}

export default compose(
  reduxForm({
    form: "formPayment",
    validate,
  }),
  connect(mapStateToProps, actions)
)(FormPayment);
