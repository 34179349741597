import { Button } from "@material-ui/core";
import CloudUpload from "@material-ui/icons/CloudUpload";
import React from "react";

//word: ".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
//images: "image/*",
//excel: ".xlsx,.xls,.csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"

function InputFile(field) {
  delete field.input.value; // <-- just delete the value property
  return (
    <div>
      <input accept={field.accept} style={{ display: "none" }} id="icon-button-photo" type="file" {...field.input} multiple={field.multiple} />
      <label htmlFor="icon-button-photo">
        <Button variant="contained" color="secondary" component="span">
          Upload
          <CloudUpload style={{ marginLeft: 10 }} />
        </Button>
      </label>
    </div>
  );
}
export default InputFile;
