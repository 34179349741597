import { Button, CardActions, CardContent, CircularProgress, IconButton, MenuItem } from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import React, { Fragment, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import CustomInputForm from "../custom/CustomInput";
import * as actions from "../actions";
import FormDialog from "../custom/FormDialog";
import HeaderPageItem from "../custom/HeaderPageItem";
import PageContainer, { CardPageItem } from "../custom/Page";
import { createNumberMask } from "redux-form-input-masks";

function ConfigContract({ showSuccessMessage, showErrorMessage }) {
  const [loading, setLoading] = useState(true);
  const [selectedSegment, setSelectedSegment] = useState();
  const [segments, setSegments] = useState([]);

  const loadSegments = () => {
    setLoading(true);
    actions
      .getInvestments()
      .then((segments) => {
        segments.sort((a, b) => a.id - b.id);
        setSegments(segments);
        setLoading(false);
      })
      .catch((e) => {
        showErrorMessage(e.message);
      });
  };

  const saveSegment = (segment) => {
    setLoading(true);
    actions
      .setInvestment(segment)
      .then((segment) => {
        setSelectedSegment(undefined);
        setLoading(false);
        loadSegments();
        showSuccessMessage("api.success");
      })
      .catch((r) => {
        showErrorMessage(r.response.data.message);
      });
  };

  useEffect(() => loadSegments(), []);

  return (
    <PageContainer>
      <HeaderPageItem title="app.segments" showBackButton destination="/more"></HeaderPageItem>
      {selectedSegment?.id}
      {loading ? (
        <CircularProgress />
      ) : (
        <SegmentListPageItem segments={segments} onEditClick={(s) => setSelectedSegment(s)} onCreateClick={() => setSelectedSegment({})} />
      )}

      <SegmentFormDialog open={!!selectedSegment} segment={selectedSegment} onClose={() => setSelectedSegment(undefined)} onSubmit={saveSegment} />
    </PageContainer>
  );
}

function SegmentListPageItem({ segments, onEditClick, onCreateClick }) {
  return (
    <CardPageItem>
      <CardContent>
        <table style={{ width: "100%" }}>
          <tbody>
            <SegmentHeaderRow />
            {segments.map((s) => (
              <SegmentRow key={s.id} segment={s} onEditClick={onEditClick} />
            ))}
          </tbody>
        </table>
      </CardContent>
      <CardActions>
        <Button fullWidth variant="contained" color="primary" onClick={(e) => onCreateClick()}>
          <FormattedMessage id="app.createNew" />
        </Button>
      </CardActions>
    </CardPageItem>
  );
}

function SegmentHeaderRow() {
  return (
    <tr>
      <td align="center">
        <FormattedMessage id="app.id" />
      </td>
      <td align="left">
        <FormattedMessage id="app.description" />
      </td>
      <td align="right">
        <FormattedMessage id="app.baseyield" />
      </td>
      <td align="right">
        <FormattedMessage id="app.commissionmodifier" />
      </td>
      <td align="right">
        <FormattedMessage id="app.premium" />
      </td>
      <td align="right">
        <FormattedMessage id="app.edit" />
      </td>
    </tr>
  );
}

function SegmentRow({ segment, onEditClick }) {
  return (
    <Fragment key={segment.id}>
      <tr>
        <td align="center">{segment.id}</td>
        <td align="left">{segment.description}</td>
        <td align="right">{segment.baseYield.toFixed(2)} %</td>
        <td align="right">{(segment.commissionModifier * 100).toFixed(2)} %</td>
        <td align="center">{segment.premium ? "Premium" : "--"}</td>
        <td align="right">
          <IconButton onClick={(e) => onEditClick(segment)}>
            <Edit />
          </IconButton>
        </td>
      </tr>
    </Fragment>
  );
}

function SegmentFormDialog({ open, segment, onClose, onSubmit }) {
  return (
    <FormDialog title={"EDIT / CREATE"} open={open} variant="secondary" noHeader onClose={(e) => onClose()}>
      <SegmentForm segment={segment} onSubmit={onSubmit} />
    </FormDialog>
  );
}

function SegmentForm_({ segment, onSubmit, handleSubmit, initialize, pristine, submitting, invalid }) {
  useEffect(() => {
    console.log("INIT FORM", segment);
    initialize(segment);
  }, []);

  return (
    <CardContent>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Field
          name="id"
          label="app.id"
          InputLabelProps={{
            shrink: true,
          }}
          component={CustomInputForm}
          disabled={true}
        />
        <Field
          name="description"
          label="app.description"
          InputLabelProps={{
            shrink: true,
          }}
          component={CustomInputForm}
        />
        <Field
          name="baseYield"
          label="app.baseYield"
          InputLabelProps={{
            shrink: true,
          }}
          component={CustomInputForm}
        />
        <Field
          name="commissionModifier"
          label="app.commissionModifier"
          InputLabelProps={{
            shrink: true,
          }}
          component={CustomInputForm}
        />
        <Field
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          select
          name="premium"
          label="app.premium"
          component={CustomInputForm}
        >
          <MenuItem value={false}>
            <FormattedMessage id={`app.general`} />
          </MenuItem>
          <MenuItem value={true}>
            <FormattedMessage id={`app.premium`} />
          </MenuItem>
        </Field>

        <Button fullWidth id="bSubmitSegmentForm" variant="contained" color="primary" type="submit" disabled={pristine || submitting || invalid}>
          <FormattedMessage id="app.button.save" />
        </Button>
      </form>
    </CardContent>
  );
}

const validateSegmentForm = (values, props) => {
  const errors = {};
  if (!values.description) {
    errors.description = "app.field.fillIn";
  } else if (values.description.trim().length == 0) {
    errors.description = "app.description.invalid";
  }

  if (values.baseYield <= 0) {
    errors.baseYield = "app.greaterThanZero";
  }

  if (values.commissionModifier <= 0) {
    errors.commissionModifier = "app.greaterThanZero";
  } else if (values.commissionModifier >= 3) {
    errors.commissionModifier = "app.valueTooHigh";
  }
  return errors;
};

const SegmentForm = compose(
  reduxForm({
    form: "SegmentForm",
    validate: validateSegmentForm,
  }),
  connect(null, actions)
)(SegmentForm_);

export default compose(connect(null, actions))(ConfigContract);
